import React, { useMemo } from 'react';
import StatusCircle from './StatusCircle';
import { getHoldingStatusClass } from '../MapControl/Helpers/GlobalHelper';

const HoldingLineStatus = ({ line }) => {

    // if (line.item.itemID === 54083)
    //console.log('line', line);




    return useMemo(() => {

        const itemClass = getHoldingStatusClass(line.itemIssue, line.itemWarning, line.active);
        const updateClass = getHoldingStatusClass(line.updateIssue, line.updateWarning, line.active);

        return (
            <React.Fragment>
                <StatusCircle className={itemClass} />
                <StatusCircle className={updateClass} />
            </React.Fragment>
        );

    }, [line]);
}


export default HoldingLineStatus
