import React from 'react';
import { Route } from "react-router-dom";
import Shipments from './Shipments/Shipments';
import VesselShipmentDetail from './Shipments/VesselShipmentDetail/VesselShipmentDetail';

// Import General Support Files
import Breadcrumb from '../Navigation/Breadcrumb';

const ShipmentTracking = () => {

    return (
        <React.Fragment>
            <Breadcrumb Module="Order Management" currentPage="Shipment Tracking" />

            <Route exact path="/sales/shipments" component={Shipments} />
            <Route exact path="/sales/shipments/:id/:lineID?" component={VesselShipmentDetail} />

        </React.Fragment>
    );
};

export default ShipmentTracking;
