import React, { useState } from 'react';
import { Popup } from 'react-leaflet';
import { List, ListItem, ListItemText, ListItemSecondaryAction, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import OrderLineStatus from '../../Widgets/OrderLineStatus';

const MapPopupSalesOrderLines = ({ popupInfo }) => {


    const [closeKey, setCloseKey] = useState(0);
    // console.log('popupInfo', popupInfo);
    return popupInfo.position && closeKey !==popupInfo.key ? (
        <Popup
            key={popupInfo.key}
            position={popupInfo.position}
            
        >
            <List dense={true} disablePadding style={{ border: '1px solid gray' }}>
                {
                    popupInfo.lines.map(line => {
                        return (
                            <Link
                                key={line.salesOrderLineID}
                                className="ListItemLink"
                                component={RouterLink}
                                to={`/sales/orders/${line.salesOrderID}/${line.salesOrderLineID}`}
                                color='inherit'
                                style={{ textDecoration: 'none', color: 'inherit' }}
                                onClick={() => setCloseKey(popupInfo.key)}
                            >
                                <ListItem button disableRipple dense divider>

                                    <OrderLineStatus line={line} />
                                    {/* <Chip size="small" label="O" /> */}
                                    <ListItemText primary={`${line.item.itemNumber} ${line.item.itemTitle}`} style={{ marginRight: '10px' }} />

                                    <ListItemSecondaryAction>
                                        <ListItemText style={{}} primary={(line.linePrice ? line.linePrice.toFixed(2) : '')} />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </Link>
                        );
                    })
                }
            </List>
        </Popup>

    ) : null;
};

export default MapPopupSalesOrderLines;
