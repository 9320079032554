import React, { useState } from 'react';
import { Checkbox } from '@material-ui/core';

const OrderStatusFilter = ({ filterChanged }) => {

    const [filter, setFilter] = useState([1, 3, 4, 5]);

    const handleChange = (e) => {
        const val = +e.target.value;

        setFilter(prev => {
            const newValues = prev.includes(val) ? prev.filter(X => X !== val) : [...prev, val];
            filterChanged(newValues);
            return newValues;
        });
    };

    return (
        <React.Fragment>
            <Checkbox value={1} className="statusGray hoverable" checked={filter.includes(1)} onChange={handleChange} />
            <Checkbox value={3} className="statusOrange hoverable" checked={filter.includes(3)} onChange={handleChange} />
            <Checkbox value={4} className="statusGreen hoverable" checked={filter.includes(4)} onChange={handleChange} />
            <Checkbox value={5} className="statusRed hoverable" checked={filter.includes(5)} onChange={handleChange} />
        </React.Fragment>
    );
};

export default OrderStatusFilter;
