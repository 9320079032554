import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import ContentColumn from '../../../../Custom/ContentColumn';
import { Typography, Paper, Table, TableHead, TableRow, TableCell, TableBody, TextField, Button, TableFooter } from '@material-ui/core';
import { useBasketStore } from '../../../../../Global/Stores/basketStore';
import { useGlobalStore } from '../../../../../Global/Stores/globalStore';
import _ from 'lodash';

const CatalogueBasketConfirm = () => {
    const [basketState,] = useBasketStore();
    const { basketLines } = basketState;


    if (basketLines.length === 0) {
        return <Redirect to="/sales/catalogue/add/" />
    }

    return (
        <ContentColumn loaded={true}>
            <div className="contentColumnHeader" style={{ padding: '0px 10px' }}>
                <Typography variant="h6">Basket Confirmation</Typography>
            </div>

            <div className="contentColumnBody">
                <BasketSummary basketLines={basketLines} />
                <BasketReference />
                <Typography variant="subtitle2">
                    Please note the order will be processed immediatly and can not be undone
                </Typography>
            </div>

            <div className="contentColumnFooter">
                <BasketOrderButton />
            </div>
        </ContentColumn>
    );
}


export const BasketSummary = ({ basketLines }) => {
    const [globalState, globalActions] = useGlobalStore();
    const { productGroups } = globalState;


    if (!Boolean(productGroups))
        return null;


    const processLines = basketLines

    return (
        <Paper square elevation={0} style={{ margin: '6px 0px', border: '1px solid black' }}>
            <Table size="small" aria-label="Order Summary">
                <TableHead>
                    <TableRow>
                        <TableCell>Product</TableCell>
                        <TableCell align="center">Qty</TableCell>
                        <TableCell >Price</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    {/* <TableRow> dfgdfgdfg</TableRow> */}
                    {
                        productGroups.map(PG => {

                            const PGID = PG.productGroupID;
                            const caption = PG.caption;

                            // const lines = basketLines.filter(X => X.item.productGroupID === PGID);
                            const lines = basketLines.filter(X => (X.productGroupID || X.item.productGroupID) === PGID);

                            if (lines.length === 0)
                                return null;


                            const QTY = _.sumBy(lines, L => (L.quantity || 1));
                            const P = _.sumBy(lines, 'itemPrice.price').toFixed(2);

                            const currencyID = lines[0].itemPrice.currencyID;
                            const currency = globalActions.getCurrency(currencyID);

                            return (
                                <TableRow key={PGID}>
                                    <TableCell component="th" scope="row">{caption}</TableCell>
                                    <TableCell align="center">{QTY}</TableCell>
                                    <TableCell>{`${currency.code} ${P}`}</TableCell>
                                </TableRow>
                            )

                        })
                    }
                </TableBody>
            </Table>
        </Paper>
    );
}


const BasketReference = () => {

    const [basketState, basketActions] = useBasketStore();
    const reference = basketState.reference || "";


    return React.useMemo(() => {

        // console.log('BasketReference', reference);

        const handleReferenceChange = (e) => {
            const newValue = e.target.value;
            // console.log('handleReferenceChange', newValue);
            basketActions.setReference(newValue);
        }

        return (<TextField fullWidth
            margin="dense"
            variant="outlined"
            // placeholder="Reference" 
            label="Basket reference"
            value={reference}
            onChange={handleReferenceChange}
        />);

    }, [reference, basketActions]);
}


const BasketOrderButton = () => {

    const [basketState, basketActions] = useBasketStore();

    const { vesselID, reference, basketLines, ordered } = basketState;
    const pricesLoaded = basketActions.hasBasketPrices();

    const canOrder = pricesLoaded && basketLines.length > 0 && reference.length > 0 && vesselID > 0;

    useEffect(() => {
        if (ordered === true) {
            basketActions.clearData();
        }


    }, [ordered, basketActions])

    return React.useMemo(() => {

        const handleBasketOrder = () => {
            // console.log('handleBasketOrder');
            basketActions.orderBasket();
        }

        return (
            <Button
                variant="contained"
                size="small"
                disabled={!canOrder}
                onClick={handleBasketOrder}
            >Confirm Order</Button>
        );
    }, [canOrder, basketActions])
}



export default CatalogueBasketConfirm
