import React, { useState, useEffect } from 'react';
import { Typography, List } from '@material-ui/core';
import { AnnouncementLine } from '../Home/Summaries/AnnouncementSummary';
import ContentColumn from '../../Custom/ContentColumn';
import Loading from '../Misc/Loading';
import { useGlobalStore } from '../../../Global/Stores/globalStore';

const NewsList = ({ match }) => {
    const [globalState, globalActions] = useGlobalStore();
    const newsItems = globalState.newsItems || [];
    const selectedID = +match.params.newsID;
    const loaded = Boolean(newsItems.length);


    useEffect(() => {
        globalActions.getAnnouncements();
    }, []);

    return (
        <ContentColumn loaded={loaded}>
            <div className="contentColumnHeader" style={{ padding: '0px 10px' }}>
                <Typography variant="h6">{`News & Announcements`}</Typography>
            </div>

            <div className="contentColumnBody">
                {
                    loaded ? (
                        <List component="div">
                            {
                                newsItems.map(X => (<AnnouncementLine key={X.chartagentNewsID} newsItem={X} selected={selectedID === X.chartagentNewsID} />))
                            }
                        </List>
                    ) : <Loading />
                }
            </div>
        </ContentColumn>
    );
}

export default NewsList
