import React from 'react'

// Maetrial UI support files
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Breadcrumb from '../Navigation/Breadcrumb';

import FAQFile from '../../../Global/Data/FAQs';

const FAQs = () => {
    return(
        <React.Fragment>
            <Breadcrumb Module="Help &amp; Support" currentPage="FAQs" />
            <div style={{margin: 10}}>
                <h1>Frequently Asked Questions</h1>
                {FAQFile.Account.map(FAQ => (
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography>{FAQ.Question}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                    <Typography>
                        {FAQ.Answer}
                    </Typography>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                ))}
            </div>
        </React.Fragment>
    )
}

export default FAQs