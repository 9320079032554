import React, { useEffect } from 'react';
import SearchFilter from '../../Misc/SearchFilter';
import Loading from '../../Misc/Loading';
import VesselShipments from './VesselShipments';
import { useGlobalStore } from '../../../../Global/Stores/globalStore';
import { useSaleStore } from '../../../../Global/Stores/saleStore';
import ContentColumn from '../../Custom/ContentColumn';
import _ from 'lodash';
import { List, Divider } from '@material-ui/core';

const Shipments = ({ match }) => {
    const [globalState,] = useGlobalStore();
    const [saleState, salesActions] = useSaleStore();

    const [filter, setFilter] = React.useState('');
    const [sort, setSort] = React.useState(1);

    const salesShipmentID = +match.params.id || 0;

    useEffect(() => {
        salesActions.getVesselShipments();
    }, []);

    let vessels = _.orderBy(globalState.vessels, [B => B.vesselName.toLowerCase()], [sort > 0 ? 'asc' : 'desc']);


    const shipments = saleState.vesselShipments;

    const loaded = (vessels !== null) && (shipments !== null);
    if (loaded === true) {
        vessels = vessels.filter(X => X.vesselName.toLowerCase().indexOf(filter) !== -1);
    }

    // const onSearch = (text) => {
    //     setFilter(() => { return text.toLowerCase(); })
    // };

    return (
        <ContentColumn loaded={loaded}>
            <div className="contentColumnHeader"  style={{ padding: '10px 0px', margin: "-10px 0px 0px 0px", width: '100%', backgroundColor: '#fff', zIndex: 1, position: 'absolute' }}>
                <SearchFilter placeholder="Search Vessel" onSearch={(F) => setFilter(() => F.toLowerCase())} onSort={(S) => setSort(() => S)} />
            </div>

            <div className="contentColumnBody disablePadding">
                <br /><br /><br />
                {
                    loaded ? (
                        <List component="div">
                            {
                                vessels.map(X => (<VesselShipments key={X.vesselID} vessel={X} selectedsalesShipmentID={salesShipmentID} />))
                            }
                            <Divider />
                        </List>
                    )
                        :
                        <Loading />
                }
                <br /><br /><br />
            </div>
        </ContentColumn>
    );
};

export default Shipments;