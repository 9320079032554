const FAQs = 
    {
        Account: [
            { 
                ID: 1,
                Active: true, // Bolean
                Question: "Why Can't I log into my account on more than one device?",
                Answer: "The Ship manager system involves very sensitive data that needs to be accessed on a per need base. The system keeps logs of all activities and as such logs must be tagged to a user. and a user can only use one system at a time.",
            },
            { 
                ID: 2,
                Active: true, // Bolean
                Question: "Can I add users?",
                Answer: "Yes! But you would need to be an administrator with 'Create User' Permissions from your company",
            },
            { 
                ID: 1,
                Active: true, // Bolean
                Question: "I have approved an order but vessel has not received data",
                Answer: "This could be as a result of the vessel not having all installations completed.",
            },
        ],
    }

export default FAQs

