import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Typography, Paper, Table, TableHead, TableRow, TableCell, TableBody, TextField, Button, TableFooter } from '@material-ui/core';
import ContentColumn from '../../../Custom/ContentColumn';
import { BasketSummary } from '../Catalogue/Basket/CatalogueBasketConfirm';
import { useAvcsoStore } from '../../../../Global/Stores/avcsoStore';
import _ from 'lodash';

const AVCSO_BasketConfirm = ({ history }) => {
    const [avcsoState] = useAvcsoStore();
    const { basketLines, ordered } = avcsoState;


    useEffect(() => {
        if (ordered === true) {
            history.push(`/sales/avcso/add/`);
        }
    }, [ordered]);


    if (basketLines.length === 0) {
        return <Redirect to="/sales/avcso/add/" />
    }

    return (
        <ContentColumn loaded={true}>
            <div className="contentColumnHeader" style={{ padding: '0px 10px' }}>
                <Typography variant="h6">AVCSO Confirmation</Typography>
            </div>

            <div className="contentColumnBody">
                <BasketSummary basketLines={basketLines} />
                <BasketReference />
                <Typography variant="subtitle2">Please note the order will be processed immediatly and can not be undone</Typography>
            </div>

            <div className="contentColumnFooter">
                <BasketOrderButton />
            </div>
        </ContentColumn>
    );
};


const BasketReference = () => {

    const [avcsoState, avcsoActions] = useAvcsoStore();
    const { reference } = avcsoState;

    return React.useMemo(() => {

        // console.log('BasketReference', reference);

        const handleReferenceChange = (e) => {
            const newValue = e.target.value;
            // console.log('handleReferenceChange', newValue);
            avcsoActions.setReference(newValue);
        }

        return (
            <TextField fullWidth
                margin="dense"
                variant="outlined"
                // placeholder="Reference" 
                label="Basket reference"
                value={reference}
                onChange={handleReferenceChange}
            />);

    }, [reference, avcsoActions]);
}



const BasketOrderButton = () => {

    const [avcsoState, avcsoActions] = useAvcsoStore();

    const { reference, basketLines, ordered } = avcsoState;
    const pricesLoaded = avcsoActions.hasBasketPrices();

    const canOrder = pricesLoaded && basketLines.length > 0 && reference.length > 0;

    useEffect(() => {
        if (ordered === true) {
            console.log('clearData');
            avcsoActions.clearData();
        }


    }, [ordered, avcsoActions])

    return React.useMemo(() => {

        const handleBasketOrder = () => {
            // console.log('handleBasketOrder');
            avcsoActions.orderBasket();
        }

        return (
            <Button
                variant="contained"
                size="small"
                disabled={!canOrder}
                onClick={handleBasketOrder}
            >Confirm Order</Button>
        );
    }, [canOrder, avcsoActions])
}

export default AVCSO_BasketConfirm;
