import React from 'react';
import { Route } from "react-router-dom";

// Import Sales catalogue/order support files
import Catalogue from './Basket/Catalogue';
// import CatalogueBasketReview from './Basket/CatalogueBasketReview';
// import CatalogueSelectVessel from './Basket/CatalogueSelectVessel';
// import CatalogueSelectItems from './Basket/CatalogueSelectItems';
// import CatalogueBasketConfirm from './Basket/CatalogueBasketConfirm';

// Import General Support Files
import Breadcrumb from '../Navigation/Breadcrumb';

// Component Description: Holds the whole Sales/Order Management Module
const Sales = () => {

    return (
        <React.Fragment>
            <Breadcrumb Module="Order Management" currentPage="New Order" />
            <div><br /><br />
                <Route exact path={["/sales/", "/sales/catalogue", "/sales/catalogue/:vesselID?", "/sales/catalogue/:vesselID/add/:itemID?", "/sales/catalogue/:vesselID/basket/:itemID?", "/sales/catalogue/:vesselID/confirm/:itemID?" ]} component={Catalogue} />
                {/* <Route exact path="/sales/catalogue/:vesselID?" component={CatalogueSelectVessel} />
                <Route exact path="/sales/catalogue/:vesselID/add/:itemID?" component={CatalogueSelectItems} />
                <Route path="/sales/catalogue/:vesselID/basket/:itemID?" component={CatalogueBasketReview} />
                <Route path="/sales/catalogue/:vesselID/confirm/:itemID?" component={CatalogueBasketConfirm} /> */}
            </div>
        </React.Fragment>
    );
};

export default Sales;
