import React, { useState, useEffect } from 'react';
import { Checkbox, MenuItem, Select, Input, ListItemText, FormControl } from '@material-ui/core';

const status = {
    valid: true,
    warning: true,
    issue: true,
    inactive: false,
};



const HoldingsFilter = ({ fleetActions, productItemGroups, selectedGroups }) => {

    const [statusFilter, setStatusFilter] = useState(status);
    const [scaleFilter, setScaleFilter] = useState(productItemGroups.map(X => X.productItemGroupID));

    const productGroupID = selectedGroups[0] || 0;
    // console.log('productItemGroups', productItemGroups);
    // console.log('selectedGroups', );
    // console.log('scaleFilter', scaleFilter);
    // const [personName, setPersonName] = React.useState([]);

    useEffect(() => {
        setScaleFilter(_ => productItemGroups.map(X => X.productItemGroupID));
        setStatusFilter(_ => status);
    }, [productItemGroups]);


    useEffect(() => {
        fleetActions.filterHoldingDetail({ productGroupID: productGroupID, scale: scaleFilter, status: statusFilter });
    }, [productGroupID])

    const statusChange = (e) => {
        const name = e.target.name;

        setStatusFilter(prev => {

            const newFilter = { ...prev };
            newFilter[name] = !newFilter[name];
            //console.log('newFilter', name, newFilter);
            fleetActions.filterHoldingDetail({ productGroupID: productGroupID, scale: scaleFilter, status: newFilter })
            return newFilter;
        });
    };

    const scaleChange = (e) => {
        const val = e.target.value;
        setScaleFilter(() => {
            const newScales = [...val];
            fleetActions.filterHoldingDetail({ productGroupID: productGroupID, scale: newScales, status: statusFilter });
            return newScales;
        });
    };

    const selectableProductItemGroups = selectedGroups.length > 0 ? productItemGroups.filter(X => X.productGroupID === selectedGroups[0]) : productItemGroups;
    const selectedPIGs = selectableProductItemGroups.filter(X => scaleFilter.includes(X.productItemGroupID)).length;

    return (
        <React.Fragment>
            <div>
                {/* OK, Warning, Error */}
                <Checkbox name="valid" value={1} className="statusGreen hoverable" checked={statusFilter.valid} onChange={statusChange} title='Valid' />
                <Checkbox name="warning" value={2} className="statusOrange hoverable" checked={statusFilter.warning} onChange={statusChange} title='Warnings' />
                <Checkbox name="issue" value={3} className="statusRed hoverable" checked={statusFilter.issue} onChange={statusChange} title='Issues' />
                <Checkbox name="inactive" value={3} className="statusGray hoverable" checked={statusFilter.inactive} onChange={statusChange} title='Inactive' />
            </div>
            {/* <Button color="primary" variant="outlined" size="small">Scale</Button> */}
            {/* <HoldingsFilterScale /> */}

            <FormControl>
                <Select
                    multiple
                    displayEmpty
                    value={scaleFilter}
                    renderValue={() => <div>Scale [{selectedPIGs}/{selectableProductItemGroups.length}]</div>}
                    // rowsMax={10}
                    onChange={scaleChange}
                    input={<Input placeholder="Scale" id="select-multiple" style={{ width: '125px' }} />}
                >
                    {
                        selectableProductItemGroups.map(PG => (
                            <MenuItem key={PG.productItemGroupID} value={PG.productItemGroupID}>
                                <Checkbox
                                    checked={scaleFilter.includes(PG.productItemGroupID)}
                                    color="primary" />
                                <ListItemText primary={PG.caption} />
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>

        </React.Fragment>
    );
};




export default HoldingsFilter;
