import React from 'react';
import List from '@material-ui/core/List';
import ListItemLink from '../Misc/ListItemLink';
import ContentColumn from '../../Custom/ContentColumn';
import img from "./../../../Images/summaries/icon-fleetmanagment.png";
import SummaryCardHeader from '../Home/Summaries/SummaryCardHeader';

const FleetOverview = () => {
    // const [orderState] = useSaleStore();
    return (
        <ContentColumn loaded={true} access={true}>
            <div className="contentColumnHeader">
                {/* <CommentIcon />
                <Typography className="mainListHeader" component="h1" variant="h5">Fleet Management</Typography> */}
                <SummaryCardHeader title="Fleet Management" image={img} justify="center" />
            </div>

            <div className="contentColumnBody">
                <List component="div" disablePadding>
                    <ListItemLink text="Fleet Holdings" to="/fleet/holdings" />
                    <ListItemLink text="Fleet Status" to="/fleet/status" />
                    {/* <ListItemLink text="Fleet Tracking" to="/fleet/tracking" /> */}
                    <ListItemLink text="Fleet Overview" to="/fleet/overview" />
                    <ListItemLink text="Navigator Updates" to="/fleet/navigator" />
                    <ListItemLink text="Folio Status" to="/folios/status" />
                    {/* <ListItemLink text="Routing" to="/fleet/routing" /> */}
                </List>
            </div>
        </ContentColumn>
    );
};

export default FleetOverview;
