import React, { } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton, Link } from '@material-ui/core';

const CloseButtonLink = ({ to }) => {
    return (
        <Link to={to} component={RouterLink} className="navLink" style={{ color: 'inherit', textDecoration: 'none' }} >
            <IconButton>
                <CloseIcon />
            </IconButton>
        </Link>
    )
}

export default CloseButtonLink
