
export const DisplayParameters =
{
    "DisplayParameters": {
        "ECDISParameters": {
            "version": "10.6.1",
            "DynamicParameters": {
                "Parameter": [
                    {
                        "name": "AreaSymbolizationType",
                        "caption": "Area symbolization",
                        "type": "E",
                        "default": 1,
                        "value": 2,
                        "Description": "Area symbolization: Sets type of symbology for area boundaries.",
                        "ExpectedInput": [
                            {
                                "code": 1,
                                "value": "Plain boundaries"
                            },
                            {
                                "code": 2,
                                "value": "Symbolized boundaries"
                            }
                        ]
                    },
                    // {
                    //     "name": "AttDesc",
                    //     "caption": "Attribute description",
                    //     "type": "E",
                    //     "default": 1,
                    //     "value": 1,
                    //     "Description": "Attribute description: Display attribute acronym or description name.",
                    //     "ExpectedInput": [
                    //         {
                    //             "code": 1,
                    //             "value": "Acronym"
                    //         },
                    //         {
                    //             "code": 2,
                    //             "value": "Description"
                    //         }
                    //     ]
                    // },
                    {
                        "name": "ColorScheme",
                        "caption": "Color Scheme",
                        "type": "E",
                        "default": 0,
                        "value": 0,
                        "Description": "Colour schemes.",
                        "ExpectedInput": [
                            {
                                "code": 0,
                                "value": "DAY"
                            },
                            {
                                "code": 1,
                                "value": "DAY_BRIGHT"
                            },
                            {
                                "code": 2,
                                "value": "DAY_BLACKBACK"
                            },
                            {
                                "code": 3,
                                "value": "DAY_WHITEBACK"
                            },
                            {
                                "code": 4,
                                "value": "DUSK"
                            },
                            {
                                "code": 5,
                                "value": "NIGHT"
                            }
                        ]
                    },
                    {
                        "name": "CompassRose",
                        "caption": "Compass Rose",
                        "type": "E",
                        "default": 1,
                        "value": 1,
                        "Description": "Display Compass Rose.",
                        "ExpectedInput": [
                            {
                                "code": 1,
                                "value": "False"
                            },
                            {
                                "code": 2,
                                "value": "True"
                            }
                        ]
                    },
                    {
                        "name": "DataQuality",
                        "caption": "Quality of Data Symbology",
                        "type": "E",
                        "default": 1,
                        "value": 1,
                        "Description": "Data quality: M_QUAL symbology.",
                        "ExpectedInput": [
                            {
                                "code": 1,
                                "value": "False"
                            },
                            {
                                "code": 2,
                                "value": "True"
                            }
                        ]
                    },
                    {
                        "name": "DeepContour",
                        "caption": "Depth Contour (meters)",
                        "type": "F",
                        "default": 30,
                        "value": 30,
                        "Description": "Deep Contour: Set depth of the deep contour value in meters."
                    },
                    {
                        "name": "DisplayAIOFeatures",
                        "caption": "Display AIO features.",
                        "type": "L",
                        "default": "1,2,3,4,5,6,7",
                        "value": "1,2,3,4,5,6,7",
                        "Description": "Display AIO features.",
                        "ExpectedInput": [
                            {
                                "code": 1,
                                "value": "Temporary notice"
                            },
                            {
                                "code": 2,
                                "value": "Preliminary notice"
                            },
                            {
                                "code": 3,
                                "value": "ENC Preliminary notice"
                            },
                            {
                                "code": 4,
                                "value": "No information"
                            },
                            {
                                "code": 5,
                                "value": "Text"
                            },
                            {
                                "code": 6,
                                "value": "Bounding box"
                            },
                            {
                                "code": 7,
                                "value": "Area pattern"
                            }
                        ]
                    },
                    {
                        "name": "DisplayCategory",
                        "type": "L",
                        "default": "1,2,4",
                        "value": "1,2,4",
                        "Description": "Display categories.",
                        "ExpectedInput": [
                            {
                                "code": 1,
                                "value": "DISPLAYBASE"
                            },
                            {
                                "code": 2,
                                "value": "STANDARD"
                            },
                            {
                                "code": 4,
                                "value": "OTHER"
                            }
                        ]
                    },
                    {
                        "name": "DisplayDepthUnits",
                        "caption": "Depth unit",
                        "type": "E",
                        "default": 1,
                        "value": 1,
                        "Description": "Depth units: Display depth units in different values.",
                        "ExpectedInput": [
                            {
                                "code": 1,
                                "value": "Meters"
                            },
                            {
                                "code": 2,
                                "value": "Feet"
                            },
                            {
                                "code": 3,
                                "value": "Fathoms"
                            }
                        ]
                    },
                    {
                        "name": "DisplayFrames",
                        "caption": "Frame Visibility",
                        "type": "E",
                        "default": 3,
                        "value": 1,
                        "Description": "Display frames: Sets dataset extent visability.",
                        "ExpectedInput": [
                            {
                                "code": 1,
                                "value": "On"
                            },
                            {
                                "code": 2,
                                "value": "Off"
                            },
                            {
                                "code": 3,
                                "value": "Automatic"
                            },
                            {
                                "code": 4,
                                "value": "Visible Only"
                            }
                        ]
                    },
                    {
                        "name": "DisplayFrameText",
                        "caption": "Frame Text",
                        "type": "E",
                        "default": 0,
                        "value": 1,
                        "Description": "Display dataset information on dataset frames.",
                        "ExpectedInput": [
                            {
                                "code": 0,
                                "value": "Off"
                            },
                            {
                                "code": 1,
                                "value": "DSNM"
                            },
                            {
                                "code": 2,
                                "value": "CSCL"
                            },
                            {
                                "code": 3,
                                "value": "DSNM over CSCL"
                            }
                        ]
                    },
                    {
                        "name": "DisplayFrameTextPlacement",
                        "caption": "Frame Text Placement",
                        "type": "E",
                        "default": 1,
                        "value": 1,
                        "Description": "Display frame text placement.",
                        "ExpectedInput": [
                            {
                                "code": 1,
                                "value": "Upper left"
                            },
                            {
                                "code": 2,
                                "value": "Centroid"
                            }
                        ]
                    },
                    {
                        "name": "DisplayLightSectors",
                        "type": "E",
                        "default": 2,
                        "value": 1,
                        "Description": "Display light sectors.",
                        "ExpectedInput": [
                            {
                                "code": 1,
                                "value": "False"
                            },
                            {
                                "code": 2,
                                "value": "True"
                            }
                        ]
                    },
                    {
                        "name": "DisplayNOBJNM",
                        "caption": " National Object Naming (local language)",
                        "type": "E",
                        "default": 1,
                        "value": 2,
                        "Description": "Display national object name: NOBJNM when available.",
                        "ExpectedInput": [
                            {
                                "code": 1,
                                "value": "False"
                            },
                            {
                                "code": 2,
                                "value": "True"
                            }
                        ]
                    },
                    {
                        "name": "DisplaySafeSoundings",
                        "type": "E",
                        "default": 2,
                        "value": 2,
                        "Description": "Display safe soundings.",
                        "ExpectedInput": [
                            {
                                "code": 1,
                                "value": "False"
                            },
                            {
                                "code": 2,
                                "value": "True"
                            }
                        ]
                    },
                    {
                        "name": "HonorScamin",
                        "caption": "Enable Scale Minimum Feature Attribution",
                        "type": "E",
                        "default": 2,
                        "value": 2,
                        "Description": "Honor SCAMIN value when displaying features.",
                        "ExpectedInput": [
                            {
                                "code": 1,
                                "value": "False"
                            },
                            {
                                "code": 2,
                                "value": "True"
                            }
                        ]
                    },
                    {
                        "name": "IntendedUsage",
                        "type": "L",
                        "default": "0",
                        "value": "0",
                        "Description": "Intended usage: Enables the display of S-57 datasets based on intended usage.",
                        "ExpectedInput": [
                            {
                                "code": 0,
                                "value": "All Datasets"
                            },
                            {
                                "code": 1,
                                "value": "Overview"
                            },
                            {
                                "code": 2,
                                "value": "General"
                            },
                            {
                                "code": 3,
                                "value": "Coastal"
                            },
                            {
                                "code": 4,
                                "value": "Approach"
                            },
                            {
                                "code": 5,
                                "value": "Harbour"
                            },
                            {
                                "code": 6,
                                "value": "Berthing"
                            }
                        ]
                    },
                    {
                        "name": "IsolatedDangers",
                        "type": "E",
                        "default": 1,
                        "value": 1,
                        "Description": "Isolated dangers in shallow water.",
                        "ExpectedInput": [
                            {
                                "code": 1,
                                "value": "False"
                            },
                            {
                                "code": 2,
                                "value": "True"
                            }
                        ]
                    },
                    {
                        "name": "IsolatedDangersOff",
                        "type": "E",
                        "default": 1,
                        "value": 1,
                        "Description": "Turn off all isolated dangers.",
                        "ExpectedInput": [
                            {
                                "code": 1,
                                "value": "False"
                            },
                            {
                                "code": 2,
                                "value": "True"
                            }
                        ]
                    },
                    {
                        "name": "LabelContours",
                        "type": "E",
                        "default": 1,
                        "value": 1,
                        "Description": "Label contours.",
                        "ExpectedInput": [
                            {
                                "code": 1,
                                "value": "False"
                            },
                            {
                                "code": 2,
                                "value": "True"
                            }
                        ]
                    },
                    {
                        "name": "LabelSafetyContours",
                        "type": "E",
                        "default": 1,
                        "value": 1,
                        "Description": "Label safety contours.",
                        "ExpectedInput": [
                            {
                                "code": 1,
                                "value": "False"
                            },
                            {
                                "code": 2,
                                "value": "True"
                            }
                        ]
                    },
                    {
                        "name": "OptionalDeepSoundings",
                        "caption": "Deep Soundings",
                        "type": "E",
                        "default": 1,
                        "value": 1,
                        "Description": "Optional deep soundings: Do not show safe water soundings in conflict with other features.",
                        "ExpectedInput": [
                            {
                                "code": 1,
                                "value": "False"
                            },
                            {
                                "code": 2,
                                "value": "True"
                            }
                        ]
                    },
                    {
                        "name": "PointSymbolizationType",
                        "caption": "Point Symbolization",
                        "type": "E",
                        "default": 1,
                        "value": 1,
                        "Description": "Point symbolization: Sets type of symbology for point features.",
                        "ExpectedInput": [
                            {
                                "code": 1,
                                "value": "Simplified"
                            },
                            {
                                "code": 2,
                                "value": "Paper chart"
                            }
                        ]
                    },
                    {
                        "name": "RemoveDuplicateText",
                        "caption": "Remove Duplicate Text",
                        "type": "E",
                        "default": 1,
                        "value": 2,
                        "Description": "Remove duplicate text for text group 21 features.",
                        "ExpectedInput": [
                            {
                                "code": 1,
                                "value": "False"
                            },
                            {
                                "code": 2,
                                "value": "True"
                            }
                        ]
                    },
                    {
                        "name": "SafetyContour",
                        "caption": "Safety Contour (meters)",
                        "type": "F",
                        "default": 30,
                        "value": 30,
                        "Description": "Safety contour: Depth of the safety contour in meters."
                    },
                    {
                        "name": "SafetyDepth",
                        "caption": "Safety Depth (meters)",
                        "type": "F",
                        "default": 30,
                        "value": 30,
                        "Description": "Safety depth: Depth of the safety depth in meters."
                    },
                    {
                        "name": "ShallowContour",
                        "caption": "Shallow Contour (meters)",
                        "type": "F",
                        "default": 2,
                        "value": 2,
                        "Description": "Shallow contour: Depth of the shallow contour in meters."
                    },
                    {
                        "name": "TextHalo",
                        "type": "E",
                        "default": 2,
                        "value": 2,
                        "Description": "Display text halo.",
                        "ExpectedInput": [
                            {
                                "code": 1,
                                "value": "On"
                            },
                            {
                                "code": 2,
                                "value": "Off"
                            }
                        ]
                    },
                    {
                        "name": "TwoDepthShades",
                        "caption": "2 Depth Shades",
                        "type": "E",
                        "default": 2,
                        "value": 1,
                        "Description": "Depth shades: Two depth area shades or four.",
                        "ExpectedInput": [
                            {
                                "code": 1,
                                "value": "False"
                            },
                            {
                                "code": 2,
                                "value": "True"
                            }
                        ]
                    }
                ],
                "ParameterGroup": [
                    {
                        "name": "AreaSymbolSize",
                        "caption": "Area Symbol Size",
                        "Parameter": [
                            {
                                "name": "scaleFactor",
                                "type": "F",
                                "default": 1,
                                "value": 1,
                                "caption": "Scale_factor",
                                "Description": "Scale factor"
                            },
                            {
                                "name": "minZoom",
                                "type": "F",
                                "default": 0.01,
                                "value": 0.01,
                                "caption": "Minimum_zoom",
                                "Description": "Minimum zoom"
                            },
                            {
                                "name": "maxZoom",
                                "type": "F",
                                "default": 1.2,
                                "value": 1.2,
                                "caption": "Maximum_zoom",
                                "Description": "Maximum zoom"
                            }
                        ]
                    },
                    {
                        "name": "DatasetDisplayRange",
                        "caption": "Display Range",
                        "Parameter": [
                            {
                                "name": "minZoom",
                                "type": "F",
                                "default": 0.01,
                                "value": 0.01,
                                "caption": "Minimum_zoom",
                                "Description": "Minimum dataset display value."
                            },
                            {
                                "name": "maxZoom",
                                "type": "F",
                                "default": 1.2,
                                "value": 1.2,
                                "caption": "Maximum_zoom",
                                "Description": "Maximum dataset display value."
                            }
                        ]
                    },
                    {
                        "name": "LineSymbolSize",
                        "caption": "Line Symbol Size",
                        "Parameter": [
                            {
                                "name": "scaleFactor",
                                "type": "F",
                                "default": 1,
                                "value": 1,
                                "caption": "Scale_factor",
                                "Description": "Scale factor."
                            },
                            {
                                "name": "minZoom",
                                "type": "F",
                                "default": 0.1,
                                "value": 0.1,
                                "caption": "Minimum_zoom",
                                "Description": "Minimum zoom."
                            },
                            {
                                "name": "maxZoom",
                                "type": "F",
                                "default": 1.2,
                                "value": 1.2,
                                "caption": "Maximum_zoom",
                                "Description": "Maximum zoom."
                            }
                        ]
                    },
                    {
                        "name": "PointSymbolSize",
                        "caption": "Point Symbol Size",
                        "Parameter": [
                            {
                                "name": "scaleFactor",
                                "type": "F",
                                "default": 1,
                                "value": 1,
                                "Description": "Scale_factor."
                            },
                            {
                                "name": "minZoom",
                                "type": "F",
                                "default": 0.1,
                                "value": 0.1,
                                "caption": "Minimum_zoom",
                                "Description": "Minimum zoom."
                            },
                            {
                                "name": "maxZoom",
                                "type": "F",
                                "default": 1.2,
                                "value": 1.2,
                                "caption": "Maximum_zoom",
                                "Description": "Maximum zoom."
                            }
                        ]
                    },
                    {
                        "name": "TextGroups",
                        "caption": "Text Groups",
                        "Parameter": [
                            {
                                "name": "11",
                                "type": "E",
                                "default": 2,
                                "value": 2,
                                "Description": "Important text",
                                "ExpectedInput": [
                                    {
                                        "code": 1,
                                        "value": "False"
                                    },
                                    {
                                        "code": 2,
                                        "value": "True"
                                    }
                                ]
                            },
                            {
                                "name": "21",
                                "type": "E",
                                "default": 2,
                                "value": 2,
                                "Description": "Names for position reporting",
                                "ExpectedInput": [
                                    {
                                        "code": 1,
                                        "value": "False"
                                    },
                                    {
                                        "code": 2,
                                        "value": "True"
                                    }
                                ]
                            },
                            {
                                "name": "23",
                                "type": "E",
                                "default": 2,
                                "value": 2,
                                "Description": "Light description string",
                                "ExpectedInput": [
                                    {
                                        "code": 1,
                                        "value": "False"
                                    },
                                    {
                                        "code": 2,
                                        "value": "True"
                                    }
                                ]
                            },
                            {
                                "name": "24",
                                "type": "E",
                                "default": 2,
                                "value": 2,
                                "Description": "Note on chart data",
                                "ExpectedInput": [
                                    {
                                        "code": 1,
                                        "value": "False"
                                    },
                                    {
                                        "code": 2,
                                        "value": "True"
                                    }
                                ]
                            },
                            {
                                "name": "25",
                                "type": "E",
                                "default": 2,
                                "value": 2,
                                "Description": "Nature of seabed",
                                "ExpectedInput": [
                                    {
                                        "code": 1,
                                        "value": "False"
                                    },
                                    {
                                        "code": 2,
                                        "value": "True"
                                    }
                                ]
                            },
                            {
                                "name": "26",
                                "type": "E",
                                "default": 2,
                                "value": 2,
                                "Description": "Geographic names",
                                "ExpectedInput": [
                                    {
                                        "code": 1,
                                        "value": "False"
                                    },
                                    {
                                        "code": 2,
                                        "value": "True"
                                    }
                                ]
                            },
                            {
                                "name": "27",
                                "type": "E",
                                "default": 2,
                                "value": 2,
                                "Description": "Magnetic variation and swept depth",
                                "ExpectedInput": [
                                    {
                                        "code": 1,
                                        "value": "False"
                                    },
                                    {
                                        "code": 2,
                                        "value": "True"
                                    }
                                ]
                            },
                            {
                                "name": "28",
                                "type": "E",
                                "default": 2,
                                "value": 2,
                                "Description": "Height of islet or land feature",
                                "ExpectedInput": [
                                    {
                                        "code": 1,
                                        "value": "False"
                                    },
                                    {
                                        "code": 2,
                                        "value": "True"
                                    }
                                ]
                            },
                            {
                                "name": "29",
                                "type": "E",
                                "default": 2,
                                "value": 2,
                                "Description": "Berth number",
                                "ExpectedInput": [
                                    {
                                        "code": 1,
                                        "value": "False"
                                    },
                                    {
                                        "code": 2,
                                        "value": "True"
                                    }
                                ]
                            },
                            {
                                "name": "30",
                                "type": "E",
                                "default": 2,
                                "value": 2,
                                "Description": "Current Velocity",
                                "ExpectedInput": [
                                    {
                                        "code": 1,
                                        "value": "False"
                                    },
                                    {
                                        "code": 2,
                                        "value": "True"
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "TextSize",
                        "caption": "Text Size",
                        "Parameter": [
                            {
                                "name": "scaleFactor",
                                "type": "F",
                                "default": 1,
                                "value": 1,
                                "caption": "Scale_factor",
                                "Description": "Scale factor."
                            },
                            {
                                "name": "minZoom",
                                "type": "F",
                                "default": 0.1,
                                "value": 0.1,
                                "caption": "Minimum_zoom",
                                "Description": "Minimum zoom."
                            },
                            {
                                "name": "maxZoom",
                                "type": "F",
                                "default": 1.2,
                                "value": 1.2,
                                "caption": "Maximum_zoom",
                                "Description": "Maximum zoom."
                            }
                        ]
                    }
                ]
            }
        }
    },
    "ServerParameters": {
        "defaultVisibility": true,
        "framesOn": false
    }
};