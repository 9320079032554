import React, {useState, useMemo, useEffect} from 'react';
import { Switch, Route, Redirect } from "react-router-dom";

//If Logged in
import Mobile from './Mobile/Mobile';
import Desktop from './Desktop/Desktop';

// If not logged in
import MobileLogin from './Mobile/AccountManagement/Mobile.Login';
import ForgotPassword from './Mobile/AccountManagement/ForgotPassword';
import ResetPassword from './Mobile/AccountManagement/ResetPassword';
import HelpPage from './Mobile/Help/HelpStatement'

import DesktopLogin from './Authentication/Login';
import DesktopNewUserPassword from './Authentication/NewUserPassword';
import DesktopForgotPassword from './Authentication/ForgotPassword';
import DesktopResetPassword from './Authentication/PasswordReset';
import UserInvitation from './Authentication/UserInvitation';
import MobileConfiguration from '../Global/Data/Config';


// NOTE: The check for if user is logged in or not is decided per the value of props.authed sent in by call.
// The check is not an actual authentication of credentials check 
// any error will allow people access without loggin in although without proper data.
const CheckScreen = ({authed}) => {
    //console.log("First Entry is: ", localStorage.getItem('isMobile'));
    //localStorage.clear()
    if(localStorage.getItem('isMobile') === null || localStorage.getItem('isMobile') === '' || localStorage.getItem('isMobile') === 'null')
    {
        let isMobile;
        (window.screen.width > 700 && window.screen.height > 1000 || window.screen.width > 1000 && window.screen.height > 700)? isMobile = "desktop": isMobile = "mobile";
        localStorage.setItem('isMobile', isMobile);
    }
    

    let MobileORDesktop = localStorage.getItem('isMobile');

    //console.log("MOBILE OR DESKTOP? ", MobileORDesktop);
    
    
    //return useMemo(() => {
        //console.log("should reload right now");
        if( authed )
        { // For if user is logged in

            if( MobileORDesktop === "desktop" ){
                return( <Desktop /> ); // For Desktop View
            } else {
                return (<Mobile /> ); // For Mobile View
            }
        }
        else
        { // For if user is not logged in
            if( MobileORDesktop === "desktop" ){
                return( // For Desktop view
                    <Switch>
                        <Route path="/login" component={DesktopLogin} />
                        <Route path="/forgotpassword" component={DesktopForgotPassword} />
                        <Route exact path="/resetpassword" component={DesktopResetPassword} />
                        <Route path="/newuser" component={DesktopNewUserPassword} />
                        <Route path="/invitation" component={UserInvitation} />
                        {/* <Route path="/help" component={HelpPage} /> */}
                        <Redirect to="/login" />
                    </Switch>
                );
            }
            else 
            { // For Mobile View 
                return (
                    <Switch>
                        <Route exact path="/login" component={MobileLogin} />
                        <Route exact path="/resetpassword" component={ResetPassword} />
                        <Route exact path="/forgotpassword" component={ForgotPassword} />
                        <Route exact path="/help" component={HelpPage} />
                        <Redirect to="/login" />
                    </Switch>
                );
            }
        }
        
    //}, [window.screen.width, window.screen.height, MobileORDesktop, authed])
}

// const ShowView = () => {

//     const [isMobile, setIsMobile] = useState(true);

//     const switchView = () => {
//         setIsMobile(!isMobile)
//     }

//     return(
//         <React.Fragment>
//             <h1 onClick={switchView}>Click Me to switch view :)</h1>
//             <CheckScreen isMobile={isMobile} />
//         </React.Fragment>
//     )
// }

export default CheckScreen;
