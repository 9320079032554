import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Typography, TableFooter } from '@material-ui/core';
import QuoteLineStatusButton from '../../../../Widgets/QuoteLineStatusButton';

const VesselQuoteLineDetailList = ({ productGroup, line }) => {
    return (
        <React.Fragment>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell align="center" colSpan={2}>
                            <Typography variant="h6">{productGroup.caption}</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    <TableRow >
                        <TableCell style={{ width: '1px' }}><Typography variant="subtitle2">Number:</Typography></TableCell>
                        <TableCell>{line.item.itemNumber}</TableCell>
                    </TableRow>

                    <TableRow >
                        <TableCell><Typography variant="subtitle2">Title:</Typography></TableCell>
                        <TableCell>{line.item.itemTitle}</TableCell>
                    </TableRow>

                    <TableRow >
                        <TableCell><Typography variant="subtitle2">Edition:</Typography></TableCell>
                        <TableCell>{String(line.item.editionDate).split(' ')[0]}</TableCell>
                    </TableRow>

                    <TableRow >
                        <TableCell><Typography variant="subtitle2">Quantity:</Typography></TableCell>
                        <TableCell>{line.quantity}</TableCell>
                    </TableRow>

                    <TableRow >
                        <TableCell><Typography variant="subtitle2">Duration:</Typography></TableCell>
                        <TableCell>{`${line.duration || 'N/A'}`}</TableCell>
                    </TableRow>

                    <TableRow >
                        <TableCell><Typography variant="subtitle2">Price:</Typography></TableCell>
                        <TableCell>{`${line.currency} ${line.linePrice}`}</TableCell>
                    </TableRow>
                </TableBody>

                <TableFooter>
                    <TableRow >
                        <TableCell colSpan={2}>
                            <div className="d-flex flex-evenly">
                                <QuoteLineStatusButton text="Reject" line={line} activeValue={2} />
                                <QuoteLineStatusButton text="Accept" line={line} activeValue={1} />
                            </div>
                        </TableCell>
                    </TableRow>
                </TableFooter>

            </Table>
            {/* <div>Group</div>
            <div>Number</div>
            <div></div>
            <div>Price</div>
            <div></div>
            <div>Total</div>
            <div>Duration</div> */}
        </React.Fragment>
    );
};

export default VesselQuoteLineDetailList;
