import React from 'react'
import { useSaleStore } from '../../../Global/Stores/saleStore';
import _ from 'lodash';
import PurchaseOrderLayer from './PurchaseOrderLayer';
import WorldAreaLayer from './WorldAreaLayer';
import FolioLayer from './FolioLayer';

const FlatFeeContractLayer = ({ match, history }) => {
    const [saleState] = useSaleStore();

    const flatFeeID = +match.params.id || 0;
    const orderID = +match.params.orderID || 0;
    const selectedLineID = +match.params.lineID || 0;

    // console.log('FlatFeeContractLayer', flatFeeID);

    const vesselFlatFeePeriodID = _.get(saleState, 'flatFeeDetail.flatFee.vesselFlatFeePeriodID', -1);
    const purchaseOrderID = _.get(saleState, 'flatFeeOrderDetail.header.purchaseOrderID', -1);

    const contractLoaded = (flatFeeID == vesselFlatFeePeriodID);
    const orderLoaded = contractLoaded && (purchaseOrderID === orderID);

    //  Contract
    // const contract = _.get(saleState, 'flatFeeDetail.flatFee', null);
    const worldArea = _.get(saleState, 'flatFeeDetail.flatFee.worldArea', null);
    const folio = _.get(saleState, 'flatFeeDetail.flatFee.folio', null);

    const order = _.get(saleState, 'flatFeeOrderDetail', null);


    // const order = _.get(saleState, 'flatFeeOrderDetail.orders', []).find();

    //console.log('contract', contract);
    // console.log('order', order);

    return (
        <React.Fragment>
            {contractLoaded ? <WorldAreaLayer worldArea={worldArea} /> : null}
            {contractLoaded ? <FolioLayer folio={folio} /> : null}            
            {orderLoaded ? <PurchaseOrderLayer purchaseOrder={order} selectedLineID={selectedLineID} history={history} /> : null}            
        </React.Fragment>
    )
}

export default FlatFeeContractLayer
