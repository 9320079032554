import React, { useMemo } from 'react';
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
// import { getToken } from '../../../Global/Services/httpService';

const NavIconHelp = () => {

    return useMemo(() => {

        return (
            // <a target="_blank" rel="noopener noreferrer" href={`/help?access_token=${getToken()}`} style={{ color: 'inherit' }}>
            <a target="_blank" rel="noopener noreferrer" href="http://www.charttrack.com/Shipmanager/Help.htm" style={{ color: 'inherit' }}>
                <IconButton color="inherit"           >
                    <HelpIcon />
                </IconButton>
            </a>
        );

    }, []);

};

export default NavIconHelp;
