import React from 'react'
import LineDetailTable2 from '../../Misc/LineDetailTable2';
import { useSaleStore } from '../../../../Global/Stores/saleStore';
import Loading from '../../Misc/Loading';
import { Link as RouterLink } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import NextIcon from '@material-ui/icons/NavigateNext';
import BeforeIcon from '@material-ui/icons/NavigateBefore';
import { IconButton, Divider, Typography, Link, Box, Dialog  } from '@material-ui/core';
import _ from 'lodash';
import ContentColumn from '../../Custom/ContentColumn';

import FloatingMenuButton from '../../Navigation/FloatingMenuButton';

const FlatFeeOrderLineDetail = ({ match }) => {

    const [open, setOpen] = React.useState(true);
    
      const handleClose = () => {
        setOpen(false);
      };

    // const flatFeeID = +match.params.id;
    const orderID = +match.params.orderID;
    const selectedLineID = +match.params.lineID;

    const [saleState] = useSaleStore();

    const purchaseOrderID = _.get(saleState, 'flatFeeOrderDetail.header.purchaseOrderID', -1);

    const loaded = (purchaseOrderID === orderID);
    const line = loaded ? saleState.flatFeeOrderDetail.lines.find(X => X.purchaseOrderLineID === selectedLineID) : null;
    const popLink = match.url.substring(0, match.url.lastIndexOf("/"));

    return (
        <Dialog open={open}  >
            <ContentColumn loaded={loaded}>
                {
                    loaded ? (
                        <React.Fragment>
                            <div className="contentColumnHeader">
                                <FlatFeeOrderLineDetailHeader line={line} closePath={popLink} />
                            </div>

                            <div className="contentColumnBody">
                                <LineDetailTable2 line={line} />
                            </div>
                        </React.Fragment>
                    ) : <Loading />
                }
            </ContentColumn>
        </Dialog>
    );
}


const FlatFeeOrderLineDetailHeader = ({ line, closePath }) => {

    return (
        <React.Fragment>

            <Link to={closePath} component={RouterLink} className="navLink" style={{ color: 'inherit', textDecoration: 'none' }} >
                <IconButton>
                    <CloseIcon />
                </IconButton>
            </Link>

            {/* <Typography className="flexFill" variant="subtitle2" > 
                {line.item.itemTitle}
            </Typography> */}

            {/* <Link to={before} component={RouterLink} className="navLink" style={{ color: 'inherit', textDecoration: 'none' }} >
                <IconButton >
                    <BeforeIcon />
                </IconButton>
            </Link>

            <Link to={next} component={RouterLink} className="navLink" style={{ color: 'inherit', textDecoration: 'none' }} >
                <IconButton >
                    <NextIcon />
                </IconButton>
            </Link> */}

            <Divider />
            <FloatingMenuButton />
        </React.Fragment>
    );
}

export default FlatFeeOrderLineDetail
