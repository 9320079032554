import React, {useState} from 'react'
import { Switch, Route, Redirect } from "react-router-dom";

// Material UI support files
import { CssBaseline, makeStyles, Button, TextField, Link, Grid, Container, InputAdornment, Typography, Paper } from "@material-ui/core";

// Global Support Imports
import { useUserStore } from "../../../Global/Stores/userStore";
import http from '../../../Global/Services/httpService';

// Images
import CT_Logo from "./../../../../src/Images/CT-logo-transparant.png";
//  theme.palette.secondary.main

////// CSS
import loginStyles from '../CSS/LoginStyles.module.css'

/*
Emmanuel Eshun-Davies, January 23, 2020

TODO:
*/


// Unneccessary required styling 
// const useStyles = makeStyles(theme => ({
// forms: {
//     marginTop: theme.spacing(0),
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     fontSize: 10,
//     width: '100%',
// },
// logoCenter: {
//     marginTop: theme.spacing(8),
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
// },
// leftText: {
//     marginTop: theme.spacing(5),
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'left',
//     color: '#777',
// },
// bottomLinks: {
//     marginTop: theme.spacing(0),
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     color: '#777',
// },
// form: {
//     width: '100%', // Fix IE 11 issue.
//     marginTop: theme.spacing(0),
// },
// submit: {
//     margin: theme.spacing(3, 0, 2),
//     backgroundColor: '#1e89c6',
//     color: '#fff',
//     fontWeight: '100',
// },
// }));


const ForgotPassword = () => {
    const [, actions] = useUserStore();
    
    // const loginStyles = useStyles();

    // Form Control
    const [formState, setFormState] = useState({
        email: '',
        loading: false,
    });

    const handleChange = event => {
        const target = event.target;
        if (!formState.loading)
            setFormState(() => ({ ...formState, [target.name]: (target.type === 'checkbox' ? target.checked : target.value) }));
    };


    const handleForgotPassword = (e) => {
        e.preventDefault();
        setFormState({ ...formState, loading: true });
        // console.log(formState.email);
        if(formState.email === ""){
            setFormState({ ...formState, loading: false });
            // We will just do nothing. They will eventually notice and have to enter an email. :D
        } else {
            const postData = {
                email: formState.email,
            };
            // const email = formState.mail;

            http.post('/api/account/users/resetpassword', postData)
                .then(result => {
                    // actions.login(result.data);
                    // history.push("/account/users");
                    window.location.href = "/home";
                })
                .catch(() => {
                    // setCreating(() => false);
                    setFormState({ ...formState, loading: false });
                });
            }

    };

    return (
        <Container component="div" maxWidth="xs">
            <CssBaseline />
            <div className={loginStyles.logoCenter}>
                <Grid>
                    <img className="logo" width="60%" src={CT_Logo} />
                </Grid>
            </div>
            <div className={loginStyles.leftText}>
                <Paper raised="true" style={{padding: 10}}>
                <Typography component="h5" variant="body2" align="left">
                    <b>Password Reset</b><br />
                    1. Enter your email <br />
                    2. click "Reset Password"<br />
                    3. An email will be sent to you<br />
                    4. Follow Instructions in email to reset password<br />
                </Typography>
                </Paper>
            </div>
            <div className={loginStyles.forms}>
                <form className={loginStyles.form}  noValidate autoComplete="on">
                    <TextField
                        className="loginField"
                        margin="normal"
                        size="small"
                        required
                        fullWidth
                        id="email"
                        label="E-mail Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={formState.email}
                                onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ endAdornment: (<InputAdornment position="end">&nbsp;</InputAdornment>),}}
                    />
                    <Button
                        className="SignIn"
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="inherit"
                        className={loginStyles.submit}
                        onClick={ handleForgotPassword }
                    >
                        Get Password
                    </Button>
                    <Grid  className={loginStyles.bottomLinks}>
                        <Grid>
                            <Link href="/login" variant="body2"  color="inherit">
                            Return to Login
                            </Link>
                            {/* &nbsp;&nbsp;| &nbsp;&nbsp;
                            <Link href="/help" variant="body2" color="inherit">
                            Need Help?
                            </Link> */}
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    )
}


export default ForgotPassword