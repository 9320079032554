import React, { useMemo, useEffect, useState } from 'react';
import { FeatureGroup } from 'react-leaflet';
import _ from 'lodash';
import { useSaleStore } from '../../../Global/Stores/saleStore';
import SalesOrderLinePolygon from '../Polygons/SalesOrderLinePolygon';
import { checkPolygonInPoint } from '../MapFunctions';
import MapPopupSalesOrderLines from '../Popup/MapPopupSalesOrderLines';
import GeneralRoutesLayer from './Catalogue/GeneralRoutesLayer';

const SalesOrderLayer = ({ match, history, mapActions }) => {
    const [saleState] = useSaleStore();
    const salesOrderID = saleState.orderDetail ? saleState.orderDetail.order.salesOrderID : 0;
    const selectedLineID = +match.params.lineID || 0;


    const filter = saleState.orderDetail ? saleState.orderDetail.statusFilter : [];

    // console.log('selectedLineID', salesOrderID, selectedLineID)

    const [popup, setPopup] = useState({
        key: 0,
        position: null,
        lines: []
    });


    useEffect(() => {
        if (salesOrderID > 0) {
            mapActions.zoomToLines(saleState.orderDetail.order.lines);
        }
    }, [salesOrderID]);


    useEffect(() => {
        if (salesOrderID > 0 && selectedLineID > 0) {
            const line = saleState.orderDetail.order.lines.find(X => X.salesOrderLineID === selectedLineID);
            mapActions.zoomToLines(line);
        }
    }, [selectedLineID]);


    return useMemo(() => {


        if (salesOrderID === 0)
            return null;


        const onClick = (e) => {
            // console.log("onClick", e);
            let selected = lines.filter(X => X.item.polygonPoints && checkPolygonInPoint(e.latlng, X.item.polygonPoints));
            if (selected.length === 0)
                selected = e.layer;

            if (selected.length === 1) {

                //console.log("history", selected.length);
                history.push(`/sales/orders/${selected[0].salesOrderID}/${selected[0].salesOrderLineID}`);
                // mapActions.zoomToLines(selected);
            }
            else if (selected.length > 0) {

                setPopup(prev => {
                    return {
                        key: prev.key + 1,
                        position: e.latlng,
                        lines: selected
                    }
                });
            }
            // console.log("selected", selected);
        };


        const lines = saleState.orderDetail.order.lines;
        const routes = saleState.orderDetail.order.routes;

        let filteredLines = lines.filter(X => X.item.polygonPoints);

        if (saleState.orderDetail.statusFilter) {
            // console.log('statusFilter', orderDetail.statusFilter);
            filteredLines = filteredLines.filter(X => saleState.orderDetail.statusFilter.includes(X.salesOrderLineStatusID));
        }

        // console.log("filteredLines", filteredLines.length);


        const sortedLines = _.orderBy(filteredLines, ['item.scale'], ['desc']);

        // mapActions.zoomToLines(filteredLines);

        return (
            <React.Fragment>
                <MapPopupSalesOrderLines popupInfo={popup} />

                <FeatureGroup onClick={onClick}>
                    {
                        // sortedLines.map(X => <Polygon key={X.item.itemID} positions={X.item.polygonPoints} />)
                        sortedLines.map(X => <SalesOrderLinePolygon key={X.item.itemID} line={X} selected={selectedLineID === X.salesOrderLineID} />)
                    }
                </FeatureGroup>
                <GeneralRoutesLayer Routes={routes} />

            </React.Fragment>
        );



    }, [salesOrderID, popup, filter, selectedLineID])

};

export default SalesOrderLayer;
