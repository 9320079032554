import React, { useEffect, useMemo, useState } from 'react';
import { List, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction, Box, IconButton, Divider, Collapse, Link, SvgIcon } from '@material-ui/core';
import { useSaleStore } from '../../../../Global/Stores/saleStore';
import BackIcon from '@material-ui/icons/ArrowBack';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';
import Loading from '../../Misc/Loading';
import { useGlobalStore } from '../../../../Global/Stores/globalStore';
import ContentColumn from '../../../Custom/ContentColumn';

const FlatFeeOrderDetail = ({ match, history }) => {

    const flatFeeID = +match.params.id || 0;
    const orderID = +match.params.orderID || 0;
    const lineID = +match.params.lineID || 0;

    const [saleState, saleActions] = useSaleStore();


    // console.log('object', flatFeeID, orderID, lineID)

    useEffect(() => {
        if (flatFeeID > 0 && orderID > 0) {
            saleActions.getVesselFlatFeeOrderDetails(flatFeeID, orderID);
        }
    }, [flatFeeID, orderID]);


    const purchaseOrderID = _.get(saleState, 'flatFeeOrderDetail.header.purchaseOrderID', -1);
    const loaded = (purchaseOrderID === orderID);

    return (
        (
            <ContentColumn loaded={loaded}>
                {
                    loaded ? (
                        <React.Fragment>
                            <div className="contentColumnHeader">
                                <FlatFeeOrderDetailHeader header={saleState.flatFeeOrderDetail.header} history={history} />
                            </div>

                            {/* <div className="contentColumnFilter">
                            contentColumnFilter
                        </div> */}

                            <div className="contentColumnBody disablePadding">
                                <FlatFeeOrderDetailLineList detail={saleState.flatFeeOrderDetail} selectedID={lineID} />
                            </div>

                            {/* <div className="contentColumnFooter">contentColumnFooter</div> */}
                        </React.Fragment>
                    ) : <Loading />
                }
            </ContentColumn>
        )
    );
};

// Header
const FlatFeeOrderDetailHeader = ({ header, history }) => {

    // console.log('history', history);

    return useMemo(() => {
        const handleBack = () => {

            const url = history.location.pathname;
            const popLink = url.substring(0, url.lastIndexOf("/"));
            history.push(popLink);
        }

        if (!header)
            return null;

        return (
            <React.Fragment>
                <IconButton onClick={handleBack}>
                    <BackIcon />
                </IconButton>
                <ListItemText className="flexFill"
                    primary={`Order: ${header.reference}`}
                // secondary={`${header.customerReference} (${header.orderDate})`}
                >
                </ListItemText>

                <Divider />
            </React.Fragment>
        );
    }, [header]);
}


// Lines
const FlatFeeOrderDetailLineList = ({ detail, selectedID }) => {
    const [globalState,] = useGlobalStore();
    const [closedGroups, setClosedGroups] = useState([]);

    if (!globalState.productGroups)
        return null;



    const toggleProductGroup = (pgid) => {
        setClosedGroups(prev => {
            const newGroups = prev.includes(pgid) ? prev.filter(X => X !== pgid) : [...prev, pgid];
            return newGroups;
        })
    }


    const sortedLines = _.orderBy(detail.lines, [I => I.item.itemNumber.toLowerCase()]);
    // console.log('object', detail)

    return (
        <List className="ListItemHeader" disablePadding>
            {
                // globalState.productGroups.map(PG => <div key={PG.productGroupID}>{PG.caption}</div>)
                globalState.productGroups.map(PG => (
                    <OrderProductGroupHeader
                        key={PG.productGroupID}
                        vesselFlatFeePeriodID={detail.header.vesselFlatFeePeriodID}
                        productGroup={PG}
                        purchaseOrderID={detail.header.purchaseOrderID}
                        lines={sortedLines.filter(X => X.item.productGroupID === PG.productGroupID)}
                        selectedID={selectedID}
                        toggleProductGroup={toggleProductGroup}
                        open={!closedGroups.includes(PG.productGroupID)}
                    />
                ))
            }
        </List>
    );
}


// ListHeader
const OrderProductGroupHeader = ({ vesselFlatFeePeriodID, productGroup, purchaseOrderID, lines, selectedID, toggleProductGroup, open }) => {
    // const open = true;
    const hasLines = lines.length > 0;

    return useMemo(() => {

        return (
            <React.Fragment>
                {
                    hasLines ? (
                        <React.Fragment>
                            <ListItem button divider selected={open} onClick={() => toggleProductGroup(productGroup.productGroupID)} disablePadding>
                                <ListItemIcon>
                                    <SendIcon />
                                </ListItemIcon>

                                <ListItemText primary={productGroup.caption} />
                                <ListItemText style={{ flexGrow: '0' }} primary={`${lines.length} Items`} />

                                <ListItemSecondaryAction>
                                    {open ? <ExpandLess /> : <ExpandMore />}
                                </ListItemSecondaryAction>
                            </ListItem>

                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {
                                        //lines.map(L => <div key={L.item.itemID}>{L.item.itemNumber}</div>)
                                        lines.map(X => <PurchaseOrderLine
                                            key={X.item.itemID}
                                            vesselFlatFeePeriodID={vesselFlatFeePeriodID}
                                            purchaseOrderID={purchaseOrderID}
                                            line={X}
                                            selected={selectedID === X.purchaseOrderLineID} />)
                                    }
                                </List>
                            </Collapse>
                        </React.Fragment>
                    ) : null
                }

            </React.Fragment>
        );

    }, [purchaseOrderID, open, selectedID]);
}


const PurchaseOrderLine = ({ vesselFlatFeePeriodID, purchaseOrderID, line, selected }) => {

    return useMemo(() => {

        // console.log('purchaseOrderLine', line);

        const to = `/sales/flatfee/${vesselFlatFeePeriodID}/${purchaseOrderID}/${line.purchaseOrderLineID}`;
        // const to = `${line.purchaseOrderLineID}`;
        return (
            <Link className="ListItemLink" component={RouterLink} to={to} color='inherit' style={{ textDecoration: 'none' }}>
                <ListItem button disableRipple dense divider selected={selected}>
                    {/* <HoldingLineStatus line={line} /> */}
                    <ListItemText primary={`${line.item.itemNumber} [${line.months} months]`} secondary={line.item.itemTitle} />


                    {/* <ListItemSecondaryAction>
                        <ListItemText primary={`${line.months} months`} />
                    </ListItemSecondaryAction> */}
                </ListItem>
            </Link>
        );

    }, [purchaseOrderID, line.itemID, selected]);
}



export default FlatFeeOrderDetail;
