import React, { useState } from 'react';
import { Popup } from 'react-leaflet';
import { List, ListItem, ListItemText, ListItemSecondaryAction, Link } from '@material-ui/core';
// import ValidationWidget from '../../Desktop/Misc/ValidationWidget';
import { Link as RouterLink } from 'react-router-dom';
import StatusCircle from '../../Widgets/StatusCircle';

const MapPopupSalesQuoteLines = ({ popupInfo }) => {

    // console.log('popupInfo', popupInfo);
    const [closeKey, setCloseKey] = useState(0);


    // console.log('popupInfo', popupInfo.lines);

    return popupInfo.position && closeKey !== popupInfo.key ? (
        <Popup
            key={popupInfo.key}
            position={popupInfo.position}
        >
            <List dense={true} disablePadding style={{ border: '1px solid gray' }}>
                {
                    popupInfo.lines.map(line => {

                        let classNameValidation = "";
                        if (line.validationID === 1)
                            classNameValidation = "statusGreen"
                        else if (line.validationID === 2)
                            classNameValidation = "statusRed"


                        return (
                            <Link
                                key={line.salesQuoteLineID}
                                className="ListItemLink"
                                component={RouterLink}
                                to={`/sales/quotes/${line.salesQuoteID}/${line.salesQuoteLineID}`}
                                color='inherit'
                                style={{ textDecoration: 'none', color: 'inherit' }}
                                onClick={() => setCloseKey(popupInfo.key)}
                            >
                                <ListItem button disableRipple dense divider>

                                    <StatusCircle className={classNameValidation} />

                                    {/* <Chip size="small" label="O" /> */}
                                    <ListItemText primary={`${line.item.itemNumber} ${line.item.itemTitle}`} style={{ marginRight: '10px' }} />

                                    <ListItemSecondaryAction>
                                        <ListItemText style={{}} primary={(line.linePrice ? line.linePrice.toFixed(2) : '')} />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </Link>
                        );
                    })
                }
            </List>
        </Popup>

    ) : null;
};

export default MapPopupSalesQuoteLines;
