import React, { useMemo } from 'react';
import { getHoldingLineColor } from '../Helpers/GlobalHelper';
import ItemPolygon from './ItemPolygon';

const HoldingLinePolygon = ({ line, selected }) => {


    return useMemo(() => {
        if (!line.item.polygonPoints)
            return null;

        const color = getHoldingLineColor(line);

        const positions = line.item.polygonPoints;
        return (
            <ItemPolygon
                positions={positions}
                // fill={true}
                fillColor={color}
                color={color}
                // fillOpacity={0.2}
                selected={selected}
            />
        );
    }, [selected])

};

export default HoldingLinePolygon;
