import React, { useState, useEffect } from 'react';
import { useUserStore } from '../Stores/userStore';
import createActivityDetector from "activity-detector";

const useAppSession = () => {
  const [, userActions] = useUserStore();
  const [active, setActive] = useState(true);

  // console.log("useAppSession");
  const refreshToken = userActions.refreshToken;

  useEffect(() => {
    const activityDetector = createActivityDetector({ timeToIdle: 60000 });
    activityDetector.on("idle", () => setActive(() => false));
    activityDetector.on("active", () => setActive(() => true));

    // clean the subscription
    return () => {
      activityDetector.stop();
    };
  }, []);

  useEffect(() => {

    const doRefresh = () => {

      setActive((P) => {
        if (P === true) {
          refreshToken();
        }

        return P;
      });
    }

    if (active === true) {
      doRefresh();
    }

    const interval = setInterval(doRefresh, 60 * 1000); // 1 minute
    return () => clearInterval(interval);

  }, [active]);
}

export default useAppSession

