import React, { useEffect } from 'react';
import { useSaleStore } from '../Global/Stores/saleStore';
import { useGlobalStore } from '../Global/Stores/globalStore';
import { useFleetStore } from '../Global/Stores/fleetStore';
import { useBasketStore } from '../Global/Stores/basketStore';
import { useMapStore } from '../Global/Stores/mapStore';
// import { useUserStore } from '../Global/Stores/userStore';
import useAppSession from '../Global/Hooks/useAppSession';
import { useCatalogueStore } from '../Global/Stores/catalogueStore';
import { useAvcsoStore } from '../Global/Stores/avcsoStore';
import { useNotificationStore } from '../Global/Stores/notificationStore';

//CheckScreen Component Loader
import CheckScreen from './CheckScreen';

const AppAuthenticated = (props) => {
    const [, globalActions] = useGlobalStore();
    const [, saleActions] = useSaleStore();
    const [, fleetActions] = useFleetStore();
    const [, basketActions] = useBasketStore();
    const [, mapActions] = useMapStore();
    const [, catalogueActions] = useCatalogueStore();
    const [, avcsoActions] = useAvcsoStore();
    const [, notificationActions] = useNotificationStore();
    // const [, userActions] = useUserStore();

    // Load/Clear initial data

    // Mobile / Tablet / Desktop

    // const onUnload = (event) => { // the method that will be used for both add and remove event
    //     event.preventDefault();
    //     const e = event;


        // console.log("AppAuthenticated", props)
    //     event.returnValue = true;
    // }

    useAppSession();


    useEffect(() => {

        // console.log('AppAuthenticated', true);
        //window.addEventListener("beforeunload", onUnload);

        globalActions.loadData();
        saleActions.loadData();
        fleetActions.loadData();
        basketActions.loadData();
        mapActions.loadData();
        
        notificationActions.clearData();

        return () => {


            globalActions.clearData();
            saleActions.clearData();
            fleetActions.clearData();
            basketActions.clearData();
            mapActions.clearData();
            catalogueActions.clearData();
            avcsoActions.clearData();
            notificationActions.clearData();
            // userActions.logout(false);
            

            //window.removeEventListener("beforeunload", onUnload);
            // console.log('AppAuthenticated', false);
        };
    }, []);

    return (<CheckScreen authed={true} />);
};

export default AppAuthenticated;
