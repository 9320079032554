import React, { useEffect, useMemo } from 'react';
import { Table, TableHead, TableCell, TableBody, TableRow } from '@material-ui/core';
import { useUserStore } from '../../../../Global/Stores/userStore';
import ContentColumn from '../../Custom/ContentColumn';
import _ from 'lodash';

import Breadcrumb from '../../Navigation/Breadcrumb';

const UserList = ({ match, history }) => {

    const [userState, userActions] = useUserStore();

    const id = +_.get(match, 'params.id', 0);

    // console.log('id', id);

    useEffect(() => {
        userActions.getUsers();
    }, []);


    const { users } = userState;
    //console.log('users 1', users);

    return useMemo(() => {

        //console.log('users 2', users);
        const sorted = _.orderBy(users, [I => I.firstName.toLowerCase()]);
        //console.log('users 3', sorted);

        const openUser = (id) => {
            history.push(`/account/users/manage/${id}`)
        }

        const loaded = users.length > 0;
        return (
            <ContentColumn loaded={loaded}>
                <Breadcrumb Module="Account Settings" currentPage="Manage Users" />
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell ><b>First Name</b></TableCell>
                            <TableCell ><b>Last Name</b></TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            sorted.map(X => {
                                // console.log('X', X)
                                return (<UserRow key={X.personID} user={X} selected={X.personID === id} openUser={openUser} />)
                            })
                        }
                    </TableBody>

                </Table>
            </ContentColumn>
        );
    }, [users, id]);
}

const UserRow = ({ user, openUser, selected }) => {

    // console.log('User Row', user);
    return useMemo(() => {

        const { personID, firstName, lastName, deleted } = user;
        return (
            deleted !== true ? (
                <TableRow onClick={() => openUser(personID)} selected={selected} hover={true} >
                    <TableCell>{firstName}</TableCell>
                    <TableCell>{lastName}</TableCell>
                </TableRow>) : null);

    }, [user, selected])
}

export default UserList
