import { useEffect } from "react";
import L from "leaflet";
import "./leaflet-polyline-measure.css";
import "./polyline-measure-custom.css";
import "./leaflet-polyline-measure.js";

export default function LeafletRuler({ mapRef }) {

    const map = mapRef.current.leafletElement; 

    const scaleOptions = {
        maxWidth: 240, 
        metric: true, 
        imperial: false,
        position: 'bottomleft'
    };

    const circle = {
        weight: 2,
        radius: 4,
        color: '#fff',
        fillColor: '#4598d5'
    };

    const line = {
        weight: 2,
        color: '#4598d5',
    };

    const polylineMeasureOption = {
        tempLine: line,
        fixedLine: line,
        endCircle: circle,
        showBearings: false, 
        startCircle: circle,
        position: 'topleft',
        unit: 'nauticalmiles', 
        intermedCircle: circle,
        showUnitControl: true, 
        measureControlLabel: '',
        showClearControl: true, 
        showFirstToolTip: false,
        clearMeasurementsOnStop: false,
        tooltipDistanceText: 'Distance',
        measureControlTitleOn: 'Start Measuring',
        measureControlTitleOff: 'End Measurement',
        tooltipTotalDistanceText: 'Total Distance',
        currentCircle: { 
            ...circle,
            radius: 8, 
            fillOpacity: 1,
        }
    };

    useEffect(() => {

        if(map !== null) 
        {
            L.control.scale(scaleOptions).addTo(map);
            L.control.polylineMeasure (polylineMeasureOption).addTo(map);
        }

    }, [map]);

    return null;
}
