import React, { useRef, useEffect } from 'react';
import { Link as Link1 } from 'react-router-dom';
import { Typography, ListItemText, Checkbox, List, ListItem, ListItemIcon, Link, FormControl, InputLabel, Select, MenuItem, Badge, Button, Divider } from '@material-ui/core';
// import { useCatalogueStore } from '../../../../../Global/Stores/catalogueStore';
import { FixedSizeList } from 'react-window';
import _ from 'lodash';
import AutoSizer from "react-virtualized-auto-sizer";
import ContentColumn from '../../Custom/ContentColumn';
import { useAvcsoStore } from '../../../../Global/Stores/avcsoStore';
import { useGlobalStore } from '../../../../Global/Stores/globalStore';
import { useParams } from "react-router";

const AVCSO_SelectItems = () => {
    const [, globalActions] = useGlobalStore();

    const loaded = globalActions.isLoaded();

    return (
        <ContentColumn loaded={loaded}>
            {/* <div className="contentColumnHeader" style={{margin: '10px'}}>
                <Typography variant="h6" display="block" >Add Licenses</Typography>
            </div> */}

            <div className="contentColumnBody" style={{ display: 'flex', flexDirection: 'row', marginLeft: '10px' }}>
                <Divider />
                <ProductScaleSelector />
                <AvcsoItemFooter />
            </div>

            <div className="contentColumnFooter" style={{ justifyContent: 'space-between', margin: '10px' }}>
                <hr />
                <AvcsoItemList />
            </div>

        </ContentColumn>
    );
}

const ProductScaleSelector = () => {

    const [, globalActions] = useGlobalStore();
    const [avcsoState, avcsoActions] = useAvcsoStore();

    const PGID = avcsoState.productGroup;
    const selectedScaleIDs = avcsoState.productItemGroups;

    const productScales = globalActions.getProductItemGroupsLocal(PGID);

    // const selectedScales = selectedIDs;
    const disabled = productScales.length === 0;

    // const PGID = productGroup;
    const valid = PGID > 0 && productScales.length > 0;

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        if (inputLabel.current) {
            setLabelWidth(inputLabel.current.offsetWidth);
        }
    }, [valid]);


    const handleScaleChange = (e) => {
        const newScales = e.target.value;
        avcsoActions.setProductItemGroups(newScales);
    }

    if (!valid)
        return null;


    return (
        <FormControl variant="outlined" style={{ minWidth: 20 }} disabled={disabled} margin="dense">
            <InputLabel ref={inputLabel}>Select Scales</InputLabel>
            <Select
                style={{ width: '100px' }}
                multiple
                value={selectedScaleIDs}
                renderValue={() => <div>Scale [{selectedScaleIDs.length}/{productScales.length}]</div>}
                onChange={handleScaleChange}
                labelWidth={labelWidth}
            >
                {
                    productScales && productScales.map(PIG => {

                        // const count = catalogueActions.getSelectedProductGroupItemCount(PIG.productItemGroupID);
                        // const count = 1;
                        const checked = selectedScaleIDs.includes(PIG.productItemGroupID);

                        // const countText = count > 0 ? ` [${count}]` : "";

                        return (
                            <MenuItem key={PIG.productItemGroupID} value={PIG.productItemGroupID}>
                                <Checkbox checked={checked} color="primary" />
                                <ListItemText primary={`${PIG.caption}`} />
                            </MenuItem>
                        )
                    })
                }
            </Select>
        </FormControl>
    );
}

const AvcsoItemList = () => {

    const [, globalActions] = useGlobalStore();
    const [avcsoState, avcsoActions] = useAvcsoStore();
    const { selectedItemIDs, avcsoItemIDs, avcsoLines, licenseLines, productItemGroups } = avcsoState;

    const pars = useParams();
    const focusedItemID = +pars.itemID || 0;
    //const focusedItemID = 0;


    useEffect(() => {
        // console.log('setAvcsoLines 1');
        if (avcsoLines.length === 0) {

            const items = globalActions.getCatalogueItems(avcsoItemIDs);
            //console.log('setAvcsoLines 2', items);
            avcsoActions.setAvcsoLines(items);
        }

    }, [avcsoLines, avcsoActions, globalActions, avcsoItemIDs])

    // const items = globalActions.getCatalogueItems(avcsoItemIDs).filter(X => productItemGroups.includes(X.productItemGroupID));
    const items = avcsoLines.filter(X => productItemGroups.includes(X.productItemGroupID));

    // console.log('items', items);

    const ref = useRef(null);
    useEffect(() => {
        if (ref && ref.current && focusedItemID > 0) {
            const focusedIndex = _.findIndex(items, (I) => I.itemID === focusedItemID);
            // console.log('focusedItemID', focusedItemID, ref.current)
            ref.current.scrollToItem(focusedIndex);
        }
    }, [focusedItemID, items]);


    const itemCount = items.length;
    const vesselItemIDs = licenseLines.map(X => X.itemID);

    // console.log('licenseLines', licenseLines);

    return (
        // <Collapse in={open} timeout="auto">
        <div style={{ height: 400 }}>
            <AutoSizer>
                {
                    ({ width, height }) => (

                        <List component="div" disablePadding>
                            <FixedSizeList
                                ref={ref}
                                height={height}
                                width={width}
                                itemSize={61}
                                itemCount={itemCount}
                                style={{ border: '0px solid rgba(0, 0, 0, 0.2)' }}

                                itemData={
                                    {
                                        listItems: items,
                                        selectedItemIDs: selectedItemIDs,
                                        vesselItemIDs: vesselItemIDs,
                                        focusedItemID: focusedItemID,
                                    }
                                }
                            >
                                {AvcsoItemListRow}
                            </FixedSizeList>
                        </List>

                    )
                }
            </AutoSizer>
        </div>
    );
}

const AvcsoItemListRow = ({ data, index, style }) => {
    // const history = useHistory();
    const [, globalActions] = useGlobalStore();
    const [, avcsoActions] = useAvcsoStore();

    const { listItems, selectedItemIDs, vesselItemIDs, focusedItemID } = data;

    const item = listItems[index];
    const itemID = item.itemID;
    //const item = Boolean(I) ? I.itemID : 0;


    //console.log('I', item, itemID);
    // console.log('I', itemID, focusedItemID);

    // const vesselItemIDs = licenseLines.map(X => X.itemID);

    const focused = itemID === focusedItemID;
    const selected = selectedItemIDs.includes(itemID);
    const inInventory = vesselItemIDs.includes(itemID);

    return React.useMemo(() => {

        // const item = globalActions.getCatalogueItem(itemID);
        //console.log('Row', itemID, item, focused);

        if (itemID <= 0 || !Boolean(item)) {
            return null;
        }

        const handleItemChecked = (id) => {
            avcsoActions.toggleSelectedItem(id);
        }

        return (
            <Link component={Link1} to={`${itemID}`} color='inherit' style={{ textDecoration: 'none' }}>
                <ListItem button disableRipple dense divider
                    key={itemID}
                    style={style}
                    disabled={inInventory}
                    selected={focused}
                    onClick={() => { handleItemChecked(itemID) }}
                >
                    <ListItemIcon>
                        <Checkbox
                            color="primary"
                            disableRipple
                            checked={selected}
                            // onClick={() => { handleItemChecked(itemID) }}
                        />
                    </ListItemIcon>
                    <ListItemText primary={item.itemNumber} secondary={item.itemTitle} style={{ maxHeight: '61px', overflow: 'hidden' }} />
                </ListItem>
            </Link>
        );

    }, [itemID, selected, inInventory, focused, globalActions, avcsoActions, item, style]);
};

const AvcsoItemFooter = () => {

    const [avcsoState, avcsoActions] = useAvcsoStore();
    const { selectedItemIDs } = avcsoState;

    const selectedCount = selectedItemIDs.length;
    const hasSelected = selectedCount > 0;

    const handleAddToBasket = () => {
        // console.log('handleAddToBasket', selectedItemIDs);
        avcsoActions.addselectedToBasket();
    }

    return (
        <div style={{ marginLeft: 5, marginTop: 5 }}>
            <Badge
                color="secondary"
                badgeContent={selectedCount}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}

            >
                <Button variant="contained" size="large" disabled={!hasSelected} onClick={handleAddToBasket}>Add to Basket</Button>
            </Badge>
        </div>)

}

export default AVCSO_SelectItems;
