import React, { useEffect, useState } from 'react';
import HoldingDetailHeader from '../VesselHoldingsDetail/HoldingDetailHeader';
import HoldingDetailList from '../VesselHoldingsDetail/HoldingDetailList';
import { useFleetStore } from '../../../../../Global/Stores/fleetStore';
import HoldingsFilter from '../../../../Widgets/Filters/HoldingsFilter';
import ContentColumn from '../../../Custom/ContentColumn';
import _ from 'lodash';
import { Divider } from '@material-ui/core';

const VesselHoldingsDetail = ({ match }) => {
    const vesselID = +match.params.vesselID;
    const itemID = +match.params.itemID;
    const [fleetState, fleetActions] = useFleetStore();


    const [selectedGroups, setSelectedGroups] = useState([]);

    // console.log('fleetState', fleetState);

    useEffect(
        () => {
            fleetActions.getVesselHoldings(vesselID);

            return () => {
                fleetActions.clearVesselHoldings();
            }
        },
        [vesselID]
    );

    const toggleSelectedGroup = (pgid) => {

        //console.log("toggleSelectedGroup", pgid);
        // const newState = selectedGroups.includes(pgid) ? selectedGroups.filter(X => X !== pgid) : [...selectedGroups, pgid];
        // setSelectedGroups(prev => prev.includes(pgid) ? prev.filter(X => X !== pgid) : [...prev, pgid]);
        setSelectedGroups(prev => prev.includes(pgid) ? [] : [pgid]);
    }


    const loaded = _.get(fleetState, 'holdingDetail.vessel.vesselID', -1) === vesselID;
    const filterID = +_.get(fleetState, 'holdingDetail.filter.id', 0);

    // console.log('filterID', { filterID, ...selectedGroups });


    return (
        <ContentColumn loaded={loaded} selectedID={{ filterID, ...selectedGroups }}>
            {
                loaded ? (
                    <React.Fragment>
                        <div className="contentColumnHeader"  style={{ padding: '5px 0px', margin: "-10px 0px 0px 0px", width: '100%', backgroundColor: '#fff', zIndex: 1, position: 'absolute' }}>
                            <HoldingDetailHeader
                                holdingDetail={fleetState.holdingDetail}
                            />
                        </div>

                        <br /><br /><br />
                        <Divider /><br />
                        <div className="contentColumnFilter" style={{ justifyContent: 'space-between', paddingRight: '8px' }}>
                            <HoldingsFilter
                                productItemGroups={fleetState.holdingDetail.productItemGroups}
                                fleetActions={fleetActions}
                                selectedGroups={selectedGroups}
                            />
                        </div>

                        <div className="contentColumnBody">
                            <HoldingDetailList
                                holdingDetail={fleetState.holdingDetail}
                                selectedGroups={selectedGroups}
                                toggleSelectedGroup={toggleSelectedGroup}
                                selectedItemID={itemID} />
                        </div>
                    </React.Fragment>) : null

            }
        </ContentColumn>
    );
};

export default VesselHoldingsDetail;
