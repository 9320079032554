import React, { createContext, useContext, useState, useEffect } from 'react';
import http from './../Services/httpService';
import _ from 'lodash';
import { useUserStore } from './userStore';

const initialState = {
    summary: {},
    // productGroups: null,
    vesselQuotes: null,
    quoteDetail: null,

    vesselOrders: null,
    orderDetail: null,

    vesselShipments: null,
    shipmentDetail: null,

    vesselFlatFees: null,
    flatFeeDetail: null,
    flatFeeOrderDetail: null,

    catalogue: {
        vesselID: 0,
        items: null, // [{}]
        vesselItems: [],
        routeItems: [],
        closedProductGroups: [],
        selectedItems: [],
        itemPrices: [],
        selectedPrices: new Object(),
        selectedDuration: 12,
    },
};

const initialCatalogue = {
    vesselID: 0,
    items: null,
    vesselItems: [],
    routeItems: [],
    closedProductGroups: [],
    selectedItems: [],
    itemPrices: [],
    selectedPrices: new Object(),
    selectedDuration: 12,
}

export const SaleContext = createContext();

export const SaleProvider = ({ children }) => {
    const [state, setState] = useState(initialState);


    // // Clear data when logout
    // const [userState] = useUserStore();
    // const logout = !Boolean(userState.tokenData.token);
    // useEffect(() => {
    //     if (logout === true) {
    //         console.log('SaleProvider logout', logout);
    //         setState(P => initialState);
    //     }
    // }, [logout]);


    const loadData = () => {
        //console.log("loadData");
        getSummary();
        getVesselQuotes();
        getVesselOrders();
    };

    const clearData = () => {
        //console.log("clearData");
        setState(() => { return { ...initialState } });
    }

    const getSummary = (force = false) => {

        if (force || !state.summary.approvals) {
            http.get('/api/sales/summary')
                .then(result => {
                    setState(prev => {
                        return {
                            ...prev,
                            summary: result.data
                        }
                    });
                })
                .catch(error => {
                    //alert(error);
                });
        }
    };


    const getVesselQuotes = () => {

        http.get('/api/sales/vesselquotes')
            .then(result => {
                setState(prev => {
                    // console.log('getVesselQuotes', state);
                    return {
                        ...prev,
                        vesselQuotes: result.data
                    }
                });
            })
            .catch(error => {
                //alert(error);
            });
    };

    const getSalesQuote = (salesQuoteID) => {

        http.get(`/api/sales/quotes/${salesQuoteID}`)
            .then(result => {
                // const vesselQuote = result.data;
                setState((prev) => {
                    return {
                        ...prev,
                        quoteDetail: result.data,
                        // productGroups: result.data.productGroups,
                    }
                });

            })
            .catch(error => {
                //alert(error);
            });
    };

    const validateSalesQuoteLine = (salesQuotelineID, validationID) => {

        setState((prev) => {
            const newQuoteDetail = { ...prev.quoteDetail };

            newQuoteDetail.quote.lines
                .find(X => X.salesQuoteLineID === salesQuotelineID)
                .validationID = validationID;

            return { ...prev, quoteDetail: newQuoteDetail }
        });
    }

    const validateSalesQuote = (validationID) => {

        setState((prev) => {
            const newQuoteDetail = { ...prev.quoteDetail };

            _.forEach(newQuoteDetail.quote.lines, (L) => { L.validationID = validationID })

            // newQuoteDetail.quote.lines.forea
            //     .find(X => X.salesQuoteLineID === salesQuotelineID)
            //     .validationID = validationID;

            return { ...prev, quoteDetail: newQuoteDetail }
        });
    }

    const confirmSalesQuote = (req) => {
        http.post('/api/sales/quotes/confirm', { ...req })
            .then(() => {

                getVesselQuotes();

                const quote = { ...state.quoteDetail, confirmed: true }
                setState((prev) => {
                    return {
                        ...prev,
                        quoteDetail: quote,
                    }
                });
            })
            .catch(error => {
                //alert(error);
            });


        const quote = { ...state.quoteDetail, confirmed: true }
        // console.log('quote', quote);
        setState((prev) => {
            return {
                ...prev,
                quoteDetail: quote,
            }
        });
    }

    const selectSalesQuoteLine = (salesQuotelineID) => {

        setState((prev) => {
            const newQuote = {
                ...prev.quoteDetail,
                selectedLineID: salesQuotelineID
            };

            return {
                ...prev,
                quoteDetail: newQuote
            }
        });
    }


    const clearSalesQuote = () => {

        setState(prev => {
            return {
                ...prev,
                quoteDetail: null
            }
        });
    };

    // Orders
    const getVesselOrders = () => {

        http.get('/api/sales/vesselorders')
            .then(result => {
                setState(prev => {
                    // console.log('getVesselOrders', result.data);
                    return {
                        ...prev,
                        vesselOrders: result.data
                    }
                });
            })
            .catch(error => {
                //alert(error);
            });
    };

    const getSalesOrder = (salesOrderID) => {

        http.get(`/api/sales/orders/${salesOrderID}`)
            .then(result => {
                setState(prev => {
                    return {
                        ...prev,
                        orderDetail: result.data,
                        // productGroups: result.data.productGroups,
                    }
                });
            })
            .catch(error => {
                //alert(error);
            });
    };


    const filterSalesOrder = (filterIDs) => {
        setState((prev) => {
            const newQuote = { ...prev.orderDetail };
            newQuote.statusFilter = filterIDs;

            return {
                ...prev,
                orderDetail: newQuote
            }
        });
    }

    const clearSalesOrder = () => {

        setState(prev => {
            return {
                ...prev,
                orderDetail: null
            }
        });
    };





    const setCatalogueVessel = (vesselID) => {
        // console.log('setCatalogueVessel', vesselID);
        setState(prev => {
            const cat = vesselID === 0 ? { ...initialCatalogue } : { ...prev.catalogue };
            cat.vesselID = vesselID;

            return {
                ...prev,
                catalogue: cat,
            }
        });
    }

    // const setSelectedCatalogueItems = (items) => {
    //     // console.log('setSelectedCatalogueItems', items);
    //     setState(prev => {
    //         const cat = { ...prev.catalogue }
    //         cat.selectedItems = items;
    //         return {
    //             ...prev,
    //             catalogue: cat,
    //         }
    //     });
    // }

    // const setCatalogueItems = (items) => {
    //     // console.log('setCatalogueItems', items);
    //     setState(prev => {
    //         const cat = { ...prev.catalogue }
    //         cat.items = items;
    //         return {
    //             ...prev,
    //             catalogue: cat,
    //         }
    //     });
    // }



    const getVesselShipments = () => {

        http.get('/api/sales/shipments')
            .then(result => {
                const shipments = result.data;
                // console.log('shipments', shipments);

                setState(prev => {
                    return {
                        ...prev,
                        vesselShipments: shipments
                    }
                });
            })
            .catch(error => {
                //alert(error);
            });
    };

    const getVesselShipment = (salesShipmentID) => {

        http.get(`/api/sales/shipments/${salesShipmentID}`)
            .then(result => {
                const detail = result.data;
                // console.log('shipment detail', detail);

                setState(prev => {
                    return {
                        ...prev,
                        shipmentDetail: detail
                    }
                });
            })
            .catch(error => {
                //alert(error);
            });
    };


    // FlatFees
    const getVesselFlatFees = () => {

        http.get('/api/sales/flatfees')
            .then(result => {
                const flatFees = result.data;

                // console.log('flatfees', flatFees);

                setState(prev => {
                    return {
                        ...prev,
                        vesselFlatFees: flatFees
                    }
                });
            })
            .catch(error => {
                //alert(error);
            });
    };


    const getVesselFlatFeeOrders = (vesselFlatFeeID) => {

        http.get(`/api/sales/flatfees/${vesselFlatFeeID}`)
            .then(result => {
                const detail = result.data;

                // console.log('FlatFee Detail', detail);

                setState(prev => {
                    return {
                        ...prev,
                        flatFeeDetail: detail
                    }
                });
            })
            .catch(error => {
                //alert(error);
            });
    }

    const getVesselFlatFeeOrderDetails = (vesselFlatFeeID, purchaseOrderID) => {

        http.get(`/api/sales/flatfees/${vesselFlatFeeID}/${purchaseOrderID}`)
            .then(result => {
                const detail = result.data;

                // console.log('getVesselFlatFeeOrderDetail', detail);

                setState(prev => {
                    return {
                        ...prev,
                        flatFeeOrderDetail: detail
                    }
                });
            })
            .catch(error => {
                //alert(error);
            });
    }



    const toggleCatalogueClosedProductGroups = (PGID) => {

        setState(prev => {
            const cat = { ...prev.catalogue };
            const isOpen = cat.closedProductGroups.includes(PGID);

            cat.closedProductGroups = isOpen ? cat.closedProductGroups.filter(X => X !== PGID) : [...cat.closedProductGroups, PGID];
            return {
                ...prev,
                catalogue: cat,
            }
        });
    }

    const actions = {
        loadData: loadData,
        clearData: clearData,

        getSummary: getSummary,

        getVesselQuotes: getVesselQuotes,
        getSalesQuote: getSalesQuote,
        clearSalesQuote: clearSalesQuote,

        confirmSalesQuote: confirmSalesQuote,
        validateSalesQuote: validateSalesQuote,
        validateSalesQuoteLine: validateSalesQuoteLine,
        selectSalesQuoteLine: selectSalesQuoteLine,

        getVesselShipments: getVesselShipments,
        getVesselShipment: getVesselShipment,

        getVesselOrders: getVesselOrders,
        getSalesOrder: getSalesOrder,

        getVesselFlatFees: getVesselFlatFees,
        getVesselFlatFeeOrders: getVesselFlatFeeOrders,
        getVesselFlatFeeOrderDetails: getVesselFlatFeeOrderDetails,

        setCatalogueVessel: setCatalogueVessel,
        toggleCatalogueClosedProductGroups: toggleCatalogueClosedProductGroups,

        filterSalesOrder: filterSalesOrder,
        clearSalesOrder: clearSalesOrder,
    };

    return (
        <SaleContext.Provider value={[state, actions]}>
            {children}
        </SaleContext.Provider >
    )
};

export const useSaleStore = () => useContext(SaleContext);


