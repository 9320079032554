import React from 'react'
import NavBar from './NavBar/NavBar';
import Home from './Home/Home';
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import './../../Styles/Desktop.scss';
import Sales from './Sales/Sales';
import Fleet from './Fleet/Fleet';
import Folios from './Folios/Folios';
import Account from './Account/Account';
import News from './News/News';
import FloatingMenuButton from './NavBar/FloatingMenuButton'
import Routes from './Routes/Routes';

const Desktop = () => {

    const location = useLocation();
    const cPage = location.pathname.split("/");

    // Since news items return numbers, we don't want to display numbers in breadcrumbs so we will simply display nothing.
    if(cPage[1] == "news") // If this spelling changes we are done! :D
    {
        cPage[2] = null;
    }
    return (
        <div className="desktopContainer">
            <div className="desktopHeader">
                <NavBar Module={cPage[1]} currentPage={cPage[2]} />
            </div>
            <div className="desktopContent">
                {/* Floating button for switching to Mobile */}
                <FloatingMenuButton /> 
                {
                    <Switch>
                        {/* <Route exact path="/" component={Home} /> */}
                        <Route path="/home" component={Home} />
                        <Route path="/sales" component={Sales} />
                        <Route path="/fleet" component={Fleet} />
                        <Route path="/folios" component={Folios} />
                        <Route path="/account" component={Account} />
                        <Route path="/news" component={News} />
                        {/* <Route path="/help" component={HelpPage} /> */}

                        {/* Profile */}
                        {/*<Route path="/map" component={MapControl} /> */}
                        <Redirect to="/home" />
                    </Switch>
                }
            </div>
        </div>
    )
}

export default Desktop
