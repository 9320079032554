import React, { useEffect } from 'react';
import { useNotificationStore } from '../../Global/Stores/notificationStore';
import { Snackbar } from '@material-ui/core';
import CustomSnackbarContentWrapper from './CustomSnackbarContentWrapper';
import axios from './../../Global/Services/httpService';
import storage from 'store';
import _ from 'lodash';

const Notifications = () => {
    const [notificationState, notificationActions] = useNotificationStore();
    // console.log('AppNotification', notificationState);



    useEffect(() => {

        axios.instance.interceptors.response.use(val => {

            const toast = _.get(val, 'data.toast', null);
            if (!!toast) {

                const { type, message } = toast;
                const variant = type.toLowerCase();
                notificationActions.showNotification({ variant: variant, message: message });
            }

            return Promise.resolve(val);

        }, error => {

            // console.log('interceptors message', error.response);

            // const resp = error.response;
            // console.log('interceptors response', resp);

            if (error.response && error.response.status === 401) {
                notificationActions.showNotification({ variant: 'error', message: 'Unauthorized' });
            }
            else if (error.response && error.response.status !== 401) {

                const toast = _.get(error.response, 'data.toast', null);
                // console.log('toast', toast);
                if (!!toast) {
                    const { type, message } = toast;
                    const variant = type.toLowerCase();
                    notificationActions.showNotification({ variant: variant, message: message });
                }
                else {
                    notificationActions.showNotification({ variant: 'error', message: error.message || 'Unable to perform the operation' });
                }
            }
            else if (!error.response) {
                notificationActions.showNotification({ variant: 'error', message: error.message || 'Could not access the remote server' });
            }

            return Promise.reject(error);
        });

    }, []);

    function handleClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        notificationActions.closeNotification();
        // setOpen(false);
    }

    if (notificationState.notifications && notificationState.notifications.length === 0) {
        // console.log('NULL')
        return null;
    }


    return (
        <div>
            {/* <Button variant="outlined" onClick={handleClick}>Open success snackbar</Button> */}
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={notificationState.notifications.length > 0}
                autoHideDuration={notificationState.autoHideDuration}
                onClose={handleClose}
            >
                <CustomSnackbarContentWrapper
                    onClose={handleClose}
                    variant={notificationState.notifications[0].variant}
                    message={notificationState.notifications[0].message}
                />
            </Snackbar>
            {/* {notificationState.open &&  <CustomSnackbarContentWrapper
                onClose={handleClose}
                variant={notificationState.variant}
                message={notificationState.message}
            />} */}
        </div>
    );
};

export default Notifications;
