import React, { createContext, useContext, useState } from 'react';

export const ColumnContext = createContext();

export const ColumnProvider = ({ children }) => {
    const [state, setState] = useState(0);

    // useEffect(() => {
    //console.log('ColumnStore', state);

    // }, [state]);

    const addColumn = (val) => {
        setState(prev => prev + val);
    }
    
    const actions = {
        addColumn,
    };

    return (
        <ColumnContext.Provider value={[state, actions]}>
            {children}
        </ColumnContext.Provider >
    );
};

export const useColumnStore = () => useContext(ColumnContext);
