import React, { useMemo } from 'react';
//import { getHoldingLineColor } from '../Helpers/GlobalHelper'; When the going gets tough, we customize everything. :D
import ItemPolygon from './ItemPolygon';

const FolioLinePolygon = ({ line, selected, blackColor = false }) => {

    return useMemo(() => {
        if (!line.polygonPoints)
            return null;

        const color = blackColor ? "#000000" : getHoldingLineColor(line);

        const positions = line.polygonPoints;
        return (
            <ItemPolygon
                positions={positions}
                // fill={true}
                fillColor={color}
                color={color}
                // fillOpacity={0.2}
                selected={selected}
            />
        );
    }, [selected, line])

};


// took out the black color. I just feel like it is what it is. either you got it or not. plus people don't understand black. 
const getHoldingLineColor = (line) => {
    //console.log("active: ", line.active, "inInventory: ", line.inInventory)
    //console.log(line);
    let color = "black";
    // if (!line.active)
    //     color = "black"
    // else 
    if (line.inInventory && line.active)
        color = "#1dc71d" // Green
    else if (line.inInventory && !line.active)
        color = "#ff9900" // Warning
    else if (!line.inInventory && !line.active)
        color = "#ff4a4a"; // Error

    return color;
};

export default FolioLinePolygon;
