import React, { useMemo } from 'react';
import './OrderStatus.scss';

const OrderStatus = ({ order }) => {

    // console.log('OrderStatus', order);

    const { openQuantity, backOrderQuantity, deliveredQuantity, shippedQuantity, totalQuantity } = order;


    // console.log('OrderStatus', order);

    return useMemo(() => {

        const total = totalQuantity;
        const percentageOpen = (openQuantity > 0 ? openQuantity / total : 0) * 100;
        const percentageBackorder = (backOrderQuantity > 0 ? backOrderQuantity / total : 0) * 100;
        const percentageShipped = (shippedQuantity > 0 ? shippedQuantity / total : 0) * 100;
        const percentageDelivered = (deliveredQuantity > 0 ? deliveredQuantity / total : 0) * 100;

        const degreesOpen = Math.ceil((percentageOpen * 360) / 100);
        const degreesBackorder = Math.ceil((percentageBackorder * 360) / 100);
        const degreesShipped = Math.ceil((percentageShipped * 360) / 100);
        const degreesDelivered = Math.ceil((percentageDelivered * 360) / 100);

        const tooltip = "Open: " + openQuantity + "\n" +
            "Backorder: " + backOrderQuantity + "\n" +
            "Shipped: " + shippedQuantity + "\n" +
            "Delivered: " + deliveredQuantity + "\n\n" +
            "Total: " + totalQuantity;

        const val = [
            {
                id: 1,
                start: 0,
                length: degreesOpen
            },
            {
                id: 2,
                start: degreesOpen,
                length: degreesBackorder
            },
            {
                id: 3,
                start: degreesOpen + degreesBackorder,
                length: degreesShipped
            },
            {
                id: 4,
                start: degreesOpen + degreesShipped + degreesBackorder,
                length: degreesDelivered
            },
        ];

        // console.log('val', val);

        return (
            <div className="order-chart" title={tooltip}>
                {
                    val.map(X => (<div key={X.id} className={X.length > 180 ? "pie-order big" : "pie-order"} data-start={X.start} data-value={X.length}></div>))
                }
                <p className="chart-text">{totalQuantity}</p>
            </div>
        )

    }, [openQuantity, backOrderQuantity, deliveredQuantity, shippedQuantity, totalQuantity])


}

export default OrderStatus
