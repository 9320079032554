import React, { useMemo } from 'react';
import { Typography } from '@material-ui/core';

const OrderDetailFooter = ({ order }) => {

    const totalPrice = { order };

    return useMemo(() => {
        return (
            <Typography variant="subtitle2" align="right" style={{ flexGrow: '1' }}>
                {`Total Price: ${order.currency} ${order.totalPrice.toFixed(2)}`}&nbsp;&nbsp;&nbsp;
            </Typography>
        );
    }, totalPrice);
};

export default OrderDetailFooter;
