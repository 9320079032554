import React, { useEffect } from 'react';
import { Route } from "react-router-dom";
import MapControl from '../../MapControl/MapControl';
import { useFleetStore } from '../../../Global/Stores/fleetStore';
import { useFolioStore } from '../../../Global/Stores/folioStore';
import ViewFolios from '../Folios/ViewFolios/ViewFolios';
import Holdings from './Holdings/Holdings';
//import VesselFolio from './Folios/ListFolios/VesselFolios';
import FolioItems from './Holdings/FolioHoldingsDetail/FolioItems';


const Folio = ({ match }) => {

//     //const [, folioActions] = useFolioStore();
//     const [, fleetActions] = useFleetStore();

//     // const selectedCompanyID = +match.params.companyID || 0;
//     // console.log(selectedCompanyID);


//     useEffect(
//         () => {
//             //fleetActions.getHoldingsOverview();
//             fleetActions.getHoldingsOverview();
//             fleetActions.getFleetPositions();
//             //folioActions.getFolioOverview();
// //            folioActions.getManagers();
//         }, []
//     );

    return (
        <div className="fleetContainer">
            {/* Folio Management*/}
            <Route path="/folios/status/:managerID?/:folioID?" component={ViewFolios} />
            {/* <Route exact path="/folios/manage/:companyID?/:folioID?/details/:vesselID?" component={FolioItems} /> */}
            <Route exact path="/folios/status/:managerID?/:folioID?/details/:vesselID?/:itemID?" component={FolioItems} />

            <MapControl  showMenu={true} menuOrientation="ver"/>
        </div>
    );
};

export default Folio;
