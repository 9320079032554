import React, { useMemo } from 'react';
import { Button } from '@material-ui/core';
import { useSaleStore } from '../../Global/Stores/saleStore';

const QuoteLineStatusButton = ({ line, activeValue, text }) => {

    const [, salesActions] = useSaleStore();

    return useMemo(() => {

        const setValidation = () => {
            if (line.validationID !== activeValue) {
                // console.log('Setvalidation', line.salesQuoteLineID);
                salesActions.validateSalesQuoteLine(line.salesQuoteLineID, activeValue);
            }
        }

        return (
            <Button
                variant="outlined"
                className={line.validationID === activeValue ? `validationButton-${text.toLowerCase()}` : ""}
                disableRipple
                onClick={setValidation}>
                {text}
            </Button>
        );

    }, [line, line.validationID])


};

export default QuoteLineStatusButton;
