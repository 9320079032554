import React, { useEffect, useMemo } from 'react';
import { Collapse, List } from '@material-ui/core';
import _ from 'lodash';
// import storage from 'store';
import OrderStatusVessel from '../../../Widgets/OrderStatusVessel';
import ListItemVessel from '../../_StyledComponents/ListItemVessel';
import { ListItemSubOrder } from '../../_StyledComponents/ListItemSub';

const VesselOrders = ({ vesselOrders, selectedSalesOrderID }) => {

    const key = `VOL${vesselOrders.vesselID}`;
    const openLoad = JSON.parse(sessionStorage.getItem(key)) || false;

    // console.log('openLoad', openLoad)

    const [open, setOpen] = React.useState(openLoad);
    useEffect(() => { sessionStorage.setItem(key, open); }, [open]);

    const orders = _.orderBy(vesselOrders.orders, ['reference'], ['desc']);


    return (
        <ListItemVessel
            vessel={vesselOrders}
            // number={orders.length}
            open={open}
            setOpen={setOpen}
            selected={open}
            subSelectedID={selectedSalesOrderID}
            statusComponent={<OrderStatusVessel orders={orders} />}
        >

            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {
                        orders.map(X => (<ListItemSubOrder key={X.salesOrderID} order={X} selected={selectedSalesOrderID === X.salesOrderID} />))
                    }
                </List>
            </Collapse>

        </ListItemVessel>
    )


    // return (
    //     <div className="ListItemHeader">
    //         <ListItem button onClick={() => setOpen(!open)} selected={open}>
    //             <ListItemIcon>
    //                 <SendIcon />
    //             </ListItemIcon>
    //             <ListItemText primary={vesselOrders.vesselName} />

    //             <OrderStatusVessel orders={orders} />
    //             {/* <Chip color="primary" variant="default" label={orders.length} size="small" /> */}

    //             {open ? <ExpandLess /> : <ExpandMore />}
    //         </ListItem>
    //         <Collapse in={open} timeout="auto" unmountOnExit>
    //             <List component="div" disablePadding>
    //                 {
    //                     orders.map(X => (<VesselOrder key={X.salesOrderID} order={X} selectedSalesOrderID={selectedSalesOrderID} selected={selectedSalesOrderID === X.salesOrderID} />))
    //                 }
    //             </List>
    //         </Collapse>
    //     </div>
    // );
};


// const VesselOrder = ({ order, selected }) => {


//     return useMemo(() => {
//         // console.log('Selected', selected);
//         const to = `/sales/orders/${order.salesOrderID}`;

//         return ((
//             <Link className="ListItemLink" component={RouterLink} to={to} color='inherit' style={{ textDecoration: 'none' }}>
//                 <ListItem
//                     key={order.salesOrderID}
//                     selected={selected}
//                     button
//                     disableRipple
//                     divider>

//                     <ListItemText
//                         primary={`${order.reference} [${order.customerReference}]`}
//                         secondary={`${order.orderDate + " - "} Total: ${order.currency} ${order.totalPrice.toFixed(2)}`}
//                     />

//                     <OrderStatus order={order} />
//                     {/* <Chip size="small" color="primary" variant="outlined" label={order.totalQuantity} /> */}
//                 </ListItem>
//             </Link>
//         ));
//     }, [selected])

// };

export default VesselOrders;
