import React, { useEffect } from 'react';
import { Route } from "react-router-dom";

import { useFleetStore } from '../../../Global/Stores/fleetStore';
import Holdings from './Holdings/Holdings';
import VesselHoldingsLineDetail from './Holdings/VesselHoldingsLineDetail/VesselHoldingsLineDetail';
import VesselHoldingsDetail from './Holdings/VesselHoldingsDetail/VesselHoldingsDetail';

import BreadCrumb from '../Navigation/Breadcrumb';


const FleetHoldings = () => {

    const [, fleetActions] = useFleetStore();

    useEffect(
        () => {
            fleetActions.getHoldingsOverview();
            fleetActions.getFleetPositions();
        }, []
    );

    return (
            <React.Fragment>
                <BreadCrumb Module="Fleet Manager" currentPage="Fleet Holdings" />
                
                <Route exact path="/fleet/holdings/" component={Holdings} />
                <Route exact path="/fleet/holdings/:vesselID/:itemID?" component={VesselHoldingsDetail} />
                <Route exact path="/fleet/holdings/:vesselID/:itemID" component={VesselHoldingsLineDetail} />
            
            <br /><br /><br />
            </React.Fragment>
        );
};

export default FleetHoldings;
