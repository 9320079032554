import React, { useMemo } from 'react';
import { ListItem, ListItemText, Chip, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import NumberStatus from '../../Widgets/NumberStatus';
import { makeStyles } from '@material-ui/styles';
import { useGlobalStore } from '../../../Global/Stores/globalStore';
import OrderStatus from '../../Widgets/OrderStatus';
// import QuoteStatus from '../../Widgets/QuoteStatus';

const useStyles = makeStyles({
    link: {
        color: 'inherit',
        textDecoration: 'none',
    },
    // listItem: {
    //     backgroundColor: '#e9edf7',
    //     textDecoration: 'none',
    // },
});

const ListItemSub = ({ mainText, subText, number, selected, linkTo, statusComponent }) => {
    const classes = useStyles();
    return (
        <Link className={classes.link} component={RouterLink} to={linkTo} style={{ textDecoration: 'none' }} >
            <ListItem
                // style={{ marginLeft: '25px', backgroundColor: 'blue' }}
                className={classes.listItem}
                selected={selected}
                button
                disableRipple
                divider>

                <ListItemText inset primary={mainText} secondary={subText} />

                {/* <Chip size="small" color="primary" variant="outlined" label={number} /> */}
                {number ? <NumberStatus number={number} /> : null}

                {statusComponent ? statusComponent : null}

            </ListItem>
        </Link>
    );
};


export const ListItemSubQuote = ({ quote, selected }) => {
    // console.log('quote', quote);
    return useMemo(() => {
        // console.log('quote selected 2', selected);
        const mainText = `${quote.reference} [${quote.customerReference}]`;
        const subText = `${quote.quoteDate + " - "} Total: ${quote.currency} ${(quote.totalPrice || '').toFixed(2)}`;
        const to = `/sales/quotes/${quote.salesQuoteID}`;
        const number = quote.lineCount;
        return (<ListItemSub
            mainText={mainText}
            subText={subText}
            selected={selected}
            number={number}
            linkTo={to}
        // statusComponent={<QuoteStatus quote={quote} />} 
        />);

    }, [quote, selected])
}

export const ListItemSubOrder = ({ order, selected }) => {
    // console.log('order selected 1', selected);
    return useMemo(() => {
        // console.log('order selected 2', selected);
        const mainText = `${order.reference} [${order.customerReference}]`;
        const subText = `${order.orderDate + " - "} Total: ${order.currency} ${order.totalPrice.toFixed(2)}`;

        const to = `/sales/orders/${order.salesOrderID}`;
        const number = null;
        return (
            <ListItemSub
                mainText={mainText}
                subText={subText}
                selected={selected}
                number={number}
                linkTo={to}
                statusComponent={<OrderStatus order={order} />}
            />);

    }, [order, selected])
}


export const ListItemSubShipment = ({ shipment, selected }) => {
    // console.log('shipment selected 1', selected);
    return useMemo(() => {
        // console.log('shipment selected 2', selected);
        const mainText = `${shipment.reference} [${shipment.customerReference}]`;
        const subText = `${shipment.salesShipmentStatus}`;

        const to = `/sales/shipments/${shipment.salesShipmentID}`;
        const number = shipment.lineCount;
        return (<ListItemSub mainText={mainText} subText={subText} selected={selected} number={number} linkTo={to} />);

    }, [shipment, selected])
}


export const ListItemSubFlatFeeContract = ({ flatFeeContract, selected }) => {
    const [, globalActions] = useGlobalStore();

    return useMemo(() => {

        const { vesselFlatFeePeriodID, startDate, endDate, description, price, currencyID } = flatFeeContract;
        const currency = globalActions.getCurrency(currencyID).code;

        // console.log('order selected 2', selected);
        const mainText = description;
        const subText = `${startDate.split('T')[0]} to ${endDate.split('T')[0]} [${currency} ${price}]`;
        const to = `/sales/flatfee/${vesselFlatFeePeriodID}`;
        const number = null;

        return (<ListItemSub mainText={mainText} subText={subText} selected={selected} number={number} linkTo={to} />);

    }, [flatFeeContract, selected])
}

export default ListItemSub;
