
export const Layers = {
    "name": "Layers",
    "type": "L",
    "default": "X21,20,19,18,17,16,15,14,13,12,11,10,9,8,7,6,5,4,3,2,1,0",
    "value": "21,20,19,18,17,16,15,14,13,12,11,10,9,8,7,6,5,4,3,2,1,0",
    "Description": "Layers to be displayed.",
    "ExpectedInput": [
        { "code": 0, "value": "Display Base" },
        { "code": 1, "value": "Drying line" },
        { "code": 2, "value": "Buoys, beacons, structures,aids to navigation" },
        { "code": 3, "value": "Lights" },
        { "code": 4, "value": "Boundaries and limits" },
        { "code": 5, "value": "Prohibited and restricted areas" },
        { "code": 6, "value": "Chart scale boundaries" },
        { "code": 7, "value": "Cautionary notes" },
        { "code": 8, "value": "Ships' routeing systems and ferry routes" },
        { "code": 9, "value": "Archipelagic sea lanes" },
        { "code": 10, "value": "Standard Miscellaneous" },
        { "code": 11, "value": "Spot soundings" },
        { "code": 12, "value": "Submarine cables and pipelines" },
        { "code": 13, "value": "All isolated dangers" },
        { "code": 14, "value": "Magnetic variation" },
        { "code": 15, "value": "Depth contours" },
        { "code": 16, "value": "Seabed" },
        { "code": 17, "value": "Tidal" },
        { "code": 18, "value": "Low accuracy symbol"},
        { "code": 19, "value": "Shallow Water Pattern"},
        { "code": 20, "value": "Other Miscellaneous"},
        { "code": 21, "value": "Admiralty Information Overlay"},
    ]
};