import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function FormDialog({ quote, active, salesActions }) {
    const [open, setOpen] = React.useState(false);
    const [reference, setReference] = React.useState("reference");

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    useEffect(() => {
        setReference(quote.customerReference);
    }, [quote.salesQuoteID])


    const handleReference = (e) => {
        // console.log('e.value', e.target.value);
        const val = e.target.value;
        setReference(() => val);
    }

    const handleConfirm = () => {

        const lines = quote.lines.map(X => (
            {
                accept: X.validationID === 1,
                salesQuoteLineID: X.salesQuoteLineID,
            }));

        const req = {
            vesselID: quote.vesselID,
            salesQuoteID: quote.salesQuoteID,
            reference: reference,
            lines: lines,
        }

        salesActions.confirmSalesQuote(req);
        
        handleClose();
    }

    return (
        <React.Fragment>
            <Dialog
                open={open}
                scroll="paper"
                onClose={handleClose}
                // TransitionComponent={Transition}
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Are you sure you want to confirm the quotation?</DialogTitle>
                <DialogContent>
                    <DialogContentText variant="subtitle2">If needed, you can change the reference before ordering below:</DialogContentText>
                    <TextField
                        onChange={handleReference}
                        autoFocus
                        variant="outlined"
                        margin="dense"
                        id="name"
                        label="Reference"
                        type="text"
                        value={reference}
                        fullWidth
                    />
                    <DialogContentText variant="caption">Please note the order will be processed immediatly and can not be undone</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose} color="primary">Close</Button>
                    <Button variant="outlined" onClick={handleConfirm} color="primary">Accept</Button>
                </DialogActions>
            </Dialog>
            <Button variant="contained" size="small" disabled={active} onClick={handleClickOpen}>CONFIRM</Button>
        </React.Fragment>
    );
}