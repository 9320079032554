import React from 'react';
import { Route } from "react-router-dom";

////// Import Sales Quotes Support Files
import Quotes from './Quotes/Quotes';
import VesselQuoteLineDetail from './Quotes/VesselQuoteLineDetail/VesselQuoteLineDetail';
import VesselQuoteDetail from './Quotes/VesselQuoteDetail/VesselQuoteDetail';

////// Import General Support Files
import Breadcrumb from '../Navigation/Breadcrumb';



const QuotationsApprove = () => {

    return (
        <React.Fragment>
            <Breadcrumb Module="Order Management" currentPage="Approve Quotations" />
            <div>
                <Route exact path="/sales/quotes" component={Quotes} />
                <Route exact path="/sales/quotes/:id/:lineID?" component={VesselQuoteDetail} />
                <Route exact path="/sales/quotes/:id/:lineID" component={VesselQuoteLineDetail} />
            </div>
        </React.Fragment>
    );
};

export default QuotationsApprove;
