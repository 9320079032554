import React, { useMemo } from 'react'
import { Typography, Button } from '@material-ui/core';
import { withRouter } from "react-router";
import http from '../../../../../Global/Services/httpService';
import { useGlobalStore } from '../../../../../Global/Stores/globalStore';
import UploadButton from '../../../../Widgets/Buttons/UploadButton';

const RouteUpload = ({ history, vesselID }) => {

    const [, globalsActions] = useGlobalStore();

    return useMemo(() => {

        const handleFileSelected = (file) => {
            const files = Array.from(file);

            let formData = new FormData();
            formData.append("File", files[0]);

            http.post("/api/catalogue/routes/upload", formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then((result) => {
                    const routeID = +result.data;
                    globalsActions.getRoutes();
                    history.push(`/sales/catalogue/${vesselID}/routes/${routeID}`);
                })
                .catch(error => {
                    //alert(error)
                });
        };

        return (
            <UploadButton text="Upload Route" accept=".rtz" FileSelected={handleFileSelected} />
        );

    }, [vesselID])
}


export const BasketUpload = withRouter(({ history, vesselID }) => {

    const [, globalsActions] = useGlobalStore();

    return useMemo(() => {

        const handleFileSelected = (file) => {
            const files = Array.from(file);

            const formData = new FormData();
            formData.append("File", files[0]);

            http.post("/api/catalogue/baskets/upload", formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then((result) => {
                    const basketID = +result.data;
                    globalsActions.getBaskets();
                    history.push(`/sales/catalogue/${vesselID}/baskets/${basketID}`);
                })
                .catch(error => {
                    //alert(error)
                });
        };

        return (
            <UploadButton text="Upload Basket File" accept=".bsk , .txt" FileSelected={handleFileSelected} />
        );

    }, [vesselID])
})


// const UploadButton = ({ text, FileSelected, accept }) => {
//     return (
//         <Button
//             variant="outlined"
//             component="label"

//             style={{ display: 'block', marginTop: '4px', textAlign: 'center' }}
//         >


//             {text}  <input type="file" accept={accept} style={{ display: "none" }} onChange={(e) => {
//                 const val = e.target.files;
//                 FileSelected(val);
//             }} />
//         </Button>
//     );
// }


export default withRouter(RouteUpload)
// export default RouteUpload
