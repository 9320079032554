import React, { createContext, useContext, useState, useEffect } from 'react';
import storage from 'store';
import axios from '../Services/httpService';
import http from '../Services/httpService';
import { useNotificationStore } from './notificationStore';
import _ from 'lodash';

const initialState = {
    users: [],
    user: {
        username: null,
        firstName: null,
        lastName: null,
        email: null,
        company: null,
    },
    appModules: [],
    appFeatures: [],
    userModules: [],
    userFeatures: [],
    appFeaturePaths: [],
    tokenData: {
        token: null,
        expires: null
    }
};

const createStore = () => {

    // console.log('createStore')

    const userData = storage.get('userData');

    if (userData) {
        sessionStorage.setItem('userData', JSON.stringify(userData));
        return userData;
    }

    const sessionData = JSON.parse(sessionStorage.getItem('userData'));
    if (sessionData) {
        return sessionData;
    }


    return initialState;
}

export const UserContext = createContext();

export const UserProvider = ({ children }) => {

    const [state, setState] = useState(createStore());
    const [, notificationActions] = useNotificationStore();

    useEffect(() => {

        const doLogout = () => {
            // console.log('doLogout');
            logout();
        }

        axios.instance.interceptors.response.use(null, error => {

            // console.log('response');

            const response = error.response;
            const statusCode = _.get(response, 'status', 0);
            const logout = _.get(response, 'data.toast.logout', false);

            // console.log('axios', statusCode, logout);

            if ((statusCode === 401) || logout === true) {
                doLogout();
            }
            return Promise.reject(error);

        }, []);

    });

    const loadData = () => {

    }

    const clearData = () => {
        setState(() => { return { ...initialState } });
    }

    const login = (userData) => {

        setState(prev => {

            const newState = {
                ...prev,
                ...userData
            }

            sessionStorage.setItem('userData', JSON.stringify(newState));

            if (userData.remember === true)
                storage.set('userData', newState);
            // console.log('prev', newState);
            return newState;
        });
    }

    const logout = (manually) => {

        // const isAuth = isAuthenticated();
        if (manually) {

            http.get('/api/auth/logout').finally(() => { doLogout(); });
        }
        else {
            doLogout();
        }
    }

    const doLogout = () => {
        // console.log('logout');
        sessionStorage.clear();
        storage.clearAll();
        localStorage.clear();

        setState(() => {
            return {
                ...initialState
            }
        });
    }

    // const setTokenData = (tokenData) => {

    //     setState(prev => {
    //         return {
    //             ...prev,
    //             users: users,
    //         }
    //     });
    // }

    const refreshToken = () => {
        http.get('/api/auth/RefreshToken')
            .then(result => {

                const newToken = result.data;
                //console.log('refreshToken', newToken);


                const userData = { ...state.userData };
                userData.tokenData = newToken.tokenData;
                login(userData);
            })
            .catch(error => {
                //alert(error);
            });
    }


    const hasAdmin = () => {
        return state.userFeatures.includes(1);
    }

    const hasUserManagement = () => {
        return hasAdmin() || state.userFeatures.includes(2);
    }

    const hasFeatureID = (fid, allowAdmin = true) => {
        return fid === 0 || (hasAdmin() && allowAdmin) || state.userFeatures.includes(fid);
    }

    const hasAppModuleID = (amid, allowAdmin = true) => {
        return (hasAdmin() && allowAdmin) || state.userModules.includes(amid);
    }


    // const hasAVCSO = () => {
    //     return hasAdmin() || hasFeatureID(6);
    // }

    const canAccessPath = (path) => {

        if (hasAdmin())
            return true; // Admin can do everything

        const val = state.appFeaturePaths.find(X => path.startsWith(X.path));
        // console.log('path', path, val);
        if (!val)
            return true; // No limitations for path

        const ft = state.userFeatures.find(X => X === val.appFeatureID);
        // console.log('feat', ft);
        if (ft)
            return true;

        notificationActions.showError(val.message);

        return false;
    }

    const getUsers = () => {

        http.get('/api/account/users')
            .then(result => {

                const users = result.data;
                // console.log('users', users);

                setState(prev => {
                    return {
                        ...prev,
                        users: users,
                    }
                });
            })
            .catch(error => {
                //alert(error);
            });
    }

    const deleteUser = (personID) => {

        // console.log('deleteUser', personID);
        http.delete(`/api/account/users/${personID}`)
            .then(() => {
                // console.log('person Deleted', result.data);
            })
            .catch(error => {
                //alert(error);
            })
            .finally(() => {
                getUsers();
            });
    }

    const isAuthenticated = () => {
        // console.log('isAuthenticated', state);
        const now = new Date();
        if (state.tokenData) {
            const expires = new Date(state.tokenData.expires);
            if ((expires > now)) {
                return true;
            }
            else if (state.tokenData.token) {
                // console.log('isAuthenticated logout', state.tokenData.expires);
                logout();
            }
        }
        return false;
    }

    const setUserFeatures = (personID, featureIDs) => {
        http.post(`/api/account/users/${personID}/features`, featureIDs)
            .then(() => {
                getUsers();
            })
            .catch(() => {

            });
    }

    const actions = {
        loadData: loadData,
        clearData: clearData,

        login,
        refreshToken,
        logout,
        isAuthenticated,
        getUsers: getUsers,
        deleteUser: deleteUser,
        hasAdmin: hasAdmin,
        hasUserManagement: hasUserManagement,
        setUserFeatures: setUserFeatures,

        hasAppModuleID: hasAppModuleID,
        hasFeatureID: hasFeatureID,
        canAccessPath: canAccessPath,
    };

    // return useMemo(() => {
    return (
        <UserContext.Provider value={[state, actions]}>
            {children}
        </UserContext.Provider >
    );
    // }, [state]);

};

export const useUserStore = () => useContext(UserContext);
