import React, { useMemo, useEffect } from 'react'
import { Collapse, List, Divider } from '@material-ui/core';
import SearchFilter from '../../Misc/SearchFilter';
import { useGlobalStore } from '../../../../Global/Stores/globalStore';
import { useSaleStore } from '../../../../Global/Stores/saleStore';
import _ from 'lodash';
import ContentColumn from '../../../Custom/ContentColumn';
import ListItemVessel from '../../_StyledComponents/ListItemVessel';
import { ListItemSubFlatFeeContract } from '../../_StyledComponents/ListItemSub';

const FlatFee = ({ match }) => {
    const [globalState,] = useGlobalStore();
    const [saleState, salesActions] = useSaleStore();

    // const [filter] = React.useState("");

    const flatFeeID = +match.params.id || 0;

    useEffect(() => {
        salesActions.getVesselFlatFees();
    }, []);


    let vessels = _.orderBy(globalState.vessels, [B => B.vesselName.toLowerCase()]);
    const flatFees = saleState.vesselFlatFees;
    const loaded = (vessels !== null) && (flatFees !== null);

    return (
        <ContentColumn loaded={loaded} selectedID={flatFeeID}>
            <FlatFeeContent flatFeeID={flatFeeID} />
        </ContentColumn>
    );
};

const FlatFeeContent = ({ flatFeeID }) => {
    const [globalState,] = useGlobalStore();
    const [saleState] = useSaleStore();

    const [filter, setFilter] = React.useState('');
    const [sort, setSort] = React.useState(1);


    let vessels = _.orderBy(globalState.vessels, [B => B.vesselName.toLowerCase()]);
    const flatFees = saleState.vesselFlatFees;

    const loaded = (vessels !== null) && (flatFees !== null);
    if (loaded === true) {
        vessels = vessels.filter(X => X.vesselName.toLowerCase().indexOf(filter) !== -1);

        vessels = _.orderBy(
            vessels,
            ['vesselName'],
            [sort > 0 ? 'asc' : 'desc']);
    }

    // console.log('loaded', loaded, saleState.vesselFlatFees)

    // const onSearch = (text) => {
    //     setFilter(() => { return text.toLowerCase(); })
    // };

    return (
        <React.Fragment>
            <div className="contentColumnHeader" style={{ padding: '0px 10px' }}>
                <SearchFilter placeholder="Search Vessel" onSearch={(F) => setFilter(() => F.toLowerCase())} onSort={(S) => setSort(() => S)} />
            </div>

            <div className="contentColumnBody disablePadding">
                {
                    <List component="div">
                        {
                            vessels.map(X => (<VesselFlatFees key={X.vesselID} vessel={X} selectedID={flatFeeID} />))
                        }
                        <Divider />
                    </List>
                }
            </div>
        </React.Fragment>
    );
}

const VesselFlatFees = ({ vessel, selectedID }) => {
    const [saleState,] = useSaleStore();
    const [open, setOpen] = React.useState(false);

    const vesselFlatFees = _.get(saleState, 'vesselFlatFees', []).filter(X => X.vesselID === vessel.vesselID);
    // console.log('vesselFlatFees 1', vesselFlatFees);

    return useMemo(() => {

        // console.log('vesselFlatFees', vesselFlatFees);

        return vesselFlatFees.length > 0 ? (
            <ListItemVessel
                vessel={vessel}
                number={vesselFlatFees.length}
                open={open}
                setOpen={setOpen}
                selected={open}
                subSelectedID={selectedID}
            >

                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {
                            vesselFlatFees.map(X => (
                                <ListItemSubFlatFeeContract
                                    key={X.vesselFlatFeePeriodID}
                                    flatFeeContract={X}
                                    selected={X.vesselFlatFeePeriodID === selectedID}
                                />))
                        }
                    </List>
                </Collapse>
            </ListItemVessel>
        ) : null;

    }, [vesselFlatFees, selectedID, open]);
};

export default FlatFee;
