import React, { useEffect, useMemo, useState } from 'react';
import { FeatureGroup } from 'react-leaflet';
import _ from 'lodash';
import { useSaleStore } from '../../../Global/Stores/saleStore';
import GeneralRoutesLayer from './Catalogue/GeneralRoutesLayer';
import ItemPolygon from '../Polygons/ItemPolygon';
import { checkPolygonInPoint } from '../MapFunctions';
import MapPopupSalesShipmentLines from '../Popup/MapPopupSalesShipmentLines';

const SalesShipmentLayer = ({ match, history, mapActions }) => {
    const [saleState] = useSaleStore();

    const [popup, setPopup] = useState({
        key: 0,
        position: null,
        lines: [],
    });

    const salesShipmentID = +match.params.id || 0;
    const selectedLineID = +match.params.lineID || 0;

    const loaded = _.get(saleState, 'shipmentDetail.header.salesShipmentID', -1) === salesShipmentID;

    const lines = _.get(saleState, 'shipmentDetail.lines', []);
    const routes = _.get(saleState, 'shipmentDetail.routes', []);


    // Zoom to shipment
    useEffect(() => {
        if (loaded) {
            mapActions.zoomToLines(lines);
        }
    }, [salesShipmentID, loaded]);

    // Zoom to line
    useEffect(() => {
        if (loaded && selectedLineID > 0) {
            const line = lines.find(X => X.salesOrderLineID === selectedLineID);
            mapActions.zoomToLines(line);
        }
    }, [selectedLineID, loaded]);


    if (salesShipmentID === 0)
        return null;


    const sortedLines = _.orderBy(lines, ['item.scale'], ['desc']);


    const onClick = (e) => {
        // console.log("onClick", e);
        let selected = lines.filter(X => X.item.polygonPoints && checkPolygonInPoint(e.latlng, X.item.polygonPoints));
        if (selected.length === 0)
            selected = e.layer;

        if (selected.length === 1) {
            history.push(`/sales/shipments/${selected[0].salesShipmentID}/${selected[0].salesShipmentLineID}`);
        }
        else if (selected.length > 0) {

            setPopup(prev => {
                return {
                    key: prev.key + 1,
                    position: e.latlng,
                    lines: selected
                }
            });
        }
    };

    return (
        <React.Fragment>
            <MapPopupSalesShipmentLines popupInfo={popup} />

            <FeatureGroup onClick={onClick}>
                {
                    sortedLines.map(X => <SalesShipmentLinePolygon key={X.item.itemID} line={X} selected={selectedLineID === X.salesShipmentLineID} />)
                }
            </FeatureGroup>
            <GeneralRoutesLayer Routes={routes} />

        </React.Fragment>
    );
};

const SalesShipmentLinePolygon = ({ line, selected }) => {

    return useMemo(() => {

        if (!line.item.polygonPoints)
            return null;

        const positions = line.item.polygonPoints;
        const color = "black";

        return (
            <ItemPolygon
                positions={positions}
                fillColor={color}
                color={color}
                selected={selected}
            />
        );

    }, [line.itemID, selected]);
};

export default SalesShipmentLayer;
