import React, { useEffect, useMemo } from 'react'
import { useGlobalStore } from '../../../../Global/Stores/globalStore';
// import Select from 'react-select';
import { Box, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, List, Button, IconButton, ListItemIcon } from '@material-ui/core';
import _ from 'lodash';
import ListItemLink from '../../Misc/ListItemLink';
// import BasketReview from './Basket/BasketReview';
import { useBasketStore } from '../../../../Global/Stores/basketStore';
import ContentColumn from '../../../Custom/ContentColumn';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';

const Catalogue = ({ match, history }) => {
    const [globalState] = useGlobalStore();
    const [basketState, basketActions] = useBasketStore();


    // // const [, saleActions] = useSaleStore();
    // const [open, setOpen] = useState(false);

    const vesselID = +match.params.vesselID || 0;

    // const handleVesselSelect = (vessel) => {
    //     history.push(`/sales/catalogue/${vessel.vesselID}`);
    // }

    // const loaded = Boolean(globalState.vessels);
    const vessels = _.orderBy(globalState.vessels, ['vesselName'], ['asc']);
    const vessel = vessels.find(X => X.vesselID === vesselID) || null;

    const itemsLoaded = Boolean(globalState.catalogue);
    // const disabled = !Boolean(vessel) || !itemsLoaded;

    // console.log('globalState.Catalogue', globalState.catalogue);

    const ordered = _.get(basketState, 'ordered', false);

    useEffect(() => {
        basketActions.setVessel(vesselID);
        return () => basketActions.setVessel(0);
    }, [vesselID]);

    useEffect(() => {
        if (ordered === true) {
            history.push(`/sales/catalogue/`);
        }
    }, [ordered]);

    return (
        <ContentColumn loaded={itemsLoaded}>
            <div className="contentColumnHeader" style={{ padding: '0px 10px' }}>
                <Typography variant="h6" display="block" >Create new order</Typography>
            </div>

            <BasketButtonList Vessel={vessel} />

            {/* 1. SELECT VESSEL */}
            {/* <div className="contentColumnFilter" style={{ padding: '8px 8px', alignItems: 'stretch', flexDirection: 'column' }}>
                <Typography variant="subtitle2" >1. Select a vessel:</Typography>
                <Select
                    getOptionLabel={(X) => X.vesselName}
                    getOptionValue={(X) => X}
                    styles={{ width: '100%' }}
                    className="basic-single"
                    classNamePrefix="select"
                    isSearchable={true}
                    name="color"
                    value={vessel}
                    onChange={(X) => { handleVesselSelect(X) }}
                    options={vessels}
                />
            </div> */}



            {/* 2. Add items to basket */}
            {/* <div className={`contentColumnFilter ${disabled ? 'ctdisabled' : ''}`}
                style={{ padding: '8px 8px', alignItems: 'stretch', flexDirection: 'column' }}>

                <Typography variant="subtitle2" >2. Add items to basket:</Typography>
                <List component="div" disablePadding>

                    <ListItemLink
                        text="- Add items from route"
                        to={`/sales/catalogue/${vesselID}/routes/`}
                        location={`/sales/catalogue/${vesselID}/routes/`} />

                    <ListItemLink
                        text="- Add items from Basket"
                        to={`/sales/catalogue/${vesselID}/baskets/`}
                        location={`/sales/catalogue/${vesselID}/baskets/`} />

                    <ListItemLink
                        text="- Add items from Quote"
                        to={`/sales/catalogue/${vesselID}/quotes/`}
                        location={`/sales/catalogue/${vesselID}/quotes/`} />

                    <ListItemLink
                        text="- Add items from Catalogue"
                        to={`/sales/catalogue/${vesselID}/products/`}
                        location={`/sales/catalogue/${vesselID}/products/`} />
                </List>
            </div>


            <BasketReview /> */}

            <div className="flexFill"></div>
            <div className="contentColumnFooter">
                <SaveManagerBasket />
            </div>
        </ContentColumn>
    );
}


const BasketButtonList = ({ Vessel }) => {
    return (
        <div
            className={`contentColumnFilter`}
            style={{ padding: '8px 8px', alignItems: 'stretch', flexDirection: 'column' }}>

            <List component="div">

                <BasketSelectVesselButton Vessel={Vessel} />
                <BasketAddItemsButton />
                <BasketReviewButton />
                <BasketConfirmButton />

            </List>
        </div>
    );
}

const BasketSelectVesselButton = ({ Vessel }) => {
    const [basketState,] = useBasketStore();
    const vesselID = basketState.vesselID;
    const selected = vesselID > 0;
    const text = Vessel ? `1. Vessel: ${Vessel.vesselName}` : `1. Select a vessel:`;

    return (
        <BasketButton
            Text={text}
            To={`/sales/catalogue/${vesselID}`}
            Location={`/sales/catalogue/${vesselID}`}
            Disabled={false}
            Selected={selected}
            Done={selected}
        // Number={basketItemCount}
        />);

    // return (
    //     <ListItemLink
    //         text={text}
    //         to={`/sales/catalogue/${vesselID}`}
    //         location={`/sales/catalogue/${vesselID}`} />
    // );
}

const BasketAddItemsButton = () => {
    const [basketState,] = useBasketStore();
    const vesselID = basketState.vesselID;
    const selected = basketState.basketLines.length > 0;
    const disabled = (vesselID === 0);

    return useMemo(() => {
        // console.log('BasketAddItemsButton', selected)
        return (
            <BasketButton
                Text="2. Add items to basket:"
                To={`/sales/catalogue/${vesselID}/add/`}
                Location={`/sales/catalogue/${vesselID}/add/`}
                Disabled={disabled}
                Selected={selected}
                Done={selected}
            />);

    }, [vesselID, disabled, selected]);
}


const BasketReviewButton = () => {
    const [basketState, basketActions] = useBasketStore();
    const hasLines = Boolean(basketState.basketLines);

    const vesselID = basketState.vesselID;
    const basketItemCount = hasLines ? basketState.basketLines.length : 0;
    const selected = basketActions.hasBasketPrices();

    const disabled = (vesselID === 0) || (basketItemCount === 0);

    return useMemo(() => {

        if (!hasLines)
            return null;

        return (
            <BasketButton
                Text="3. Review Basket:"
                To={`/sales/catalogue/${vesselID}/basket/`}
                Location={`/sales/catalogue/${vesselID}/basket/`}
                Disabled={disabled}
                // Loaded={loaded}
                Selected={selected}
                Count={basketItemCount}
            />);

    }, [hasLines, basketItemCount, vesselID, disabled, selected]);
}

const BasketConfirmButton = () => {
    const [basketState, basketActions] = useBasketStore();
    const hasLines = Boolean(basketState.basketLines);

    const vesselID = basketState.vesselID;
    const basketItemCount = hasLines ? basketState.basketLines.length : 0;

    const pricesLoaded = basketActions.hasBasketPrices();
    const disabled = (vesselID === 0) || (basketItemCount === 0) || (!pricesLoaded);
    // const selected = vesselID > 0;

    const done = !disabled && basketState.reference.length > 0;

    return useMemo(() => {

        if (!hasLines)
            return null;

        return (
            <BasketButton
                Text="4. Confirm Basket:"
                To={`/sales/catalogue/${vesselID}/confirm/`}
                Location={`/sales/catalogue/${vesselID}/confirm/`}
                Disabled={disabled}
                Done={done}
            // Selected={selected}
            />);

    }, [hasLines, vesselID, disabled, done]);
}


export const BasketButton = ({ Text, To, Location, Count, Selected, Disabled, Done }) => {

    const icon = Done === true ? (
        <IconButton disableFocusRipple disableRipple disableTouchRipple>
            <DoneOutlineIcon />
        </IconButton>) : null;

    return (
        <div className={` ${Disabled === true ? 'ctdisabled' : ''}`}>
            <ListItemLink
                number={Count}
                text={Text}
                to={To}
                location={Location}
                selected={Selected}
                icon={icon}
            />
        </div>
    );
}



const SaveManagerBasket = () => {
    return (<SaveManagerBasketConfirmation />);
}


const SaveManagerBasketConfirmation = () => {
    const [basketState, basketActions] = useBasketStore();

    const [open, setOpen] = React.useState(false);
    const [reference, setReference] = React.useState("");

    const disabled = basketState.basketLines.length <= 0;

    const handleReference = (e) => {
        const val = e.target.value;
        setReference(() => val);
    }

    const SaveManagerBasket = () => {

        basketActions.saveManagerBasket(reference);
        setOpen(false);
    }

    return (
        <React.Fragment>
            <Dialog
                open={open}
                scroll="paper"
                onClose={() => { setOpen(false); }}
                aria-labelledby="Save Basket">
                <DialogTitle id="Save Basket">Do you want to save the basket?</DialogTitle>
                <DialogContent>
                    <DialogContentText variant="subtitle2">Please enter the reference for the basket you want to save:</DialogContentText>
                    <TextField
                        onChange={handleReference}
                        autoFocus
                        variant="outlined"
                        margin="dense"
                        id="name"
                        label="Reference"
                        type="text"
                        value={reference}
                        fullWidth
                    />
                    <DialogContentText variant="caption">After saving the basket will become available from the existing baskets</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => { SaveManagerBasket(); }} color="primary">Save</Button>
                    <Button variant="outlined" onClick={() => { setOpen(false); }} color="primary">Close</Button>
                </DialogActions>
            </Dialog>
            <Button variant="contained" size="small" disabled={disabled} onClick={() => { setOpen(true); }}>Save Basket</Button>
        </React.Fragment>
    );
}

export default Catalogue;
