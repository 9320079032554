import React, { useState } from "react";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import AccountCircle from '@material-ui/icons/MailOutline';
import { useUserStore } from "../../Global/Stores/userStore";
import http from '../../Global/Services/httpService';
import { Avatar, Button, TextField, FormControlLabel, Checkbox, Link, Grid, Box, Container, IconButton, InputAdornment, Typography } from "@material-ui/core";
// import { useNotificationStore } from "../../Global/Stores/notificationStore";
import BackgroundHeader from "./../../Images/bg_shipmanager.jpg";
import CT_Logo from "./../../Images/CT-logo-transparant.png";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        width: '100%',
        '& fieldset': {
            borderColor: '#dfe4ed !important',
            borderWidth: '5px',
        }
    },
    top: {
        flexGrow: 1,
        flexShrink: 0,
        flexBasis: '45%',
        backgroundImage: `url(${BackgroundHeader})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    bottom: {
        flexGrow: 1,
        flexShrink: 0,
        flexBasis: '55%',
    },
    logoContainer: {
        height: '100%',
        width: '100%',
    },
    logoText: {
        // flexGrow: 1,
        textTransform: 'uppercase',
        backgroundImage: `url(${CT_Logo})`,
        backgroundPosition: 'left',
        backgroundRepeat: 'no-repeat',
        // fontvariant: 'small-caps',
        fontSize: '5em',
        letterSpacing: '10px',
        color: '#e3e3e3',
        padding: '10px 20px 20px 120px',
        backgroundColor: 'rgba(255, 225, 255, 0.3)'
    },
    loginContainer: {
        marginTop: '2rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: '#C0C0C0',
    },
    loginText: {
        // textTransform: 'uppercase',
        fontVariant: 'small-caps',
        // letterSpacing: '10px',
        color: '#4d4d4d',
    },
    forgotPassword: {
        color: '#C0C0C0',
        textDecoration: 'underline',
        display: 'block',
        padding: '5px 0px',
        textAlign: 'right',
        '&:hover': {
            color: '#026b94',
        }
    },
}));

const Login = () => {
    const [, actions] = useUserStore();

    const classes = useStyles();
    const [formState, setFormState] = useState({
        email: '',
        password: '',
        remember: false,
        showPassword: false,
        loading: false,
    });


    const handleChange = event => {

        const target = event.target;
        // console.log('event', target);

        if (!formState.loading)
            setFormState(() => ({ ...formState, [target.name]: (target.type === 'checkbox' ? target.checked : target.value) }));
    };

    const handleClickShowPassword = () => {
        if (!formState.loading)
            setFormState({ ...formState, showPassword: !formState.showPassword });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setFormState({ ...formState, loading: true });

        const postData = {
            Username: formState.email,
            Password: formState.password,
            Remember: formState.remember
        };

        http.post('/api/auth', postData)
            .then(result => {

                const userData = { ...result.data, remember: formState.remember }
                actions.login(userData);
            })
            .catch((error) => {
                setFormState({
                    ...formState,
                    password: '',
                    loading: false
                });
            });
    };


    const handleForgotPassword = (e) => {
        e.preventDefault();
        setFormState({ ...formState, loading: true });
        // console.log(formState.email);
        if(formState.email === ""){
            setFormState({ ...formState, loading: false });
            // We will just do nothing. They will eventually notice and have to enter an email. :D
        } else {
            const postData = {
                email: formState.email,
            };
            // const email = formState.mail;

            http.post('/api/account/users/resetpassword', postData)
                .then(result => {
                    // actions.login(result.data);
                    // history.push("/account/users");
                    window.location.href = "/home";
                })
                .catch(() => {
                    // setCreating(() => false);
                    setFormState({ ...formState, loading: false });
                });
            }

    };


    return (
        <Grid
            container
            direction="column"
            // justify="center"
            // alignItems="center"
            wrap="nowrap"
            className={classes.root}
        >

            <Grid item className={classes.top}>

                <Grid
                    container
                    direction="column"
                    justify="center"
                    wrap="wrap"
                    className={classes.logoContainer}
                >

                    <Grid item className={classes.logoText}>
                        <span className="loginTitle">ship manager</span>
                    </Grid>

                </Grid>

            </Grid>

            <Grid item className={classes.bottom}>
                <Container component="div" maxWidth="xs">
                    <div className={classes.loginContainer}>

                        <Avatar>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography className={classes.loginText} component="h1" variant="h6" align="center">enter your email address to reset passwword</Typography>


                        <form noValidate>
                            <TextField
                                className="loginField"
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={formState.email}
                                onChange={handleChange}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AccountCircle style={{ color: '#C0C0C0' }} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {/* <TextField
                                className="loginField"
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type={formState.showPassword ? 'text' : 'password'}
                                id="password"
                                autoComplete="current-password"
                                value={formState.password}
                                onChange={handleChange}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                style={{ color: '#C0C0C0' }}
                                                edge="end"
                                                aria-label="Toggle password visibility"
                                                onClick={handleClickShowPassword}
                                            >
                                                {formState.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            /> */}
                            {/* <FormControlLabel
                                control=
                                {
                                    <Checkbox className="SignIn"
                                        value="remember"
                                        name="remember"
                                        color="primary"
                                        checked={formState.remember}
                                        onChange={handleChange}
                                        style={{ color: '#C0C0C0' }}
                                    />
                                }
                                label="REMEMBER ME?"
                            /> */}
                            <Button
                                className="SignIn"
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={formState.loading}
                                onClick={handleForgotPassword}
                            >Get Password</Button>

                            <Grid container>
                                <Grid item xs className="">
                                    <Link href="/Login" variant="body2" className={classes.forgotPassword} >Return to Login</Link>
                                </Grid>
                            </Grid>

                        </form>
                    </div>
                    <Box mt={2}>
                        <Typography variant="body2" color="textSecondary" align="center">
                            <Link color="inherit" href="http://www.charttrack.com/" target="_blank">
                                &copy; Chart Track {new Date().getFullYear()}
                            </Link>
                        </Typography>
                    </Box>
                </Container>
            </Grid>

            {/* <Container component="div" maxWidth="xs"></Container> */}


        </Grid>


    );
}


export default Login;
