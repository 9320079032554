import React from 'react';
import { Route } from "react-router-dom";
import VesselOrderLineDetail from './Orders/VesselOrderLineDetail/VesselOrderLineDetail';
import Orders from './Orders/Orders';
import VesselOrderDetail from './Orders/VesselOrderDetail/VesselOrderDetail';


// Import General Support Files
import Breadcrumb from '../Navigation/Breadcrumb';

const OrderHistory = () => {

    return (
        <React.Fragment>
            <Breadcrumb Module="Order Management" currentPage="Orders History" />
            <div>
            <Route exact path="/sales/orders" component={Orders} />
            <Route exact path="/sales/orders/:id/:lineID?" component={VesselOrderDetail} />
            <Route exact path="/sales/orders/:id/:lineID" component={VesselOrderLineDetail} />
            </div>
        </React.Fragment>
    );
};

export default OrderHistory;
