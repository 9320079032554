import React, { useEffect } from 'react';
import { Box, List, Divider } from '@material-ui/core';
import SearchFilter from '../../Misc/SearchFilter';
import { useFleetStore } from '../../../../Global/Stores/fleetStore';
import Loading from '../../Misc/Loading';
import _ from 'lodash';
import VesselProductGroups from './VesselProductGroups';
import ContentColumn from '../../../Custom/ContentColumn';
import ListItemVessel from '../../_StyledComponents/ListItemVessel';
import { HoldingStatus } from '../../../Widgets/ProductGroupStatus';

const Holdings = ({ match }) => {

    const [fleetState] = useFleetStore();
    const [filter, setFilter] = React.useState('');
    const [sort, setSort] = React.useState(1);


    const selectedVesselID = +match.params.vesselID || 0;
    // console.log('match', selectedVesselID);

    // const salesOrderID = 0;

    const onSearch = (text) => {
        setFilter(() => { return text.toLowerCase(); })
    };


    const loaded = Boolean(fleetState.vessels);
    let vessels = loaded ? fleetState.vessels.filter(X => X.vesselName.toLowerCase().indexOf(filter) !== -1) : [];

    vessels = _.orderBy(vessels, ['vesselName'], [sort > 0 ? 'asc' : 'desc']);


    const counter = selectedVesselID + filter.length + sort;
    return (
        <ContentColumn loaded={loaded} selectedID={counter}>
            <div className="contentColumnHeader" style={{ padding: '0px 10px' }}>
                <SearchFilter placeholder="Search Vessel" onSearch={onSearch} onSort={(S) => setSort(() => S)} />
            </div>

            <div className="contentColumnBody disablePadding">
                {
                    <List component="div">
                        {
                            vessels.map(X => (
                                <ListItemVessel
                                    key={X.vesselID}
                                    vessel={X}
                                    selected={X.vesselID === selectedVesselID}
                                    statusComponent={<HoldingStatus vesselProductGroupStatus={X.vesselItemProductGroups} />}
                                    linkTo={`/fleet/holdings/${X.vesselID}`}
                                />))
                        }
                        <Divider />
                    </List>
                }
            </div>
        </ContentColumn>
    );
};

export default Holdings;
