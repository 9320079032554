import React, { useMemo } from 'react';
import { useGlobalStore } from '../../../../Global/Stores/globalStore';
import { useBasketStore } from '../../../../Global/Stores/basketStore';
import { FeatureGroup } from 'react-leaflet';
import ItemPolygon from '../../Polygons/ItemPolygon';
import { checkPolygonInPoint } from '../../MapFunctions';
import _ from 'lodash';
import GeneralRoutesLayer from './GeneralRoutesLayer';

const CatalogueBasketLayer = ({ match, history }) => {
    const [globalState,] = useGlobalStore();
    const [basketState,] = useBasketStore();


    const loaded = _.get(globalState, 'catalogue.items', []).length > 0;
    if (!loaded)
        return null;


    const selectedItemID = +match.params.itemID || 0;
    // const { mapItems } = basketState;
    const { basketLines, closedProductGroups, routes } = basketState;

    const mapLines = basketLines.filter(L => Boolean(L.item) && !closedProductGroups.includes(L.item.productGroupID) && Boolean(L.item.polygonPoints));


    const routeLayer = routes.length > 0 ? <GeneralRoutesLayer Routes={routes} /> : null;

    //console.log('CatalogueBasketLayer', mapLines);

    const onClick = (e) => {
        let selected = mapLines.filter(X => X.item.polygonPoints && checkPolygonInPoint(e.latlng, X.item.polygonPoints));
        if (selected.length === 0)
            selected = e.layer;

        if (selected.length === 1) {
            history.push(`${selected[0].itemID}`);
            // mapActions.zoomToLines(selected);
        }
    };

    return (
        <React.Fragment>
            {/* <CatalogueMapPopup popupInfo={popup} selectedItemID={selectedItemID} /> */}
            <FeatureGroup onClick={onClick}>
                {
                    mapLines
                        .map(X => {
                            const selected = (X.itemID === selectedItemID);
                            return (<CatalogItemPolygon key={X.itemID} Item={X.item} Selected={selected} Focused={selected} />)
                        })
                }
            </FeatureGroup>
            {routeLayer}
        </React.Fragment>
    );
}


const CatalogItemPolygon = ({ Item, Selected, Focused }) => {
    const [basketState] = useBasketStore();

    const inBasket = !!basketState.basketLines.find(X => X.itemID === Item.itemID);
    return useMemo(() => {

        // console.log('CatalogItemPolygon', Item);

        if (!Item.polygonPoints)
            return null;


        // const color = Item.validationID === 1 ? "#1dc71d" : "#ff4a4a";
        // const fillOpacity = Selected === true ? 0.2 : 0.01;
        const color = inBasket === true ? "green" : "black";

        return (
            <ItemPolygon
                positions={Item.polygonPoints}
                fillColor={color}
                color={color}
                selected={Selected}
                focused={Focused}
            />);

    }, [Item, Selected, inBasket, Focused]);
}

export default CatalogueBasketLayer
