import React from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import NextIcon from '@material-ui/icons/NavigateNext';
import BeforeIcon from '@material-ui/icons/NavigateBefore';
import { IconButton, Divider, ListItemText, Typography, Link } from '@material-ui/core';
import { useSaleStore } from '../../../../../Global/Stores/saleStore';

const VesselQuoteLineDetailHeader = ({ line, closePath, next, before }) => {

    return (
        <React.Fragment>

            <Link to={closePath} component={RouterLink} className="navLink" style={{ color: 'inherit', textDecoration: 'none' }} >
                <IconButton>
                    <CloseIcon />
                </IconButton>
            </Link>

            <Typography className="flexFill" variant="subtitle2" >
                {line.item.itemTitle}
            </Typography>

            <Link to={before} component={RouterLink} className="navLink" style={{ color: 'inherit', textDecoration: 'none' }} >
                <IconButton >
                    <BeforeIcon />
                </IconButton>
            </Link>

            <Link to={next} component={RouterLink} className="navLink" style={{ color: 'inherit', textDecoration: 'none' }} >
                <IconButton >
                    <NextIcon />
                </IconButton>
            </Link>

            <Divider />
        </React.Fragment>
    );
}

export default VesselQuoteLineDetailHeader
