import React, { useMemo, useEffect } from 'react';
import { List, Divider } from '@material-ui/core';
import ContentColumn from '../../Custom/ContentColumn';
import { useFleetStore } from '../../../../Global/Stores/fleetStore';
import ListItemVessel from '../../_StyledComponents/ListItemVessel';
import { useGlobalStore } from '../../../../Global/Stores/globalStore';
import _ from 'lodash';
import ChipStatus from '../../../Widgets/Status/ChipStatus';
import BlockIcon from '@material-ui/icons/Block';
import SearchFilter from '../../Misc/SearchFilter';

const NavigatorUpdates = ({ match }) => {

    const [fleetState, fleetActions] = useFleetStore();
    const [globalState] = useGlobalStore();
    const [filter, setFilter] = React.useState('');
    const [sort, setSort] = React.useState(1);

    const vessels = _.orderBy(globalState.vessels, ['vesselName'], [sort > 0 ? 'asc' : 'desc']);

    const selectedVesselID = +match.params.vesselID || 0;

    const hasUpdateInfo = Boolean(fleetState.vesselUpdates);
    const loaded = Boolean(globalState.vessels) && hasUpdateInfo;

    // const vessels = fleetState.vessels || [];    
    // const vessels = _.orderBy(globalState.vessels, ['vesselName']);

    useEffect(() => {
        fleetActions.getVesselUpdating();
    }, []);


    const onSearch = (text) => {
        // console.log('onSearch', text);
        setFilter(() => { return text.toLowerCase(); });
    };

    const counter = selectedVesselID + filter.length + sort;
    return (
        <ContentColumn loaded={loaded} selectedID={counter} >
            <div className="contentColumnFilter"  style={{ padding: '10px 0px', margin: "-10px 0px 0px 0px", width: '100%', backgroundColor: '#fff', zIndex: 1, position: 'absolute' }}>
                <SearchFilter placeholder="Search Vessel" onSearch={onSearch} onSort={(S) => setSort(() => S)} />
            </div>

            <div className="contentColumnBody disablePadding">
                <br /><br /><br />
                {
                    <List component="div" disablePadding>
                        {
                            vessels
                            .filter(X => (X.vesselName.toLowerCase().indexOf(filter) !== -1))
                                .map(X => (
                                    <NavigatorVesselListItem
                                        key={X.vesselID}
                                        Vessel={X}
                                        UpdateInfo={hasUpdateInfo ? fleetState.vesselUpdates.find(U => X.vesselID === U.vesselID) : null}
                                        Selected={X.vesselID === selectedVesselID}
                                    />))
                        }
                        <Divider />
                    </List>
                }
            </div>
        </ContentColumn>
    );
}

const NavigatorVesselListItem = ({ Vessel, UpdateInfo, Selected }) => {

    //let history = useHistory();

    return useMemo(() => {

        // console.log('basket VesselID', Selected);

        const { vesselID } = Vessel;



        // const patchLevel = _.get(UpdateInfo, 'patchLevel', null);
        const days = _.get(UpdateInfo, 'days', null);
        const updateIssue = _.get(UpdateInfo, 'updateIssue', false);
        const updateWarning = _.get(UpdateInfo, 'updateWarning', null);
        const blocked = _.get(UpdateInfo, 'blocked', false);
        // console.log('UpdateInfo.patchLevel', patchLevel);

        let statusColor = "Green";
        if (updateIssue) { statusColor = "Red" }
        else if (updateWarning) { statusColor = "Orange" }


        const handleVesselClick = (vesselID) => {
            //// console.log('Vessel Click', Vessel, vesselID);
            //BasketActions.setVessel(vesselID);
            //history.push(`/sales/catalogue/${vesselID}`);
        }

        return (
            <ListItemVessel
                vessel={Vessel}
                onClick={handleVesselClick}
                selected={Selected}
                linkTo={`/fleet/navigator/${vesselID}`}
                // numberSmall={patchLevel}
                // subText="7 days ago"
                statusComponent={
                    <React.Fragment>
                        <ChipStatus number={days} statusColor={statusColor} />
                        {blocked === true && (<BlockIcon color="error" />)}

                    </React.Fragment>
                }
            >

            </ListItemVessel>
        );

    }, [Vessel, UpdateInfo, Selected]);

}

export default NavigatorUpdates
