
const getSalesOrderStatusColor = (StatusID) => {
    let result = "black";
    switch (StatusID) {
        case 3:
            result = "#ff9900";
            break;
        case 4:
            result = "#1dc71d";
            break;
        case 5:
            result = "#ff4a4a";
            break;
        default:
    }
    return result;
};

const getHoldingLineColor = (line) => {
    let color = "black";
    if (!line.active)
        color = "black"
    else if (line.valid)
        color = "#1dc71d" // Green
    else if (line.warning)
        color = "#ff9900" // Warning
    else if (line.issue)
        color = "#ff4a4a"; // Error

    return color;
};


const getHoldingStatusClass = (issue, warning, active) => {
    let result = "statusGreen";
    if (active == false)
        result = "statusGray"
    else if (issue === true)
        result = "statusRed"
    else if (warning === true)
        result = "statusOrange"


    // console.log('getHoldingStatusClass', result, { issue, warning })
    return result;
};


export { getSalesOrderStatusColor, getHoldingLineColor, getHoldingStatusClass }