import React, { useMemo, useEffect, useState } from 'react';
import { FeatureGroup } from 'react-leaflet';
import _ from 'lodash';
import { useFolioStore } from '../../../Global/Stores/folioStore';
//import { useFleetStore } from '../../../Global/Stores/fleetStore';
import FolioLinePolygon from '../Polygons/FolioLinePolygon';
import MapPopupHoldingLines from '../Popup/MapPopupHoldingLines';
import { checkPolygonInPoint } from '../MapFunctions';

const FolioItemsLayer = ({ match, history, mapActions }) => {
    const [folioState] = useFolioStore();
    //const [fleetState] = useFleetStore();

    const vesselID = folioState.folioVesselItems ? folioState.folioVessel.vesselID : 0;
    // const filter = fleetState.holdingDetail ? fleetState.holdingDetail.filter : {};
    const managerID = +match.params.managerID || 0;
    const folioID = +match.params.folioID || 0;
    const selectedItemID = +match.params.itemID || 0;
    const vesID = +match.params.vesselID || 0;
    //console.log(folioID)
    const [popup, setPopup] = useState({
        key: 0,
        position: null,
        lines: []
    });
    
    useEffect(() => {
        // if (managerID > 0 && folioID > 0) {
        //      const lines = folioState.folioVesselItems ? folioState.folioVesselItems :null;
        //     // console.log("LINES: ", folioState);

        //     //console.log("FOLIO ID CHANGED: ", lines);
        //     mapActions.zoomToLines(lines, false);
        // }


        if (folioID > 0 && selectedItemID > 0 ) {
            const line = folioState.folioVesselItems ? folioState.folioVesselItems.find(X => X.itemID === selectedItemID) :null;
            //console.log("LINE: ", line)
            mapActions.zoomToLines(line, false);
        }
        
    }, [vesID, vesselID, selectedItemID]);

    //return useMemo(() => {
        // console.log('HoldingLayer', fleetState.holdingDetail);

        if (vesselID === 0)
            return null;

        let lines = Boolean(folioState.folioVesselItems) ? folioState.folioVesselItems.filter(X => X.polygonPoints) : null;

        const onClick = (e) => {
            let selected = lines.filter(X => X.polygonPoints && checkPolygonInPoint(e.latlng, X.polygonPoints));
            if (selected.length === 0)
                selected = e.layer;

            if (selected.length === 1) {
                //console.log("history", selected.length);
                //history.push(`/fleet/holdings/${selected[0].vesselID}/${selected[0].item.itemID}`); //TO FIX LATER
                // mapActions.zoomToLines(selected);
            }
            else if (selected.length > 0) {

                setPopup(prev => {
                    return {
                        key: prev.key + 1,
                        position: e.latlng,
                        lines: selected
                    }
                });
            }

            // console.log("selected", selected);
        };

        // const filteredLines = lines);
        const sortedLines = _.orderBy(lines, ['item.scale'], ['desc']);
        //console.log("Sorted Lines: ", sortedLines)
        // mapActions.zoomToLines(filteredLines);

        return (
            <React.Fragment>
                <MapPopupHoldingLines popupInfo={popup} />

                <FeatureGroup >
                    {
                        // sortedLines.map(X => <Polygon key={X.item.itemID} positions={X.item.polygonPoints} />)
                        sortedLines.map(X => <FolioLinePolygon key={X.itemID} line={X} selected={selectedItemID === X.itemID} />)
                    }
                </FeatureGroup>

            </React.Fragment>
        );



    //}, [vesselID, vesID, popup, selectedItemID, folioID])
};

export default FolioItemsLayer;
