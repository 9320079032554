import React from 'react';
import { Link as RouterLink} from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import NextIcon from '@material-ui/icons/NavigateNext';
import BeforeIcon from '@material-ui/icons/NavigateBefore';
import { IconButton, Divider, Link, Grid } from '@material-ui/core';

const VesselQuoteLineDetailHeader = ({ line, closePath, next, before }) => {

    return (
        <React.Fragment>
            <Grid container spacing={1}>
                <Grid>
                    <Link to={closePath} component={RouterLink} className="navLink" style={{ color: 'inherit', textDecoration: 'none' }} >
                        <IconButton>
                            <CloseIcon />
                        </IconButton>
                    </Link>
                </Grid>
                <Grid>
                    <Link to={before} component={RouterLink} className="navLink" style={{ color: 'inherit', textDecoration: 'none' }} >
                        <IconButton >
                            <BeforeIcon />
                        </IconButton>
                    </Link>
                </Grid>
                <Grid>
                    <Link to={next} component={RouterLink} className="navLink" style={{ color: 'inherit', textDecoration: 'none' }} >
                        <IconButton >
                            <NextIcon />
                        </IconButton>
                    </Link>
                </Grid>
                {/* <Grid>
                    <Typography className="flexFill" variant="button" >
                        {line.item.itemTitle}
                    </Typography>
                </Grid> */}
                <Divider />
            </Grid>
        </React.Fragment>
    );
}

export default VesselQuoteLineDetailHeader
