import React from 'react';
import { Route } from "react-router-dom";
import Profile from './Profile/Profile';
import MapControl from '../../MapControl/MapControl';
import Users from './Users/Users';
import UserList from './Users/UserList';
import UserCreate from './Users/UserCreate';
import UserDetail from './Users/UserDetail';

const Account = () => {
    return (
        <div className="accountContainer">
            <Route exact path="/account" component={Profile} />
            <Route path="/account/profile" component={Profile} />


            <Route exact path="/account/users" component={Users} />
            <Route exact path="/account/users/create" component={Users} />
            <Route exact path="/account/users/manage" component={Users} />


            <Route path="/account/users/create" component={UserCreate} />

            <Route path="/account/users/manage/:id?" component={UserList} />
            <Route path="/account/users/manage/:id" component={UserDetail} />

            <MapControl />
        </div>);
}

export default Account
