import React, { useMemo } from 'react';
import ItemPolygon from './ItemPolygon';

const SalesQuoteLinePolygon = ({ line, selected }) => {


    return useMemo(() => {

        // console.log('SalesQuoteLinePolygon', line);

        if (!line.item.polygonPoints)
            return null;

        const positions = line.item.polygonPoints;
        const color = line.validationID === 1 ? "#1dc71d" : "#ff4a4a";

        return (
            <ItemPolygon
                positions={positions}
                fillColor={color}
                color={color}
                selected={selected}
            />
        );

    }, [line.validationID, selected]);
}

export default SalesQuoteLinePolygon
