import React, { useEffect, useState, useMemo } from 'react'
import { Box, Typography, Grid, TextField, Button } from '@material-ui/core';
import { useUserStore } from '../../../../Global/Stores/userStore';
import { isValidateEmail } from '../../../../Global/Data/Methods';
import http from '../../../../Global/Services/httpService';
import ContentColumn from '../../../Custom/ContentColumn';
import ProtectedArea from '../../../Custom/ProtectedArea';

const Profile = () => {
    const [userState, userActions] = useUserStore();
    const [loading, setLoading] = useState(false);
    const [profileState, setProfileState] = useState();

    useEffect(() => {
        setProfileState(() => ({ ...userState.user, password: "", password2: "" }))
    }, []);

    return useMemo(() => {

        if (!profileState)
            return null;

        const { firstName, lastName, email, password, password2 } = profileState;
        // console.log('profileState', profileState);

        const canSave = (
            (firstName.length > 0) &&
            (lastName.length > 0) &&
            (email.length > 0) &&
            (isValidateEmail(email)) &&
            (password.length > 5) &&
            (password === password2));

        const handleChange = (e) => {
            if (!loading) {
                const { name, value } = e.target;
                setProfileState(() => ({ ...profileState, [name]: value }));
            }
        };

        const onSubmit = (e) => {
            e.preventDefault();

            if (!canSave) return;

            const postData = {
                firstName: firstName,
                lastName: lastName,
                email: email,
                password: password,
            };

            setLoading(() => true);

            http.post('/api/account/users/change', postData)
                .then(result => {

                    // console.log('changed Profile', result.data);
                    userActions.login({ user: result.data.user });
                })
                .catch(() => {

                    setLoading(() => false);
                });
        }

        return (
            <ContentColumn loaded={true}>
                <div style={{ padding: '10px' }} disabled={true}>

                    <Typography component="h1" variant="h5" align="center">My Profile</Typography>

                    <form noValidate>
                        <Grid container direction="column" spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    autoComplete="fname"
                                    value={firstName}
                                    onChange={handleChange}
                                    name="firstName"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label="First Name"
                                    autoFocus
                                    disabled={loading}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    value={lastName}
                                    onChange={handleChange}
                                    required
                                    fullWidth
                                    id="lastName"
                                    label="Last Name"
                                    name="lastName"
                                    autoComplete="lname"
                                    disabled={loading}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    value={email}
                                    onChange={handleChange}
                                    required
                                    type="email"
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    disabled={loading}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    value={password}
                                    onChange={handleChange}
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    disabled={loading}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    value={password2}
                                    onChange={handleChange}
                                    required
                                    fullWidth
                                    name="password2"
                                    label="Repeat Password"
                                    type="password"
                                    id="passwordRepeat"
                                    autoComplete="current-password-repeat"
                                    disabled={loading}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={onSubmit}
                                    disabled={!canSave || loading}
                                >SAVE PROFILE</Button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </ContentColumn>
        )

    }, [profileState, loading])
}

export default Profile
