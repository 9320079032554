import React, { useEffect, useMemo } from 'react';
import { useSaleStore } from '../../../../Global/Stores/saleStore';
import { Box, IconButton, ListItemText, Divider, List, ListItem, ListItemIcon, Link, Grid } from '@material-ui/core';
import Loading from '../../Misc/Loading';
import SendIcon from '@material-ui/icons/Send';
import { Link as RouterLink } from 'react-router-dom';
import BackIcon from '@material-ui/icons/ArrowBack';
import _ from 'lodash';
import ContentColumn from '../../Custom/ContentColumn';

const FlatFeeOrders = ({ match, history }) => {
    const flatFeeID = +match.params.id || 0;
    const orderID = +match.params.orderID || 0;

    const [filter, setFilter] = React.useState('');
    const [sort, setSort] = React.useState(0);

    const [saleState, saleActions] = useSaleStore();


    useEffect(() => {
        if (flatFeeID > 0) {
            saleActions.getVesselFlatFeeOrders(flatFeeID);
        }
    }, [flatFeeID]);


    const detailID = _.get(saleState, 'flatFeeDetail.flatFee.vesselFlatFeePeriodID', -1);
    const loaded = (detailID === flatFeeID);

    // console.log('loaded', loaded, flatFeeID, loaded, detailID);

    //Filter included should there be a need for including a filtering feature in the future
    let flatFeeOrderSort = loaded ?
    saleState.flatFeeDetail.orders.filter(X => X.orderDate.toLowerCase().indexOf(filter) !== -1) : [];
    console.log(saleState.flatFeeDetail);

    flatFeeOrderSort = _.orderBy(flatFeeOrderSort, ['orderDate'], ['desc']);

    return (
        <ContentColumn loaded={loaded}>
            {
                loaded ? (
                    <React.Fragment>
                        <div className="contentColumnHeader"  style={{ padding: '10px 0px', margin: "-10px 0px 0px 0px", width: '100%', backgroundColor: '#fff', zIndex: 1, position: 'absolute' }}>
                            <FlatFeeHeader flatFee={saleState.flatFeeDetail.flatFee} history={history} />
                        </div>

                        <div className="contentColumnBody disablePadding"> <br /><br />
                            <List className="ListItemHeader" disablePadding>
                                {
                                    flatFeeOrderSort.map(X => <FlatFeeOrder
                                        key={X.purchaseOrderID}
                                        vesselFlatFeePeriodID={flatFeeID}
                                        order={X}
                                        selected={X.purchaseOrderID === orderID}
                                    />
                                    )
                                }
                            </List>
                            <br /><br /><br />
                        </div>
                    </React.Fragment>
                ) : null
            }
        </ContentColumn>
    );
};

// Header
const FlatFeeHeader = ({ flatFee, history }) => {

    return useMemo(() => {
        const handleBack = () => {
            const url = history.location.pathname;
            const popLink = url.substring(0, url.lastIndexOf("/"));
            history.push(popLink);
        }

        if (!flatFee)
            return null;

        return (
            <Grid container spacing={0}>
                <Grid>
                <IconButton onClick={handleBack}>
                    <BackIcon />
                </IconButton>
                </Grid>
                <Grid>
                <ListItemText className="flexFill"
                    primary={`Contract: ${flatFee.description}`}
                // secondary={`${order.customerReference} (${order.orderDate})`}
                >
                </ListItemText>
                </Grid>

                <Divider />
            </Grid>
        );
    }, [flatFee]);
}


const FlatFeeOrder = ({ vesselFlatFeePeriodID, order, selected }) => {


    return useMemo(() => {

        const { purchaseOrderID, reference, totalQuantity } = order;
        const to = `/sales/flatfee/${vesselFlatFeePeriodID}/${purchaseOrderID}`;

        return (
            <React.Fragment>
                <Link className="ListItemLink" component={RouterLink} to={to} color='inherit' style={{ textDecoration: 'none' }}>
                    <ListItem button divider selected={selected}>
                        <ListItemText
                            primary={reference}
                            secondary={`Ordered on: ${order.orderDate.split('T')[0]}`}
                        />
                        <ListItemText style={{ flexGrow: '0' }} primary={`${totalQuantity} Items`} />
                    </ListItem>
                </Link>
            </React.Fragment>

        );
    }, [vesselFlatFeePeriodID, selected]);
}


export default FlatFeeOrders;
