import React from 'react';
import { IconButton, SvgIcon } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/SaveAlt';
import http, { getToken } from '../../../Global/Services/httpService'

const DownloadFile = ({ to, disabled }) => {

    const btn = (
        <IconButton disabled={disabled}>
            <SaveIcon />
        </IconButton>
    );

    return disabled ? btn : (
        <a target="_blank" rel="noopener noreferrer" href={`${to}?access_token=${getToken()}`}>
            {btn}
        </a>
    );
};


export const DownloadQuote = ({ salesQuoteID }) => {
    return (
        <DownloadFile to={`${http.endpoint}/api/file/quotes/${salesQuoteID}`} disabled={Boolean((salesQuoteID || 0) === false)} />
    );
};

export const DownloadOrder = ({ salesOrderID }) => {
    return (
        <DownloadFile to={`${http.endpoint}/api/file/orders/${salesOrderID}`} disabled={Boolean((salesOrderID || 0) === false)} />
    );
};

export const DownloadHoldings = ({ vesselID }) => {
    return (
        <DownloadFile to={`${http.endpoint}/api/file/holdings/${vesselID}`} disabled={Boolean((vesselID || 0) === false)} />
    );
};


export const OpenShipment = ({ to, disabled }) => {

    // console.log('OpenShipment', to);

    const btn = (
        <IconButton disabled={disabled}>
            <SvgIcon>
                <path
                    d="M3,4A2,2 0 0,0 1,6V17H3A3,3 0 0,0 6,20A3,3 0 0,0 9,17H15A3,3 0 0,0 18,20A3,3 0 0,0 21,17H23V12L20,8H17V4M10,6L14,10L10,14V11H4V9H10M17,9.5H19.5L21.47,12H17M6,15.5A1.5,1.5 0 0,1 7.5,17A1.5,1.5 0 0,1 6,18.5A1.5,1.5 0 0,1 4.5,17A1.5,1.5 0 0,1 6,15.5M18,15.5A1.5,1.5 0 0,1 19.5,17A1.5,1.5 0 0,1 18,18.5A1.5,1.5 0 0,1 16.5,17A1.5,1.5 0 0,1 18,15.5Z" />
            </SvgIcon>
        </IconButton>
    );

    return disabled ? btn : (
        <a target="_blank" rel="noopener noreferrer" href={`${to}`}>
            {btn}
        </a>
    );
};

export default DownloadFile;
