import React, { useMemo } from 'react';
import { Link as Link1, withRouter } from 'react-router-dom';
import { Link, ListItem, ListItemText, ListItemSecondaryAction, Chip } from '@material-ui/core';
import NumberStatus from '../../Widgets/NumberStatus';


const ListItemLink = ({ text, subText, to, location, number, numberSmall, selected, isHeader = true, divider = false, icon = null }) => {
    // console.log('number 1', number);

    const isSelected = selected === true || (location.pathname.startsWith(to));

    const hasIcon = Boolean(icon);


    return useMemo(() => {
        // console.log('ListItemLink number', number);

        return (
            <Link component={Link1} to={to} color='inherit' style={{ textDecoration: 'none' }}>
                <ListItem button className={isHeader ? "ListItemHeader" : ""} selected={isSelected} divider={divider}>
                    <ListItemText primary={text} secondary={subText} />
                    <ListItemSecondaryAction>
                        {
                            numberSmall ? <Chip size="small" style={{ color: '#4d4d4d' }} label={numberSmall} variant="outlined" /> : null
                        }
                        {
                            number ? <NumberStatus number={number} /> : null
                        }


                        {icon !== null ? icon : null}
                    </ListItemSecondaryAction>
                </ListItem>
            </Link>
        );

        // }, []);
    }, [text, to, number, numberSmall, isSelected, isHeader, hasIcon]);
}

export default withRouter(ListItemLink);
