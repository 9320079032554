import React, { useMemo, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Typography, ListItem, ListItemIcon, List, ListItemText, Collapse, Chip, Link, ListItemSecondaryAction, IconButton, FormControl, Select, Input, MenuItem, Button } from '@material-ui/core';
import { useGlobalStore } from '../../../../../Global/Stores/globalStore';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Link as RouterLink } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import { useBasketStore } from '../../../../../Global/Stores/basketStore';
import { useFleetStore } from '../../../../../Global/Stores/fleetStore';
import _ from 'lodash';
import ContentColumn from '../../../../Custom/ContentColumn';

const CatalogueBasketReview = ({ match }) => {
    const [globalState,] = useGlobalStore();
    const [basketState, basketActions] = useBasketStore();
    // const [saleState, saleActions] = useSaleStore();

    const selectedItemID = +match.params.itemID || 0;

    // console.log('selectedItemID', selectedItemID);

    useEffect(() => {
        // const { selectedItems, vesselID } = saleState.catalogue;
        // if (vesselID > 0)
        basketActions.getBasketPrices();
    }, []);


    const itemsLoaded = Boolean(globalState.catalogue);

    const productGroups = _.get(globalState, 'catalogue.productGroups', []);
    const { basketLines, closedProductGroups, vesselID } = basketState;
    const listItems = basketLines || [];

    

    if (listItems.length === 0) {
        return <Redirect to="/sales/catalogue/add/" />
    }

    return (
        <ContentColumn loaded={itemsLoaded}>
            <div className="contentColumnHeader" style={{ padding: '0px 10px' }}>
                <Typography variant="h6">Basket Review</Typography>
            </div>

            <div className="contentColumnFilter" >
                <BasketDurationSelector />
            </div>

            <div className="contentColumnBody">
                <List component="div" className="ListItemHeader" disablePadding>
                    {
                        productGroups.map(PG => (
                            <BasketProductGroupListHeader
                                key={PG.productGroupID}
                                productGroup={PG}
                                vesselID={vesselID}
                                lines={listItems.filter(I => Boolean(I.item) && I.item.productGroupID === PG.productGroupID)}
                                selectedItemID={selectedItemID}
                                open={!closedProductGroups.includes(PG.productGroupID)}
                                toggleOpen={basketActions.toggleClosedProductGroups}
                            />))
                    }
                </List>
            </div>

            <div className="contentColumnFooter">
                <BasketFooter />
            </div>
        </ContentColumn>
    );
};


const BasketDurationSelector = () => {

    const [basketState, basketActions] = useBasketStore();
    const allMonths = [3, 6, 9, 12];
    const { months } = basketState;

    return useMemo(() => {

        const durationChange = (e) => {
            const newMonths = +e.target.value;
            basketActions.setBasketDuration(newMonths);
            // console.log('durationChange', months);
        }

        return (
            <React.Fragment>
                <Typography variant="button" >Set Durations:&nbsp;&nbsp;</Typography>
                <FormControl>
                    <Select
                        displayEmpty
                        // disabled={hasEmptyBasketDurations}
                        value={months}
                        renderValue={(X) => <div>{`${X} Months`}</div>}
                        onChange={durationChange}
                        input={<Input placeholder="Duration" style={{ width: '125px' }} />}
                    >
                        {
                            allMonths.map(M => (
                                <MenuItem key={M} value={M}>
                                    <ListItemText primary={`${M} Months`} />
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </React.Fragment>
        );
    }, [months])
}


const BasketProductGroupListHeader = ({ productGroup, vesselID, lines, selectedItemID, open, toggleOpen }) => {

    // console.log('BasketProductGroupListHeader', productGroup);
    // const open = true;
    const { productGroupID, caption, isDigitalPoductGroup } = productGroup;
    const [, basketActions] = useBasketStore();
    const [fleetState, fleetActions] = useFleetStore();
    const doubleOrder = [];

    useEffect (
        () => {
        fleetActions.getVesselHoldings(vesselID)
        return () => {
            fleetActions.clearVesselHoldings();
        }
        }, [vesselID])

        const loadedHoldings = Boolean(fleetState.holdingDetailItems);

        const holdingItems = loadedHoldings ? fleetState.holdingDetailItems : []


         const anything = loadedHoldings ? console.log(holdingItems) : null;
         // We will simply do a check for each array
         // console.log("Before: ", doubleOrder);
         
         // lines.forEach(X => 
         //     holdingslist.includes(X.item.itemNumber) ?
         //         doubleOrder.push(X.item.itemNumber)
         //     :null
         
         //     )
         
         //     console.log("After: ", doubleOrder);
         
         if (lines.length === 0)
         return null;
         
         // console.log('items', items);
         console.log(lines);

    return (

        <React.Fragment>
            <ContentColumn loaded={loadedHoldings}>
            <ListItem dense button divider selected={open} onClick={() => toggleOpen(productGroupID)}>
                <ListItemIcon>
                    <SendIcon />
                </ListItemIcon>

                <ListItemText primary={caption} secondary="Subtotal" />
                <Chip size="small" color="primary" label={lines.length} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {
                        lines.map(X => 
                        // This was to ensure that existing items are not ordered twice. so it simply takes it out
                        // Plan was to also include a popup message. but this has been replaced
                        // with a check at the point of adding items. disables existing items
                        //     holdingItems.some(Y => Y.itemID === X.itemID) ?
                        //     basketActions.removeBasketItems([X.itemID])
                        //  :
                        <BasketProductGroupListItem
                            key={X.itemID}
                            vesselID={vesselID}
                            isDigital={isDigitalPoductGroup}
                            line={X}
                            selected={selectedItemID === X.itemID} />
                        )
                    }
                </List>
            </Collapse>
            </ContentColumn>
        </React.Fragment>
    );
}

const BasketProductGroupListItem = ({ line, selected, isDigital }) => {
    const [, basketActions] = useBasketStore();
    const { item, itemPrice, quantity } = line;

    //console.log(line.itemID)

    const months = itemPrice ? itemPrice.months : false;

    return useMemo(() => {
        const to = `${item.itemID}`;
        const label = isDigital ? (Boolean(months) ? `${months} Months` : null) : `x ${quantity}`

        return (
            <Link className="ListItemLink" component={RouterLink} to={to} color='inherit' style={{ textDecoration: 'none' }}>
                <ListItem button disableRipple dense divider selected={selected}>
                    <ListItemText primary={item.itemNumber} secondary={item.itemTitle} />
                    {
                        Boolean(label) ? <Chip size="small" label={label} /> : null
                    }

                    <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="Comments" onClick={(e) => {
                            e.preventDefault();
                            basketActions.removeBasketItems([item.itemID]);
                        }}>
                            <DeleteIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            </Link>
        );

    }, [item.itemID, selected, months, quantity])
}

const BasketFooter = () => {

    const [globalState,] = useGlobalStore();
    const [basketState, basketActions] = useBasketStore();

    const vesselID = basketState.vesselID;

    const months = basketState.months;
    const lineCount = basketState.basketLines.length;
    const validLines = basketState.basketLines.filter(X => Boolean(X.itemPrice));

    const validPrices = lineCount === validLines.length;

    // if (!validPrices) {
    //     const invalid = basketState.basketLines.filter(X => !Boolean(X.itemPrice));
    // console.log('Invalid Lines', invalid);
    // }

    const P = _.sumBy(basketState.basketLines, 'itemPrice.price');
    const QTY = _.sumBy(basketState.basketLines, 'quantity');

    return useMemo(() => {

        const totalValue = validPrices ? [] : ["Calculating..."];

        if (validPrices === true) {
            globalState.currencies.forEach(C => {

                const currencyLines = validLines
                    .filter(P => P.itemPrice.currencyID === C.currencyID)
                    .map(L => +((L.quantity * L.itemPrice.price).toFixed(2)));

                if (currencyLines.length > 0) {
                    const currencyPrice = _.sum(currencyLines).toFixed(2);
                    totalValue.push(`${currencyPrice} ${C.code}`);
                }
            });
        }

        const priceString = totalValue.join(" + ");
        // const confirmDisabled = !validPrices || validLines.length === 0;


        // console.log('BasketFooter', priceString)

        return (
            <React.Fragment>
                <Typography variant="subtitle2" align="left" style={{ flexGrow: '1' }}>
                    {`Total Price: ${priceString}`}
                </Typography>

                {/* <BasketConfirmation disabled={confirmDisabled} /> */}

                <Button variant="contained" size="small" onClick={() => { basketActions.clearData(); basketActions.setVessel(vesselID); }}>Clear Basket</Button>
            </React.Fragment>
        );
    }, [vesselID, validPrices, months, validLines.length, P, QTY, basketActions]);
};


export default CatalogueBasketReview;
