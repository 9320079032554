import React from 'react'
import { ListItemIcon } from '@material-ui/core';
import CircleIcon from '@material-ui/icons/FiberManualRecord';

const StatusCircle = ({ className }) => {

    return (
        <ListItemIcon
            className={className}
            style={{ width: '30px', minWidth: '24px' }}
        >
            <CircleIcon />
        </ListItemIcon>
    )
}

export default StatusCircle
