import React, { useEffect } from 'react';
import SearchFilter from '../../Misc/SearchFilter';
import { useSaleStore } from '../../../../Global/Stores/saleStore';
import VesselQuotes from './VesselQuotes';
import ContentColumn from '../../Custom/ContentColumn';
import _ from 'lodash';
import { List, Divider } from '@material-ui/core';

const Quotes = ({ match }) => {
    const [saleState, salesActions] = useSaleStore();
    const [filter, setFilter] = React.useState('');
    const [sort, setSort] = React.useState(1);

    const selectedID = +match.params.id || 0;


    useEffect(
        () => {
            salesActions.getVesselQuotes();
        }, []
    );


    // const onSearch = (text) => {
    //     setFilter(() => { return text.toLowerCase(); })
    // };


    const loaded = Boolean(saleState.vesselQuotes);

    let vessels = loaded ?
        saleState.vesselQuotes.filter(X => X.vesselName.toLowerCase().indexOf(filter) !== -1) : [];

    vessels = _.orderBy(vessels, ['vesselName'], [sort > 0 ? 'asc' : 'desc']);

    return (
        <ContentColumn loaded={loaded}>
            <div className="contentColumnHeader" style={{ padding: '10px 0px', margin: "-10px 0px 0px 0px", width: '100%', backgroundColor: '#fff', zIndex: 1, position: 'absolute' }}>
                <SearchFilter
                    placeholder="Search Vessel"
                    onSearch={(F) => setFilter(() => F.toLowerCase())}
                    onSort={(S) => setSort(() => S)}
                />
            </div>

            <div className="contentColumnBody"> 
                <br /><br /><br />
                {
                    <List component="div">
                        {
                            vessels.map(X => (<VesselQuotes key={X.vesselID} vesselQuotes={X} selectedID={selectedID} />))
                        }
                        <Divider />
                    </List>
                }
                <br /><br />
            </div>
        </ContentColumn>
    );
};

export default Quotes;
