import React, { useEffect } from 'react';
import { Route, Switch } from "react-router-dom";
import NewsList from './NewsList';
import NewsDetail from './NewsDetail';
import Breadcrumb from '../Navigation/Breadcrumb';

const News = () => {

    return (
        <div className="fleetContainer">
            <Breadcrumb Module="News &amp; Announcements" currentPage="News/Announcements" />
            <Switch>
                <Route exact path="/news" component={NewsList} />
                <Route exact path="/news/:newsID?" component={NewsDetail} />
            </Switch>
        </div>
    );
}

export default News;
