import React from 'react';

////// Call Map
import MapControl from '../../MapControl/MapControl';

////// Material UI support Files
import { Fab, Icon, Dialog, Slide } from '@material-ui/core';

////// Styling Things
import menuStyling from '../CSS/MenuStyles.module.css';

// Modal Window
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const FloatingMenuButton = () => {
  // Modal Window
  const [open, setOpen] = React.useState(false);

  const openMap = () => {
    setOpen(true);
  };

  const closeMap = () => {
    setOpen(false);
  };

  return (
    <div>
      <div>
        <Dialog fullScreen open={open} TransitionComponent={Transition} className={menuStyling.mainDialog} >
              <div className={menuStyling.floatRightClose}>
                <Fab color="primary" onClick={closeMap}>
                <Icon fontSize="large" >close</Icon>
                </Fab>
              </div>
              <MapControl showMenu={true} menuOrientation="hor"/>
        </Dialog>
      </div>

      <div className={menuStyling.floatRight}>
        <Fab color="primary" onClick={openMap}>
        <Icon fontSize="large" >location_on</Icon>
        </Fab>
      </div>
    </div>
    
  );
}

export default FloatingMenuButton