import React, { useMemo } from 'react';
import { Box, List, ListItem, ListItemIcon, ListItemText, Link, Chip, Divider } from '@material-ui/core';
import SearchFilter from '../../Misc/SearchFilter';
import { Link as RouterLink } from 'react-router-dom';
import { useFleetStore } from '../../../../Global/Stores/fleetStore';
import Loading from '../../Misc/Loading';
import ChipStatus from '../../../Widgets/Status/ChipStatus';
import ContentColumn from '../../Custom/ContentColumn';
import ListItemVessel from '../../_StyledComponents/ListItemVessel';
import _ from 'lodash';

const FleetStatus = ({ match }) => {

    const [fleetState, fleetActions] = useFleetStore();
    const [filter, setFilter] = React.useState('');
    const [sort, setSort] = React.useState(1);

    const selectedID = +match.params.vesselID;

    const onSearch = (text) => {
        setFilter(() => { return text.toLowerCase(); })
    };

    const loaded = Boolean(fleetState.vessels);

    const vessels = _.orderBy(fleetState.vessels, ['vesselName'], [sort > 0 ? 'asc' : 'desc']);

    return (
        <ContentColumn loaded={loaded}>
            <div className="contentColumnHeader" style={{ padding: '10px 0px', margin: "-10px 0px 0px 0px", width: '100%', backgroundColor: '#fff', zIndex: 1, position: 'absolute' }}>
                <SearchFilter placeholder="Search Vessel" onSearch={onSearch} onSort={(S) => setSort(() => S)} />
            </div>

            <div className="contentColumnBody disablePadding">
                <br /><br /><br />
                {
                    loaded ? (
                        <List component="div">
                            {

                                vessels
                                    .filter(X => (X.vesselName.toLowerCase().indexOf(filter) !== -1))
                                    .map(X => (
                                        <ListItemVessel
                                            key={X.vesselID}
                                            vessel={X}
                                            selected={X.vesselID === selectedID}
                                            statusComponent={<VesselFleetStatusChips vesselItemProductGroups={X.vesselItemProductGroups} />}
                                            linkTo={`/fleet/status/${X.vesselID}`}
                                        />))
                            }
                            <Divider />
                        </List>
                    ) : <Loading />
                }
            </div>
        </ContentColumn>
    );
};

const VesselFleetStatusChips = ({ vesselItemProductGroups }) => {

    const totalQuantity = _.sumBy(vesselItemProductGroups, "totalQuantity");
    const totalWarning = _.sumBy(vesselItemProductGroups, "itemWarningCount") + _.sumBy(vesselItemProductGroups, "updateWarningCount");
    const totalIssue = _.sumBy(vesselItemProductGroups, "itemIssueCount") + _.sumBy(vesselItemProductGroups, "updateIssueCount");
    const totalValid = totalQuantity - totalWarning - totalIssue;
    return useMemo(() => {
        return (
            <React.Fragment>
                <ChipStatus number={totalValid} statusColor="Green" />
                <ChipStatus number={totalWarning} statusColor="Orange" />
                <ChipStatus number={totalIssue} statusColor="Red" />
            </React.Fragment>
        );
    }, []);
}

export default FleetStatus;
