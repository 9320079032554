import React, { useState } from 'react';
import { Paper, InputBase, IconButton, Divider, TextField, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
//import FilterIcon from '@material-ui/icons/FilterList';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import _ from 'lodash';

const SearchFilter = ({ placeholder, onSearch, onSort }) => {

  const [, setState] = useState('');
  const [sort, setSort] = useState(1);

  const hasSorting = !!onSort;


  const handleOnSearch = (e) => {
    // console.log('e', e.target.value);
    const val = e.target.value;
    setState(() => setState(val));
    onSearch(val);
  }

  const handleOnSort = () => {
    // const val = e.target.value;
    setSort((prev) => {

      const val = prev * -1;
      // console.log('sort', val);
      if (!!onSort)
        onSort(val);

      return val;
    });
  }


  return (
    <Paper className="searchFilter" square elevation={2} style={{ width: '100%', display: 'flex' }}>
      <IconButton aria-label="Search" className="iconButton" disabled>
        <SearchIcon />
      </IconButton>
      <InputBase
        placeholder={placeholder || 'Search'}
        // className="input"
        margin="dense"
        style={{ flexGrow: '1' }}
        onChange={handleOnSearch}
      />
      {
        hasSorting && (
          <React.Fragment>
            <Divider orientation="vertical" style={{ height: '48px', margin: '0px' }} />
            <IconButton color="primary" onClick={() => handleOnSort()}>
              <SwapVertIcon />
            </IconButton>
          </React.Fragment>)
      }
    </Paper>
  );
};


export const TextFieldSearch = ({ Placeholder, OnSearch }) => {

  // const [, setState] = useState('');  
    const handleSearch = _.debounce((e) => {
        // console.log('handleSearch', e)
        // catalogueActions.setFilterText(e);
        OnSearch(e);
    }, 400);

  return (
    <TextField margin="dense" size="small" label={Placeholder || 'Search'} variant="outlined" fullWidth
      onChange={(e) => handleSearch(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color="disabled" />
          </InputAdornment>
        ),
      }} />
  );

}



export default SearchFilter;
