import React, { useState, useMemo } from 'react';
import { DisplayParameters } from './DisplayParams';
import { Layers } from './Layers';
import { Typography, FormControlLabel, Radio, RadioGroup, Divider, FormControl, FormGroup, Checkbox, FormLabel, TextField, Box, OutlinedInput, InputLabel, Select, MenuItem, ListItemText } from '@material-ui/core';
import { useMapStore } from '../../../../Global/Stores/mapStore';
import _ from 'lodash';

const AVCSO_Options = DisplayParameters.DisplayParameters.ECDISParameters.DynamicParameters;

const AvcsOLayerOptions = () => {

    // const options = DisplayParameters.DisplayParameters.ECDISParameters.DynamicParameters;

    // console.log('AvcsOLayerOptions', options);

    // const valuesE = options.Parameter
    //     .filter(X => X.type === 'E')
    //     .map(X => <LayerOptionsE key={X.name} Option={X} />);

    // const valuesL = options.Parameter
    //     .filter(X => X.type === 'L')
    //     .map(X => <LayerOptionsL key={X.name} Option={X} />);


    // const valuesF = options.Parameter
    //     .filter(X => X.type === 'F')
    //     .map(X => <LayerOptionsF key={X.name} Option={X} />);


    const parameters = AVCSO_Options.Parameter
        // .filter(X => X.type === 'L' && X.name == 'IntendedUsage')
        .map(X => <ParameterOption key={X.name} Parameter={X} />);


    // console.log('parameters', parameters.length, valuesE.length, valuesL.length, valuesF.length);


    const groups = AVCSO_Options.ParameterGroup.map(X => <ParameterGroupOptions key={X.name} Group={X} />);

    return (
        <div>
            {parameters}
            {groups}
            <AvcsoLayerParameter Layer={Layers} />
        </div>
    );
};

// 1
export const AVCSO_LayerOptionsContainer = () => {

    const [mapState] = useMapStore();
    const activeLayers = mapState.AVCSO.layers.value;

    return (
        <React.Fragment>
            {
                <AvcsoLayerParameter Layer={Layers} ActiveLayers={activeLayers} />
            }
        </React.Fragment>
    );
}

// 2
export const AVCSO_ParameterOptionsContainer = () => {

    // console.log('AVCSO_Options.Parameter', AVCSO_Options.Parameter)
    return (
        <React.Fragment>
            {
                AVCSO_Options.Parameter.map(X => {
                    return (
                        <React.Fragment key={X.name}>
                            <ParameterOption Parameter={X} IsGroup={false} />
                            <Divider />
                        </React.Fragment>
                    );
                })
            }
        </React.Fragment>
    );
}

// 3
export const AVCSO_ParameterGroupOptionsContainer = () => {
    return (
        <React.Fragment>
            {
                AVCSO_Options.ParameterGroup.map(X => <ParameterGroupOptions key={X.name} Group={X} />)
            }
        </React.Fragment>
    );
}


const ParameterGroupOptions = ({ Group }) => {
    const groupName = Group.caption || Group.name;

    return (
        <React.Fragment>
            <Box border={0} padding={1}>
                <Typography variant="subtitle2" component="h2">{groupName}</Typography>
                {
                    Group.Parameter.map(X => <ParameterOption key={X.name} Parameter={X} GroupName={Group.name} IsGroup={true} />)
                }
            </Box>
            <Divider />
        </React.Fragment>
    );
}


const ParameterOption = ({ Parameter, GroupName, IsGroup }) => {

    const [mapState] = useMapStore();
    //const activeValue = mapState.AVCSO.parameter.find(P => P.name === Parameter.name);
    // const activeValue = mapState.AVCSO.parameter.find(P => P.name === Parameter.name);

    // console.log('activeValue', activeValue);


    let activeValue = mapState.AVCSO.parameter.find(P => P.name === Parameter.name);
    if (IsGroup) {
        const grp = mapState.AVCSO.parameterGroup.find(X => X.name === GroupName);
        if (grp) {
            activeValue = grp.Parameter.find(P => P.name === Parameter.name);
        }
    }

    const getOption = (option, IsGroup, GroupName) => {
        switch (option.type) {
            case 'E':
                return (<LayerOptionsE key={option.name} Option={option} Value={activeValue} GroupName={GroupName} IsGroup={IsGroup} />);
            case 'L':
                return (<LayerOptionsL key={option.name} Option={option} Value={activeValue} GroupName={GroupName} IsGroup={IsGroup} />);
            case 'F':
                return (<LayerOptionsF key={option.name} Option={option} Value={activeValue} GroupName={GroupName} IsGroup={IsGroup} />);
            default:
                return null;
        }
    }

    return (getOption(Parameter, IsGroup, GroupName));
}


const LayerOptionsE = ({ Option, Value, GroupName, IsGroup }) => {
    const [, mapActions] = useMapStore();
    //const [value, setValue] = useState(Option.default);
    const [value, setValue] = useState(_.get(Value, 'value') || Option.default);


    // console.log('Value', _.get(Value, 'value', Option.default));
    // console.log('LayerOptionsE', GroupName);

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => { if (inputLabel.current) { setLabelWidth(inputLabel.current.offsetWidth); } }, [value]);

    return useMemo(() => {

        const { name, caption, Description, ExpectedInput } = Option;

        const handleValueChanged = (e) => {
            const target = e.target;
            const val = target.type == "checkbox" ? (1 + +target.checked) : (+target.value);

            setValue(_ => {
                const par = val == Option.default && false ? { name, value: null } : { name, value: val };

                console.log('par', par);

                mapActions.setAvcsoParameter({ Parameter: par, IsGroup, GroupName });

                return val.toString();
            })
        }

        const displayName = caption || (Description.length < 35 ? Description : name);
        const isBinary = ExpectedInput.length == 2 && ExpectedInput[0].value == "False" && ExpectedInput[1].value == "True";

        if (isBinary == true) {
            return (
                <FormControlLabel
                    style={{ display: 'block' }}
                    control={
                        <Checkbox
                            checked={Boolean(value - 1)}
                            onChange={handleValueChanged}
                            // value={value}
                            color="primary"
                        />
                    }
                    label={displayName}
                />
            );
        }

        return (
            <Box marginTop={1} marginBottom={1}>
                <FormControl variant="outlined" title={Description}>
                    <InputLabel ref={inputLabel} htmlFor={name}>{displayName}</InputLabel>
                    <Select
                        style={{ minWidth: '300px' }}
                        value={value}
                        labelWidth={labelWidth}
                        onChange={handleValueChanged}
                    >
                        {
                            ExpectedInput.map(X => (<MenuItem key={X.code} value={X.code}>{X.value}</MenuItem>))
                        }
                    </Select>

                </FormControl>
            </Box>
        );

    }, [value, IsGroup, GroupName, labelWidth]);
}


const LayerOptionsL = ({ Option, Value, GroupName, IsGroup }) => {
    const [, mapActions] = useMapStore();
    const [value, setValue] = useState((_.get(Value, 'value') || Option.default).split(',').map(X => +X));

    // return useMemo(() => {

    // console.log(Option.name, value);
    // console.log('Option.default', Option.default.split(',').map(X => +X));

    const { name, Description, ExpectedInput } = Option;

    const handleValueChanged = (e) => {

        const target = e.target;
        const val = +target.value;

        setValue(P => {
            const newState = P.includes(val) ? P.filter(X => X !== val) : [...P, val].map(X => +X);
            const par = newState == Option.default && false ? { name, value: null } : { name, value: newState.toString() };
            mapActions.setAvcsoParameter({ Parameter: par, IsGroup, GroupName });

            return newState;
        });
    }

    return (
        <Box margin={1}>
            <FormControl component="fieldset" >
                <FormLabel component="legend" title={Description}>{Description}</FormLabel>
                <FormGroup>
                    {

                        ExpectedInput.map(X => (
                            <FormControlLabel
                                // title={Description}
                                key={+X.code}
                                value={+X.code}
                                control={<Checkbox color="primary" checked={value.includes(+X.code)} value={+X.code} onChange={handleValueChanged} />}
                                label={X.value}
                            />
                        ))
                    }
                </FormGroup>
            </FormControl>
            {/* <Divider /> */}
        </Box>
    );

    // }, [value]);
}


const LayerOptionsF = ({ Option, Value, GroupName, IsGroup }) => {
    const [, mapActions] = useMapStore();
    // const [value, setValue] = useState(+Option.default);
    const [value, setValue] = useState(+_.get(Value, 'value', Option.default));



    //const inputLabel = React.useRef(null);
    // const [labelWidth, setLabelWidth] = React.useState(0);
    // React.useEffect(() => {
    //     if (inputLabel.current) {
    //         console.log(inputLabel.current.offsetWidth);
    //         setLabelWidth(inputLabel.current.offsetWidth);
    //     }
    // }, [value]);



    //console.log('LayerOptionsF', GroupName, Option.name, Value);
    // console.log('Option.default', Option.default);

    //return useMemo(() => {
        const { name, caption, Description, ExpectedInput } = Option;

        // console.log('Option', Option);
        // console.log('value', value);

        const handleValueChanged = (e) => {
            const val = +e.target.value;

            setValue(() => {

                const par = val == +Option.default && false ? { name, value: null } : { name, value: val };
                // console.log('LayerOptionsF', par);

                mapActions.setAvcsoParameter({ Parameter: par, IsGroup, GroupName });

                return val
            });
        }

        const inputProps = {
            min: 0,
            step: 0.1,
        };

        const label = caption || Description;
        return (
            <TextField
                fullWidth
                label={label}
                // InputLabel
                // ref={inputLabel}
                title={Description}
                value={value}
                onChange={handleValueChanged}
                margin="dense"
                variant="outlined"
                type="number"
                inputProps={inputProps}
            />
        );

    //}, [value, labelWidth]);
}

const AvcsoLayerParameter = ({ Layer, ActiveLayers }) => {
    const [, mapActions] = useMapStore();

    //const [value, setValue] = useState(Layer.default.split(',').map(X => +X));
    const [value, setValue] = useState(ActiveLayers || Layer.default.split(',').map(X => +X));

    // console.log('ActiveLayers', ActiveLayers);

    return useMemo(() => {
        const { name, Description, ExpectedInput } = Layer;

        const handleValueChanged = (e) => {
            const val = +e.target.value;

            setValue(P => {
                const newState = _.sortBy((P.includes(val) ? P.filter(X => X !== val) : [...P, val]), [(X) => -X]);
                const par = newState == Layer.default ? { name, value: null } : { name, value: newState };

                mapActions.setAvcsoLayers(par);

                return newState;
            });
        }

        return (
            <div>
                <FormControl component="fieldset" >
                    <FormLabel component="legend" title={Description}>{name}</FormLabel>
                    <FormGroup>
                        {

                            ExpectedInput.map(X => (
                                <FormControlLabel
                                    // title={Description}
                                    key={+X.code}
                                    value={+X.code}
                                    control={<Checkbox color="primary" checked={value.includes(+X.code)} value={+X.code} onChange={handleValueChanged} />}
                                    label={X.value}
                                />
                            ))
                        }
                    </FormGroup>
                </FormControl>
                {/* <Divider /> */}
            </div>
        );

    }, [value, ActiveLayers]);

}

export default AvcsOLayerOptions;
