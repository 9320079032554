import React from 'react';
import { Polyline } from 'react-leaflet';


const defaultColor = "blue";
const defaultColorSelected = "red";



// const defaultStroke?: boolean;
const defaultWeight = 1;
const defaultWeightSelected = 2;


// const defaultOpacity?: number;
// const defaultLineCap?: LineCapShape;
// const defaultLineJoin?: LineJoinShape;
// const defaultDashArray?: string | number[];
// const defaultDashOffset?: string;
// const defaultFill?: boolean;
// const defaultFillColor?: string;
// const defaultFillOpacity?: number;

const BaseRoute = ({ color = defaultColor, positions, selected = false, sourceRouteID }) => {
    return (<Polyline key={sourceRouteID} color={color} positions={positions} weight={selected ? defaultWeightSelected : defaultWeight} />);
}

export default BaseRoute
