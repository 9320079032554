import React from 'react'
import { Route } from "react-router-dom";

// Breadcrumb
import Breadcrumb from '../Navigation/Breadcrumb';

// Support Files
import AVCSO from './AVCSO/AVCSO';
// import AVCSO_SelectItems from './AVCSO/AVCSO_SelectItems';
// import AVCSO_BasketReview from './AVCSO/AVCSO_BasketReview';
// import AVCSO_BasketConfirm from './AVCSO/AVCSO_BasketConfirm';

const AVCSOnline = () => {

    return(
        <React.Fragment>
            <Breadcrumb Module="Order Management" currentPage="AVCS Online" />
            <Route  path="/sales/avcso" component={AVCSO} />
            {/* <Route  path="/sales/avcso/add/:itemID?" component={AVCSO_SelectItems} />
            <Route  path="/sales/avcso/basket/:itemID?" component={AVCSO_BasketReview} />
            <Route  path="/sales/avcso/confirm/:itemID?" component={AVCSO_BasketConfirm} /> */}
        </React.Fragment>
    )
}

export default AVCSOnline