import React, {useState} from 'react'
import { Route } from "react-router-dom";

// Material UI support files
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { CssBaseline, makeStyles, Button, TextField, FormControlLabel, Checkbox, Link, Grid, Container, IconButton, InputAdornment, Typography } from "@material-ui/core";

// Support Files
import HelpPage from '../Help';
import ResetPassword from './ResetPassword';
import ForgotPassword from './ForgotPassword';

// Global Support Imports
import { useUserStore } from "../../../Global/Stores/userStore";
import http from '../../../Global/Services/httpService';

// Images
import CT_Logo from "./../../../../src/Images/CT-logo-transparant.png";

////// CSS
import loginStyles from '../CSS/LoginStyles.module.css'

/*
Emmanuel Eshun-Davies, January 23, 2020

TODO:
*/

const Login = () => {
    const [, actions] = useUserStore();

    // Form Control
    const [formState, setFormState] = useState({
        email: '',
        password: '',
        remember: false,
        showPassword: false,
        loading: false,
    });

    // Password Visibility control
    const handleChange = event => {
        const target = event.target;
        if (!formState.loading)
            setFormState(() => ({ ...formState, [target.name]: (target.type === 'checkbox' ? target.checked : target.value) }));
    };

    const clickShowPassword = () => {
        if (!formState.loading)
            setFormState({ ...formState, showPassword: !formState.showPassword });
    };

    // Handle onSubmit actions 
    const onSubmit = (e) => {
        e.preventDefault();
        setFormState({ ...formState, loading: true });

        const postData = {
            Username: formState.email,
            Password: formState.password,
            Remember: formState.remember
        };

        if(formState.email === "" || formState.password === ""){
            setFormState({ ...formState, loading: false });
            // We will do nothing! They will eventually get the idea. :/
        } else { 
            http.post('/api/auth', postData)
                .then(result => {

                    const userData = { ...result.data, remember: formState.remember }
                    actions.login(userData);
                })
                .catch((error) => {
                    setFormState({
                        ...formState,
                        password: '',
                        loading: false
                    });
                });
        }
    };


    return (
        <Container component="div" maxWidth="xs">
            {/* <CssBaseline /> */}
            <div className={loginStyles.logoCenter}>
                <Grid>
                    <img className="logo" width="60%" src={CT_Logo} />
                </Grid>
            </div>
            <div className={loginStyles.leftText}>
                <Typography component="h5" variant="body2" align="left">
                    Please sign in to access shipmanager
                </Typography>
            </div>
            <div className={loginStyles.forms}>
                <form className={loginStyles.form}  noValidate autoComplete="on">
                    <TextField
                        className="loginField"
                        margin="normal"
                        size="small"
                        required
                        fullWidth
                        id="email"
                        label="E-mail Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={formState.email}
                                onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ endAdornment: (<InputAdornment position="end">&nbsp;</InputAdornment>),}}
                    />
                    <TextField
                        className="loginField"
                        margin="normal"
                        size="small"
                        required
                        fullWidth
                        id="password"
                        label="Password"
                        name="password"
                        autoComplete="current-password"
                        type={formState.showPassword ? 'text' : 'password'}
                        value={formState.password}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        style={{ color: '#C0C0C0' }}
                                        edge="end"
                                        aria-label="Toggle password visibility"
                                        onClick={clickShowPassword}
                                    >
                                        {formState.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <FormControlLabel
                    control=
                    {
                       <Checkbox 
                            className="SignIn"
                            value="remember" 
                            name="remember" 
                            color="primary" 
                            checked={formState.remember}
                            onChange={handleChange}
                        />
                    }
                        label="Remember me"
                    />
                    <Button
                        className="SignIn"
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="inherit"
                        className={loginStyles.submit}
                        onClick={onSubmit}
                    >
                        Sign In
                    </Button>
                    <Grid  className={loginStyles.bottomLinks}>
                        <Grid>
                            <Link href="/resetpassword" variant="body2"  color="inherit">
                            Forgot your password?
                            </Link>
                            {/* &nbsp;&nbsp;| &nbsp;&nbsp;
                            <Link href="/help" variant="body2" color="inherit">
                            Need Help?
                            </Link> */}
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    )
}


const LoadPage = () => {
    return (
        <React.Fragment>
            {/* <Login /> */}
            <Route exact path="/login" component={Login} />
            <Route exact path="/forgotpassword" component={ForgotPassword} />
            <Route exact path="/resetpassword" component={ResetPassword} />
            <Route exact path="/help" component={HelpPage} />
        </React.Fragment>
    )
}


export default LoadPage