import React, { useEffect, useState, useMemo } from 'react'
import { Route } from "react-router-dom";
import ContentColumn from '../../Custom/ContentColumn';
import { Typography, Button, List, Divider, ButtonGroup } from '@material-ui/core';
import httpService from '../../../../Global/Services/httpService';
import { BasketButton } from '../Basket/Catalogue';
import { useAvcsoStore } from '../../../../Global/Stores/avcsoStore';

import AVCSO_SelectItems from './AVCSO_SelectItems';
import AVCSO_BasketReview from './AVCSO_BasketReview';
import AVCSO_BasketConfirm from './AVCSO_BasketConfirm';

// CSS Files
import salesStyling from '../../CSS/sales.module.css';

const AVCSO = () => {

    const [avcsoState, avcsoActions] = useAvcsoStore();
    // const [state, setState] = useState(null);

    const loaded = avcsoActions.isLoaded();
    const hasLicense = avcsoActions.hasAvcsoLicense();
    // console.log('AVCSO', state, hasLicense);

    useEffect(() => {
        avcsoActions.loadData();

    }, []);


    //console.log(hasLicense);
    return (
        <ContentColumn loaded={loaded}><br />
            {
                hasLicense ? <AvcsoLicenses /> : <AvcsoInformation />
            }
            <br />
            {/* <Route  exact path="/sales/avcso" component={AVCSO_SelectItems} />  */}
            {hasLicense ?
            <React.Fragment>
                <Route  exact path={["/sales/avcso", "/sales/avcso/add/:itemID?"]} component={AVCSO_SelectItems} />
                <Route  path="/sales/avcso/basket/:itemID?" component={AVCSO_BasketReview} />
                <Route  path="/sales/avcso/confirm/:itemID?" component={AVCSO_BasketConfirm} />
            </React.Fragment>
            : null}

        </ContentColumn>
    );
}


const AvcsoLicenses = () => {

    return (
        <React.Fragment>

            <div className="contentColumnBody" >
                <AvcsoButtonList />
            </div>

            <div className="contentColumnFooter">
            <Divider /><br />
            </div>
        </React.Fragment>

    );
}

const AvcsoButtonList = ({ Vessel }) => {
    return (
        <div
            className={`contentColumnFilter`}
            className={salesStyling.salesMenu}
            >

            {/* <List component="div"> */}
            <ButtonGroup  variant="contained" color="primary" aria-label="contained primary button group">


                <AvcsoAddItemsButton />
                <AvcsoReviewButton />
                <AvcsoConfirmButton />

            </ButtonGroup>
        </div>
    );
}

const AvcsoAddItemsButton = () => {
    const [avcsoState] = useAvcsoStore();
    const { basketLines } = avcsoState;

    const selected = basketLines.length > 0;
    const disabled = false;

    // return useMemo(() => {

    return (
        <React.Fragment>
            <BasketButton
                Text="Add Items"
                To={`/sales/avcso/add/`}
                Location={`/sales/avcso/add/`}
                Disabled={disabled}
                Selected={selected}
                Done={selected}
            />
        </React.Fragment>
        );
    // }, []);
}


const AvcsoReviewButton = () => {
    const [avcsoState, avcsoActions] = useAvcsoStore();
    const { basketLines } = avcsoState;


    // const hasLines = Boolean(basketLines.length);

    // const vesselID = basketState.vesselID;
    const basketItemCount = basketLines.length;
    const selected = avcsoActions.hasBasketPrices();
    //const selected = null;
    const disabled = basketLines.length === 0;


    useEffect(() => {

        avcsoActions.getBasketPrices();

    }, []);


    return useMemo(() => {

        // if (!hasLines) 
        //     return null;

        return (
            <React.Fragment>
                <BasketButton
                    Text="Review"
                    To={`/sales/avcso/basket/`}
                    Location={`/sales/avcso/basket/`}
                    Disabled={disabled}
                    Selected={selected}
                    Count={basketLines.length}
                />
                </React.Fragment>
            );

    }, [basketItemCount, disabled, selected]);
}

const AvcsoConfirmButton = () => {
    const [avcsoState, avcsoActions] = useAvcsoStore();
    // const hasLines = Boolean(basketState.basketLines);

    // const vesselID = basketState.vesselID;
    const lineCount = avcsoState.basketLines.length;
    const pricesLoaded = avcsoActions.hasBasketPrices();
    const disabled = (lineCount === 0) || (!pricesLoaded);
    const done = !disabled && avcsoState.reference.length > 0;

    // return useMemo(() => {

    //     if (!hasLines)
    //         return null;

    return (
        <React.Fragment>
            <BasketButton
                Text="Confirm"
                To={`/sales/avcso/confirm/`}
                Location={`/sales/avcso/confirm/`}
                Disabled={disabled}
                Done={done}
            />
        </React.Fragment>
    );

    // }, [hasLines, vesselID, disabled, done]);
}

const AvcsoInformation = () => {

    return (
        <React.Fragment>
            {/* <div className="contentColumnHeader" style={{ padding: '0px 10px' }}>
                <Typography variant="h6">AVCS Online</Typography>
            </div> */}

            <div className="contentColumnBody" style={{ padding: '15px', textAlign: 'justify' }}>
                <Typography>
                    With AVCS online you can now view official UKHO ENC’s directly in your web browser.
                    This allows you to verify the safety of intended routes for your vessels, while looking at the same charts as on board.
                    <br /><br />
                    A standard AVCS Online license grants access to all ENC’s in band 1.
                <br /><br />
                    Additional ENC coverage can be purchased and added to your AVCS Online license, should this be required.
                <br /><br />
                    AVCS Online is the easiest way to have updated ENC’s available to you at any time, anywhere you are.
                </Typography>
                <br />

                <Typography variant="caption">
                    -	In depth insight for maritime professionals     -
                </Typography>
            </div>

            <div className="contentColumnFooter">
                <RequestAvcsoInformationButton />
            </div>
        </React.Fragment>
    );

}




const RequestAvcsoInformationButton = () => {


    const handleRequestInformation = () => {

        // const vesselID = 0;

        httpService.get(`/api/sales/avcso/information`)
            .then(response => {
                const data = response.data;
                // console.log('handleRequestInformation', data);
            })
            .catch(error => {
                //alert(error);
            });
    }


    return (
        <div  style={{ padding: '15px' }}>
            <Button
                variant="contained"
                size="small"
                fullWidth
                onClick={handleRequestInformation}>Request more information</Button>
        </div>
            );
}

export default AVCSO
