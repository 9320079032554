import React, { createContext, useContext, useState, useEffect } from 'react';
import http from './../Services/httpService';
import _ from 'lodash';
import { useNotificationStore } from './notificationStore';
import { useMapStore } from './mapStore';
// import { useUserStore } from './userStore';

const initialState = {
    // productGroups: [],
    vessels: null,
    fleetPositions: null,
    holdingDetail: null,
    holdingDetailItems: null,
    statusDetail: null,
    vesselUpdates: null,
};

export const FleetContext = createContext();

export const FleetProvider = ({ children }) => {
    const [state, setState] = useState(initialState);
    const [, notificationActions] = useNotificationStore();
    const [, mapActions] = useMapStore();

    // // Clear data when logout
    // const [userState] = useUserStore();
    // const logout = !Boolean(userState.tokenData.token);
    // useEffect(() => {
    //     if (logout === true) {
    //         console.log('FleetProvider logout', logout);
    //         setState(P => initialState);
    //     }
    // }, [logout]);

    const loadData = () => {
        // console.log("loadData");
    }

    const clearData = () => {
        // console.log("clearData");
        setState(() => { return { ...initialState } });
    }

    const filterHoldingDetail = (filter) => {
        setState((prev) => {
            const newDetail = { ...prev.holdingDetail };

            const fid = +_.get(prev, 'holdingDetail.filter.id', 0) + 1;
            newDetail.filter = filter;
            newDetail.filter.id = fid;

            return {
                ...prev,
                holdingDetail: newDetail
            }
        });
    }

    const getHoldingsOverview = () => {
        
        http.get('/api/fleet/holdings')
            .then(result => {

                const { vessels, productGroups } = result.data;
                //console.log('getVesselItems', result.data);

                setState(prev => {
                    return {
                        ...prev,
                        vessels: vessels,
                        productGroups: productGroups,
                    }
                });
            })
            .catch(error => {                
                //alert(error);
            });
    };

    // const getVesselHoldingsStatus = (vesselID) => {

    //     http.get(`/api/fleet/holdings/${vesselID}/status`)
    //         .then(result => {

    // console.log('getVesselItems', result.data);

    //             setState(prev => {
    //                 return {
    //                     ...prev,
    //                     vessels: result.data.vessels,
    //                     productGroups: result.data.productGroups,
    //                 }
    //             });
    //         })
    //         .catch(error => {
    //alert(error);
    //         });
    // };


    const getVesselHoldings = (vesselID, onCompleted) => {

        const complete = (success) => {
            if(onCompleted && typeof onCompleted === 'function') {
                onCompleted(success);
            }
        };

        http.get(`/api/fleet/holdings/${vesselID}`)
            .then(result => {
                const holdingDetailData = result.data;
                //console.log('Holding Detail Data', holdingDetailData);

                setState((prev) => {
                    return {
                        ...prev,
                        holdingDetail: holdingDetailData,
                        holdingDetailItems: holdingDetailData.vessel.vesselItems,
                    }
                });
                complete(true);
            })
            .catch(error => {
                //alert(error);
                complete(false);
            });
    };


    const getFleetPositions = () => {

        http.get(`/api/fleet/positions`)
            .then(result => {
                const positions = result.data;
                setState((prev) => {
                    return {
                        ...prev,
                        fleetPositions: positions,
                    }
                });
            })
            .catch(error => {
                
            });
    };


    const getVesselUpdating = () => {

        http.get(`/api/fleet/navigator`)
            .then(result => {
                const data = result.data;
                // console.log('navigator', data)
                setState((prev) => {
                    return {
                        ...prev,
                        vesselUpdates: data,
                    }
                });
            })
            .catch(error => {
                
            });
    };



    const clearVesselHoldings = () => {

    };


    const setLicenseAvailability = (vesselID, itemID, active, onCompleted) => {

        const complete = (success) => {
            if(onCompleted && typeof onCompleted === 'function') {
                onCompleted(success);
            }
        };

        const postData = {
            itemID: itemID,
            vesselID: vesselID,
            active: active ? 1 : 0,
        };

        http.post(`/api/fleet/holdings/active`, postData)
            .then(() => {
                getVesselHoldings(
                    postData.vesselID,
                    (success) => {
                        notificationActions.showNotification({ 
                            variant: `${success ? 'success' : 'error'}`, 
                            message: `License has been updated successfully ${!success ? ', but, some errors ocurs getting current holdings.' : ''}`, 
                            displayTime: 2000 
                        });

                        complete(true);
                    }
                )
            })
            .catch(error => {
                complete(false);
            });
    };

    const zoomToVessel = (vesselID) => {
        const arePositionLoaded = Boolean(state.fleetPositions);
        if(!arePositionLoaded) {
            return;
        }

        const [vesselPosition] = state.fleetPositions.filter(position => position.vesselID === vesselID);
        if(vesselPosition) {
            mapActions.zoomToPoint(vesselPosition.long, vesselPosition.lat);
        }
    }

    const actions = {
        loadData: loadData,
        clearData: clearData,

        // getSummary: getSummary,

        // Holdings
        getHoldingsOverview: getHoldingsOverview,
        getFleetPositions: getFleetPositions,
        getVesselHoldings: getVesselHoldings,
        getVesselUpdating:getVesselUpdating,
        filterHoldingDetail: filterHoldingDetail,
        clearVesselHoldings: clearVesselHoldings,

        // FleetStatus
        setLicenseAvailability: setLicenseAvailability,
        zoomToVessel: zoomToVessel


        // clearFleetsQuote: clearFleetsQuote,

        // validateFleetsQuoteLine: validateFleetsQuoteLine,
        // selectFleetsQuoteLine: selectFleetsQuoteLine,

        // getVesselOrders: getVesselOrders,
        // getFleetsOrder: getFleetsOrder,
        // clearFleetsOrder: clearFleetsOrder,
    };

    return (
        <FleetContext.Provider value={[state, actions]}>
            {children}
        </FleetContext.Provider >
    )
};

export const useFleetStore = () => useContext(FleetContext);


