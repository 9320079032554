import axios from 'axios';
// import storage from 'store';

//const apiEndpoint = 'https://localhost:44397';
const apiEndpoint = 'https://shipmanwstest.charttrack.com';
//const apiEndpoint = 'https://test.shipmanager.charttrack.com:44397';

//axios.defaults.timeout = 7000;
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
// axios.defaults.responseType = 'json';


const def = axios.create({
    baseURL: apiEndpoint,
    responseType: "json"
});


def.interceptors.request.use(
    config => {

        const token = getToken();
        if (token)
            config.headers.authorization = `Bearer ${token}`;

        return config;
    },
    error => {
        return Promise.reject(error);
    },
);

export const getToken = () => {
    const userData = JSON.parse(sessionStorage.getItem('userData'));

    if (userData && userData.tokenData)
        return userData.tokenData.token;

    return null;
}

export const getAvcsoImageLink = (url) => {
    const link = `${apiEndpoint}/api/file/avcso/image?url=${encodeURIComponent(url)}`
    return link;
}

export const getVesselCertificateLink = (VesselID) => {
    const link = `${apiEndpoint}/api/file/enrollment/${VesselID}?access_token=${getToken()}`
    return link;
}

export const getVesselNavigatorLicenseLink = (VesselID) => {
    const link = `${apiEndpoint}/api/file/navigatorlicense/${VesselID}?access_token=${getToken()}`
    return link;
}

export const getVesselAvcsPermitLink = (VesselID) => {
    const link = `${apiEndpoint}/api/file/avcspermit/${VesselID}?access_token=${getToken()}`
    return link;
}

export default {
    instance: def,
    get: def.get,
    post: def.post,
    delete: def.delete,
    endpoint: apiEndpoint,
    getVesselCertificateLink: getVesselCertificateLink,
    getVesselNavigatorLicenseLink: getVesselNavigatorLicenseLink,
    getVesselAvcsPermitLink: getVesselAvcsPermitLink,
};