import React, { useMemo } from 'react';
import { Collapse, List, Divider } from '@material-ui/core';
import { useSaleStore } from '../../../../Global/Stores/saleStore';
import ListItemVessel from '../../_StyledComponents/ListItemVessel';
import { ListItemSubShipment } from '../../_StyledComponents/ListItemSub';

const VesselShipments = ({ vessel, selectedsalesShipmentID }) => {
    const [saleState,] = useSaleStore();
    const [open, setOpen] = React.useState(false);

    const vesselShipments = saleState.vesselShipments.filter(X => X.vesselID === vessel.vesselID);

    return useMemo(() => {

        return vesselShipments.length > 0 ? (
            <ListItemVessel vessel={vessel} number={vesselShipments.length} open={open} selected={open} setOpen={setOpen} subSelectedID={selectedsalesShipmentID} >
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {
                            vesselShipments.map(X => (
                                <ListItemSubShipment
                                    key={X.salesShipmentID}
                                    shipment={X}
                                    selected={selectedsalesShipmentID === X.salesShipmentID} />
                            ))
                        }
                    </List>
                </Collapse>
            </ListItemVessel>
        ) : null;
    }, [vesselShipments, selectedsalesShipmentID]);
};

export default VesselShipments;
