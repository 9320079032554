import React, { useMemo } from 'react';
import httpService from '../../../Global/Services/httpService';
import UploadButton from './UploadButton';

const UploadRouteButton = ({ vesselID }) => {

    // const [, globalsActions] = useGlobalStore();

    return useMemo(() => {

        const handleFileSelected = (file) => {
            const files = Array.from(file);

            let formData = new FormData();
            formData.append("File", files[0]);

            httpService.post("/api/catalogue/routes/upload", formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then((result) => {

                    const data = result.data;
                    //console.log('Upload Route Result', data);
                    // const routeID = +result.data;
                    // globalsActions.getRoutes();
                    // history.push(`/sales/catalogue/${vesselID}/routes/${routeID}`);
                })
                .catch(error => {
                    //alert(error)
                });
        };

        return (
            <UploadButton text="UP" accept=".rtz" FileSelected={handleFileSelected} />
        );

    }, [vesselID])
};

export default UploadRouteButton
