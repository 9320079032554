import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Typography, List, Button, Paper } from '@material-ui/core';
// import { AnnouncementLine } from '../Home/Summaries/AnnouncementSummary';
import ContentColumn from '../Custom/ContentColumn';
// import Loading from '../Misc/Loading';
import ReactHtmlParser from 'react-html-parser';
import Moment from 'react-moment';
import { useGlobalStore } from '../../../Global/Stores/globalStore';
// import { ListItem, ListItemText, Divider, Card, List, Button } from "@material-ui/core";

const NewsDetail = ({ match }) => {
    const [globalState,] = useGlobalStore();
    const newsItems = globalState.newsItems || [];
    const loaded = Boolean(newsItems.length);

    const selectedID = +match.params.newsID;
    const newsItem = loaded && newsItems.find(X => X.chartagentNewsID === selectedID);

    return (
        <ContentColumn loaded={loaded}>
            <div className="contentColumnHeader" style={{ padding: '10px' }}>
                <Typography variant="h6">{`Details`}</Typography>
            </div>

            <div className="contentColumnBody" style={{ margin: '10px' }}>
                {
                    newsItem && (
                        <Paper style={{ padding: '15px' }}>
                            <Typography variant="h6" component="h3">{newsItem.title}</Typography>
                            <Typography component="p">
                                {ReactHtmlParser(newsItem.message)}
                            </Typography>
                            <Typography variant="caption" component="h3" align="right">
                                <br />
                               Posted on: <Moment date={newsItem.startDate} format="LL" />
                            </Typography>
                        </Paper>
                    )
                }
            </div>
            <div   style={{paddingRight: '10px', paddingTop: '7px', textAlign: 'right'}} >
                <Link to="/home"  style={{textDecoration: 'none', right: '0'}}>
                 <Button color="primary" variant="contained">Back to News</Button>
                </Link>
            </div>
            <br /><br /><br /><br />
        </ContentColumn>
    );
}

export default NewsDetail
