import React, { useState, useMemo } from 'react';
import { List, ListItem, ListItemText, ListItemIcon, Collapse, Link } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import _ from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
// import { HoldingLinesStatus } from '../../../../Widgets/ProductGroupStatus';
// import HoldingLineStatus from '../../../../Widgets/HoldingLineStatus';
import StatusCircle from '../../../../Widgets/StatusCircle';

/*What will happen here;
 * Categorize result under status (1)Active (2)Inactive (3)Missing
 *
 *
 */

const HoldingDetailList = ({ holdingDetail, selectedItemID, selectedGroups, toggleSelectedGroup, managerID, folioID, vesselID }) => {

    // const companyID = this.props.match.params.companyID || 0;
    // const folioID = +match.params.folioID || 0;
    // const vesselID = +match.params.vesselID || 0;
    // const itemID = +match.params.itemID || 0;
    // console.log(companyID, folioID, vesselID, itemID);
    
    // const [outOpen, setInOpen] = React.useState(true);
    // const [inOpen, setOutOpen] = React.useState(true);

    // //handle items in inventory open close click
    // const handleOutClick = () => {
    //     setOutOpen(!outOpen);
    // };

    // //handle items not in inventory open and close click
    // const handleInClick = () => {
    //     setInOpen(!inOpen);
    // };




    // console.log("Out: ", outOpen, "\nIn: ", inOpen);

    if (!holdingDetail) return null;
    //Get all items in inventory
    const itemsInInventory = holdingDetail.filter(X => X.inInventory === true);

    //Get all items not in inventory
    const itemsNotInInventory = holdingDetail.filter(X => X.inInventory === false);

    //Get total of folio items, items present and items not present
    let totalFolioItems = holdingDetail.length;
    let totalPresentITems = itemsInInventory.length;
    let totalMissingItems = itemsNotInInventory.length;

    // console.log("Folio Total: ", totalFolioItems, "\nPresent: ", totalPresentITems, "\nMissing: ", totalMissingItems)


    //console.log("Items in Inventory", itemsInInventory);
    //console.log("Items Not in Inventory", itemsNotInInventory);

    return (
        <React.Fragment>
            <List component="div" disablePadding>
                {
                    holdingDetail.map(X => 
                        <FolioItemsList 
                            itemID={X.itemID} 
                            itemNumber={X.itemNumber} 
                            itemTitle={X.itemTitle} 
                            inInventory={X.inInventory} 
                            active={X.active} 
                            selectedItemID={selectedItemID} 
                            managerID={managerID}
                            folioID={folioID}
                            vesselID={vesselID}
                            key={X.itemID}
                            />
                    )
                }
            </List>
        </React.Fragment>
    );
};

// This will display everything along with the statuses
const FolioItemsList = ({ itemID, itemNumber, itemTitle, inInventory, active, selectedItemID, managerID, folioID, vesselID }) => {

    let itemStatus;
    // Lets now decide the status of each item;
    // red(not in inventory and not active) 
    // OR orange(in inventory but not active)
    // OR green(in inventory and active)
    if(!inInventory && !active)
    {
        itemStatus = "statusRed";
    }
    else if(inInventory && !active)
    {
        itemStatus = "statusOrange";
    }
    else if(inInventory && active)
    {
        itemStatus = "statusGreen";
    }

    const to = `/folios/status/${managerID}/${folioID}/details/${vesselID}/${itemID}`;
    //console.log("this ID: ", itemID, "matches: ", selectedItemID )
    return(
        <Link className="ListItemLink" component={RouterLink} to={to} color='inherit' style={{ textDecoration: 'none' }}>
            <ListItem key={itemID} button disableRipple dense divider selected={itemID === selectedItemID ? true : false}>
                <StatusCircle className={itemStatus} />
                <ListItemText key={itemID} primary={itemNumber} secondary={itemTitle} key={itemID} />
            </ListItem>
        </Link>
    )
}

// const HoldingProductGroup = ({ productGroup, vessel, lines, open, toggleSelectedGroup, selectedItemID }) => {

//     // const productgroupItemsAll = vessel.vesselItems.filter(I => I.item.productGroupID === productGroup.productGroupID);
//     // const productgroupItems = lines.filter(I => I.item.productGroupID === productGroup.productGroupID);

//     // const itemLength = productgroupItems.length;


//     return useMemo(() => {

//         // console.log('productgroupItemsAll.length', productgroupItemsAll.length);
//         // console.log('productgroupItems.length', open, productgroupItems.length);

//         if (lines.length === 0)
//             return null;

//         return (
//             <React.Fragment>
//                 <ListItem onClick={() => toggleSelectedGroup(lines.itemID)} button divider selected={open}>
//                     <ListItemIcon>
//                         <SendIcon />
//                     </ListItemIcon>

//                     <ListItemText primary="InInventory" /> {/* Display name */}
//                     {/* <HoldingLinesStatus lines={vessel.vesselItems.filter(I => I.item.productGroupID === productGroup.productGroupID)} /> */}
//                     {/* <Chip color="primary" variant="default" label={productgroupItems.length} size="small" /> */}
//                     {
//                         open ? <ExpandLess /> : <ExpandMore />
//                     }
//                 </ListItem>
//                 <Collapse in={open} timeout="auto" unmountOnExit>
//                     <List component="div" disablePadding>
//                         {
//                             lines.map(X => <HoldingLine key={X.itemID} line={X} selected={selectedItemID === X.itemID} />)
//                         }
//                     </List>
//                 </Collapse>
//             </React.Fragment>);

//     }, [open, selectedItemID]);
// }


// const HoldingLine = ({ line, selected }) => {
//     //const to = `/fleet/holdings/${line.vesselID}/${line.item.itemID}`;
//     return (
//         // <Link className="ListItemLink" component={RouterLink} to={to} color='inherit' style={{ textDecoration: 'none' }}>
//             <ListItem button disableRipple dense divider selected={selected}>
//                 <HoldingLineStatus line={line} />
//                 <ListItemText primary={line.itemNumber} secondary={line.itemTitle} />
//             </ListItem>
//         // </Link>
//     );
// }

//Keeping this. Just in case the need arises. 
// const FolioItemSection = ({ dataArray, groupHeader }) => {
    
//     const [open, setOpen] = React.useState(false);
    
//     //handle items not in inventory open and close click
//     const handleClick = () => {
//         setOpen(!open);
//     };

//     return(
//         //const to = `/fleet/holdings/${line.vesselID}/${line.item.itemID}`;
//         <List className="ListItemHeader" disablePadding>
//                     <ListItem onClick={handleClick} button divider selected={open}>
//                         <ListItemIcon>
//                             <SendIcon />
//                         </ListItemIcon>
//                             <ListItemText primary={groupHeader} /> {/* Display name */}
//                         {/* <HoldingLinesStatus lines={vessel.vesselItems.filter(I => I.item.productGroupID === productGroup.productGroupID)} /> */}
//                         {/* <Chip color="primary" variant="default" label={productgroupItems.length} size="small" /> */}
//                         {
//                             open ? <ExpandLess /> : <ExpandMore />
//                         }
//                     </ListItem>
//                     <Collapse in={open} timeout="auto" unmountOnExit>
//                         <List component="div" disablePadding>
//                             {
//                             // lines.map(X => <HoldingLine key={X.itemID} line={X} selected={selectedItemID === X.itemID} />)
//                             }
//                                 {/* <Link className="ListItemLink" component={RouterLink} to="" color='inherit' style={{ textDecoration: 'none' }}> */}
//                                     {/* <HoldingLineStatus line={line} /> */}
//                                     {/* <ListItemText primary="{line.itemNumber}" secondary="{line.itemTitle}" /> */}
//                                     {
//                                         dataArray.map(X => 
//                                             <ListItem button disableRipple dense divider selected="">
//                                                 <ListItemText primary={X.itemID} secondary={X.itemTitle} key={X.itemID} />
//                                             </ListItem>
//                                         )
//                                     }
//                             {/* </Link> */}
//                         </List>
//                     </Collapse>
//             </List>
//     )
// }


export default HoldingDetailList;
