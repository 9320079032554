import React, { useState } from 'react';
import { List, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction, Chip, Collapse, Link } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';
import OrderLineStatus from '../../../../Widgets/OrderLineStatus';

const OrderDetailList = ({ selectedLineID, orderDetail }) => {

    const [OpenGroups, setOpenGroups] = useState(orderDetail ? orderDetail.productGroups.map(X => X.productGroupID) : []);

    if (!orderDetail || !orderDetail.order) return null;

    // const productGroups = order.productGroups;


    const { order, productGroups } = orderDetail;
    // console.log('OrderDetailList', orderDetail);

    let lines = order.lines;
    if (orderDetail.statusFilter) {
        //console.log('statusFilter', orderDetail.statusFilter);
        lines = lines.filter(X => orderDetail.statusFilter.includes(X.salesOrderLineStatusID));
    }
    // console.log('lines', lines.length);

    lines = _.orderBy(lines, ['item.itemNumber'], ['asc']);

    // console.log('lines', lines.length);

    const toggleProductGroup = (productGroupID) => {
        setOpenGroups(prev => prev.includes(productGroupID) ? prev.filter(X => X !== productGroupID) : [...prev, productGroupID])
    }


    const headers = productGroups.map(X => {
        const productgroupItems = lines.filter(I => I.item.productGroupID === X.productGroupID);
        const subTotal = _.sumBy(productgroupItems, 'linePrice').toFixed(2);
        const open = OpenGroups.includes(X.productGroupID);
        // const open = true;
        return productgroupItems.length > 0 ? (
            <React.Fragment key={X.productGroupID}>
                <ListItem button divider selected={open} dense onClick={() => toggleProductGroup(X.productGroupID)}>
                    <ListItemIcon>
                        <SendIcon />
                    </ListItemIcon>

                    <ListItemText primary={X.caption} secondary={`Sub Total: ${order.currency} ${subTotal}`} />

                    <ListItemText style={{ flexGrow: '0' }} primary={`${productgroupItems.length} Items`} />
                    <ListItemSecondaryAction>
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemSecondaryAction>


                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {
                            productgroupItems.map(X => <OrderLine
                                key={X.salesOrderLineID}
                                selected={X.salesOrderLineID === selectedLineID}
                                line={X}
                            />)
                        }
                    </List>
                </Collapse>
            </React.Fragment>
        ) : null;
    });

    return (
        <List className="ListItemHeader" disablePadding>
            {headers}
        </List>
    );
};

const OrderLine = ({ line, selected }) => {

    const to = `/sales/orders/${line.salesOrderID}/${line.salesOrderLineID}`;
    return (
        <Link className="ListItemLink" component={RouterLink} to={to} color='inherit' style={{ textDecoration: 'none' }}>
            <ListItem button disableRipple dense divider selected={selected}>
                <OrderLineStatus line={line} />
                <ListItemText primary={line.item.itemNumber} secondary={line.item.itemTitle} />
                <ListItemSecondaryAction>
                    {/* <ListItemText primary={`${line.currency} ${line.linePrice}`} /> */}
                    <ListItemText primary={(line.linePrice ? line.linePrice.toFixed(2) : '')} />
                </ListItemSecondaryAction>
            </ListItem>
        </Link>
    );
}

export default OrderDetailList;
