import React, { createContext, useContext, useState, useEffect } from 'react';
import http from './../Services/httpService';
import _ from 'lodash';
// import { useUserStore } from './userStore';

const initialState = {
    managers               : null,
    folios                 : null,
    folioItems             : null,
    folioVessels           : null,
    folioVesselItemStatus  : null,
    folioVesselItems       : null,
    folioManager           : null,
    folioVessel            : null,
};

export const FolioContext = createContext();

export const FolioProvider = ({ children }) => {
    const [state, setState] = useState(initialState);


    // // Clear data when logout
    // const [userState] = useUserStore();
    // const logout = !Boolean(userState.tokenData.token);
    // useEffect(() => {
    //     if (logout === true) {
    //         console.log('FleetProvider logout', logout);
    //         setState(P => initialState);
    //     }
    // }, [logout]);

    const loadData = () => {
        // console.log("loadData");
    }

    const clearData = () => {
        // console.log("clearData");
        setState(() => { return { ...initialState } });
    }

    const getManagers = () => {

        http.get(`/api/companies`)
            .then(result => {
                const managersData = result.data;
                 //console.log('Folio Managers', managersData);

                setState((prev) => {
                    return {
                        ...prev,
                        managers: managersData,
                    }
                });

            })
            .catch(error => {
                //alert(error);
                return{
                    managers: [],
                    }
            });
    };


    const getFolios = (managerID) => {

        http.get(`/api/companies/${managerID}/folios`)
            .then(result => {
                const thefolios = result.data;
                //console.log('Folios', thefolios)

                setState((prev) => {
                    return {
                        ...prev,
                        folios: thefolios,
                    }
                });
            })
            .catch(error => {
                
            });
    };

    const getFolioItems = (managerID, folioID) => {

        http.get(`/api/companies/${managerID}/folios/${folioID}/items`)
            .then(result => {
                const theFolioItems = result.data;
                
                //console.log('FOLIO ITEMS', result.data)
    
                setState((prev) => {
                    return {
                        ...prev,
                        folioItems           : theFolioItems,
                        //folioVesselItemStatus  : theFolioVessels.folioStatus,
                    }
                });
            })
            .catch(error => {

            });
        };

    const getFolioVessels = (managerID, folioID) => {

        http.get(`/api/companies/${managerID}/folios/${folioID}/vessels`)
            .then(result => {
                const theFolioVessels = result.data;
                
                 //console.log('Folio vessels', theFolioVessels)
    
                setState((prev) => {
                    return {
                        ...prev,
                        folioVessels           : theFolioVessels,
                        folioVesselItemStatus  : theFolioVessels.folioStatus,
                    }
                });
            })
            .catch(error => {

            });
        };


        //API/Companies/#ID#/Folios/#FID#/#VesselID#
        const getFolioVesselItems = (managerID, folioID, vesselID) => {

            http.get(`/api/companies/${managerID}/folios/${folioID}/vessels/${vesselID}`)
            .then(result => {
                const theFolioVesselItems = result.data.folioItems;
                const theFolioManager = result.data.folio;
                const theFolioVessel = result.data.vessel;
                
                //console.log('Folio Vessel Items', result.data)

                setState((prev) => {
                    return {
                        ...prev,
                        folioVesselItems    : theFolioVesselItems,
                        folioManager        : theFolioManager,
                        folioVessel         : theFolioVessel,
                    }
                });
            })
            .catch(error => {
                
            });
        };
    



    const clearVesselHoldings = () => {

    };




    const actions = {
        loadData   : loadData,
        clearData  : clearData,

        getManagers          : getManagers,
        getFolios            : getFolios,
        getFolioItems        : getFolioItems,
        getFolioVessels      : getFolioVessels,
        getFolioVesselItems  : getFolioVesselItems,
        clearVesselHoldings  : clearVesselHoldings

    };

    return (
        <FolioContext.Provider value={[state, actions]}>
            {children}
        </FolioContext.Provider >
    )
};

export const useFolioStore = () => useContext(FolioContext);


