import React, { useMemo } from 'react';
import { useGlobalStore } from '../../../Global/Stores/globalStore';
import { Table, TableHead, TableRow, TableCell, Typography, TableBody, Checkbox } from '@material-ui/core';

const LineDetailTable2 = ({ line }) => {

    const [globalState, globalActions] = useGlobalStore();

    return useMemo(() => {

        if (!line || !globalState.productGroups)
            return null;

        // console.log('line', line);

        const productGroup = globalActions.getProductGroup(line.item.productGroupID);

        const { quantity, months, price, totalPrice, active, endDate, currencyID, customerCurrencyID } = line;
        const { itemNumber, itemTitle, editionDate, itemStatus } = line.item;

        const showEditionDate = editionDate ? editionDate.split(' ')[0] : 'N/A';
        const showEndDate = endDate ? endDate.split(' ')[0] : 'N/A';

        const showPrice = price || totalPrice;
        const showPriceCurrencyID = currencyID || customerCurrencyID;
        const hasPrice = Boolean(showPrice);
        const showCurrencyCode = hasPrice ? globalActions.getCurrency(showPriceCurrencyID).code : null;

        // console.log('showPrice', showPrice);

        return (
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell align="center" colSpan={2}>
                            <Typography variant="h6">{productGroup.caption}</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>

                    <TableRow >
                        <TableCell style={{ width: '1px' }}><Typography variant="subtitle2">Number:</Typography></TableCell>
                        <TableCell>{itemNumber}</TableCell>
                    </TableRow>

                    <TableRow >
                        <TableCell style={{ width: '1px' }}><Typography variant="subtitle2">Title:</Typography></TableCell>
                        <TableCell>{itemTitle}</TableCell>
                    </TableRow>

                    <TableRow >
                        <TableCell style={{ width: '1px' }}><Typography variant="subtitle2">Edition:</Typography></TableCell>
                        <TableCell>{showEditionDate}</TableCell>
                    </TableRow>

                    <TableRow >
                        <TableCell><Typography variant="subtitle2">Item Status:</Typography></TableCell>
                        <TableCell>{itemStatus}</TableCell>
                    </TableRow>

                    {/* <TableRow>
                        <TableCell align="center" colSpan={2}>
                            <Typography variant="h6">Order Details</Typography>
                        </TableCell>
                    </TableRow> */}

                    <TableRow >
                        <TableCell><Typography variant="subtitle2">Quantity:</Typography></TableCell>
                        <TableCell>{quantity}</TableCell>
                    </TableRow>

                    <TableRow >
                        <TableCell><Typography variant="subtitle2">Months:</Typography></TableCell>
                        <TableCell>{months}</TableCell>
                    </TableRow>

                    {
                        hasPrice ? (
                            <TableRow >
                                <TableCell><Typography variant="subtitle2">Price:</Typography></TableCell>
                                <TableCell>{`${showCurrencyCode} ${showPrice}`}</TableCell>
                            </TableRow>
                        ) : null
                    }

                    <TableRow >
                        <TableCell><Typography variant="subtitle2">Active:</Typography></TableCell>
                        <TableCell padding="none"><Checkbox checked={active} disabled /></TableCell>
                    </TableRow>

                    <TableRow >
                        <TableCell><Typography variant="subtitle2">License Expires:</Typography></TableCell>
                        <TableCell>{showEndDate}</TableCell>
                    </TableRow>

                </TableBody>

            </Table>
        );
    }, [line, globalState.productGroups]);
};


// const ItemPartRow = (Caption, Value) => {

//     // return useMemo(() => {
//     return (
//         <TableRow >
//             <TableCell style={{ width: '1px' }}><Typography variant="subtitle2">{Caption}</Typography></TableCell>
//             <TableCell>{Value}</TableCell>
//         </TableRow>
//     );
//     // }, [Caption, Value]);
// }

export default LineDetailTable2;
