import React, { useMemo, useState, useEffect } from 'react';
import { Box, Typography, Table, TableBody, TableRow, TableCell, FormControl, FormControlLabel, Checkbox, Button } from '@material-ui/core';
import { useUserStore } from '../../../../Global/Stores/userStore';
import _ from 'lodash';
import ContentColumn from '../../../Custom/ContentColumn';

const UserDetail = ({ match }) => {
    const [userState, userActions] = useUserStore();


    const id = +_.get(match, 'params.id', 0);

    const user = userState.users.find(X => X.personID === id);
    const hasUserManagement = userActions.hasUserManagement();


    return useMemo(() => {
        // console.log('UserDetail', userState);

        if (!user)
            return null;

        const handleDeleteUser = () => {
            // console.log('deleteUser', user.personID);
            userActions.deleteUser(user.personID);
        }

        const { firstName, lastName, email } = user;

        return (
            <ContentColumn loaded={true}>
                <div className="contentColumnHeader">
                    <Typography variant="h6">User Detail</Typography>
                </div>

                <div className="contentColumnBody">


                    <Table size="small" style={{ Width: '100%' }}>

                        <TableBody>
                            <TableRow >
                                <TableCell style={{ width: '1px' }}><Typography variant="subtitle2">Firstname:</Typography></TableCell>
                                <TableCell>{firstName}</TableCell>
                            </TableRow>

                            <TableRow >
                                <TableCell><Typography variant="subtitle2">Lastname:</Typography></TableCell>
                                <TableCell>{lastName}</TableCell>
                            </TableRow>

                            <TableRow >
                                <TableCell><Typography variant="subtitle2">Email:</Typography></TableCell>
                                <TableCell>
                                    {email}
                                    {/* <Typography variant="caption">{email}</Typography> */}
                                </TableCell>
                            </TableRow>

                            <TableRow >
                                <TableCell style={{ verticalAlign: 'top' }}><Typography variant="subtitle2">Features:</Typography></TableCell>
                                <TableCell>
                                    <UserFeatures user={user} />
                                </TableCell>
                            </TableRow>

                            <TableRow >
                                <TableCell colSpan={2}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="secondary"
                                        onClick={handleDeleteUser}
                                        disabled={!hasUserManagement}
                                    >Delete User</Button>
                                </TableCell>
                            </TableRow>

                        </TableBody>
                    </Table>
                </div>
            </ContentColumn>
        );
    }, [user]);
};


const UserFeatures = ({ user }) => {
    const [userState, userActions] = useUserStore();


    const [features, setFeatures] = useState([]);

    useEffect(() => {

        setFeatures(p => user.userFeatureIDs);

    }, [user])



    return useMemo(() => {

        const toggleFeature = (fid) => {
            setFeatures(p => {
                return p.includes(fid) ? p.filter(X => X !== fid) : [...p, fid];
            })
        }

        const onSubmit = (e) => {
            e.preventDefault();
            userActions.setUserFeatures(user.personID, features);
        }


        const canSave = !_.isEqual(user.userFeatureIDs.sort(), features.sort());
        const hasUserManagement = userActions.hasUserManagement();
        // console.log('hasUserManagement', hasUserManagement)


        return (
            <React.Fragment>
                <FormControl component="fieldset" disabled={!hasUserManagement}>
                    {
                        userState.appFeatures.map(X => (
                            <FormControlLabel key={X.appFeatureID} control={
                                <Checkbox
                                    value={X.appFeatureID}
                                    checked={features.includes(X.appFeatureID)}
                                    color="primary"
                                    onClick={() => toggleFeature(X.appFeatureID)}
                                />
                            }
                                label={X.name} />))
                    }
                </FormControl>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={onSubmit}
                    disabled={!hasUserManagement || !canSave}
                >Save</Button>
            </React.Fragment>
        );

    }, [user, user.userFeatureIDs, features]);
}

export default UserDetail;
