import React, {useState} from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { Link, ListItem, ListItemText } from '@material-ui/core';

const NavMenuItem = ({ selected, label, to, location }) => {
  //const isSelected = (location.pathname.startsWith(to));
  const [selectedID, setSelectedID] = useState(0);

  let setSelected;
  setSelected = location.pathname.includes("folios") ? 3 : setSelected;
  setSelected = location.pathname.includes("fleet") ? 3 : setSelected;
  setSelected = location.pathname.includes("sales") ? 2 : setSelected;
  setSelected = location.pathname.includes("news") ? 1 : setSelected;

  //console.log(selected)
  const isSelected = Boolean(selected === setSelected);
  const decoration = isSelected ? 'underline' : 'none';

  return (
    <Link to={to} component={RouterLink} style={{ color: 'inherit', textDecoration: decoration }} >
      <ListItem button selected={isSelected} >
        <ListItemText>{label}</ListItemText>
      </ListItem>
    </Link >
  );
};

export default withRouter(NavMenuItem);
