import React, { useMemo } from 'react';
import { ListItem, ListItemText, Chip, Divider, Link, makeStyles } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import NumberStatus from '../../Widgets/NumberStatus';
// import _ from 'lodash';


const useStyles = makeStyles({
    link: {
        color: 'inherit',
        textDecoration: 'none',
    },
    listItem: {
        // backgroundColor: '#e9edf7',
        textDecoration: 'none',
    },
});

const ListFoilioItems = ({ vessel, subText, number, numberSmall, open, subSelectedID, children, setOpen, onClick, statusComponent, linkTo, selected }) => {
    const classes = useStyles();

    // return useMemo(() => {

        const handleClick = () => {

            if (onClick) {
                onClick(vessel.vesselID);
            }


            if (children) {
                setOpen(!open);
            }

        }
        //Convert to upper case
        const vesselName = vessel.vesselName.toUpperCase();

        const listItem = (
            <ListItem button onClick={handleClick} selected={selected}>
                {/* <ListItemIcon><SendIcon /></ListItemIcon> */}
                <ListItemText primary={vesselName} secondary={subText} />

                {
                    number ? <NumberStatus number={number} /> : null
                }

                {
                    numberSmall ? <Chip style={{ color: '#4d4d4d' }} variant="outlined" label={numberSmall} size="small" /> : null
                }

                {
                    statusComponent ? statusComponent : null
                }

                {
                    children ? (open === true ? <ExpandLess /> : <ExpandMore />) : null
                }

            </ListItem>
        );

        return (
            <React.Fragment>
                <Divider />
                {
                    linkTo ? <Link className={classes.link} component={RouterLink} to={linkTo} style={{ textDecoration: 'none' }} >{listItem}</Link> : listItem
                }

                {
                    children ? children : null
                }
            </React.Fragment>
        );
    // }, [vessel, number, subText, numberSmall, subSelectedID, open, selected]);

};

export default ListFoilioItems;
