// import React, { Component } from "react";
import { withLeaflet, MapControl } from "react-leaflet";
import L from "leaflet";

class MapInfo extends MapControl {

    constructor(props, context) {
        super(props);
    }

    displayInfo = (ev) => {

        let lng = ev.latlng.lng;
        if (lng > 180 || lng < -180) {
            const shift = parseInt(Math.abs(ev.latlng.lng / 180));
            lng = ((ev.latlng.lng + 180 + shift * 360) % 360) - 180
        }

        this.panelDiv.innerHTML = `<div><span>Lat: ${ev.latlng.lat.toFixed(4)}</span>&nbsp;&nbsp;<span>Lng: ${lng.toFixed(4)}</span></div>`;
        // console.log(this.panelDiv.innerHTML);
    }

    createLeafletElement(opts) {
        const MapInfo = L.Control.extend({
            onAdd: map => {
                this.panelDiv = L.DomUtil.create("div", "leaflet-positionInfo");
                this.panelDiv.innerHTML = `<div><span>Lat: _</span>&nbsp;&nbsp;<span>Lng: _</span></div>`;
                return this.panelDiv;
            },
            onRemove: map => {
                this.panelDiv = L.DomUtil.create("div", "leaflet-positionInfo");
                return this.panelDiv;
            },
        });
        return new MapInfo({ position: (this.props.position || "bottomleft") });
    }

    componentDidMount() {
        const { map } = this.props.leaflet;
        this.leafletElement.addTo(map);
        map.addEventListener("mousemove", this.displayInfo);
    }

    componentWillUnmount() {
        const { map } = this.props.leaflet;
        this.leafletElement.remove();
        map.removeEventListener("mousemove", this.displayInfo);
    }
}

export default withLeaflet(MapInfo);