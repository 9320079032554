import React from 'react';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { useUserStore } from '../../../Global/Stores/userStore';
import { Menu, MenuItem } from '@material-ui/core';

const NavIconAccount = () => {

    const [, actions] = useUserStore();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);


    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }


    const onLogout = () => {
        // setAnchorEl(null);
        actions.logout(true);
    }

    return (
        <div>
            <IconButton
                aria-owns={open ? 'menu-appbar' : undefined}
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
            >
                <AccountCircle />
            </IconButton>

            <Menu
                id="menu-appbar"

                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
            >

                {/* <MenuItemLink to="/profile" caption="Profile" />
                <MenuItemLink to="/users" caption="Users" /> */}
                {/* 
                <MenuItem component={Link} to="/profile" >Profile</MenuItem>
                <MenuItem component={Link} to="/users" >Users</MenuItem> */}

                <Link to="/account/profile" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <MenuItem onClick={handleClose}>Profile</MenuItem>
                </Link>

                <Link to="/account/users" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <MenuItem onClick={handleClose}>Users</MenuItem>
                </Link>

                <MenuItem onClick={onLogout}>Logout</MenuItem>
            </Menu>
        </div>
    );
};

// export const MenuItemLink = (to, caption) => {
//     return (
//         <Link to={to} component={RouterLink} className="navLink" style={{ color: 'inherit', textDecoration: 'none' }} >
//             <MenuItem >{caption}</MenuItem>
//         </Link>);
// }


export default NavIconAccount;
