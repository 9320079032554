import React from 'react';
import { withRouter } from 'react-router-dom';
import BackIcon from '@material-ui/icons/ArrowBack';
import { IconButton, Divider, ListItemText, Grid } from '@material-ui/core';
import { MailQuoteDetailTo } from '../../../Misc/MailTo';
import { DownloadQuote } from '../../../Misc/DownloadFile';

const QuoteDetailHeader = ({ quoteDetail, history }) => {

    if (!quoteDetail || !quoteDetail.quote) return null;

    const { quote } = quoteDetail;

    const handleBack = () => {
        const url = history.location.pathname;
        const popLink = url.substring(0, url.lastIndexOf("/"));
        history.push(popLink);
    }

    return (
        <Grid container spacing={3} >
            <Grid>
                <IconButton onClick={handleBack}>
                &nbsp;&nbsp;&nbsp;<BackIcon />
                </IconButton>
            </Grid>
            <Grid>
                <ListItemText className="flexFill" primary={quote.reference} secondary={quote.customerReference} />
            </Grid>
            {/* <Grid>
                <QuoteStatus quote={quote} />
            </Grid> */}
            <Grid>
                <MailQuoteDetailTo quoteDetail={quoteDetail} />
            </Grid>
            <Grid>
                <DownloadQuote salesQuoteID={quote.salesQuoteID} />
            </Grid>
            <Divider />
        </Grid>
    );
};

export default withRouter(QuoteDetailHeader);
