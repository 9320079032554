import React, { createContext, useContext, useState, useEffect } from 'react';
import http from './../Services/httpService';
import _ from 'lodash';
//import { useUserStore } from './userStore';

const initialState = {
    vessels: null,
    routes: null,
    baskets: null,
    newsItems: null,
    productGroups: null,
    productItemGroups: null,
    currencies: null,
    catalogue: null,
    portInfo: {
        // ports: [],
        // routingPoints: []
    }
};

export const GlobalContext = createContext();


export const GlobalProvider = ({ children }) => {
    const [state, setState] = useState(initialState);

    // // Clear data when logout
    // const [userState] = useUserStore();
    // const logout = !Boolean(userState.tokenData.token);
    // useEffect(() => {
    //     if (logout === true) {
    //         console.log('GlobalProvider logout', logout);
    //         setState(P => initialState);
    //     }
    // }, [logout]);

    const loadData = () => {
        //console.log("loadData");
        getVessels();
        getCurrencies();
        getPorts();
        getRoutes();
        getBaskets();
        getAnnouncements();
        getProductgroups();
        getProductItemgroups();
        getCatalogue();
    };

    const isLoaded = () => {
        return Boolean(state.catalogue) && Boolean(state.vessels);
    }

    const clearData = () => {
        //console.log("clearData");
        setState(() => { return { ...initialState } });
    }

    const getVessels = () => {
        http.get('/api/fleet')
            .then(result => {
                // console.log('result.data', result.data);
                setState(prev => {
                    return {
                        ...prev,
                        vessels: result.data,
                    }
                });
            })
            .catch(error => {
                //alert(error);
            });
    };

    const getPorts = () => {
        http.get('/api/catalogue/ports')
            .then(result => {
                //console.log('ports', result.data);
                setState(prev => {
                    return {
                        ...prev,
                        portInfo: result.data,
                    }
                });
            })
            .catch(error => {
                //alert(error);
            });
    };


    const getProductgroups = () => {
        http.get('/api/catalogue/productgroups')
            .then(result => {
                // console.log('productgroups', result.data);
                setState(prev => {
                    return {
                        ...prev,
                        productGroups: result.data,
                    }
                });
            })
            .catch(error => {
                //alert(error);
            });
    };


    const getProductItemgroups = () => {
        http.get('/api/catalogue/productitemgroups')
            .then(result => {
                // console.log('productitemgroups', result.data);
                setState(prev => {
                    return {
                        ...prev,
                        productItemGroups: result.data,
                    }
                });
            })
            .catch(error => {
                //alert(error);
            });
    };

    const getRoutes = () => {
        http.get('/api/catalogue/routes')
            .then(result => {
                // console.log('routes', result.data);
                setState(prev => {
                    return {
                        ...prev,
                        routes: result.data,
                    }
                });
            })
            .catch(error => {
                //alert(error);
            });
    };

    const getBaskets = () => {
        http.get('/api/catalogue/baskets')
            .then(result => {
                // console.log('getBaskets', result.data);
                setState(prev => {
                    return {
                        ...prev,
                        baskets: result.data,
                    }
                });
            })
            .catch(error => {
                //alert(error);
            });
    };

    const getAnnouncements = () => {
        http.get(`/api/home/news`)
            .then(result => {
                const data = result.data.newsItems;
                setState(prev => {
                    return {
                        ...prev,
                        newsItems: data,
                    }
                });
            })
            .catch(error => { alert(error); });
    };

    const getCatalogue = (force = false) => {

        if (!state.catalogue || force === true) {
            http.get('/api/catalogue/items')
                .then(result => {
                    // console.log('catalogue', result.data);
                    setState(prev => {
                        return {
                            ...prev,
                            catalogue: result.data,
                        }
                    });
                })
                .catch(error => {
                    //alert(error);
                });
        }
    };

    const getCurrencies = () => {
        http.get('/api/catalogue/currencies')
            .then(result => {
                // console.log('currencies', result.data);
                setState(prev => {
                    return {
                        ...prev,
                        currencies: result.data,
                    }
                });
            })
            .catch(error => {
                //alert(error);
            });
    };

    const getCatalogueItem = (itemID) => {
        if (Boolean(state.catalogue)) {
            return state.catalogue.items.find(X => X.itemID === itemID)
        }
        return null;
    }

    const getCatalogueItems = (itemIDs) => {
        if (Boolean(state.catalogue)) {
            return state.catalogue.items.filter(X => itemIDs.includes(X.itemID))
        }
        return null;
    }

    const getCurrency = (currencyID) => {
        return state.currencies.find(C => C.currencyID === currencyID);
    }

    const getProductGroup = (productGroupID) => {
        return state.productGroups.find(C => C.productGroupID === productGroupID);
    }

    const getProductItemGroupsLocal = (productGroupID) => {

        const PGIDS = _.uniq(state.catalogue.items.filter(X => X.productGroupID === productGroupID).map(X => X.productItemGroupID));
        return state.productItemGroups.filter(X => PGIDS.includes(X.productItemGroupID));

        //return state.productItemGroups.filter(C => C.productGroupID === productGroupID);
    }

    const isDigitalProductGroup = (productGroupID) => {
        const val = state.productGroups.find(C => C.productGroupID === productGroupID).isDigitalPoductGroup;
        // console.log('isDigitalProductGroup', productGroupID, val);
        return val;
    }

    const findVessel = (vesselID) => {
        // console.log('findVessel', state.vessels);
        if (!!!state.vessels) return null;

        // console.log('FIND')
        return state.vessels.find(V => V.vesselID === vesselID);
    }

    const actions = {
        loadData: loadData,
        clearData: clearData,
        isLoaded: isLoaded,

        getVessels: getVessels,
        getPorts: getPorts,
        getProductgroups: getProductgroups,
        getRoutes: getRoutes,
        getBaskets: getBaskets,
        getAnnouncements: getAnnouncements,
        getCatalogue: getCatalogue,
        getCatalogueItem: getCatalogueItem,
        getCatalogueItems: getCatalogueItems,
        getCurrency: getCurrency,

        getProductGroup: getProductGroup,
        getProductItemGroupsLocal: getProductItemGroupsLocal,

        findVessel: findVessel,

        isDigitalProductGroup: isDigitalProductGroup,
    };

    return (
        <GlobalContext.Provider value={[state, actions]}>
            {children}
        </GlobalContext.Provider >
    );

}

export const useGlobalStore = () => useContext(GlobalContext);
