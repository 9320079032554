//import classes from '*.module.css';
import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

// Custom Styling Properties
//import menuStyles from '../CSS/MenuStyles.module.css';

// styles
const useStyles = makeStyles({
    font: {
        fontSize: '12px',
    }
});

// Actual work starts here
const Breadcrumb = ({Module, currentPage}) => {

    const classes = useStyles();

        // We are going to have to write a dirty little hack here. eventually this will have to come off since Folio will be a module of its own
    // but till then, lets replace names here. 
    // 1. if Module is Folio, change to Fleet
    //2. if Module is Folio and current Page is Status, change to Folio
    
    //Looks like the hack has to spread. lol
    // Possibly writing out a clearer solution in the future will help. this can get dirty. realllllyyyy dirty
    let newModule = Module;
    let newCurrentPage = currentPage;
    //console.log(Module)
    // This is for the Modules
    if(Module === "folios"){
        newModule = "fleet"
    } 
    else if(Module === "sales"){
        newModule = "orders"
    } 
    //Module === "folios" ? currentPage === "status" ? "folio" : currentPage : currentPage
    //console.log(currentPage, "|", newcurrentPage)
    //newModule = Module === "sales" ? "orders" : Module;

    // This is for the current Page or sub modules if you will
    if(Module === "folios" && currentPage === "status"){
        newCurrentPage = "folio"
    }
    else if(Module === "sales" && currentPage === "orders"){
        newCurrentPage = "history"
    }
    else if(Module === "sales" && currentPage === "flatfee")
    {
        newCurrentPage = "flat fee"
    }
    //newcurrentPage = Module === "sales" ? currentPage === "orders" ? "history" : currentPage : currentPage
    //newcurrentPage = Module === "sales" ? currentPage === "flatfee" ? "flat fee" : currentPage : currentPage

    // Check if current Page is empty. if yes
    let divider = null;
    if(currentPage != null)
    {
        divider = '|';
    }

    return (
        <div className={classes.font}>
            <p><b>You are here: </b> {newModule} {divider} {newCurrentPage}</p>
        </div>
    )
}

export default Breadcrumb