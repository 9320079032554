import React, { useEffect, useState } from 'react';
import { FeatureGroup } from 'react-leaflet';
import _ from 'lodash';
import { useSaleStore } from '../../../Global/Stores/saleStore';
import SalesQuoteLinePolygon from '../Polygons/SalesQuoteLinePolygon';
import { checkPolygonInPoint } from '../MapFunctions';
import MapPopupSalesQuoteLines from '../Popup/MapPopupSalesQuoteLines';
import GeneralRoutesLayer from './Catalogue/GeneralRoutesLayer';

const SalesQuoteLayer = ({ match, history, mapActions }) => {

    const [saleState] = useSaleStore();
    const salesQuoteID = saleState.quoteDetail ? saleState.quoteDetail.quote.salesQuoteID : 0;

    const [popup, setPopup] = useState({
        key: 0,
        position: null,
        lines: []
    });



    // const salesQuoteID = +match.params.id;
    const selectedLineID = +match.params.lineID || 0;
    // console.log('selectedLineID', selectedLineID);

    useEffect(() => {
        if (salesQuoteID > 0) {
            //console.log("selected line ID: ", saleState.quoteDetail.quote.lines) 
            mapActions.zoomToLines(saleState.quoteDetail.quote.lines);
        }
    }, [salesQuoteID]);
    

    useEffect(() => {
        if (salesQuoteID > 0 && selectedLineID > 0) {
            const line = saleState.quoteDetail.quote.lines.find(X => X.salesQuoteLineID === selectedLineID);
            mapActions.zoomToLines(line);
        }
    }, [salesQuoteID, selectedLineID]);

    if (salesQuoteID === 0) return null;

    const lines = saleState.quoteDetail.quote.lines;
    const routes = saleState.quoteDetail.quote.routes;

    const filteredLines = lines.filter(X => X.item.polygonPoints);
    const sortedLines = _.orderBy(filteredLines, ['item.scale'], ['desc']);
    //const selectedLineID = saleState.quoteDetail.selectedLineID;

    const onClick = (e) => {
        // console.log("onClick", e);
        let selected = lines.filter(X => X.item.polygonPoints && checkPolygonInPoint(e.latlng, X.item.polygonPoints));
        if (selected.length === 0)
            selected = e.layer;

        if (selected.length === 1) {
            history.push(`/sales/quotes/${selected[0].salesQuoteID}/${selected[0].salesQuoteLineID}`);
            // mapActions.zoomToLines(selected);
        }
        else if (selected.length > 0) {

            setPopup(prev => {
                return {
                    key: prev.key + 1,
                    position: e.latlng,
                    lines: selected
                }
            });
        }

        // console.log("selected", selected);
    };


    return (
        <React.Fragment>
            <MapPopupSalesQuoteLines popupInfo={popup} />

            <FeatureGroup onClick={onClick}>
                {
                    // sortedLines.map(X => <Polygon key={X.item.itemID} positions={X.item.polygonPoints} />)
                    sortedLines.map(X => <SalesQuoteLinePolygon key={X.item.itemID} line={X} selected={X.salesQuoteLineID === selectedLineID} />)
                }
            </FeatureGroup>
            <GeneralRoutesLayer Routes={routes} />

        </React.Fragment>
    );
};

export default SalesQuoteLayer;