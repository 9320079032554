import React, { useMemo } from 'react';
import './QuoteStatus.scss';

const QuoteStatus = ({ quote }) => {

    const total = quote.lines.length;
    const accepted = Math.ceil(1.0 * quote.lines.filter(X => X.validationID === 1).length / total * 360);
    const rejected = Math.ceil(1.0 * quote.lines.filter(X => X.validationID === 2).length / total * 360);
    const open = Math.max(360 - (accepted + rejected), 0);


    return useMemo(() => {
        // console.log('QuoteStatus', total, accepted, rejected, open);

        return (
            <div className="quotation-chart">
                <div className={accepted > 180 ? "pie-quote big" : "pie-quote"} data-start={0} data-value={accepted}></div>
                <div className={rejected > 180 ? "pie-quote big" : "pie-quote"} data-start={accepted} data-value={rejected}></div>
                <div className={open > 180 ? "pie-quote big" : "pie-quote"} data-start={accepted + rejected} data-value={open}></div>
                <p className="chart-text">{total}</p>
            </div>
        );
    }, [total, accepted, rejected, open]);

};

export default QuoteStatus;
