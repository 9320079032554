import React, { useMemo } from 'react';
import { Dialog } from '@material-ui/core';
import VesselOrderLineDetailHeader from './VesselOrderLineDetailHeader';
import LineDetailTable from '../../../Misc/LineDetailTable';
import { useSaleStore } from '../../../../../Global/Stores/saleStore';
import ContentColumn from '../../../Custom/ContentColumn';
import _ from 'lodash';
import FloatingMenuButton from '../../../Navigation/FloatingMenuButton';


const VesselOrderLineDetail = ({ match }) => {
    const [open, setOpen] = React.useState(true);
    
      const handleClose = () => {
        setOpen(false);
      };

    const [saleState] = useSaleStore();

    const salesOrderID = +match.params.id;
    const salesOrderLineID = +match.params.lineID;

    return useMemo(() => {

        const loaded = Boolean(_.get(saleState, 'orderDetail.order', false));

        // console.log('loaded', loaded);

        // if (!saleState.orderDetail || !saleState.orderDetail.order) 
        //     return null;

        const currentLine = loaded ? saleState.orderDetail.order.lines.find(X => X.salesOrderLineID === salesOrderLineID) : null;

        // if (!currentLine) 
        // return null;

        const productGoup = loaded ? saleState.orderDetail.productGroups.find(X => X.productGroupID === currentLine.item.productGroupID) : null;

        const orderLines = _.get(saleState, 'orderDetail.order.lines', []);

        const lines = _.orderBy(orderLines, ['item.productGroupID', 'item.itemNumber'], ['asc', 'asc']);
        // const lines = _.orderBy(saleState.orderDetail.order.lines, ['item.productGroupID', 'item.itemNumber'], ['asc', 'asc']);
        const lineCount = lines.length;
        const SQLID_Array = lines.map(X => X.salesOrderLineID);
        const index = _.indexOf(SQLID_Array, salesOrderLineID);

        const before = SQLID_Array[(lineCount + index - 1) % lineCount];
        const next = SQLID_Array[(index + 1) % lineCount];

        return (
            <Dialog open={open} onClose={handleClose} >
                <ContentColumn loaded={loaded}>
                    <div className="contentColumnHeader">
                        <VesselOrderLineDetailHeader
                            line={currentLine}
                            closePath={`/sales/orders/${salesOrderID}`}
                            next={`/sales/orders/${salesOrderID}/${next}`}
                            before={`/sales/orders/${salesOrderID}/${before}`} />
                    </div>

                    <div className="contentColumnBody">
                        <LineDetailTable productGroup={productGoup} line={currentLine} showOrder={true} />
                    </div>
                    <FloatingMenuButton />
                </ContentColumn>
            </Dialog>
        );
    }, [saleState.orderDetail, salesOrderLineID])


};

export default VesselOrderLineDetail;
