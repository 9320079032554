import React, { useEffect, useState, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import { useAvcsoStore } from '../../../../Global/Stores/avcsoStore';
import ContentColumn from '../../../Custom/ContentColumn';
import { Typography, Select, FormControl, Input, MenuItem, ListItemText, ListItem, ListItemIcon, Chip, List, Link, ListItemSecondaryAction, IconButton, Button } from '@material-ui/core';
import { useGlobalStore } from '../../../../Global/Stores/globalStore';
import { Link as RouterLink } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import SendIcon from '@material-ui/icons/Send';
import { useParams } from "react-router";
import _ from 'lodash';

const AVCSO_BasketReview = () => {

    const [, globalActions] = useGlobalStore();
    const [avcsoState, avcsoActions] = useAvcsoStore();

    // const selectedItemID = +match.params.itemID || 0;

    // console.log('selectedItemID', selectedItemID);

    useEffect(() => {
        // const { selectedItems, vesselID } = saleState.catalogue;
        // if (vesselID > 0)
        avcsoActions.getBasketPrices();
    }, []);


    const itemsLoaded = globalActions.isLoaded();

    // const productGroups = _.get(globalState, 'catalogue.productGroups', []);
    // const { basketLines, productItemGroups } = avcsoState;
    // const listItems = basketLines || [];
    

    if (avcsoState.basketLines.length === 0) {
        return <Redirect to="/sales/avcso/add/" />
    }

    return (
        <ContentColumn loaded={itemsLoaded}>
            <div className="contentColumnHeader" style={{ padding: '0px 10px' }}>
                <Typography variant="h6">AVCSO Basket Review</Typography>
            </div>

            <div className="contentColumnFilter" >
                {/* contentColumnFilter */}
                <BasketDurationSelector />
            </div>

            <div className="contentColumnBody">
                <AVCSO_BasketList />
            </div>

            <div className="contentColumnFooter">
                <BasketFooter />
            </div>
        </ContentColumn>
    );
}

const BasketDurationSelector = () => {

    const allMonths = [3, 6, 9, 12];
    // const [months, setMonths] = useState(12);

    const [avcsoState, avcsoActions] = useAvcsoStore();
    const { months } = avcsoState;


    return useMemo(() => {
        console.log('BasketDurationSelector')

        const durationChange = (e) => {
            const newMonths = +e.target.value;
            avcsoActions.setBasketDuration(newMonths);
            // console.log('durationChange', months);
            // setMonths(P => newMonths);
        }

        return (
            <React.Fragment>
                <Typography variant="button" >Set Durations:&nbsp;&nbsp;</Typography>
                <FormControl>
                    <Select
                        displayEmpty
                        // disabled={hasEmptyBasketDurations}
                        value={months}
                        renderValue={(X) => <div>{`${X} Months`}</div>}
                        onChange={durationChange}
                        input={<Input placeholder="Duration" style={{ width: '125px' }} />}
                    >
                        {
                            allMonths.map(M => (
                                <MenuItem key={M} value={M}>
                                    <ListItemText primary={`${M} Months`} />
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </React.Fragment>
        );
    }, [months, avcsoActions])
}


const AVCSO_BasketList = () => {

    const [avcsoState, avcsoActions] = useAvcsoStore();
    const { basketLines, months } = avcsoState;


    const pars = useParams();
    const selectedItemID = +pars.itemID || 0;

    // const lines = [];
    const caption = "AVCS Online";
    // const { productGroupID, caption, isDigitalPoductGroup } = productGroup;

    return (
        <React.Fragment>
            <ListItem dense button divider>
                <ListItemIcon>
                    <SendIcon />
                </ListItemIcon>

                <ListItemText primary={caption} secondary=" " />
                <Chip size="small" color="primary" label={basketLines.length} />
                {/* {open ? <ExpandLess /> : <ExpandMore />} */}
            </ListItem>

            <List component="div" disablePadding>
                {
                    basketLines.map(X => <AVCSO_BasketListLine
                        key={X.itemID}
                        line={X}
                        selected={selectedItemID === X.itemID} />)
                }
            </List>
        </React.Fragment>
    );
}

const AVCSO_BasketListLine = ({ line, selected }) => {

    const [, avcsoActions] = useAvcsoStore();
    const { itemPrice, quantity } = line;

    const item = line;

    const months = itemPrice ? itemPrice.months : false;

    return useMemo(() => {
        const to = `${item.itemID}`;
        const label = `${months} Months`;

        return (
            <Link className="ListItemLink" component={RouterLink} to={to} color='inherit' style={{ textDecoration: 'none' }}>
                <ListItem button disableRipple dense divider selected={selected}>
                    <ListItemText primary={item.itemNumber} secondary={item.itemTitle} />
                    {
                        Boolean(months) ? <Chip size="small" label={label} /> : null
                    }

                    <ListItemSecondaryAction>
                        <IconButton edge="end" onClick={(e) => {
                            e.preventDefault();
                            avcsoActions.removeBasketItems([item.itemID]);
                        }}>
                            <DeleteIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            </Link>
        );

    }, [item.itemID, selected, months, quantity])
}


const BasketFooter = () => {

    const [, globalActions] = useGlobalStore();
    const [, avcsoActions] = useAvcsoStore();

    const priceInfo = avcsoActions.getTotalPriceLocal();
    const currency = globalActions.getCurrency(priceInfo.currencyID);

    const priceString = priceInfo.valid ? `${currency.code} ${priceInfo.totalPrice}` : 'Calculating...';
    return (
        <React.Fragment>
            <Typography variant="subtitle2" align="left" style={{ flexGrow: '1' }}>
                {`Total Price: ${priceString}`}
            </Typography>

            {/* <BasketConfirmation disabled={confirmDisabled} /> */}

            <Button variant="contained" size="small" onClick={() => { avcsoActions.clearData(); }}>Clear Basket</Button>
        </React.Fragment>
    );
}

export default AVCSO_BasketReview;
