import React from 'react'

// Custom Styling Properties
import menuStyles from '../CSS/MenuStyles.module.css';

// Actual work starts here
const Breadcrumb = ({Module, currentPage}) => {

    return (
        <div className={menuStyles.breadcrumbs}>
            <b>{Module}</b> | {currentPage}
        </div>
    )
}

export default Breadcrumb