import React from 'react'
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect } from "react-router-dom";

// Import General Support Files
import Breadcrumb from './Navigation/Breadcrumb';

//Import Support Files
import UserManual from './Help/UserManual';
import FAQs from './Help/FAQs';
import ContactUs from './Help/ContactUs';
import HelpStatement from './Help/HelpStatement';



const Help = () => {
    return(
        <React.Fragment>
            <div>
                <Route exact Path="/help" component={HelpStatement} />
                <Route exact path="/help/usermanual" component={UserManual} />
                <Route exact path="/help/faqs" component={FAQs} />
                <Route exact path="/help/contactus" component={ContactUs} />
                <br /><br /><br /><br />
            </div>
        </React.Fragment>
    )
}

export default Help