import React, { createContext, useContext, useState, useEffect } from 'react';
import http from './../Services/httpService';
import { useGlobalStore } from './globalStore';
// import { useUserStore } from './userStore';
import _ from 'lodash';

const initialState = {
    ordered: false,
    vesselID: 0,
    reference: "",
    months: 12,
    saveSourceBasketID: 0,
    vesselItemIDs: [], // ItemIDs from inventory + holdings
    routes: [],

    basketLines: [],
    quoteIDs: [],

    routeItemIDs: [], // Buffer + Hit

    quoteBasket: {
        header: {},
        lines: []
    },

    mapItems: [],

    closedProductGroups: [],
};

const basketLineTemplate = {
    itemID: null,
    quantity: 1,
    item: null,
    itemDurations: null,
    itemPrice: null,
};

export const BasketContext = createContext();


export const BasketProvider = ({ children }) => {
    const [state, setState] = useState(initialState);
    const [, globalActions] = useGlobalStore();

    // // Clear data when logout
    // const [userState] = useUserStore();
    // const logout = !Boolean(userState.tokenData.token);
    // useEffect(() => {
    //     if (logout === true) {
    //         console.log('BasketProvider logout', logout);
    //         setState(P => initialState);
    //     }
    // }, [logout]);


    const loadData = () => {
        //console.log("loadData");
    }

    const clearData = () => {
        //console.log("clearData");
        setState(() => { return { ...initialState } });
    }

    const setVessel = (vesselID) => {
        setState(() => ({
            ...initialState,
            vesselID: vesselID
        }));
    };


    const setBasketDuration = (months, itemID) => {

        setState(prev => {
            const cat = { ...prev };

            const id = itemID || 0;

            //console.log('id', id);

            if (id === 0) {
                // Set all durations
                cat.months = months;
                cat.basketLines
                    .filter(X => Boolean(X.itemDurations))
                    .forEach(L => {
                        L.itemPrice = L.itemDurations.filter(D => D.months === months)[0] || L.itemPrice || L.itemDurations[0];
                    });
            }
            else {
                // Set line duration
                const L = cat.basketLines.find(I => I.itemID === itemID);
                L.itemPrice = L.itemDurations.filter(D => D.months === months)[0] || L.itemPrice || L.itemDurations[0];
            }
            return { ...cat };
        });
    }

    const setBasketLineQuantity = (itemID, quantity) => {

        setState(prev => {
            const cat = { ...prev };

            // Set line duration
            const L = cat.basketLines.find(I => I.itemID === itemID);
            L.quantity = quantity;
            return { ...cat };
        });
    }

    const addRoutes = (routes) => {

        if (routes.length === 0)
            return;

        setState(prev => {

            const routeIDs = prev.routes.map(X => X.sourceRouteID);
            const newRoutes = routes.filter(X => !routeIDs.includes(X.sourceRouteID));

            return {
                ...prev,
                routes: [...prev.routes, ...newRoutes],
            }
        });
    }

    const addQuoteIDs = (quoteIDs) => {

        if (quoteIDs.length === 0)
            return;

        setState(prev => {
            const newState = { ...prev }

            const newQuoteIDs = quoteIDs.filter(X => !prev.quoteIDs.includes(X));

            if (newQuoteIDs.length > 0) {
                newState.quoteIDs = [...prev.quoteIDs, ...newQuoteIDs];
            }

            return { ...newState }
        });
    }

    const addVesselRouteItems = (vesselID, route, PGIDS) => {

        const routeID = route.sourceRouteID;
        http.post(`/api/catalogue/${vesselID}/routes/${routeID}/items`, {
            vesselID: vesselID,
            routeID: routeID,
            productGroupIDs: PGIDS
        })
            .then(result => {
                // console.log("addVesselRouteItems", result.data);
                const response = result.data;
                const newVesselItemIDs = response.vesselItemIDs;
                const selectedItems = response.selectedItemIDs;
                const bufferItemIDs = response.bufferItemIDs;

                setState(prev => {
                    const currentItemsIDs = prev.basketLines.map(X => X.itemID);

                    const newSelectedItemIDs = selectedItems.filter(I => !currentItemsIDs.includes(I));


                    const newBasketLines = newSelectedItemIDs.map(I => ({
                        ...basketLineTemplate,
                        itemID: I,
                        item: globalActions.getCatalogueItem(I),
                    }));

                    // console.log('newBasketLines', newBasketLines);
                    const totalBasketLines = _.orderBy([...prev.basketLines, ...newBasketLines], 'item.itemNumber');
                    // console.log('totalBasketLines', totalBasketLines);

                    const newRoutes = prev.routes.includes(route) ? [...prev.routes] : [...prev.routes, route];

                    // console.log('vesselItemIDs', newVesselItemIDs);

                    return {
                        ...prev,
                        vesselItemIDs: newVesselItemIDs,
                        basketLines: totalBasketLines,
                        routes: newRoutes,
                        routeItemIDs: [...bufferItemIDs, ...selectedItems],
                        // selectedItems: newSelectedItemIDs,
                    }
                });
            })
            .catch(error => {
                //alert(error);
            });
    }

    const addBasketItems = (itemIDs) => {
        setState(prev => {
            const val = { ...prev }
            //console.log('removeBasketItems', itemIDs, val.selectedItems);

            const currentItemsIDs = prev.basketLines.map(X => X.itemID);
            const newItemIDs = itemIDs.filter(X => !currentItemsIDs.includes(X));

            const newBasketLines = newItemIDs.map(I => ({
                ...basketLineTemplate,
                itemID: I,
                item: globalActions.getCatalogueItem(I),
            }));

            val.basketLines = [...val.basketLines, ...newBasketLines];
            return { ...val }
        });
    }



    const addSourceRecord = (sourceRecord) => {
        if (sourceRecord) {
            const SQID = sourceRecord.salesQuoteID || 0;
            if (SQID > 0) {
                addQuoteIDs([SQID]);
            }


            const sourceRouteID = sourceRecord.sourceRouteID || 0;
            if (sourceRouteID > 0) {
                addRoutes([sourceRecord]);
            }
        }
    }

    const getQuoteBasketLines = (quote) => {

        // console.log('quote', quote);
        const quoteID = _.get(quote, "salesQuoteID", 0);
        if (quoteID === 0)
            return

        http.get(`/api/sales/quotes/${quoteID}/lines`)
            .then(result => {
                const quotesLines = result.data;
                // console.log('getQuoteBasketLines', quotesLines);

                setState(prev => {
                    return {
                        ...prev,
                        quoteBasket: { header: quote, lines: quotesLines },
                    };
                });
            })
            .catch(error => {
                //alert(error);
            });

    }

    const clearQuoteBasketLines = () => {
        setState(prev => {
            return {
                ...prev,
                quoteBasketLines: []
            };
        });
    }

    const addQuoteBasketItems = () => {

        // console.log('addQuoteBasketItems', quoteID, itemIDs);



        const itemIDs = state.quoteBasket.lines.map(X => X.item.itemID);
        addBasketItems(itemIDs);

        const routes = state.quoteBasket.header.routes;
        addRoutes(routes);

        const quoteID = state.quoteBasket.header.salesQuoteID;
        addQuoteIDs([quoteID]);
        // setState(prev => {
        //     const val = { ...prev }

        //     if (!val.quoteIDs.includes(quoteID))
        //         val.quoteIDs.push(quoteID)

        //     return { ...val }
        // });
    }

    const removeBasketItems = (itemIDs) => {
        setState(prev => {
            const val = { ...prev }
            //console.log('removeBasketItems', itemIDs, val.selectedItems);

            val.basketLines = val.basketLines.filter(X => !itemIDs.includes(X.itemID));

            // val.selectedItems = val.selectedItems.filter(I => !itemIDs.includes(I));
            return { ...val }
        });
    }

    const getBasketPrices = () => {

        const vesselID = state.vesselID;
        const itemIDs = state.basketLines.filter(L => !Boolean(L.itemPrice)).map(L => L.itemID);
        // console.log("getBasketPrices", itemIDs)

        if (itemIDs.length === 0)
            return null;

        http.post(`/api/catalogue/${vesselID}/prices`, {
            vesselID: vesselID,
            itemIDs: itemIDs,
            avcso: false
        })
            .then(result => {
                const newItemPrices = _.orderBy(result.data, ['itemID', 'months'], ['asc', 'desc']);

                setState(prev => {
                    const months = prev.months;
                    const BL = [...prev.basketLines];

                    BL
                        .filter(L => !Boolean(L.itemPrice))
                        .forEach(L => {
                            const prices = newItemPrices.filter(I => L.itemID === I.itemID);
                            L.itemDurations = prices;
                            L.itemPrice = prices.filter(I => I.months === months)[0] || prices[0];
                        });

                    // console.log('BasketLinePrices', BL);

                    return {
                        ...prev,
                        basketLines: BL,
                    };
                });
            })
            .catch(error => {
                //alert(error);
            });
    }

    const toggleClosedProductGroups = (PGID) => {

        setState(prev => {
            // const cat = { ...prev.catalogue };
            const isOpen = prev.closedProductGroups.includes(PGID);

            // console.log('prev', cat.closedProductGroups)
            const newVal = isOpen ? prev.closedProductGroups.filter(X => X !== PGID) : [...prev.closedProductGroups, PGID];
            return {
                ...prev,
                closedProductGroups: newVal,
            }
        });
    }

    const saveManagerBasket = (reference) => {

        // console.log('basketLines', state.basketLines);

        const postData = {
            sourceRouteIDs: state.routes.map(X => X.sourceRouteID),
            reference: reference,
            lines: state.basketLines.map(X => ({
                itemID: X.itemID,
                quantity: X.quantity,
                months: _.get(X, "itemPrice.months", 12),
            })),
            // quantity: (globalActions.isDigitalProductGroup(X.item.productGroupID) ? X.itemPrice.months : X.quantity) })),
        };

        // console.log('postData', postData);

        http.post("/api/catalogue/baskets/update", postData)
            .then((result) => {
                globalActions.getBaskets();

                const sourceRouteID = result;
                setState(prev => {
                    return {
                        ...prev,
                        sourceRouteID: sourceRouteID
                    }
                });
            })
            .catch(error => {
                //alert(error)
            });
    }


    const orderBasket = () => {

        const postData = {
            vesselID: state.vesselID,
            sourceRouteIDs: state.routes.map(X => X.sourceRouteID),
            salesQuoteIDs: state.quoteIDs,
            reference: state.reference,
            lines: state.basketLines.map(X => ({
                itemID: X.itemID,
                quantity: X.quantity,
                months: _.get(X, "itemPrice.months", 12),
            })),
            avcso: false,
        };


        // console.log('orderBasket', postData);

        // setState(prev => {
        //     return { ...prev, ordered: true }
        // })

        http.post("/api/sales/orders/create", postData)
            .then((result) => {
                // const response = result.data;

                setState(prev => {

                    return { ...prev, ordered: true }
                })

                // console.log('orders/create', response);
                // setVessel(0);
            })
            .catch(error => {
                //alert(error)
            });

    }

    const setMapItems = (items) => {
        setState(prev => {

            // console.log('setMapItems', items);
            // const newState = { ...prev, mapItems: [...items] }
            return {
                ...prev,
                mapItems: [...items]
            }
        });
    }

    const setReference = (reference) => {
        setState(prev => {

            return {
                ...prev,
                reference: reference
            }
        });
    }

    const hasBasketPrices = () => {
        const lineCount = state.basketLines.length;
        const validLines = state.basketLines.filter(X => Boolean(X.itemPrice));
        return lineCount === validLines.length;
    }


    const actions = {
        loadData: loadData,
        clearData: clearData,

        setVessel: setVessel,
        setBasketDuration: setBasketDuration,
        setBasketLineQuantity: setBasketLineQuantity,


        addRoutes: addRoutes,
        addQuoteIDs: addQuoteIDs,

        saveManagerBasket: saveManagerBasket,

        setMapItems: setMapItems,

        addVesselRouteItems, addVesselRouteItems,
        addBasketItems: addBasketItems,

        getQuoteBasketLines: getQuoteBasketLines,
        clearQuoteBasketLines: clearQuoteBasketLines,
        addQuoteBasketItems: addQuoteBasketItems,
        removeBasketItems: removeBasketItems,

        getBasketPrices: getBasketPrices,
        hasBasketPrices: hasBasketPrices,
        toggleClosedProductGroups: toggleClosedProductGroups,

        orderBasket: orderBasket,

        setReference: setReference,
        addSourceRecord: addSourceRecord,
    }

    return (
        <BasketContext.Provider value={[state, actions]}>
            {children}
        </BasketContext.Provider >
    );
};

export const useBasketStore = () => useContext(BasketContext);
