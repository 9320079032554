import React from 'react';
import { CircularProgress } from '@material-ui/core';

//Modified here in order to be able to use the loading for several other parts. 
// turns out it always comes in a pack of three. 
// Inasmuch as am a fan of free things... not this one! :D
const Loading = () => {
    return (
        <div className="loadingSpinner">
            <CircularProgress color="primary" />
            <CircularProgress color="primary" />
            <CircularProgress color="primary" />
        </div>
    );
};

const LoadingOne = () => {
    return (
        <div className="loadingSpinner">
            <CircularProgress color="primary" />
        </div>
    );
};

export default Loading;
