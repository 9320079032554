import React, { useMemo, useState } from 'react'
import { List, ListItem, Paper, ListItemText, Link, CircularProgress } from '@material-ui/core'
import { useGlobalStore } from './../../../../Global/Stores/globalStore'
import _ from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import SearchFilter from '../../../Desktop/Misc/SearchFilter';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import { useMapStore } from '../../../../Global/Stores/mapStore';

const RouteContainer = () => {

    const [mapState, mapActions] = useMapStore();
    const [globalState] = useGlobalStore();
    const [filter, setFilter] = useState('');
    const [sort, setSort] = useState(1);
    
    const [ defaultRoute ] = mapState.activeRoutes;

    const [selectedItem, setSelectedItem] = useState(defaultRoute ? defaultRoute.sourceRouteID : 0);

    const loaded = Boolean(globalState.routes);
    const routes = loaded ? globalState.routes : [];
    let filterRoutes = routes.filter(route => route.name.toLowerCase().indexOf(filter) !== -1);
    
    filterRoutes = _.orderBy(filterRoutes, [B => B.name.toLowerCase()], [sort > 0 ? 'asc' : 'desc']);

    const onRouteClick = (route) => {
        setSelectedItem(() => route.sourceRouteID);

        mapActions.setActiveDrawerMenu(0);
        mapActions.zoomTo(route.routePoints);
        mapActions.setActiveRoutes([route]);
    };    
    
    const onRouteClear = (e) => {
        e.stopPropagation();

        setSelectedItem(0);
        mapActions.clearActiveRoutes();
        mapActions.setActiveDrawerMenu(0);
    };

    const onSearch = (text) => {
        setFilter(() => { return text.toLowerCase(); })
    };

    return useMemo(() => {
        return (
            <React.Fragment>
                <Paper square elevation={0} style={{ background: 'none' }}>
                    <React.Fragment>
                        <SearchFilter placeholder="Search Route" onSearch={onSearch} onSort={(S) => setSort(() => S)} />
                        {
                            <List dense={true} disablePadding style={{margin: "10px 0"}}>
                                {
                                    (loaded) && (routes.length > 0) &&
                                    (
                                        filterRoutes.map(route => {
                                            return (
                                                <ListItem key={route.sourceRouteID} button disableRipple dense divider selected={selectedItem == route.sourceRouteID} onClick={() => onRouteClick(route)}>
                                                    <ListItemText primary={route.name} style={{ marginRight: '10px' }} />
                                                    {
                                                        (selectedItem == route.sourceRouteID) && 
                                                        (
                                                            <IconButton aria-label="delete" size="small" onClick={(e) => onRouteClear(e)}>
                                                                <CloseIcon />
                                                            </IconButton>
                                                        )
                                                    }
                                                </ListItem>
                                            );
                                        })
                                    )
                                }       
                            </List>
                        }
                        <React.Fragment>
                            <div style={{ display: "block", textAlign: "center", margin: "3rem 0" }}>
                                {
                                    (loaded) && (routes.length === 0) &&
                                    (
                                        <React.Fragment>
                                            <ListItemText primary={"There aren't routes defined"} style={{ marginRight: '10px' }} />
                                            <Link
                                                underline="none"
                                                className="ListItemLink"
                                                component={RouterLink}
                                                to={`/sales/catalogue`}
                                                style={{ textDecoration: 'none', margin: "5px 0", textAlign: "center", display: "block" }}
                                            >
                                                click to add new route
                                            </Link>
                                        </React.Fragment>
                                    )
                                }
                                {
                                    (!loaded) && 
                                    (
                                        <div style={{ display: "block", textAlign: "center", margin: "3rem 0" }}>
                                            <CircularProgress />
                                        </div>
                                    )
                                }
                            </div>
                        </React.Fragment>
                    </React.Fragment>
                </Paper>
            </React.Fragment>
        )
    }, [filterRoutes, selectedItem]);
};

export default RouteContainer;