import React, { useMemo, useEffect, useState } from 'react';
import { Divider, Typography, ListItem, TableBody, TableCell, Table, TableHead, TableRow, Dialog } from '@material-ui/core';
import { useFleetStore } from '../../../../../Global/Stores/fleetStore';
import LineDetailTable from '../../../Misc/LineDetailTable';
import StatusCircle from '../../../../Widgets/StatusCircle';
import { getHoldingStatusClass } from '../../../../MapControl/Helpers/GlobalHelper';

import http from '../../../../../Global/Services/httpService';
import CloseButtonLink from '../../../../Widgets/Buttons/CloseButtonLink';
import ContentColumn from '../../../Custom/ContentColumn';
import _ from 'lodash';

import FloatingMenuButton from '../../../Navigation/FloatingMenuButton';

const VesselHoldingsLineDetail = ({ match }) => {

    const [fleetState] = useFleetStore();

    const vesselID = +match.params.vesselID;
    const itemID = +match.params.itemID;
    const hasData = Boolean(fleetState.holdingDetail);

    const [openDialog, setOpenDialog] = React.useState(true);
    
      const closeDialog = () => {
        setOpenDialog(false);
      };


    // console.log('VesselHoldingsLineDetail 00');


    return useMemo(() => {
        // console.log('VesselHoldingsLineDetail 01');

        const loaded = Boolean(_.get(fleetState, 'holdingDetail.vessel', false));

        // if (!fleetState.holdingDetail || !fleetState.holdingDetail.vessel) return null;
        const currentLine = loaded ? fleetState.holdingDetail.vessel.vesselItems.find(X => X.itemID === itemID) : null;

        // console.log('VesselHoldingsLineDetail 02');

        // if (!currentLine)
        //     return null;


        // console.log('VesselHoldingsLineDetail 03', currentLine);

        const productGroup = loaded ? fleetState.holdingDetail.productGroups.find(X => X.productGroupID === currentLine.item.productGroupID) : null;
        // console.log('productGroup', productGroup);

        return (
            <Dialog open={openDialog} onClose={closeDialog} >
                <ContentColumn loaded={loaded}>
                    <div className="contentColumnHeader">
                        <VesselHoldingsLineDetailHeader line={currentLine} productGroup={productGroup} />
                    </div>

                    <div className="contentColumnBody">
                        <VesselHoldingsLineDetailList productGroup={productGroup} line={currentLine} />
                    </div>
                </ContentColumn>
                <FloatingMenuButton />
            </Dialog>
        );


    }, [hasData, vesselID, itemID]);
};

const VesselHoldingsLineDetailHeader = ({ line }) => {

    return useMemo(() => {
        return (
            <React.Fragment>

                <CloseButtonLink to={`/fleet/holdings/${line.vesselID}`} />

                {/* <Typography className="flexFill" variant="subtitle2" >
                    {line.item.itemTitle}
                </Typography> */}
                <Divider />
            </React.Fragment>
        );
    }, [line.item.itemID])

}

const VesselHoldingsLineDetailList = ({ line, productGroup }) => {

    const itemClass = getHoldingStatusClass(line.itemIssue, line.itemWarning);
    const updateClass = getHoldingStatusClass(line.updateIssue, line.updateWarning);

    return (
        <React.Fragment>
            <LineDetailTable productGroup={productGroup} line={line} showLicense={true} />
            <Divider />

            {
                !line.item.polygonPoints && (
                    <ListItem>
                        <StatusCircle line={line} className="statusRed" /> <Typography variant="button">Map outline not available</Typography>
                    </ListItem>
                )
            }

            <ListItem>
                <StatusCircle line={line} className={itemClass} /> <Typography variant="button">{line.item.isDigital ? 'License' : 'Edition'}</Typography>
            </ListItem>
            <ListItem>
                <StatusCircle line={line} className={updateClass} /> <Typography variant="button">{line.item.isDigital ? 'Updates' : 'Corrections'}</Typography>
            </ListItem>
            <VesselHoldingsLineDetailCorrections line={line} />
        </React.Fragment>
    );
}


const VesselHoldingsLineDetailCorrections = ({ line }) => {

    const [detailState, setDetailState] = useState({
        vesselID: 0,
        itemID: 0,
        itemCorrections: [],
        itemHistoryItems: [],
    });

    const { vesselID, item } = line;

    useEffect(() => {
        if (vesselID > 0) {
            http.get(`/api/fleet/holdings/${vesselID}/${item.itemID}/details`)
                .then(result => {

                    if (result.data && result.data.vesselID === vesselID) {
                        setDetailState(() => {
                            return { ...result.data }
                        });
                    }
                })
                .catch(error => {
                    //alert(error);
                });
        }

    }, [vesselID, item.itemID])


    return useMemo(() => {

        if (vesselID !== detailState.vesselID)
            return null;

        if (detailState.itemCorrections.length === 0)
            return null;

        return (
            <div style={{ padding: '0px 15px 0px 50px', maxHeight: '50vh', overflow: 'auto' }}>
                <Table size="small" padding="none">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">

                                <Typography variant="subtitle2">NM Number</Typography>
                            </TableCell>
                            <TableCell align="center">
                                {/* Published */}
                                <Typography variant="subtitle2">Published</Typography>
                            </TableCell>
                            <TableCell align="center">

                                <Typography variant="subtitle2">Status</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            detailState.itemCorrections.map((X, i) => (
                                <TableRow key={X.name} selected={(i % 2) == 1}>
                                    <TableCell align="left">{X.name}</TableCell>
                                    <TableCell align="center">{X.year}/{("0" + X.week).slice(-2)}</TableCell>
                                    <TableCell align="center">
                                        <CorrectionItemStatus correctionItem={X} />
                                        {/* {X.issue ? 'Y' : 'No'} */}
                                    </TableCell>
                                </TableRow>
                            ))
                        }

                    </TableBody>
                </Table>
            </div>
        );
    }, [detailState, vesselID, item]);
}

const CorrectionItemStatus = ({ correctionItem }) => {

    const updateClass = getHoldingStatusClass(correctionItem.issue, correctionItem.warning);
    return (
        <StatusCircle className={updateClass} />
    );
}

export default VesselHoldingsLineDetail;
