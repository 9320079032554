import React, { useMemo, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Typography, ListItem, ListItemIcon, List, ListItemText, Collapse, Chip, Link, ListItemSecondaryAction, IconButton, FormControl, Select, Input, MenuItem, Button } from '@material-ui/core';
import { useGlobalStore } from '../../../../Global/Stores/globalStore';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Link as RouterLink } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import { useBasketStore } from '../../../../Global/Stores/basketStore';
import _ from 'lodash';
import ContentColumn from '../../Custom/ContentColumn';

const CatalogueBasketReview = ({ match }) => {
    const [globalState,] = useGlobalStore();
    const [basketState, basketActions] = useBasketStore();
    const selectedItemID = +match.params.itemID || 0;

    useEffect(() => {
        basketActions.getBasketPrices();
    }, []);


    const itemsLoaded = Boolean(globalState.catalogue);

    const productGroups = _.get(globalState, 'catalogue.productGroups', []);
    const { basketLines, closedProductGroups, vesselID } = basketState;
    const listItems = basketLines || [];

    // console.log(itemsLoaded)

    if (listItems.length === 0) {
        return <Redirect to="/sales/catalogue/add/" />
    }

    return (
        <ContentColumn loaded={itemsLoaded} >
            {/* <div className="contentColumnHeader" style={{ padding: '0px 10px' }}>
                <Typography variant="h6">Basket Review</Typography>
            </div> */}
            <br />
            <div className="contentColumnFilter" >
                <BasketDurationSelector />
            </div>

            <div className="contentColumnBody" style={{padding: "10px"}}>
                <List component="div" className="ListItemHeader" disablePadding>
                    {
                        productGroups.map(PG => (
                            <BasketProductGroupListHeader
                                key={PG.productGroupID}
                                productGroup={PG}
                                vesselID={vesselID}
                                lines={listItems.filter(I => Boolean(I.item) && I.item.productGroupID === PG.productGroupID)}
                                selectedItemID={selectedItemID}
                                open={!closedProductGroups.includes(PG.productGroupID)}
                                toggleOpen={basketActions.toggleClosedProductGroups}
                            />))
                    }
                </List>
            </div>

            <div className="contentColumnFooter" style={{padding: "10px"}}>
                <BasketFooter />
            </div>
        </ContentColumn>
    );
};


const BasketDurationSelector = () => {

    const [basketState, basketActions] = useBasketStore();
    const allMonths = [3, 6, 9, 12];
    const { months } = basketState;

    return useMemo(() => {

        const durationChange = (e) => {
            const newMonths = +e.target.value;
            basketActions.setBasketDuration(newMonths);
        }

        return (
            <React.Fragment>&nbsp;&nbsp;&nbsp;
                <Typography variant="button" >Set Duration(s):&nbsp;&nbsp;</Typography>
                <FormControl>
                    <Select
                        displayEmpty
                        value={months}
                        renderValue={(X) => <div>{`${X} Months`}</div>}
                        onChange={durationChange}
                        input={<Input placeholder="Duration" style={{ width: '125px' }} />}
                    >
                        {
                            allMonths.map(M => (
                                <MenuItem key={M} value={M}>
                                    <ListItemText primary={`${M} Months`} />
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </React.Fragment>
        );
    }, [months])
}


const BasketProductGroupListHeader = ({ productGroup, vesselID, lines, selectedItemID, open, toggleOpen }) => {
    // console.log(productGroup)

    const { productGroupID, caption, isDigitalPoductGroup } = productGroup;
    if (lines.length === 0)
    return null;
    
    // console.log(productGroupID)
    
    return (
        <React.Fragment>
            <ListItem dense button divider selected={open} onClick={() => toggleOpen(productGroupID)}>
                <ListItemIcon>
                    <SendIcon />
                </ListItemIcon>

                <ListItemText primary={caption} secondary="Subtotal" />
                <Chip size="small" color="primary" label={lines.length} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {/* {console.log(lines)} */}
                    {
                        lines.map(X => <BasketProductGroupListItem
                            key={X.itemID}
                            productID={productGroupID}
                            vesselID={vesselID}
                            isDigital={isDigitalPoductGroup}
                            line={X}
                            selected={selectedItemID === X.itemID} />)
                    }
                </List>
            </Collapse>
        </React.Fragment>
    );
}

const BasketProductGroupListItem = ({ line, selected, isDigital, productID }) => {
    const [, basketActions] = useBasketStore();
    const { item, itemPrice, quantity, month } = line;
    const allMonths = [3, 6, 9, 12];
    
    const durationChange = (e) => {
        const newMonths = +e.target.value;
        basketActions.setBasketDuration(newMonths, item.itemID);
        // console.log('durationChange', months);
    }
    
    
    const months = itemPrice ? itemPrice.months : false;
    
    return useMemo(() => {
        const to = `${item.itemID}`;
        const label = isDigital ? (Boolean(months) ? `${months} Months` : null) : `x ${quantity}`
        console.log(productID)

        return (
            <Link className="ListItemLink" component={RouterLink} to={to} color='inherit' style={{ textDecoration: 'none' }}>
                <ListItem button disableRipple dense divider selected={selected}>
                    <ListItemText primary={item.itemNumber} secondary={item.itemTitle} />
                    {productID === 1 ||
                    productID === 2 ||
                    productID === 5 ||
                    productID === 6 ||
                    productID === 7 ||
                    productID === 8 ||
                    productID === 9 ||
                    productID === 10 ||
                    productID === 11 ||
                    productID === 12
                    ?(Boolean(label) ? <Chip size="small" label={label} /> : null):
                    <FormControl>
                        <Select
                            displayEmpty
                            value={months}
                            renderValue={(X) => <div>{`${X} Months`}</div>}
                            onChange={durationChange}
                            input={<Input placeholder="Duration" style={{ width: 'auto' }} />}
                        >
                            {
                                allMonths.map(M => (
                                    <MenuItem key={M} value={M}>
                                        <ListItemText primary={`${M} Months`} />
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl> }

                    <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="Comments" onClick={(e) => {
                            e.preventDefault();
                            basketActions.removeBasketItems([item.itemID]);
                        }}>
                            <DeleteIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            </Link>
        );

    }, [item.itemID, selected, months, quantity])
}

const BasketFooter = () => {

    const [globalState,] = useGlobalStore();
    const [basketState, basketActions] = useBasketStore();

    const vesselID = basketState.vesselID;

    const months = basketState.months;
    const lineCount = basketState.basketLines.length;
    const validLines = basketState.basketLines.filter(X => Boolean(X.itemPrice));

    const validPrices = lineCount === validLines.length;

    const P = _.sumBy(basketState.basketLines, 'itemPrice.price');
    const QTY = _.sumBy(basketState.basketLines, 'quantity');

    return useMemo(() => {

        const totalValue = validPrices ? [] : ["Calculating..."];

        if (validPrices === true) {
            globalState.currencies.forEach(C => {

                const currencyLines = validLines
                    .filter(P => P.itemPrice.currencyID === C.currencyID)
                    .map(L => +((L.quantity * L.itemPrice.price).toFixed(2)));

                if (currencyLines.length > 0) {
                    const currencyPrice = _.sum(currencyLines).toFixed(2);
                    totalValue.push(`${currencyPrice} ${C.code}`);
                }
            });
        }

        const priceString = totalValue.join(" + ");

        return (
            <React.Fragment>
                <Typography variant="subtitle2" align="left" style={{ flexGrow: '1' }}>
                    {`Total Price: ${priceString}`}
                </Typography>

                <Button variant="contained" size="small" onClick={() => { basketActions.clearData(); basketActions.setVessel(vesselID); }}>Clear Basket</Button>
            </React.Fragment>
        );
    }, [vesselID, validPrices, months, validLines.length, P, QTY, basketActions]);
};


export default CatalogueBasketReview;
