import React from 'react';
import ReactGA from 'react-ga';
import { withRouter } from "react-router-dom";

const Analytics = ({ history }) => {
    history.listen(location => ReactGA.pageview(location.pathname));
    return (<React.Fragment />);
};

export default withRouter(Analytics);
