import React, { useState } from 'react';
import { List, ListItem, ListItemText, ListItemIcon, Collapse, ListItemSecondaryAction, IconButton, Link } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import ValidationWidget from '../../../Misc/ValidationWidget';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash'

const QuoteDetailList = ({ selectedLineID, quoteDetail, salesActions }) => {

    //console.log('quoteDetail', quoteDetail)

    const [OpenGroups, setOpenGroups] = useState((quoteDetail ? quoteDetail.productGroups.map(X => X.productGroupID) : []));

    if (!quoteDetail || !quoteDetail.quote) return null;


    // console.log('quoteDetail', quoteDetail);

    // const quote = quoteDetail.quote;
    // const productGroups = quote.productGroups;


    const toggleProductGroup = (productGroupID) => {
        setOpenGroups(prev => prev.includes(productGroupID) ? prev.filter(X => X !== productGroupID) : [...prev, productGroupID])
    }


    const { quote, productGroups } = quoteDetail;

    const lines = _.orderBy(quote.lines, ['item.productGroupID', 'item.itemNumber'], ['asc', 'asc']);



    const headers = productGroups.map(X => {
        const productgroupItems = lines.filter(I => I.item.productGroupID === X.productGroupID);
        const subTotal = (_.sumBy(productgroupItems, 'linePrice') || 0).toFixed(2);
        const subTotalSelected = (_.sumBy(productgroupItems.filter(X => X.validationID === 1), 'linePrice') || 0).toFixed(2);

        const open = OpenGroups.includes(X.productGroupID);
        return productgroupItems.length > 0 ? (
            <React.Fragment key={X.productGroupID}>

                <ListItem button divider selected={open} dense onClick={() => toggleProductGroup(X.productGroupID)}>
                    <ListItemIcon>
                        <SendIcon />
                    </ListItemIcon>

                    <ListItemText primary={X.caption} secondary={`${quote.currency} ${subTotalSelected} / ${subTotal}`} />

                    <ListItemText style={{ flexGrow: '0' }} primary={`${productgroupItems.length} Items`} />
                    <ListItemSecondaryAction>
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemSecondaryAction>
                </ListItem>

                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {
                            productgroupItems.map(X => <QuoteLine
                                key={X.salesQuoteLineID}
                                line={X}
                                selected={X.salesQuoteLineID === selectedLineID}
                                onValidate={salesActions.validateSalesQuoteLine}
                                onSelect={salesActions.selectSalesQuoteLine}
                            />)
                        }
                    </List>
                </Collapse>
            </React.Fragment>
        ) : null;
    });

    return (
        <List className="ListItemHeader" disablePadding>
            {headers}
        </List>
    );
};

const QuoteLine = ({ line, onValidate, onSelect, selected }) => {

    // console.log('selected', selected);

    const handleValidation = (newValidation) => {
        // console.log('handleValidation', newValidation);
        onValidate(line.salesQuoteLineID, newValidation);
    };

    const handleSelection = () => {
        //console.log('onSelect', line.salesQuoteLineID);
        onSelect(line.salesQuoteLineID);
    };

    const to = `/sales/quotes/${line.salesQuoteID}/${line.salesQuoteLineID}`;

    return (
        <Link className="ListItemLink" component={RouterLink} to={to} color='inherit' style={{ textDecoration: 'none' }}>
            <ListItem button disableRipple dense divider onSelect={handleSelection} selected={selected}>
                <ValidationWidget validationID={line.validationID} onChange={handleValidation} />
                <ListItemText primary={line.item.itemNumber} secondary={line.item.itemTitle} />

                <ListItemSecondaryAction>
                    <ListItemText primary={(line.linePrice ? line.linePrice.toFixed(2) : '')} />
                </ListItemSecondaryAction>
            </ListItem>
        </Link>
    );
}

export default QuoteDetailList;
