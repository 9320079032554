import React, { useEffect } from 'react';
import { Route } from "react-router-dom";

import { useFleetStore } from '../../../Global/Stores/fleetStore';
import FleetDetails from './Details/FleetDetails';
import FleetDetailsVessel from './Details/FleetDetailsVessel';

import BreadCrumb from '../Navigation/Breadcrumb';


const FleetOverview = () => {

    const [, fleetActions] = useFleetStore();

    useEffect(
        () => {
            fleetActions.getHoldingsOverview();
            fleetActions.getFleetPositions();
        }, []
    );

    return (
            <React.Fragment>
                <BreadCrumb Module="Fleet Manager" currentPage="Fleet Status" />
                <Route exact path="/fleet/overview" component={FleetDetails} />
                <Route exact path="/fleet/overview/:vesselID" component={FleetDetailsVessel} />
            
            <br /><br /><br />
            </React.Fragment>
        );
};

export default FleetOverview;
