import React, { useEffect } from 'react';
import OrderDetailHeader from './OrderDetailHeader';
import OrderDetailList from './OrderDetailList';
import OrderDetailFooter from './OrderDetailFooter';
import { useSaleStore } from '../../../../../Global/Stores/saleStore';
import OrderStatusFilter from '../../../../Widgets/Filters/OrderStatusFilter';
import ContentColumn from '../../../Custom/ContentColumn';
import _ from 'lodash';

const VesselOrderDetail = ({ match }) => {
    const salesOrderID = +match.params.id;
    const selectedLineID = +match.params.lineID;
    const [saleState, salesActions] = useSaleStore();

    // console.log('VesselOrderDetail', salesOrderID); 

    const filterChanged = (statusIDs) => {
        salesActions.filterSalesOrder(statusIDs);
    }

    useEffect(
        () => {
            salesActions.getSalesOrder(salesOrderID);
            return () => {
                salesActions.clearSalesOrder();
            }
        },
        [salesOrderID]
    );

    const loaded = _.get(saleState, 'orderDetail.order.salesOrderID', -1) === salesOrderID;
    const order = _.get(saleState, 'orderDetail.order');

    return (
        <ContentColumn loaded={loaded}>
            <div className="contentColumnHeader"  style={{ padding: '10px 0px', margin: "-10px 0px 0px 0px", width: '100%', backgroundColor: '#fff', zIndex: 1, position: 'absolute' }}>
                <OrderDetailHeader orderDetail={saleState.orderDetail} />
            </div>
            <br /><br /><br /><br />
            <div className="contentColumnFilter">
                <OrderStatusFilter filterChanged={filterChanged} />
            </div>

            <div className="contentColumnBody">
                <OrderDetailList orderDetail={saleState.orderDetail} selectedLineID={selectedLineID} />
            </div>

            <div className="contentColumnFooter">
                <OrderDetailFooter order={order} />
            </div>
            <br /><br /><br /><br /><br />
            
        </ContentColumn>
    );
};

export default VesselOrderDetail;
