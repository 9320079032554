import React, { useEffect } from 'react';
import { Route } from "react-router-dom";

import { useFleetStore } from '../../../Global/Stores/fleetStore';
import Status from './FleetStatus/FleetStatus';
import FleetStatusDetail from './FleetStatus/FleetStatusDetail';

import BreadCrumb from '../Navigation/Breadcrumb';


const FleetStatus = () => {

    const [, fleetActions] = useFleetStore();

    useEffect(
        () => {
            fleetActions.getHoldingsOverview();
            fleetActions.getFleetPositions();
        }, []
    );

    return (
            <React.Fragment>
                <BreadCrumb Module="Fleet Manager" currentPage="Fleet Status" />
                <Route path="/fleet/status/" component={Status} />
                <Route exact path="/fleet/status/:vesselID/:itemID?" component={FleetStatusDetail} />
            
            <br /><br /><br />
            </React.Fragment>
        );
};

export default FleetStatus;
