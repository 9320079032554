import React, { useMemo } from 'react';
import _ from 'lodash';

const OrderStatusVessel = ({ orders }) => {

    // if (orders.length !== 25) return null;

    return useMemo(() => {

        const total = orders.length;

        const countData = _.countBy(orders, X => X.salesOrderStatusID);

        // console.log('countData', countData);

        const tooltip = "Open: " + (countData[1] || 0) + "\n" +
            "Backorder: " + (countData[5] || 0) + "\n" +
            "Shipped: " + (countData[3] || 0) + "\n" +
            "Delivered: " + (countData[4] || 0);

        const percentageOpen = countData[1] / total * 100;
        const percentageBackorder = countData[5] / total * 100;
        const percentageShipped = countData[3] / total * 100;
        const percentageDelivered = countData[4] / total * 100;

        const degreesOpen = Math.ceil((percentageOpen * 360) / 100) || 0;
        const degreesBackorder = Math.ceil((percentageBackorder * 360) / 100) || 0;
        const degreesShipped = Math.ceil((percentageShipped * 360) / 100) || 0;
        const degreesDelivered = Math.ceil((percentageDelivered * 360) / 100) || 0;

        // if (!degreesOpen) degreesOpen = 0;
        // if (!degreesBackorder) degreesBackorder = 0;
        // if (!degreesShipped) degreesShipped = 0;
        // if (!degreesDelivered) degreesDelivered = 0;


        if ((degreesOpen === 0) && (degreesBackorder === 0) && (degreesShipped === 0) && (degreesDelivered === 0)) {
            degreesDelivered = 360;
        }

        const chartData = {
            tooltip: tooltip,
            open: {
                start: 0,
                length: degreesOpen
            },
            backorder: {
                start: degreesOpen,
                length: degreesBackorder
            },
            shipped: {
                start: degreesOpen + degreesBackorder,
                length: degreesShipped
            },
            delivered: {
                start: degreesOpen + degreesShipped + degreesBackorder,
                length: degreesDelivered
            }
        };

        return (
            <div className="order-chart" title={tooltip}>
                <div
                    className={chartData.open.length > 180 ? "pie-order big" : "pie-order"}
                    data-start={chartData.open.start}
                    data-value={chartData.open.length}></div>
                <div
                    className={chartData.backorder.length > 180 ? "pie-order big" : "pie-order"}
                    data-start={chartData.backorder.start}
                    data-value={chartData.backorder.length}></div>
                <div
                    className={chartData.shipped.length > 180 ? "pie-order big" : "pie-order"}
                    data-start={chartData.shipped.start}
                    data-value={chartData.shipped.length}></div>
                <div
                    className={chartData.delivered.length > 180 ? "pie-order big" : "pie-order"}
                    data-start={chartData.delivered.start}
                    data-value={chartData.delivered.length}></div>
                <p className="chart-text" style={{ color: 'black' }}>{orders.length}</p>
            </div>
        );
    }, [orders.length]);
};

export default OrderStatusVessel;
