import React from 'react';
import { IconButton } from '@material-ui/core';
import MailIcon from '@material-ui/icons/MailOutline';

function MailTo({ vessel }) {
    return (
        <a target="_top"
            rel="noopener noreferrer"
            href={`mailto:${vessel.vesselEmail}?subject=Ship Manager - ${vessel.vesselName}`}>
            <IconButton edge="end">
                <MailIcon />
            </IconButton>
        </a>
    );
};

export function MailOrderDetailTo({ orderDetail }) {
    return (
        <a target="_top"
            rel="noopener noreferrer"
            href={`mailto:${orderDetail.vessel.vesselEmail}?subject=Ship Manager - ${orderDetail.vessel.vesselName} - ${orderDetail.order.customerReference}`}>
            <IconButton edge="end">
                <MailIcon />
            </IconButton>
        </a>
    );
};

export function MailQuoteDetailTo({ quoteDetail }) {
    return (
        <a target="_top"
            rel="noopener noreferrer"
            href={`mailto:${quoteDetail.vessel.vesselEmail}?subject=Ship Manager - ${quoteDetail.vessel.vesselName} - ${quoteDetail.quote.customerReference}`}>
            <IconButton edge="end">
                <MailIcon />
            </IconButton>
        </a>
    );
};


export function MailHoldingDetailTo({ vessel }) {
    
    // console.log('MailHoldingDetailTo', vessel);
    return (
        <a target="_top"
            rel="noopener noreferrer"
            href={`mailto:${vessel.vesselEmail}?subject=Ship Manager - ${vessel.vesselName} - Holdings`}>
            <IconButton edge="end">
                <MailIcon />
            </IconButton>
        </a>
    );
};

export default MailTo;
