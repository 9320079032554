import React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

// Universal Views
import BottomNav from './Navigation/BottomNav';
import TopNav from './Navigation/TopNav';
import FloatingMenuButton from './Navigation/FloatingMenuButton';
import MenuCategory from './Navigation/MenuCategory';

// Content Files
///// Account Management
import AccountSettings from './AccountManagement/AccountSettings';

////// Home
import Home from './Home';

////// Help
import Help from './Help';

/////Order Management
import QuotationsApprove from './Sales/Order.QuotationsApprove';
import Catalogue from './Sales/Order.CatalogueSales';
import OrderHistory from './Sales/Order.History';
import ShipmentTracking from './Sales/Order.ShipmentTracking';
import FlatFeeContracts from './Sales/Order.FlatFeeContracts';
import AVCSO from './Sales/AVCSOnline';

/////Fleet Management
import FleetHoldings from './FleetManagement/Fleet.Holdings';
import FleetStatus from './FleetManagement/Fleet.Status';
import NavigatorUpdates from './FleetManagement/Fleet.NavigatorUpdates';
import FleetTracking from './FleetManagement/Fleet.Tracking';
import FleetOverview from './FleetManagement/Fleet.Overview';

//////Folio Management
import Folios from './Folios/Folios';


////// News
import News from './News/News';

////// Image Imports
// import pageNotFoundBG from './Images/OldShip-Fading.jpg';

////// CSS Files
import mainStyles from './CSS/MainStyles.module.css';

////// Load Config Files
import MobileConfig from '../../Global/Data/Config';
// import StatusCodes from '../../Global/Data/StatusCodes';

////// 404 Page
import PageNotFound404 from './404';

// let EG200 = "Hey there";

// console.log(window);
// console.log(window.screen.width+"||"+window.screen.height);
/*
PROJECT:         Chart Track Ship Management System - Mobile Version
PROJECT FILE(S): /Mobile, ../Global
START DATE:      January 23, 2020 : 12:34:20
MODIFIED:        March 26, 2021 : 09:09:10 (Folio Status included)
DEVELOPER(S)     Emmanuel Eshun-Davies, 

NOTES:
- Due to floating button, content must always have -> Bottom: 20% for styling in order to allow reading last content
- Navigation data loop when console logged, seems to print at every click. Possible cause to look into should final app run slow
- Updated Menu.js file to include "to:" parameters to the subItems. 

KNOWN BUGS:
- on the iphone, the input boxes at the top (example case: folio status); the inputs seem to move when touched and scrolled.
  This is unusual because on android, same behavior has not been recorded. might be a Material-UI thing but we will figure out
  a solution

FIXES:
- set the border definitions in Components/Custom/ContentColumn.jsx to 0
- Global/Data/Menu.jsx updated as found from Menu Summary Cards

TODO NOW:
- Password reset and support page

TODO FUTURE:
- Add loading feature so with heavy data, user is not forced to watch a blank white painful endless screen
- Setup error codes and definition file
- Make Bottom Menu Dynamic to a config file.
- To prevent several clicks on the tracking information request, disable button after request click

TODO END OF DEVELOPMENT (Well Development never really ends but end of version :D )
- Detect device and screen size then decide which version of app to show.

STATUS MESSAGES
Status message is a tiny component used to display messages to the user.
StatusMessage is in the Mobile/ Directory with File Name StatusMessage.jsx

It is initiated with value props of
- messageTitle: Title of message that appears at the title of the modal/dailog page
- messageBody: Displays intended body or message
- actionLink: takes the intended URL Link to navigate to when user clicks. (It is likely this is upgraded to take an array of buttons in the future should that be required)
- actionTitle: takes the display value of the button. in the likely case where the button is turned to an array, this must be included. they work together
- showActions: takes bolean (true/false) to display title
eg:
<StatusMessage messageTitle={msgTitle} messageBody={msgBody} actionValue={msgBtnTitle} actionLink={msgBtbLink} showActions={true} showTitle={true}  />

*/

// Component description: Holds the whole app in place. Meet the Mobile Component Display.
const Mobile = () => {

    return (
    <Router>
        <div >
            {/*Top Menu: Displays Main Menu, Active User Full Name, Active User Shipment Company*/}
            <div className={mainStyles.zeroBorder}>
                <TopNav />
            </div>
            {/*Content Area: Will take care of displaying the content area of the view. *May contain other sectors*/}
            <div className={mainStyles.contentAreaDefault} > 
            <br /><br /><br /><br /><br />
                {/* URL remains /login after successful login so I fixed it with this */}
                { (window.location.pathname === "/login") ? <Redirect to="/home?mv" /> : null }
                
                <Switch>
                    <Route exact path={["/", "/home"]} component={Home} />

                    <Route exact path={["/fleet", "folios/", "/sales"]} component={MenuCategory} /> {/* To display categories of each menu when clicked from the home screen or root of module is loaded/called */}
                    
                    <Route path="/sales/quotes" component={QuotationsApprove} />
                    <Route path="/sales/catalogue" component={Catalogue} />
                    <Route path="/sales/orders"  component={OrderHistory} />
                    <Route path="/sales/shipments"  component={ShipmentTracking} />
                    <Route path="/sales/flatfee"  component={FlatFeeContracts} />
                    <Route path="/sales/avcso"  component={AVCSO} />

                    <Route path="/fleet/holdings"  component={FleetHoldings} />
                    <Route path="/fleet/status"  component={FleetStatus} />
                    <Route path="/fleet/navigator"  component={NavigatorUpdates} />
                    {/* <Route path="/fleet/tracking"  component={FleetTracking} /> */}
                    <Route path="/fleet/overview"  component={FleetOverview} />                    

                    <Route path="/folios/status"  component={Folios} />


                    <Route exact path={[ "/account", "/account/profile", "/account/users", "/account/users/create", "/account/users/manage", "/account/users/manage/:id" ]}  component={AccountSettings} />

                    <Route path="/news"  component={News} />

                    {/* <Route exact path={["/help", "/help/usermanual", "/help/faqs", "/help/contactus"]}  component={Help} /> */}

                    <Route path="/help"  component={Help} />

                    <Route component={PageNotFound404} />
                </Switch>
                <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            </div>
            <div>
                <FloatingMenuButton />
            </div>
            {/*Bottom Menu: Holds Bottom Menu with default quick menu items defined per the most likely used menus by users*/} 
            <div className={mainStyles.zeroBorder}>
                <BottomNav />
            </div>
        </div>
    </Router>
    )

}

// This serves as an easy way to turn off/shut down the whole app should there be an issue to deal with.
// Works with the config file found in "Global/"
const MobileState = () => {
    // document.getElementById("hey").innerHTML=navigator;
   if(MobileConfig.Shutdown.Status === true){
        return (
                <div style={{margin: 100}}>
                <h1>{MobileConfig.Shutdown.Header}</h1>
                <p><b>Status Code</b>
                <br />{MobileConfig.Shutdown.Code}</p>
                <p><b>Event</b>
                <br />[Code {MobileConfig.Shutdown.Code}] {MobileConfig.Shutdown.ShortDescription}</p>
                <p><b>Details:</b>
                <br />{MobileConfig.Shutdown.LongDescription}</p>
                <p><b>Resolve:</b>
                <br />{MobileConfig.Shutdown.Resolve}</p>
                <div id="hey">{window.navigator.oscpu}</div>
                </div>
        );
   } else {
       return (
        <Mobile />
       )
   }
    
}

export default MobileState