import React, { useMemo, useState, useEffect } from 'react';
import { FeatureGroup } from 'react-leaflet';
import { useGlobalStore } from '../../../../Global/Stores/globalStore';
import { useAvcsoStore } from '../../../../Global/Stores/avcsoStore';
import ItemPolygon from '../../Polygons/ItemPolygon';
import { checkPolygonInPoint } from '../../MapFunctions';
import CatalogueMapPopup from './CatalogueMapPopup';

const AvcsoItemsLayer = ({ match, history, mapActions }) => {

    const [, globalActions] = useGlobalStore();
    const [avcsoState,] = useAvcsoStore();



    const [popup, setPopup] = useState({
        key: 0,
        position: null,
        lines: []
    });


    const { selectedItemIDs, avcsoLines, licenseLines, productItemGroups } = avcsoState;
    const focusedItemID = +match.params.itemID || 0;


    const loaded = globalActions.isLoaded();

    useEffect(() => {

        if (focusedItemID > 0) {
            const line = avcsoLines.find(X => X.itemID === focusedItemID);
            if (line) {
                // console.log('focusedItemID', focusedItemID, [line]);
                mapActions.zoomTo(line.polygonPoints);
            }
        }

    }, [focusedItemID, loaded, avcsoLines]);


    // console.log('AvcsoItemsLayer', loaded);

    //return null;

    if (!loaded) {
        return null;
    }

    const vesselItemIDs = licenseLines.map(X => X.itemID);
    const mapItems = avcsoLines.filter(X => productItemGroups.includes(X.productItemGroupID));

    const onClick = (e) => {

        // console.log('onClick', e);

        // const mapItems = globalActions.getCatalogueItems(avcsoItemIDs);

        let selected = mapItems.filter(X => X.polygonPoints && checkPolygonInPoint(e.latlng, X.polygonPoints));
        if (selected.length === 0) {
            selected = [e.layer];
        }

        if (selected.length === 1) {
            history.push(`${selected[0].itemID}`);
        }
        else if (selected.length > 0) {

            setPopup(prev => {
                return {
                    key: prev.key + 1,
                    position: e.latlng,
                    lines: selected
                }
            });
        }
    }

    return (
        <React.Fragment>
            <CatalogueMapPopup popupInfo={popup} selectedItemID={0} selectedItemIDs={selectedItemIDs} />
            <FeatureGroup onClick={onClick}>
                {
                    mapItems
                        .map(X => {
                            //const selected = (X.itemID === selectedItemID);
                            const selected = selectedItemIDs.includes(X.itemID);
                            const focused = focusedItemID === X.itemID;
                            const inInventory = vesselItemIDs.includes(X.itemID);

                            //return (<CatalogItemPolygon key={X.itemID} Item={X} Selected={selected} Focused={focused} />)
                            return (
                                <AvcsoItemPolygon key={X.itemID} ItemID={X.itemID} Item={X} Selected={selected} Focused={focused} InInventory={inInventory} />
                            );
                        })
                }
            </FeatureGroup>
        </React.Fragment>
    );
}


const AvcsoItemPolygon = ({ Item, ItemID, Selected, Focused, InInventory }) => {
    const [, globalActions] = useGlobalStore();

    return useMemo(() => {

        //const Item = globalActions.getCatalogueItem(ItemID);
        if (!Item || !Item.polygonPoints) {
            // console.log('Skip Map Item', Item);
            return null;
        }


        // const color = Item.validationID === 1 ? "#1dc71d" : "#ff4a4a";
        // const fillOpacity = Selected === true ? 0.2 : 0.01;
        const color = InInventory ? 'blue' : Selected === true ? "green" : "black";
        // const color = "black";

        return (
            <ItemPolygon
                positions={Item.polygonPoints}
                fillColor={color}
                color={color}
                selected={Selected}
                focused={Focused}
            />);

    }, [ItemID, Selected, Focused, InInventory, globalActions]);
}

export default AvcsoItemsLayer
