import React, { useState, useEffect, useMemo } from 'react';
import ContentColumn from '../../Custom/ContentColumn';
import { IconButton, Typography, Paper, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, Link, Chip, Grid, Divider } from '@material-ui/core';
import Moment from 'react-moment';
import httpService from '../../../../Global/Services/httpService';
import { ChipStatusValid, ChipStatusWarning, ChipStatusIssue } from '../../../Widgets/Status/ChipStatus';
import { Link as RouterLink } from 'react-router-dom';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import GetAppIcon from '@material-ui/icons/GetApp';
import EmailIcon from '@material-ui/icons/Email';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { useGlobalStore } from '../../../../Global/Stores/globalStore';
import _ from 'lodash'
// import CloseButtonLink from '../../../Widgets/Buttons/CloseButtonLink';

// import FloatingMenuButton from '../../Navigation/FloatingMenuButton';

const FleetDetailsVessel = ({ match }) => {

    const [openDialog, setOpenDialog] = React.useState(true);
    
    const closeDialog = () => {
    setOpenDialog(false);
    };

    const vesselID = +match.params.vesselID || 0;
    // const vesselName = 
    if (vesselID <= 0)
        return null;

    return (
        <div>
            <ContentColumn loaded={true}>

                <div className="contentColumnHeader" style={{ padding: '10px 0px', margin: "-10px 0px 0px 0px", width: '100%', backgroundColor: '#fff', zIndex: 1, position: 'absolute' }}>
                    <Grid>
                        <Grid>
                        {/* <CloseButtonLink to={`/fleet/overview/`} /> */}
                        <Link href={`/fleet/overview/`} style={{ color: 'inherit', textDecoration: 'none' }} >
                            <IconButton fontSize="large">
                                <ArrowBack />
                            </IconButton>
                        </Link> 
                        {/* </Grid>
                        <Grid> */}
                    <VesselDetailsHeader VesselID={vesselID} />
                        </Grid>
                    </Grid>
                </div>

                <div className="contentColumnBody" style={{ padding: '0px' }}>
                    <VesselDetails VesselID={vesselID} />
                </div>

            </ContentColumn>
            {/* <FloatingMenuButton /> */}
        </div>
    );
}

const VesselDetailsHeader = ({ VesselID }) => {
    const [globalState, globalActions] = useGlobalStore();

    const { vessels } = globalState;
    return useMemo(() => {
        const vessel = globalActions.findVessel(VesselID) || {};
        return (<Typography align="center" variant="h6">{vessel.vesselName}<Divider /></Typography>);
    }, [VesselID, vessels]);
}

const VesselDetails = ({ VesselID }) => {

    return (
        <Paper elevation={0} square>
            <VesselGeneralInfoTable VesselID={VesselID} />
            <VesselDocumentsTable VesselID={VesselID} />
            <VesselSubscriptionsTable VesselID={VesselID} />
            <VesselShipmentHistory VesselID={VesselID} />
            <VesselApprovelsTable VesselID={VesselID} />
            <VesselHoldingStatusTable VesselID={VesselID} />
            <VesselOrderHistory VesselID={VesselID} />
            <VesselFlatFeeHistory VesselID={VesselID} />
        </Paper>
    );
}

const VesselGeneralInfoTable = ({ VesselID }) => {
    const [open, setOpen] = useState(false);
    const [details, setDetails] = useState(null);

    useEffect(() => {

        httpService.get(`/api/fleet/general/${VesselID}`)
            .then(response => {
                const data = response.data;
                setDetails(() => data);
            })
            .catch(error => {
                //alert(error);
            });

    }, [VesselID]);

    const Title = "GENERAL INFORMATION";

    return (
        <Table size="small" >
            <TableHead className="clickable" onClick={() => setOpen(O => !O)}>
                <TableRow>
                    <TableCell className="ctcolors" colSpan={2} align="center">{Title}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    open && details && (
                        <React.Fragment>
                            <TableRow hover>
                                <TableCell variant="head"  >e-mail:</TableCell>
                                <TableCell >
                                    <LinkTo href={`mailto:${details.vesselEmail}?subject=Ship Manager`} text={details.vesselEmail} />
                                </TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell variant="head"  >IMO:</TableCell>
                                <TableCell >{details.imo || 'N/A'}</TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell variant="head">Manager:</TableCell>
                                <TableCell >{details.manager}</TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell variant="head"  >Responsible:</TableCell>
                                <TableCell >
                                    <LinkTo
                                        href={`mailto:${details.responsibleEmail}?subject=Ship Manager - ${details.vesselName}`}
                                        text={details.responsible} />
                                </TableCell>
                            </TableRow>
                            <TableRow hover >
                                <TableCell variant="head"  >Chart Agent:</TableCell>
                                <TableCell >{details.chartagent}</TableCell>
                            </TableRow>
                        </React.Fragment>)
                }

            </TableBody>
        </Table>
    );
}

const VesselDocumentsTable = ({ VesselID }) => {

    const [open, setOpen] = useState(false);
    const [details, setDetails] = useState(null);

    useEffect(() => {

        httpService.get(`/api/fleet/documents/${VesselID}`)
            .then(response => {
                const data = response.data;
                setDetails(() => data);
            })
            .catch(error => {

            });

    }, [VesselID]);

    const Title = "LICENSES/CERTIFICATES";
    //const titleFull = details ? `${Title} [${1 + +details.avcsPermit + +details.navigator}]` : `${Title}`;
    const count = details && <CountChip Object={null} ObjectLink="" Count={(1 + +details.avcsPermit + +details.navigator)} />;
    return (
        <Table size="small" >
            <TableHead className="clickable" onClick={() => setOpen(O => !O)}>
                <TableRow>
                    <TableCell className="ctcolors" colSpan={2} align="center">
                        {Title}&nbsp;{count}
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    open && details && (
                        <React.Fragment>
                            {
                                details.avcsPermit && (
                                    <TableRow hover >
                                        <TableCell variant="head" >AVCS permit:</TableCell>
                                        <TableCell >
                                            <LinkTo icon={<GetAppIcon fontSize="small" />} href={httpService.getVesselAvcsPermitLink(VesselID)} />
                                        </TableCell>
                                    </TableRow>
                                )}

                            <TableRow hover >
                                <TableCell variant="head"  >Certificate of Enrollment:</TableCell>
                                <TableCell >
                                    <LinkTo icon={<GetAppIcon fontSize="small" />} href={httpService.getVesselCertificateLink(VesselID)} />
                                    &nbsp;
                                    <SendVesselCertificateIcon VesselID={VesselID} />
                                </TableCell>
                            </TableRow>

                            {
                                details.navigator && (
                                    <TableRow hover >
                                        <TableCell variant="head"  >Navigator License:</TableCell>
                                        <TableCell >
                                            <LinkTo icon={<GetAppIcon fontSize="small" />} href={httpService.getVesselNavigatorLicenseLink(VesselID)} />
                                        </TableCell>
                                    </TableRow>
                                )}
                        </React.Fragment>
                    )
                }

            </TableBody>
        </Table>
    );
}

const SendVesselCertificateIcon = ({ VesselID }) => {

    const handleMailCertificate = () => {
        // console.log('handleMailCertificate', VesselID);

        httpService.get(`/api/file/enrollment/${VesselID}/mail`)
            .then(response => { })
            .catch(error => { });
    }

    return (<EmailIcon onClick={handleMailCertificate} fontSize="small" style={{ color: 'rgb(4, 153, 212)', cursor: 'pointer' }} />);
}


const VesselSubscriptionsTable = ({ VesselID }) => {

    const [open, setOpen] = useState(false);
    const [details, setDetails] = useState(null);

    useEffect(() => {
        setDetails(() => null);

        httpService.get(`/api/fleet/subscriptions/${VesselID}`)
            .then(response => {
                const data = response.data;
                // console.log('general', data);
                setDetails(() => data);
            })
            .catch(error => {
                //alert(error);
            });

    }, [VesselID]);


    const Title = "SUBSCRIPTIONS";
    //const titleFull = details ? `${Title} [${details.length}]` : `${Title}`;
    const count = <CountChip object={details} objectLink="length" />;

    return (
        <Table size="small" >
            <TableHead className="clickable" onClick={() => setOpen(O => !O)}>
                <TableRow>
                    <TableCell className="ctcolors" colSpan={3} align="center">
                        {Title}&nbsp;{count}
                    </TableCell>
                </TableRow>

                {open && details && details.length > 0 && (
                    <TableRow>
                        <TableCell  >Subscription</TableCell>
                        <TableCell  >Until</TableCell>
                        <TableCell align="center" >Paused</TableCell>
                    </TableRow>
                )}
            </TableHead>
            <TableBody>

                {
                    open && details && details.map(X => (
                        <TableRow hover key={X.subscriptionID}>
                            <TableCell >{X.subscriptionService}</TableCell>
                            <TableCell padding="none"><Moment date={X.paidUntil} format="LL" /></TableCell>
                            <TableCell padding="checkbox" align="center" ><Checkbox disabled checked={X.paused} /></TableCell>
                        </TableRow>))
                }


                {
                    open && details && details.length === 0 && (
                        <TableRow>
                            <TableCell variant="head" align="center" colSpan={3}>- There are no Subscriptions -</TableCell>
                        </TableRow>
                    )
                }


            </TableBody>
        </Table>
    );
}

const VesselShipmentHistory = ({ VesselID }) => {

    const [open, setOpen] = useState(false);
    const [details, setDetails] = useState(null);

    useEffect(() => {
        setDetails(() => null);

        httpService.get(`/api/fleet/shipments/${VesselID}`)
            .then(response => {
                const data = _.orderBy(response.data, X => -X.salesShipmentID);
                // console.table(data);
                setDetails(() => data);
            })
            .catch(error => {
                //alert(error);
            });

    }, [VesselID]);

    const Title = "SHIPMENT HISTORY";
    //const titleFull = details ? `${Title} [${details.length}]` : `${Title}`;
    const count = <CountChip object={details} objectLink="length" />;

    return (
        <Table size="small" >
            <TableHead className="clickable" onClick={() => setOpen(O => !O)}>
                <TableRow>
                    <TableCell colSpan={5} className="ctcolors" align="center">{Title}&nbsp;{count}</TableCell>
                </TableRow>

                {open && details && details.length > 0 && (
                    <TableRow>
                        <TableCell padding="none" >Delivery</TableCell>
                        <TableCell padding="none" >Shipment</TableCell>
                        <TableCell padding="none" align="center" >Status</TableCell>
                        <TableCell padding="none" align="center"  >Link</TableCell>
                    </TableRow>
                )}
            </TableHead>
            <TableBody>
                {
                    open && details && details.map((X, index) => (
                        <TableRow key={X.salesShipmentID} className={index % 2 ? "ctTableRow" : ""} hover>
                            <TableCell padding="none"> <Moment date={X.deliveryDate} format="YYYY-MM-DD" /> </TableCell>
                            <TableCell padding="none">{X.reference}</TableCell>
                            <TableCell padding="none" align="center" >{X.salesShipmentStatus}</TableCell>
                            {/* <TableCell padding="none" align="right" >{X.totalPrice} {X.currency}</TableCell> */}
                            <TableCell padding="none" align="center" >

                                <Link component={RouterLink} to={`/sales/shipments/${X.salesShipmentID}`} style={{ textDecoration: 'none' }} >
                                    <ArrowForwardIcon fontSize="small" />
                                </Link>
                            </TableCell>
                        </TableRow>
                    ))
                }


                {
                    open && details && details.length === 0 && (
                        <TableRow>
                            <TableCell variant="head" align="center" colSpan={4}>- There are no Shipments -</TableCell>
                        </TableRow>
                    )
                }


            </TableBody>
        </Table>
    );
}

const VesselApprovelsTable = ({ VesselID }) => {

    const [open, setOpen] = useState(false);
    const [details, setDetails] = useState([]);

    useEffect(() => {
        setDetails(() => null);

        httpService.get(`/api/fleet/approvals/${VesselID}`)
            .then(response => {
                const data = response.data;
                // console.log('general', data);
                setDetails(() => data);
            })
            .catch(error => {
                //alert(error);
            });

    }, [VesselID]);

    const Title = "QUOTATIONS TO APPROVE";
    //const titleFull = details ? `${Title} [${details.length}]` : `${Title}`;
    const count = <CountChip object={details} objectLink="length" />;

    return (
        <Table size="small" >
            <TableHead className="clickable" onClick={() => setOpen(O => !O)}>
                <TableRow>
                    <TableCell colSpan={5} className="ctcolors" align="center">{Title}&nbsp;{count}</TableCell>
                </TableRow>
                {
                    open && details && details.length > 0 && (
                        <TableRow>
                            <TableCell padding="none" >Expires</TableCell>
                            <TableCell padding="none" >Quote</TableCell>
                            <TableCell padding="none" align="center" >Qty</TableCell>
                            <TableCell padding="none" align="center"  >Price</TableCell>
                            <TableCell padding="none" align="center"  >Link</TableCell>
                        </TableRow>
                    )}
            </TableHead>
            <TableBody>
                {
                    open && details && details.map((X, index) => (
                        <TableRow key={X.salesQuoteID} className={index % 2 ? "ctTableRow" : ""}>
                            <TableCell padding="none"> <Moment date={X.expiryDate} format="YYYY-MM-DD" /> </TableCell>
                            <TableCell padding="none">{X.reference}</TableCell>
                            <TableCell padding="none" align="center" >{X.totalQuantity}</TableCell>
                            <TableCell padding="none" align="right" >{X.totalPrice} {X.currency}</TableCell>
                            <TableCell padding="none" align="center" >
                                <Link component={RouterLink} to={`/sales/quotes/${X.salesQuoteID}`} style={{ textDecoration: 'none' }} >
                                    <ArrowForwardIcon fontSize="small" />
                                </Link>
                            </TableCell>
                        </TableRow>
                    ))
                }

                {
                    open && details && details.length === 0 && (
                        <TableRow>
                            <TableCell variant="head" align="center" colSpan={5}>- There are no Approvals -</TableCell>
                        </TableRow>
                    )
                }


            </TableBody>
        </Table>);

}

const VesselFlatFeeHistory = ({ VesselID }) => {

    const [open, setOpen] = useState(false);
    const [details, setDetails] = useState(null);

    useEffect(() => {
        setDetails(() => null);

        httpService.get(`/api/fleet/flatfees/${VesselID}`)
            .then(response => {
                const data = _.orderBy(response.data, X => -X.salesShipmentID);
                // console.table(data);
                setDetails(() => data);
            })
            .catch(error => {
                //alert(error);
            });

    }, [VesselID]);

    const Title = "FLAT FEE CONTRACTS";
    //const titleFull = details ? `${Title} [${details.length}]` : `${Title}`;
    const count = <CountChip object={details} objectLink="length" />;

    return (
        <Table size="small" >
            <TableHead className="clickable" onClick={() => setOpen(O => !O)}>
                <TableRow>
                    <TableCell colSpan={5} className="ctcolors" align="center">{Title}&nbsp;{count}</TableCell>
                </TableRow>

                {open && details && details.length > 0 && (
                    <TableRow>
                        <TableCell padding="none" >Products</TableCell>
                        <TableCell padding="none" >End Date</TableCell>
                        <TableCell padding="none" align="center"  >Price</TableCell>
                        <TableCell padding="none" align="center"  >Link</TableCell>
                    </TableRow>
                )}
            </TableHead>
            <TableBody>
                {
                    open && details && details.map((X, index) => (
                        <TableRow key={X.vesselFlatFeePeriodID} className={index % 2 ? "ctTableRow" : ""} hover>
                            <TableCell padding="none" align="left" >{X.productGroups}</TableCell>
                            <TableCell padding="none"><Moment date={X.endDate} format="YYYY-MM-DD" /></TableCell>
                            <TableCell padding="none" align="right" >{X.price} {X.currency}</TableCell>
                            <TableCell padding="none" align="center" >
                                <Link component={RouterLink} to={`/sales/flatfee/${X.vesselFlatFeePeriodID}`} style={{ textDecoration: 'none' }} >
                                    <ArrowForwardIcon fontSize="small" />
                                </Link>
                            </TableCell>
                        </TableRow>
                    ))
                }

                {
                    open && details && details.length === 0 && (
                        <TableRow>
                            <TableCell variant="head" align="center" colSpan={4}>- There are no Flat Fee contracts -</TableCell>
                        </TableRow>
                    )
                }
            </TableBody>
        </Table>
    );
}

const VesselOrderHistory = ({ VesselID }) => {

    const [open, setOpen] = useState(false);
    const [details, setDetails] = useState(null);

    useEffect(() => {
        setDetails(() => null);

        httpService.get(`/api/fleet/orders/${VesselID}`)
            .then(response => {
                const data = _.orderBy(response.data, X => -X.salesOrderID);
                setDetails(() => data);
            })
            .catch(error => {
                //alert(error);
            });

    }, [VesselID]);


    const Title = "ORDER HISTORY";
    //const titleFull = details ? `${Title} [${details.length}]` : `${Title}`;
    const count = <CountChip object={details} objectLink="length" />;

    return (
        <Table size="small" >
            <TableHead className="clickable" onClick={() => setOpen(O => !O)}>
                <TableRow>
                    <TableCell colSpan={5} className="ctcolors" align="center">{Title}&nbsp;{count}</TableCell>
                </TableRow>

                {open && details && details.length > 0 && (
                    <TableRow>
                        <TableCell padding="none" >Ordered</TableCell>
                        <TableCell padding="none" >Order</TableCell>
                        <TableCell padding="none" align="center" >Qty</TableCell>
                        <TableCell padding="none" align="center"  >Price</TableCell>
                        <TableCell padding="none" align="center"  >Link</TableCell>
                    </TableRow>
                )}
            </TableHead>
            <TableBody>
                {
                    open && details && details.map((X, index) => (
                        <TableRow key={X.salesOrderID} className={index % 2 ? "ctTableRow" : ""} hover>
                            <TableCell padding="none"> <Moment date={X.ordered} format="YYYY-MM-DD" /> </TableCell>
                            <TableCell padding="none">{X.reference}</TableCell>
                            <TableCell padding="none" align="center" >{X.totalQuantity}</TableCell>
                            <TableCell padding="none" align="right" >{X.totalPrice} {X.currency}</TableCell>
                            <TableCell padding="none" align="center" >

                                <Link component={RouterLink} to={`/sales/orders/${X.salesOrderID}`} style={{ textDecoration: 'none' }} >
                                    <ArrowForwardIcon fontSize="small" />
                                </Link>
                            </TableCell>
                        </TableRow>
                    ))
                }


                {
                    open && details && details.length === 0 && (
                        <TableRow>
                            <TableCell variant="head" align="center" colSpan={5}>- There are no Orders -</TableCell>
                        </TableRow>
                    )
                }
            </TableBody>
        </Table>
    );
}

const VesselHoldingStatusTable = ({ VesselID }) => {

    const [open, setOpen] = useState(false);
    const [details, setDetails] = useState(null);

    useEffect(() => {
        setDetails(() => null);

        httpService.get(`/api/fleet/holdings/${VesselID}/status`)
            .then(response => {
                const data = response.data;
                // console.log('holdings status', data);
                setDetails(() => data);
            })
            .catch(error => {
                //alert(error);
            });

    }, [VesselID]);

    const Title = "INVENTORY STATUS";
    //const titleFull = details ? `${Title} [${details.productGroupStatus.length}]` : `${Title}`;
    const count = <CountChip object={details} objectLink="productGroupStatus.length" />;

    return (
        <Table size="small" >
            <TableHead className="clickable" onClick={() => setOpen(O => !O)}>
                <TableRow>
                    <TableCell colSpan={5} className="ctcolors" align="center">{Title}&nbsp;{count}</TableCell>
                </TableRow>

                {open && details && details.productGroupStatus.length > 0 && (
                    <TableRow>
                        <TableCell padding="none" >Product</TableCell>
                        <TableCell padding="none" align="center" >OK</TableCell>
                        <TableCell padding="none" align="center" >Edition</TableCell>
                        <TableCell padding="none" align="center" >Correction</TableCell>
                    </TableRow>

                )}
            </TableHead>
            <TableBody>

                {
                    open && details && details.productGroupStatus.map((X, index) => (
                        <TableRow key={`V${X.vesselID}PG${X.productGroupID}`} className={index % 2 ? "ctTableRow" : ""}>
                            <TableCell padding="none">{details.productGroups.find(P => P.productGroupID === X.productGroupID).caption}</TableCell>
                            <TableCell padding="none" align="center" >
                                <ChipStatusValid number={X.itemCount} />
                            </TableCell>
                            <TableCell padding="none" align="center" >
                                <ChipStatusWarning number={X.itemWarningCount} />
                                <ChipStatusIssue number={X.itemIssueCount} /></TableCell>
                            <TableCell padding="none" align="center" >
                                <ChipStatusWarning number={X.updateWarningCount} />
                                <ChipStatusIssue number={X.updateIssueCount} /></TableCell>
                        </TableRow>
                    ))
                }

                {
                    open && details && details.productGroupStatus.length === 0 && (
                        <TableRow>
                            <TableCell variant="head" align="center" colSpan={4}>- There are no Holdings -</TableCell>
                        </TableRow>
                    )
                }

            </TableBody>
        </Table>
    );

}


const CountChip = ({ object, objectLink, Count = -1 }) => {


    const count = _.get(object, objectLink, Count);

    if (count < 0) return null;

    return (<Chip
        variant="outlined"
        color="primary"
        size="small"
        label={count}
        style={{ background: '#eee' }}
    />);
}

const LinkTo = ({ text, icon, href }) => {

    return (
        <a target="_blank" rel="noopener noreferrer" href={href} style={{ color: '#0499d4' }}>
            {text}
            {icon}
        </a>);
}


export default FleetDetailsVessel
