import React, { useMemo } from 'react';
import { Box, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ListItemLink from '../../Misc/ListItemLink';

const useStyles = makeStyles({
    root: {
        border: '5px solid #e9edf7',
        width: '100%',
        minWidth: '260px',
        maxWidth: '360px',
        padding: '15px',
        margin: '10px',
        alignSelf: 'flex-start'
    },
});

const SummaryCard = ({ children }) => {
    const classes = useStyles();

    return (
        <Box className={classes.root} borderRadius={16}>
            {children}
        </Box>
    );
};


export const SummaryCardLine = ({ text, subText, to, numberSmall, selected }) => {

    return useMemo(() => {

        return (
            <React.Fragment>
                <Divider />
                <ListItemLink text={text} subText={subText} to={to} selected={selected} numberSmall={numberSmall} isHeader={false} />
            </React.Fragment>
        )
    }, [text, subText, to, numberSmall, selected]);
}

export default SummaryCard;
