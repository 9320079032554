import React, { useEffect } from 'react';
import { Route } from "react-router-dom";
import MapControl from '../../MapControl/MapControl';
import FleetOverview from './FleetOverview';
import Holdings from './Holdings/Holdings';
import VesselHoldingsLineDetail from './Holdings/VesselHoldingsLineDetail/VesselHoldingsLineDetail';
import VesselHoldingsDetail from './Holdings/VesselHoldingsDetail/VesselHoldingsDetail';
import FleetStatus from './FleetStatus/FleetStatus';
import { useFleetStore } from '../../../Global/Stores/fleetStore';
import FleetStatusDetail from './FleetStatus/FleetStatusDetail';
import Tracking from './Tracking/Tracking';
import NavigatorUpdates from './Navigator/Navigator';
import NavigatorUpdateList from './Navigator/NavigatorUpdateList';
import FleetDetails from './Details/FleetDetails';
import FleetDetailsVessel from './Details/FleetDetailsVessel';
//import ViewFolios from './Folios/Folio';
//import VesselFolio from './Folios/ListFolios/VesselFolios';

const Fleet = () => {

    const [, fleetActions] = useFleetStore();

    useEffect(
        () => {
            fleetActions.getHoldingsOverview();
            fleetActions.getFleetPositions();
        }, []
    );

    return (
        <div className="fleetContainer">
            <Route exact path="/fleet/" component={FleetOverview} />
            <Route exact path="/fleet/holdings/:vesselID?" component={Holdings} />

            {/* <Route exact path="/fleet/orders" component={FleetOverview} />
            <Route path="/fleet/orders/:id?" component={OrderDetailList} /> */}
            <Route path="/fleet/holdings/:vesselID/:itemID?" component={VesselHoldingsDetail} />
            <Route exact path="/fleet/holdings/:vesselID/:itemID" component={VesselHoldingsLineDetail} />

            
            <Route exact path="/fleet/catalogue" component={FleetOverview} />
            <Route exact path="/fleet/shipments" component={FleetOverview} />

            <Route path="/fleet/status/:vesselID?" component={FleetStatus} />
            <Route path="/fleet/status/:vesselID/:itemID?" component={FleetStatusDetail} />


            {/* <Route path="/fleet/tracking/:vesselID?" component={Tracking} /> */}


            <Route path="/fleet/navigator/:vesselID?" component={NavigatorUpdates} />
            <Route path="/fleet/navigator/:vesselID" component={NavigatorUpdateList} />

            <Route path="/fleet/overview/:vesselID?" component={FleetDetails} />
            <Route path="/fleet/overview/:vesselID" component={FleetDetailsVessel} />

            <MapControl />
        </div>);
};

export default Fleet;
