import React, { useMemo, useEffect, useState } from 'react';
import { FeatureGroup } from 'react-leaflet';
import { useSaleStore } from '../../../Global/Stores/saleStore';
import SalesOrderLinePolygon from '../Polygons/SalesOrderLinePolygon';
import { checkPolygonInPoint } from '../MapFunctions';
import MapPopupSalesOrderLines from '../Popup/MapPopupSalesOrderLines';
import GeneralRoutesLayer from './Catalogue/GeneralRoutesLayer';
import MapPopupPurchaseOrderLines from '../Popup/MapPopupPurchaseOrderLines';
import ItemPolygon from '../Polygons/ItemPolygon';
import WorldAreaLayer from './WorldAreaLayer';
import _ from 'lodash';
import { useMapStore } from '../../../Global/Stores/mapStore';

// const PurchaseOrderLayer = ({ match, history, mapActions }) => {
const PurchaseOrderLayer = ({ purchaseOrder, selectedLineID, history }) => {
    const [, mapActions] = useMapStore();
    // const [saleState] = useSaleStore();

    // const flatFeeID = +match.params.id || 0;
    // const orderID = +match.params.orderID || 0;
    // const selectedLineID = +match.params.lineID || 0;



    // const vesselFlatFeePeriodID = _.get(saleState, 'flatFeeOrderDetail.header.vesselFlatFeePeriodID', -1);
    // const purchaseOrderID = _.get(saleState, 'flatFeeOrderDetail.header.purchaseOrderID', -1);

    // const loaded = (purchaseOrderID === orderID);

    const [popup, setPopup] = useState({
        key: 0,
        position: null,
        lines: []
    });

    // Zoom to order
    useEffect(() => {
        if (purchaseOrder) {
            mapActions.zoomToLines(purchaseOrder.lines);
        }
    }, [purchaseOrder]);

    // // Zoom to line
    // useEffect(() => {
    //     if (orderID > 0 && selectedLineID > 0) {
    //         const line = saleState.flatFeeOrderDetail.lines.find(X => X.purchaseOrderLineID === selectedLineID);
    //         mapActions.zoomToLines(line);
    //     }
    // }, [selectedLineID]);


    return useMemo(() => {

        if (!purchaseOrder)
            return null;

        // console.log('selectedLineID', selectedLineID);


        // console.log('flatFeeOrderDetail', saleState.flatFeeOrderDetail);
        const purchaseOrderID = purchaseOrder.header.purchaseOrderID;
        const vesselFlatFeePeriodID = purchaseOrder.header.vesselFlatFeePeriodID;
        const lines = purchaseOrder.lines;
        const routes = purchaseOrder.routes;

        const mapLines = lines.filter(X => X.item.polygonPoints);
        const sortedLines = _.orderBy(mapLines, ['item.scale'], ['desc']);


        const onClick = (e) => {
            let selected = lines.filter(X => X.item.polygonPoints && checkPolygonInPoint(e.latlng, X.item.polygonPoints));
            if (selected.length === 0)
                selected = e.layer;

            if (selected.length === 1) {

                // const url = history.location.pathname;
                // const popLink = url.substring(0, url.lastIndexOf("/"));
                // const to = `${popLink}/${selected[0].purchaseOrderLineID}`;

                // console.log('url', url);
                // console.log('to', to);
                // history.push(to);

                history.push(`/sales/flatfee/${vesselFlatFeePeriodID}/${purchaseOrderID}/${selected[0].purchaseOrderLineID}`);


            }
            else if (selected.length > 0) {

                setPopup(prev => {
                    return {
                        key: prev.key + 1,
                        position: e.latlng,
                        lines: selected
                    }
                });
            }
        }

        return (
            <React.Fragment>
                <MapPopupPurchaseOrderLines popupInfo={popup} vesselFlatFeePeriodID={vesselFlatFeePeriodID} />

                <FeatureGroup onClick={onClick}>
                    {
                        sortedLines.map(X => <PurchaseOrderLinePolygon
                            key={X.item.itemID}
                            line={X}
                            selected={selectedLineID === X.purchaseOrderLineID}
                        />)
                    }
                </FeatureGroup>

                <GeneralRoutesLayer Routes={routes} />

            </React.Fragment>
        );

    }, [purchaseOrder, selectedLineID, popup])
};

const PurchaseOrderLinePolygon = ({ line, selected }) => {

    return useMemo(() => {

        if (!line.item.polygonPoints)
            return null;

        const positions = line.item.polygonPoints;
        const color = "black";

        return (
            <ItemPolygon
                positions={positions}
                fillColor={color}
                color={color}
                selected={selected}
            />
        );

    }, [line.itemID, selected]);
};

export default PurchaseOrderLayer;
