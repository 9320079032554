import React from 'react'
import { Button, IconButton } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
// const UploadButton = () => {
//     return (
//         <div>

//         </div>
//     )
// }


const UploadButton = ({ text, FileSelected, accept }) => {
    // return (
    //     <IconButton
    //         variant="contained"
    //         color="default"
    //     >
    //         <PublishIcon />
    //         <input type="file" accept={accept} style={{ display: "none" }} onChange={(e) => {
    //             const val = e.target.files;
    //             FileSelected(val);
    //         }} />
    //     </IconButton>
    // );


    return (
        <Button
            variant="outlined"
            component="label"

            style={{ minWidth: '40px', padding: '5px' }}
        >


            <PublishIcon />  <input type="file" accept={accept} style={{ display: "none" }} onChange={(e) => {
                const val = e.target.files;
                FileSelected(val);
            }} />
        </Button>
    );


    // return (
    //     <Button
    //         variant="outlined"
    //         component="label"

    //         style={{ display: 'block', marginTop: '4px', textAlign: 'center' }}
    //     >


    //         {text}  <input type="file" accept={accept} style={{ display: "none" }} onChange={(e) => {
    //             const val = e.target.files;
    //             FileSelected(val);
    //         }} />
    //     </Button>
    // );

}

export default UploadButton
