import React, { useEffect, useMemo, useState } from 'react';
import { List, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction, Box, IconButton, Divider, Collapse, Link, SvgIcon } from '@material-ui/core';
import { useSaleStore } from '../../../../../Global/Stores/saleStore';
import BackIcon from '@material-ui/icons/ArrowBack';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Loading from '../../../Misc/Loading';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';
import { useGlobalStore } from '../../../../../Global/Stores/globalStore';
import { OpenShipment } from '../../../Misc/DownloadFile';
import ContentColumn from '../../../../Custom/ContentColumn';

const VesselShipmentDetail = ({ history, match }) => {
    const [saleState, saleActions] = useSaleStore();

    const salesShipmentID = +match.params.id || 0;
    const selectedID = +match.params.lineID || 0;

    // const shipment = (saleState.vesselShipments || []).find(X => X.salesShipmentID === salesShipmentID);


    // const loaded = Boolean(saleState.shipmentDetail) && saleState.shipmentDetail;

    const loaded = +_.get(saleState, 'shipmentDetail.header.salesShipmentID', -1) === salesShipmentID;

    const detail = loaded ? saleState.shipmentDetail : null;

    // console.log('shipment', detail, loaded);

    useEffect(() => {
        if (salesShipmentID > 0) {
            saleActions.getVesselShipment(salesShipmentID);
        }
    }, [salesShipmentID]);

    // const content = saleState.orderDetail ? <OrderDetailList orderDetail={saleState.orderDetail} selectedLineID={selectedLineID} /> : <Loading />;

    return (
        <ContentColumn loaded={loaded}>
            {
                loaded ? (
                    <React.Fragment>
                        <div className="contentColumnHeader">
                            <ShipmentDetailHeader shipmentHeader={detail.header} history={history} />
                        </div>

                        {/* <div className="contentColumnFilter">
                            contentColumnFilter
                        </div> */}

                        <div className="contentColumnBody">
                            <ShipmentLineList shipmentDetail={detail} selectedID={selectedID} />
                        </div>

                        {/* <div className="contentColumnFooter">contentColumnFooter</div> */}
                    </React.Fragment>
                ) : null
            }
        </ContentColumn>
    );
};

// Header
const ShipmentDetailHeader = ({ shipmentHeader, history }) => {


    return useMemo(() => {

        if (!shipmentHeader) return null;

        const handleBack = () => {
            const url = history.location.pathname;
            const popLink = url.substring(0, url.lastIndexOf("/"));
            history.push(popLink);
        }

        const { reference, customerReference, trackingLink, shippingDate } = shipmentHeader;
        const hasTracking = Boolean(trackingLink);

        return (
            <React.Fragment>

                <IconButton onClick={handleBack}>
                    <BackIcon />
                </IconButton>

                <ListItemText
                    className="flexFill"
                    primary={`${reference}`}
                    secondary={`${customerReference}`}>
                </ListItemText>


                <OpenShipment
                    disabled={!hasTracking}
                    to={trackingLink}
                />

                {/* 
                <IconButton onClick={openShipment}>
                    <SvgIcon>
                        <path
                            d="M3,4A2,2 0 0,0 1,6V17H3A3,3 0 0,0 6,20A3,3 0 0,0 9,17H15A3,3 0 0,0 18,20A3,3 0 0,0 21,17H23V12L20,8H17V4M10,6L14,10L10,14V11H4V9H10M17,9.5H19.5L21.47,12H17M6,15.5A1.5,1.5 0 0,1 7.5,17A1.5,1.5 0 0,1 6,18.5A1.5,1.5 0 0,1 4.5,17A1.5,1.5 0 0,1 6,15.5M18,15.5A1.5,1.5 0 0,1 19.5,17A1.5,1.5 0 0,1 18,18.5A1.5,1.5 0 0,1 16.5,17A1.5,1.5 0 0,1 18,15.5Z" />
                    </SvgIcon>
                </IconButton> */}

                <Divider />
            </React.Fragment>
        );
    }, [shipmentHeader]);
};

// Lines
const ShipmentLineList = ({ shipmentDetail, selectedID }) => {
    const [globalState,] = useGlobalStore();
    const [closedGroups, setClosedGroups] = useState([]);

    if (!globalState.productGroups)
        return null;



    const toggleProductGroup = (pgid) => {
        setClosedGroups(prev => {
            const newGroups = prev.includes(pgid) ? prev.filter(X => X !== pgid) : [...prev, pgid];
            return newGroups;
        })
    }

    // console.log('shipmentDetail.lines', shipmentDetail.lines);
    const sortedLines = _.orderBy(shipmentDetail.lines, [I => +(I.item.itemNumber || "").toLowerCase()]);
    return (
        <List className="ListItemHeader" disablePadding>
            {
                globalState.productGroups.map(PG => (
                    <ShipmentProductGroupHeader
                        key={PG.productGroupID}
                        productGroup={PG}
                        salesShipmentID={shipmentDetail.header.salesShipmentID}
                        lines={sortedLines.filter(X => X.item.productGroupID === PG.productGroupID)}
                        selectedID={selectedID}
                        toggleProductGroup={toggleProductGroup}
                        open={!closedGroups.includes(PG.productGroupID)}
                    />
                ))
            }
        </List>
    );
}

// ListHeader
const ShipmentProductGroupHeader = ({ productGroup, salesShipmentID, lines, selectedID, toggleProductGroup, open }) => {
    // const open = true;
    const hasLines = lines.length > 0;

    return useMemo(() => {

        // console.log('ShipmentProductGroupHeader', lines);
        return (
            <React.Fragment>
                {
                    hasLines ? (
                        <React.Fragment>
                            <ListItem button divider selected={open} onClick={() => toggleProductGroup(productGroup.productGroupID)}>
                                <ListItemIcon>
                                    <SendIcon />
                                </ListItemIcon>

                                <ListItemText primary={productGroup.caption} />
                                <ListItemText style={{ flexGrow: '0' }} primary={`${lines.length} Items`} />

                                <ListItemSecondaryAction>
                                    {open ? <ExpandLess /> : <ExpandMore />}
                                </ListItemSecondaryAction>
                            </ListItem>

                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {
                                        lines.map(X => <ShipmentLine
                                            key={X.item.itemID}
                                            salesShipmentID={salesShipmentID}
                                            line={X}
                                            selected={selectedID === X.salesShipmentLineID} />)
                                    }
                                </List>
                            </Collapse>
                        </React.Fragment>
                    ) : null
                }

            </React.Fragment>
        );

    }, [salesShipmentID, open, selectedID]);
}

const ShipmentLine = ({ salesShipmentID, line, selected }) => {

    return useMemo(() => {

        // console.log('ShipmentLine');

        const to = `/sales/shipments/${salesShipmentID}/${line.salesShipmentLineID}`;
        return (
            <Link className="ListItemLink" component={RouterLink} to={to} color='inherit' style={{ textDecoration: 'none' }}>
                <ListItem button disableRipple dense divider selected={selected}>
                    {/* <HoldingLineStatus line={line} /> */}
                    <ListItemText primary={line.item.itemNumber} secondary={line.item.itemTitle} />
                </ListItem>
            </Link>
        );

    }, [salesShipmentID, line.itemID, selected]);
}

export default VesselShipmentDetail;
