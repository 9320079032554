import React, { useEffect, useMemo } from 'react'
import { Route, Link } from "react-router-dom";
import _ from 'lodash';

// Material UI
import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, List, Button, IconButton, ButtonGroup } from '@material-ui/core';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';

// Global Support Files
import { useGlobalStore } from '../../../../Global/Stores/globalStore';
import { useBasketStore } from '../../../../Global/Stores/basketStore';
import ContentColumn from '../../Custom/ContentColumn';
import ListItemLink from '../../Misc/ListItemLink';

import CatalogueBasketReview from './CatalogueBasketReview';
import CatalogueSelectVessel from './CatalogueSelectVessel';
import CatalogueSelectItems from './CatalogueSelectItems';
import CatalogueBasketConfirm from './CatalogueBasketConfirm';


// CSS Files
import salesStyling from '../../CSS/sales.module.css';

const Catalogue = ({ match, history }) => {
    const [globalState] = useGlobalStore();
    const [basketState, basketActions] = useBasketStore();

    const vesselID = +match.params.vesselID || 0;

    const vessels = _.orderBy(globalState.vessels, ['vesselName'], ['asc']);
    const vessel = vessels.find(X => X.vesselID === vesselID) || null;

    const itemsLoaded = Boolean(globalState.catalogue);

    const ordered = _.get(basketState, 'ordered', false);

    useEffect(() => {
        basketActions.setVessel(vesselID);
        return () => basketActions.setVessel(0);
    }, [vesselID]);

    useEffect(() => {
        if (ordered === true) {
            history.push(`/sales/catalogue/`);
        }
    }, [ordered]);


    return (
        <ContentColumn loaded={itemsLoaded} >
            <BasketButtonList Vessel={vessel} />

            
            {/* <SaveManagerBasket /> */}
            {/* <div className="flexFill"></div>
            <div className="contentColumnFooter">
            </div> */}
            {/* <hr /> */}<br /><br />
            <Route exact path="/sales/catalogue/:vesselID?" component={CatalogueSelectVessel} />
            <Route exact path="/sales/catalogue/:vesselID/add/:itemID?" component={CatalogueSelectItems} />
            <Route path="/sales/catalogue/:vesselID/basket/:itemID?" component={CatalogueBasketReview} />
            <Route path="/sales/catalogue/:vesselID/confirm/:itemID?" component={CatalogueBasketConfirm} />
        </ContentColumn>
    );
}


const BasketButtonList = ({ Vessel }) => {

    const showVesselName = Vessel ? `Selected Vessel: ${Vessel.vesselName}` : null;

    return (
        <div
            className={`contentColumnFilter`}
            className={salesStyling.salesMenu}
        >

            <ButtonGroup  variant="contained" color="primary" aria-label="contained primary button group">

                <BasketSelectVesselButton Vessel={Vessel} />
                <BasketAddItemsButton />
                <BasketReviewButton />
                <BasketConfirmButton />
                <SaveManagerBasket />

            </ButtonGroup>
            <br />
            <h1>{showVesselName}</h1>
        </div>
    );
}

const BasketSelectVesselButton = ({ Vessel }) => {
    const [basketState,] = useBasketStore();
    const vesselID = basketState.vesselID;
    const selected = vesselID > 0;
    const text = "Vessels"; //Vessel ? ` ${Vessel.vesselName}` : `Vessel`;

    return (
        <React.Fragment>
        <BasketButton
            Text={text}
            To={`/sales/catalogue/${vesselID}`}
            Location={`/sales/catalogue/${vesselID}`}
            Disabled={false}
            Selected={selected}
            Done={selected}
        />
        </React.Fragment>
        );
}

const BasketAddItemsButton = () => {
    const [basketState,] = useBasketStore();
    const vesselID = basketState.vesselID;
    const selected = basketState.basketLines.length > 0;
    const disabled = (vesselID === 0);

    return useMemo(() => {
        return (
            <React.Fragment>
            <BasketButton
                Text="Add"
                To={`/sales/catalogue/${vesselID}/add/`}
                Location={`/sales/catalogue/${vesselID}/add/`}
                Disabled={disabled}
                Selected={selected}
                Done={selected}
            />
            </React.Fragment>
            );

    }, [vesselID, disabled, selected]);
}


const BasketReviewButton = () => {
    const [basketState, basketActions] = useBasketStore();
    const hasLines = Boolean(basketState.basketLines);

    const vesselID = basketState.vesselID;
    const basketItemCount = hasLines ? basketState.basketLines.length : 0;
    const selected = basketActions.hasBasketPrices();

    const disabled = (vesselID === 0) || (basketItemCount === 0);

    return useMemo(() => {

        if (!hasLines)
        return null;
        
        return (
            <React.Fragment>
            <BasketButton
                Text={`Review ${basketItemCount? "("+basketItemCount+")" : ""}`}
                To={`/sales/catalogue/${vesselID}/basket/`}
                Location={`/sales/catalogue/${vesselID}/basket/`}
                Disabled={disabled}
                Selected={selected}
                Count={basketItemCount}
            />
            </React.Fragment>
            );

    }, [hasLines, basketItemCount, vesselID, disabled, selected]);
}

const BasketConfirmButton = () => {
    const [basketState, basketActions] = useBasketStore();
    const hasLines = Boolean(basketState.basketLines);

    const vesselID = basketState.vesselID;
    const basketItemCount = hasLines ? basketState.basketLines.length : 0;

    const pricesLoaded = basketActions.hasBasketPrices();
    const disabled = (vesselID === 0) || (basketItemCount === 0) || (!pricesLoaded);

    const done = !disabled && basketState.reference.length > 0;

    return useMemo(() => {

        if (!hasLines)
            return null;

        return (
            <React.Fragment>
            <BasketButton
                Text="Confirm"
                To={`/sales/catalogue/${vesselID}/confirm/`}
                Location={`/sales/catalogue/${vesselID}/confirm/`}
                Disabled={disabled}
                Done={done}
            />
                </React.Fragment>
            );

    }, [hasLines, vesselID, disabled, done]);
}


export const BasketButton = ({ Text, To, Location, Count, Selected, Disabled, Done }) => {

    const icon = Done === true ? (
        <IconButton disableFocusRipple disableRipple disableTouchRipple>
            <DoneOutlineIcon />
        </IconButton>) : null;

    return (
        <div className={` ${Disabled === true ? 'ctdisabled' : ''}`} style={{ backgroundColor: "#ccc" }}>
            <Link to={To} style={{textDecoration: "none"}}>
            <Button
                number={Count}
                location={Location}
                selected={Selected}
                icon={icon}
            > {Text} </Button>
            </Link>
        </div>
    );
}



const SaveManagerBasket = () => {
    return (<SaveManagerBasketConfirmation />);
}


const SaveManagerBasketConfirmation = () => {
    const [basketState, basketActions] = useBasketStore();

    const [open, setOpen] = React.useState(false);
    const [reference, setReference] = React.useState("");

    const disabled = basketState.basketLines.length <= 0;

    const handleReference = (e) => {
        const val = e.target.value;
        setReference(() => val);
    }

    const SaveManagerBasket = () => {

        basketActions.saveManagerBasket(reference);
        setOpen(false);
    }

    return (
        <React.Fragment>
            <Dialog
                open={open}
                scroll="paper"
                onClose={() => { setOpen(false); }}
                aria-labelledby="Save Basket">
                <DialogTitle id="Save Basket">Do you want to save the basket?</DialogTitle>
                <DialogContent>
                    <DialogContentText variant="subtitle2">Please enter the reference for the basket you want to save:</DialogContentText>
                    <TextField
                        onChange={handleReference}
                        autoFocus
                        variant="outlined"
                        margin="dense"
                        id="name"
                        label="Reference"
                        type="text"
                        value={reference}
                        fullWidth
                    />
                    <DialogContentText variant="caption">After saving the basket will become available from the existing baskets</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => { SaveManagerBasket(); }} color="primary">Save</Button>
                    <Button variant="outlined" onClick={() => { setOpen(false); }} color="primary">Close</Button>
                </DialogActions>
            </Dialog>
            &nbsp;&nbsp; <Button variant="contained" size="small" disabled={disabled} onClick={() => { setOpen(true); }}>Save</Button>
        </React.Fragment>
    );
}

export default Catalogue;
