import React, { useState, useMemo } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useHistory } from "react-router-dom";
import { useFleetStore } from '../../../Global/Stores/fleetStore';
import { Typography, Checkbox, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Button, CircularProgress } from '@material-ui/core';

const LineDetailTable = ({ productGroup, item, line, showOrder = false, showLicense = false, showBasket = false }) => {

    const hasLine = Boolean(line);
    const showItem = Boolean(item) ? item : line.item;

    return useMemo(() => {

        return (
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell align="center" colSpan={2}>
                            <Typography variant="h6">{productGroup ? productGroup.caption : ""}</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    <TableRow >
                        <TableCell style={{ width: '1px' }}><Typography variant="subtitle2">Number:</Typography></TableCell>
                        <TableCell>{showItem.itemNumber}</TableCell>
                    </TableRow>

                    <TableRow >
                        <TableCell><Typography variant="subtitle2">Title:</Typography></TableCell>
                        <TableCell>{showItem.itemTitle}</TableCell>
                    </TableRow>

                    <TableRow >
                        <TableCell><Typography variant="subtitle2">Edition:</Typography></TableCell>
                        <TableCell>{String(showItem.editionDate).split(' ')[0]}</TableCell>
                    </TableRow>
                    {
                        (hasLine && !showBasket) && (
                            <TableRow >
                                <TableCell><Typography variant="subtitle2">Quantity:</Typography></TableCell>
                                <TableCell>{line.quantity}</TableCell>
                            </TableRow>
                        )
                    }

                    {
                        Boolean(showItem.itemStatus) ? (
                            <TableRow >
                                <TableCell><Typography variant="subtitle2">Item Status:</Typography></TableCell>
                                <TableCell>{showItem.itemStatus}</TableCell>
                            </TableRow>
                        ) : null
                    }

                    {
                        (hasLine && showOrder) && (
                            <React.Fragment>
                                <TableRow >
                                    <TableCell><Typography variant="subtitle2">Duration:</Typography></TableCell>
                                    <TableCell>{`${line.duration || 'N/A'}`}</TableCell>
                                </TableRow>
                                <TableRow >
                                    <TableCell><Typography variant="subtitle2">Price:</Typography></TableCell>
                                    <TableCell>{`${line.currency} ${line.linePrice}`}</TableCell>
                                </TableRow>
                            </React.Fragment>
                        )
                    }

                    {
                        (hasLine && showLicense) && (
                            <React.Fragment>
                                <TableRow >
                                    <TableCell><Typography variant="subtitle2">Active:</Typography></TableCell>
                                    <TableCell padding="none"><ActiveLicenceConfirmation line={line}/></TableCell>
                                </TableRow>
                                {
                                    line.endDate && (
                                        <TableRow >
                                            <TableCell><Typography variant="subtitle2">License Expires:</Typography></TableCell>
                                            <TableCell>{String(line.endDate).slice(0, 10)}</TableCell>
                                        </TableRow>)
                                }
                            </React.Fragment>
                        )
                    }

                    {
                        (showBasket && hasLine) && (
                            <React.Fragment>
                                <TableRow >
                                    <TableCell><Typography variant="subtitle2">Quantity:</Typography></TableCell>
                                    <TableCell>{`${line.quantity.toFixed(2)}`}</TableCell>
                                </TableRow>

                                <TableRow >
                                    <TableCell><Typography variant="subtitle2">Duration:</Typography></TableCell>
                                    <TableCell>{`${line.itemPrice.months}`}</TableCell>
                                </TableRow>
                            </React.Fragment>
                        )
                    }
                </TableBody>
            </Table>
        );

    }, [line, hasLine, showItem, showLicense, showOrder, showBasket, productGroup]);
};

const ActiveLicenceConfirmation = ({ line }) => {

    const [open, setOpen] = useState(false);
    const [, fleetActions] = useFleetStore();
    const [disabledButton, setDisabledButton] = useState(false);

    const history = useHistory();

    const Confirm = () => {
        
        setDisabledButton(true);

        fleetActions.setLicenseAvailability(line.vesselID, line.itemID, !line.active,
            (success) => {
                if(!success) {
                    setOpen(false);
                    setDisabledButton(false);
                }

                if(success) {
                    history.push(`/fleet/holdings/${line.vesselID}`);
                };  
            }
        );
    };

    const actionText = line.active ? 'deactivate' : 'activate';
    console.log(line);  
    
    return useMemo(() => {
        return (
            <React.Fragment>
                <Dialog
                    open={open}
                    scroll="paper"    
                    aria-labelledby="Save License">
                    <DialogTitle id="Save License" style={{textTransform: 'capitalize'}}>{actionText} license?</DialogTitle>
                    <DialogContent style={{paddingBottom: 0}} dividers>
                        <DialogContentText variant="subtitle2">
                            Do you want to {actionText} <strong>{line.item.itemTitle}</strong>?
                        </DialogContentText>                    
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" size="small" disabled={disabledButton} onClick={() => { Confirm(); }} color="primary">
                            {
                                (disabledButton) && 
                                (
                                    <CircularProgress size="0.7rem" sx={{ mx: 2 }} style={{margin: "0 5px 0 0"}}/>
                                )
                            }
                            {actionText}
                        </Button>
                        <Button variant="outlined" disabled={disabledButton} size="small" onClick={() => { setOpen(false); }} color="primary">Close</Button>
                    </DialogActions>
                </Dialog>   
                <Checkbox name="active" color="primary" checked={line.active} onChange={() => { setOpen(true); }} />
            </React.Fragment>
        )
    }, [line, disabledButton, open, actionText]);
}

export default LineDetailTable;
