import React, { useMemo, useState, useEffect } from 'react';
import { FeatureGroup } from 'react-leaflet';
import CatalogueMapPopup from './CatalogueMapPopup';
import ItemPolygon from '../../Polygons/ItemPolygon';
import { useGlobalStore } from '../../../../Global/Stores/globalStore';
import { useCatalogueStore } from '../../../../Global/Stores/catalogueStore';
import { checkPolygonInPoint } from '../../MapFunctions';
import GeneralRoutesLayer from './GeneralRoutesLayer';
// import _ from 'lodash';

const CatalogueSelectLayer = ({ match, history, mapActions }) => {

    // const [globalState,] = useGlobalStore(); 
    const [, globalActions] = useGlobalStore();
    const [catalogueState,] = useCatalogueStore();

    const [popup, setPopup] = useState({
        key: 0,
        position: null,
        lines: []
    });


    //console.log('CatalogueSelectLayer');

    const { sourceLines, selectedItemIDs, productGroup, productItemGroups, sourceRecord } = catalogueState;
    // console.log('selectedItemIDs', selectedItemIDs);
    const focusedItemID = +match.params.itemID || 0;

    const loaded = globalActions.isLoaded();

    useEffect(() => {

        if (focusedItemID > 0) {
            const line = sourceLines.find(X => X.itemID === focusedItemID);
            if (line) {
                // console.log('focusedItemID', focusedItemID, [line]);
                mapActions.zoomTo(line.polygonPoints);
            }
        }

    }, [focusedItemID, loaded, sourceLines]);

    // const sourceOptionID = itemSource.id;
    // const isRoute = sourceOptionID === 1


    const selectedItemID = +match.params.itemID || 0;
    // const items = _.get(globalState, 'catalogue.items', []);


    // if (!globalState.catalogue || !globalState.catalogue.items)
    //     return null;

    const mapItems = sourceLines.filter(X => productGroup === X.productGroupID && X.polygonPoints && productItemGroups.includes(X.productItemGroupID));
    // const mapItemIDs = mapItems.map(X => X.itemID); //.slice(1, 10000);


    return useMemo(() => {

        //console.log('CatalogueSelectLayer 2');

        //const testItems = mapItems.filter(X => mapItemIDs.includes(X.itemID));

        //console.log('CatalogueSelectLayer', selectedItemID, mapItemIDs, items, popup);
        // console.log('mapItemIDs', mapItemIDs);

        const routeLayer = sourceRecord && sourceRecord.sourceRouteID ? <GeneralRoutesLayer Routes={[sourceRecord]} /> : null;

        const onClick = (e) => {
            // const event = e;
            let selected = mapItems.filter(X => X.polygonPoints && checkPolygonInPoint(e.latlng, X.polygonPoints));
            if (selected.length === 0) {
                selected = e.layer;
            }


            if (selected.length === 1) {
                history.push(`${selected[0].itemID}`);
            }
            else if (selected.length > 0) {

                setPopup(prev => {
                    return {
                        key: prev.key + 1,
                        position: e.latlng,
                        lines: selected
                    }
                });
            }

            // console.log('onClick', selected);
        }

        return (
            <React.Fragment>
                <CatalogueMapPopup popupInfo={popup} selectedItemID={selectedItemID} selectedItemIDs={selectedItemIDs} />
                <FeatureGroup onClick={onClick}>
                    {
                        mapItems
                            .map(X => {
                                //const selected = (X.itemID === selectedItemID);
                                const selected = selectedItemIDs.includes(X.itemID);
                                const focused = selectedItemID === X.itemID;

                                //return (<CatalogItemPolygon key={X.itemID} Item={X} Selected={selected} Focused={focused} />)
                                return (<CatalogItemPolygon key={X.itemID} Item={X} Selected={selected} Focused={focused} />)
                            })
                    }
                </FeatureGroup>
                {routeLayer}
            </React.Fragment>
        );

    }, [productItemGroups, selectedItemIDs, selectedItemID, mapItems, sourceRecord]);
    //}, [productItemGroups]);
}


const CatalogItemPolygon = ({ Item, Selected, Focused }) => {

    // const inBasket = !!basketState.basketLines.find(X => X.itemID === Item.itemID);

    const itemID = Item.itemID;
    return useMemo(() => {

        // console.log('CatalogItemPolygon', Item);
        if (!Item.polygonPoints) {
            // console.log('Skip Map Item', Item);
            return null;
        }


        // const color = Item.validationID === 1 ? "#1dc71d" : "#ff4a4a";
        // const fillOpacity = Selected === true ? 0.2 : 0.01;
        const color = Selected === true ? "green" : "black";
        // const color = "black";

        return (
            <ItemPolygon
                positions={Item.polygonPoints}
                fillColor={color}
                color={color}
                selected={Selected}
                focused={Focused}
            />);

    }, [itemID, Item, Selected, Focused]);
}

export default CatalogueSelectLayer
