import React from 'react';
import { withRouter } from 'react-router-dom';
import BackIcon from '@material-ui/icons/ArrowBack';
import { IconButton, Divider, ListItemText, Grid } from '@material-ui/core';
import { MailOrderDetailTo } from '../../../Misc/MailTo';
import { DownloadOrder } from '../../../Misc/DownloadFile';
import OrderStatus from '../../../../Widgets/OrderStatus';

const OrderDetailHeader = ({ orderDetail, history }) => {

    if (!orderDetail || !orderDetail.order) return null;

    const { order } = orderDetail;

    const handleBack = () => {
        const url = history.location.pathname;
        const popLink = url.substring(0, url.lastIndexOf("/"));
        history.push(popLink);
    }

    return (
        <React.Fragment>
            <Grid container spacing={1}>
                <Grid item xs={1}>
                    <IconButton onClick={handleBack}>
                        <BackIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={2}>
                    <OrderStatus order={order} />
                </Grid>
                <Grid item>
                    <ListItemText className="flexFill" primary={order.reference} secondary={`${order.customerReference} (${order.orderDate})`}></ListItemText>
                </Grid>
                <Grid item>
                    <MailOrderDetailTo orderDetail={orderDetail} />
                </Grid>
                <Grid item>
                    <DownloadOrder salesOrderID={order.salesOrderID} />
                </Grid>
            </Grid>
            {/* <Divider /> */}
        </React.Fragment>
    );
};

export default withRouter(OrderDetailHeader);
