import React, { useEffect } from 'react';
import _ from 'lodash';
import {InputLabel, MenuItem, FormControl, Select } from '@material-ui/core';
import { useHistory, Link, Redirect } from 'react-router-dom';
//import { useFleetStore } from '../../../Global/Stores/fleetStore';

const SelectList = ({ managerID, dataList, inputLabel, inputType, selected }) => {
    let history = useHistory();
    const [company, setCompany] = React.useState('');

    // Set this so if there is no selecttion, we can 
    if(selected === 0 || selected === null){selected = null};
    //const [companyID, setCompanyID] = React.useState('');

    //lets count the results so if it's empty we show something nice. 
    // Am a nice person... no?
    let countDataResults = dataList.length;

    //lets define what to show when there is no data in the select box 
    let noDataMessage = inputType === "managers" ? "No Managers Found" : "No Folios Found";


    // We are going to count the results and only use it if its a folio. 
    // When result is one we preselect.
    let countData = dataList.length;
    let loaded = Boolean(dataList);

    useEffect (
        () => {
            if(inputType === "folios"){
                if(dataList.length === 1)
                {
                    //console.log(dataList)
                    selected = loaded ? dataList[0].folioID : selected;
                    history.push(`/folios/status/${managerID}/${selected}`)
                }
            }
        }, [dataList]
    );

    // const [, fleetActions] = useFleetStore();
    // const [fleetState] = useFleetStore();
    // const [filter, setFilter] = React.useState('');
    // const [sort, setSort] = React.useState(1);

    // useEffect(
    //     () => {
    //         //fleetActions.getHoldingsOverview();
    //         fleetActions.getFolioOverview();
    //         fleetActions.getFleetPositions();
    //     }, []
    // );

    // const loaded = Boolean(fleetState.folios);
    // let companyList = loaded ? fleetState.folios.filter(X => X.vesselName.toLowerCase().indexOf(filter) !== -1) : [];

    //console.log(`Company ID is now: ${companyID}`);

    const handleChange = (event) => {
        setCompany(event.target.value);
        //setCompanyID(event.target.key);
        if(countDataResults >= 1){
            if(managerID != null)
            {
                history.push(`/folios/status/${managerID || 0}/${event.target.value}`);
                //console.log("Triger folio change");
            }
            else
            {
                history.push(`/folios/status/${event.target.value}`);
                //console.log("Trigger company");
            }
        }
    };

    //if(companyID != 0)
    //{
        return (
            <div>
                <FormControl variant="outlined" fullWidth margin="dense">
                    <InputLabel id={inputType}>{inputLabel}</InputLabel>
                    <Select
                    labelId={inputType}
                    id={inputType}
                    value={selected === null ? "null" : selected}
                    key={managerID === null ? 10 : managerID }
                    selected={selected}
                    onChange={handleChange}
                    label={inputLabel}
                    style={{padding: '5px'}}
                    >
                    {
                        countDataResults <= 0 ?
                            <MenuItem key={managerID === null ? 1 : managerID } value="null">{noDataMessage}</MenuItem>
                        :
                        dataList.map(X => (
                            inputType === "managers"?
                            <MenuItem key={X.managerID} value={X.managerID}>{X.name}</MenuItem>
                            :
                            <MenuItem key={X.folioID} value={X.folioID}>{X.title}</MenuItem>
                        ))
                    }
                    </Select>
                </FormControl>
            </div>
        );
    // }
    // else
    // {
    //     return (null);
    // }
};

export default SelectList;
