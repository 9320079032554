import React from 'react';
import { withRouter } from 'react-router-dom';
import BackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/SaveAlt';
import { IconButton, Divider, ListItemText, Grid } from '@material-ui/core';
import MailTo, { MailHoldingDetailTo } from '../../../Misc/MailTo';
// import OrderStatus from '../../../Widgets/OrderStatus';
import { HoldingLinesStatus } from '../../../../Widgets/ProductGroupStatus';
import { DownloadHoldings } from '../../../Misc/DownloadFile';

const HoldingDetailHeader = ({ holdingDetail, history }) => {

    if (!holdingDetail) return null;

    const handleBack = () => {
        const url = history.location.pathname;
        const popLink = url.substring(0, url.lastIndexOf("/"));
        history.push(popLink);
    }

    return (
        <Grid container spacing={0}>
            <Grid>
                <IconButton onClick={handleBack}>
                    <BackIcon />
                </IconButton>
            </Grid>
            <Grid>
                <HoldingLinesStatus lines={holdingDetail.vessel.vesselItems} />
            </Grid>
            <Grid>
                <MailHoldingDetailTo vessel={holdingDetail.vessel} />
            </Grid>
            <Grid>
                <DownloadHoldings vesselID={holdingDetail.vessel.vesselID} />
            </Grid>
            <Grid>
                <ListItemText className="flexFill" primary={holdingDetail.vessel.vesselName} />
            </Grid>

            <Divider />
        </Grid>
    );
};

export default withRouter(HoldingDetailHeader);
