import React, { useState } from 'react';
import { Popup } from 'react-leaflet';
import { List, ListItem, ListItemText, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';


const MapPopupSalesShipmentLines = ({ popupInfo }) => {


    const [closeKey, setCloseKey] = useState(0);
    // console.log('popupInfo', popupInfo);
    return popupInfo.position && closeKey !==popupInfo.key ? (
        <Popup
            key={popupInfo.key}
            position={popupInfo.position}
            
        >
            <List dense={true} disablePadding style={{ border: '1px solid gray' }}>
                {
                    popupInfo.lines.map(line => {
                        return (
                            <Link
                                key={line.salesShipmentLineID}
                                className="ListItemLink"
                                component={RouterLink}
                                to={`/sales/shipments/${line.salesShipmentID}/${line.salesShipmentLineID}`}
                                color='inherit'
                                style={{ textDecoration: 'none', color: 'inherit' }}
                                onClick={() => setCloseKey(popupInfo.key)}
                            >

                                <ListItem button disableRipple dense divider>
                                    <ListItemText primary={`${line.item.itemNumber} ${line.item.itemTitle}`} style={{ marginRight: '10px' }} />

                                    {/* <ListItemSecondaryAction>
                                        <ListItemText style={{}} primary={(line.linePrice ? line.linePrice.toFixed(2) : '')} />
                                    </ListItemSecondaryAction> */}
                                </ListItem>
                            </Link>
                        );
                    })
                }
            </List>
        </Popup>

    ) : null;
};

export default MapPopupSalesShipmentLines;


