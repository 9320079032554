import React from 'react'
import { Link } from "react-router-dom";

////// Matrial UI support files
import { ListItem, ListItemText, Divider, Card, List, Button } from "@material-ui/core";

////// Global Support Files
import Menu from '../../../Global/Data/Menu';

////// CSS Files
import homeStyles from '../CSS/HomeStyles.module.css';
import menuStyles from '../CSS/MenuStyles.module.css';


const MyHome = () => {
    return(
        <React.Fragment>
            <div className={menuStyles.menuCategory}>
            {Menu.map(mainMenu => {
            if (mainMenu.subItems){
                if (mainMenu.to === window.location.pathname){
                    return (<div  key={mainMenu.id}>
                        <ListItem>
                            <ListItemText primary={mainMenu.label} secondary={mainMenu.shortDescription} />
                        </ListItem>
                        <Divider />
                        <Card raised>
                    {mainMenu.subItems.map(subMenu => {
                        return (<List key={subMenu.id}>
                                    <Link to={subMenu.to} className={homeStyles.noFormat} >
                                    <ListItem button >
                                        <ListItemText  primary={subMenu.label} />
                                    </ListItem>
                                    </Link>
                                </List>
                        )
                    }
                    )
                    }
                    </Card><br />
                    <div className={homeStyles.homeButton}>
                        <Link to="/home" className={homeStyles.noFormat} >
                            <Button color="primary" variant="contained">Return Home</Button>
                        </Link>
                    </div>
                </div>
                )};
            }})}

            </div>
        </React.Fragment>
    )
}

export default MyHome