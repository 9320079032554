import React from 'react';
import { Route } from "react-router-dom";
import Profile from './Profile/Profile';
import ViewProfile from './Profile/ViewProfile';
// import Users from './Users/Users';
import UserList from './Users/UserList';
import UserCreate from './Users/UserCreate';
import UserDetail from './Users/UserDetail';
import { Button, ButtonGroup} from '@material-ui/core';

const Account = () => {
    return (
        <React.Fragment>
            <div>
                <br />
                <AccountOptions />
            </div>
            <div>

                <Route exact path="/account" component={ViewProfile} />
                <Route exact path="/account/profile" component={Profile} />

                {/* <Route exact path="/account/users" component={UserList} /> */}
                {/* <Route exact path="/account/users/create" component={Users} />
                <Route exact path="/account/users/manage" component={Users} /> */}

                <Route exact path="/account/users/create" component={UserCreate} />

                <Route exact path={[ "/account/users", "/account/users/manage"]} component={UserList} />
                <Route exact path="/account/users/manage/:id" component={UserDetail} />
            </div>
        </React.Fragment>

    );
}

const AccountOptions = () => {
    return (
        <React.Fragment>
            <div style={{ alignContent: "center", width: "100%" }}>
                <ButtonGroup size="small" color="primary" variant="contained" aria-label="small contained button group">
                    <Button href="/account">My Profile</Button>
                    <Button href="/account/profile">Edit Profile</Button>
                    <Button href="/account/users">Manage Users</Button>
                    <Button href="/account/users/create">Create Users</Button>
                </ButtonGroup>
            </div>
        </React.Fragment>
    );
}

export default Account
