import React, { useState, useEffect } from 'react';
import { Typography, List, Paper } from '@material-ui/core';
import { AnnouncementLine } from '../Home/Summaries/AnnouncementSummary';
import ContentColumn from '../../Custom/ContentColumn';
import Loading from '../Misc/Loading';
import ReactHtmlParser from 'react-html-parser';
import Moment from 'react-moment';
import { useGlobalStore } from '../../../Global/Stores/globalStore';

const NewsDetail = ({ match }) => {
    const [globalState,] = useGlobalStore();
    const newsItems = globalState.newsItems || [];
    const loaded = Boolean(newsItems.length);

    const selectedID = +match.params.newsID || 0;
    const newsItem = selectedID > 0 ? loaded && newsItems.find(X => X.chartagentNewsID === selectedID) : null;

    return (
        selectedID > 0 ?
        <ContentColumn loaded={loaded}>
            <div className="contentColumnHeader" style={{ padding: '0px 10px' }}>
                <Typography variant="h6">{`Details`}</Typography>
            </div>

            <div className="contentColumnBody"  style={{margin: '7px'}}>
                {
                    newsItem && (
                        <Paper>
                            <Typography variant="h6" component="h3">{newsItem.title}</Typography>
                            <Typography variant='body2' component="span">
                                {ReactHtmlParser(newsItem.message)}
                            </Typography>
                            <Typography variant="caption" component="h3" align="right">
                                <br />
                               Posted on: <Moment date={newsItem.startDate} format="LL" />
                            </Typography>
                        </Paper>
                    )
                }
            </div>
        </ContentColumn>
        :null
    );
}

export default NewsDetail
