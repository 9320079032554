import React, { useMemo } from 'react';
import ItemPolygon from '../Polygons/ItemPolygon';
import { useSaleStore } from '../../../Global/Stores/saleStore';
import _ from 'lodash';

const FolioLayer = ({ folio }) => {

    return useMemo(() => {

        // console.log('FolioLayer', folio);
        
        if (!folio)
            return null;

        const { number, title, items } = folio;
        const mapItems = items.filter(I => !!I.polygonPoints);

        const color = "purple";
        const selected = true;
        return (
            <React.Fragment>
                {
                    mapItems.map(I => (
                        <ItemPolygon
                            key={I.itemID}
                            positions={I.polygonPoints}
                            fillColor={color}
                            color={color}
                            selected={selected}
                        />))}
            </React.Fragment>
        )

    }, [folio]);
};

export default FolioLayer;
