import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '../../../Global/Data/Menu';
import NavMenuItem from './NavMenuItem';
import NavIconAccount from './NavIconAccount';
import NavBarLogo from './NavBarLogo';
import NavIconHelp from './NavIconHelp';
import NavBreadCrumbs from './NavBarBreadCrumbs';

const useStyles = makeStyles({
    root: {
        backgroundColor: '#FFFFFF',
        color: '#4d4d4d',
        fontSize: '1rem',
        letterSpacing: '4px',
    },
    flexFill: {
        flex: 1,
    }
});



//console.log(Menu);
const NavBar = ({Module, currentPage})  => {

    const classes = useStyles();

    return (
        <AppBar position="static" elevation={0} className={classes.root}>
            <Toolbar variant="dense">

                {/* <div className="" to="/"> */}
                {/* <Typography variant="h6" color="inherit">SM</Typography> */}
                {/* <img src={CT_Logo} className={classes.logo} alt="logo" /> */}
                {/* </div> */}

                <NavBarLogo />
                {
                    Menu.map(X => <NavMenuItem key={X.id} selected={X.id} to={X.to} label={X.label} />)
                }
                <div className={classes.flexFill}></div>
                <NavBreadCrumbs Module={Module} currentPage={currentPage} />
                <NavIconHelp />
                <NavIconAccount />
            </Toolbar>
        </AppBar>
    )
}

export default NavBar;
