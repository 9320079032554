import React from 'react';
import { Chip } from '@material-ui/core';
// import _ from 'lodash';

const ChipStatus = ({ number, statusColor }) => {
    return ((number || 0) ? <Chip size="small" label={(number || 0)} className={`statusChip${statusColor}`} /> : null)
}

export const ChipStatusValid = ({ number }) => {
    return ((number || 0) ? <Chip size="small" label={(number || 0)} className={`statusChipGreen`} /> : null)
}

export const ChipStatusWarning = ({ number }) => {
    return ((number || 0) ? <Chip size="small" label={(number || 0)} className={`statusChipOrange`} /> : null)
}

export const ChipStatusIssue = ({ number }) => {

    // console.log('number', number)
    return ((number || 0) ? <Chip size="small" label={(number || 0)} className={`statusChipRed`} /> : null)
}

export default ChipStatus;
