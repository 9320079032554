import React, { useMemo } from 'react';
import { Dialog } from '@material-ui/core';
import VesselQuoteLineDetailHeader from './VesselQuoteLineDetailHeader';
import { useSaleStore } from '../../../../../Global/Stores/saleStore';
import VesselQuoteLineDetailList from './VesselQuoteLineDetailList';
import _ from 'lodash';
// import LineDetailTable from '../../../Misc/LineDetailTable';
import ContentColumn from '../../../Custom/ContentColumn';
import FloatingMenuButton from '../../../Navigation/FloatingMenuButton';
import MapControl from '../../../../MapControl/MapControl';

const VesselQuoteLineDetail = ({ match }) => {

    const [open, setOpen] = React.useState(true);
    
      const handleClose = () => {
        setOpen(false);
      };
    

    const [saleState,] = useSaleStore();

    const salesQuoteID = +match.params.id;
    const salesQuoteLineID = +match.params.lineID;

    return useMemo(() => {

        // if (!saleState.quoteDetail || !saleState.quoteDetail.quote) return null;

        const loaded = Boolean(_.get(saleState, 'quoteDetail.quote', false));

        const currentLine = loaded ? saleState.quoteDetail.quote.lines.find(X => X.salesQuoteLineID === salesQuoteLineID) : null;

        // if (!currentLine) return null;


        const productGoup = loaded ? saleState.quoteDetail.productGroups.find(X => X.productGroupID === currentLine.item.productGroupID) : null;

        const quoteLines = _.get(saleState, 'quoteDetail.quote.lines', []);
        const lines = _.orderBy(quoteLines, ['item.productGroupID', 'item.itemNumber'], ['asc', 'asc']);
        const lineCount = lines.length;
        const SQLID_Array = lines.map(X => X.salesQuoteLineID);
        const index = _.indexOf(SQLID_Array, salesQuoteLineID);

        const before = SQLID_Array[(lineCount + index - 1) % lineCount];
        const next = SQLID_Array[(index + 1) % lineCount];

        return (
            <Dialog open={open} onClose={handleClose} >
                <ContentColumn loaded={loaded} >
                <div  style={{width: "320px"}}>
                    <div style={{width: "100%", height: "200px"}}>
                        <MapControl showMenu={false}/>
                    </div>
                    <div className="contentColumnHeader">
                        <VesselQuoteLineDetailHeader
                            line={currentLine}
                            closePath={`/sales/quotes/${salesQuoteID}`}
                            next={`/sales/quotes/${salesQuoteID}/${next}`}
                            before={`/sales/quotes/${salesQuoteID}/${before}`} />
                    </div>

                    <div className="contentColumnBody">
                        {/* <LineDetailTable productGroup={productGoup} line={currentLine} showOrder={true} /> */}
                        <VesselQuoteLineDetailList productGroup={productGoup} line={currentLine} />
                    </div>
                </div>
                </ContentColumn>
                <FloatingMenuButton />
            </Dialog>
        );
    }, [saleState.quoteDetail, salesQuoteLineID])


};

export default VesselQuoteLineDetail;
