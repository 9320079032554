import React from 'react';
import { withRouter } from 'react-router-dom';
import BackIcon from '@material-ui/icons/ArrowBack';
import { IconButton, Divider, ListItemText } from '@material-ui/core';
import { MailQuoteDetailTo } from '../../../Misc/MailTo';
import QuoteStatus from '../../../../Widgets/QuoteStatus';
import { DownloadQuote } from '../../../Misc/DownloadFile';

const QuoteDetailHeader = ({ quoteDetail, history }) => {

    if (!quoteDetail || !quoteDetail.quote) return null;

    const { quote } = quoteDetail;

    const handleBack = () => {
        const url = history.location.pathname;
        const popLink = url.substring(0, url.lastIndexOf("/"));
        history.push(popLink);
    }

    return (
        <React.Fragment>
            <IconButton onClick={handleBack}>
                <BackIcon />
            </IconButton>

            <ListItemText className="flexFill" primary={quote.reference} secondary={quote.customerReference} />

            <QuoteStatus quote={quote} />

            <MailQuoteDetailTo quoteDetail={quoteDetail} />
            <DownloadQuote salesQuoteID={quote.salesQuoteID} />

            <Divider />
        </React.Fragment>
    );
};

export default withRouter(QuoteDetailHeader);
