import React, { useMemo } from 'react';
import './NumberStatus.scss';

const NumberStatus = ({ number }) => {

    return useMemo(() => {
        // console.log('NumberStatus', number);

        return (
            <div className="number-chart">
                <div className="pie-number big" data-start={0} data-value={360}></div>
                <p className="chart-text">{number}</p>
            </div>
        );

    }, [number]);
};

export default NumberStatus;
