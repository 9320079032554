import React, { useMemo, useEffect, useState } from 'react';
import { FeatureGroup } from 'react-leaflet';
import _ from 'lodash';
import { useFleetStore } from '../../../Global/Stores/fleetStore';
import HoldingLinePolygon from '../Polygons/HoldingLinePolygon';
import MapPopupHoldingLines from '../Popup/MapPopupHoldingLines';
import { checkPolygonInPoint } from '../MapFunctions';

const HoldingLayer = ({ match, history, mapActions }) => {
    const [fleetState] = useFleetStore();
    const vesselID = fleetState.holdingDetail ? fleetState.holdingDetail.vessel.vesselID : 0;
    const filter = fleetState.holdingDetail ? fleetState.holdingDetail.filter : {};
    const selectedItemID = +match.params.itemID || 0;

    const productGroupID = _.get(fleetState, 'holdingDetail.filter.productGroupID', -1);

    // console.log('productGroupID', productGroupID);

    //console.log(filter);



    const [popup, setPopup] = useState({
        key: 0,
        position: null,
        lines: []
    });


    useEffect(() => {
        if (vesselID > 0 && selectedItemID > 0) {
            const line = fleetState.holdingDetail.vessel.vesselItems.find(X => X.item.itemID === selectedItemID);
            mapActions.zoomToLines(line);
            //console.log("LINES: ", line);
        }
    }, [selectedItemID]);


    return useMemo(() => {
        // console.log('HoldingLayer', fleetState.holdingDetail);

        if (vesselID === 0)
            return null;

        let lines = fleetState.holdingDetail.vessel.vesselItems.filter(X => X.item.polygonPoints);
        if (fleetState.holdingDetail.filter) {

            const statusFilter = fleetState.holdingDetail.filter.status;
            // console.log('filter.status', fleetState.holdingDetail.filter.status);

            lines = lines
                .filter(X => (productGroupID === 0) || (X.item.productGroupID === productGroupID))
                .filter(X => fleetState.holdingDetail.filter.scale.includes(X.item.productItemGroupID || 0))
                .filter(X => X.active || statusFilter.inactive)
                .filter(X => (
                    (
                        (statusFilter.issue && (X.issue)) ||
                        (statusFilter.warning && (X.warning)) ||
                        (statusFilter.valid && (X.valid))
                    )));
            // .filter(X => (
            //     (fleetState.holdingDetail.filter.status.issue === true && (X.itemIssue === true || X.updateIssue === true)) ||
            //     (fleetState.holdingDetail.filter.status.warning === true && (X.itemWarning === true || X.updateWarning === true)) ||
            //     (fleetState.holdingDetail.filter.status.valid && X.itemIssue === false && X.updateIssue === false && X.itemWarning === false && X.updateWarning === false)
            // ));
        }

        const onClick = (e) => {
            let selected = lines.filter(X => X.item.polygonPoints && checkPolygonInPoint(e.latlng, X.item.polygonPoints));
            if (selected.length === 0)
                selected = e.layer;

            if (selected.length === 1) {
                //console.log("history", selected.length);
                history.push(`/fleet/holdings/${selected[0].vesselID}/${selected[0].item.itemID}`);
                // mapActions.zoomToLines(selected);
            }
            else if (selected.length > 0) {

                setPopup(prev => {
                    return {
                        key: prev.key + 1,
                        position: e.latlng,
                        lines: selected
                    }
                });
            }

            // console.log("selected", selected);
        };

        // const filteredLines = lines);
        const sortedLines = _.orderBy(lines, ['item.scale'], ['desc']);

        // mapActions.zoomToLines(filteredLines);

        return (
            <React.Fragment>
                <MapPopupHoldingLines popupInfo={popup} />

                <FeatureGroup onClick={onClick}>
                    {
                        // sortedLines.map(X => <Polygon key={X.item.itemID} positions={X.item.polygonPoints} />)
                        sortedLines.map(X => <HoldingLinePolygon key={X.item.itemID} line={X} selected={selectedItemID === X.item.itemID} />)
                    }
                </FeatureGroup>

            </React.Fragment>
        );



    }, [vesselID, filter, popup, selectedItemID, productGroupID])
};

export default HoldingLayer;
