import React, { useMemo } from 'react';
import _ from 'lodash'
import './ProductGroupStatus.scss';

const ProductGroupStatus = ({ productGroupStatus }) => {

    return useMemo(() => {

        const { itemCount, itemIssueCount, updateIssueCount, itemWarningCount, updateWarningCount } = productGroupStatus;
        const total = itemCount;
        const issues = Math.ceil(((itemIssueCount + updateIssueCount) * (1 / total) * 360));
        const warnings = Math.ceil(((itemWarningCount + updateWarningCount) * (1 / total) * 360));
        const open = Math.max(360 - (issues + warnings), 0);

        const tooltip = `Warnings: ${itemWarningCount + updateWarningCount}\nIssues: ${itemIssueCount + updateIssueCount}`;

        return (
            <div className="holding-chart" title={tooltip} >
                <div className={open > 180 ? "pie-vesselgroup big" : "pie-vesselgroup"} data-start={0} data-value={open}></div>
                <div className={warnings > 180 ? "pie-vesselgroup big" : "pie-vesselgroup"} data-start={open} data-value={warnings}></div>
                <div className={issues > 180 ? "pie-vesselgroup big" : "pie-vesselgroup"} data-start={open + warnings} data-value={issues}></div>
                <p className="chart-text">{total}</p>
            </div>
        );
    }, [productGroupStatus]);
};


export const HoldingStatus = ({ vesselProductGroupStatus }) => {

    // console.log('HoldingStatus', vesselProductGroupStatus);

    const itemCount = _.sumBy(vesselProductGroupStatus, "itemCount") || 0;

    return useMemo(() => {
        const itemIssueCount = _.sumBy(vesselProductGroupStatus, "itemIssueCount") || 0;
        const updateIssueCount = _.sumBy(vesselProductGroupStatus, "updateIssueCount") || 0;
        const itemWarningCount = _.sumBy(vesselProductGroupStatus, "itemWarningCount") || 0;
        const updateWarningCount = _.sumBy(vesselProductGroupStatus, "updateWarningCount") || 0;


        // console.log('Counts', itemIssueCount, updateIssueCount, itemWarningCount, updateWarningCount);

        // const { itemCount, itemIssueCount, updateIssueCount, itemWarningCount, updateWarningCount } = vesselProductGroupStatus;
        const total = itemCount;
        const issues = total > 0 ? Math.ceil(((itemIssueCount + updateIssueCount) * (1 / total) * 360)) : 0;
        const warnings = total > 0 ? Math.ceil(((itemWarningCount + updateWarningCount) * (1 / total) * 360)) : 0;
        const open = Math.max(360 - (issues + warnings), 0);

        const tooltip = `Warnings: ${itemWarningCount + updateWarningCount}\nIssues: ${itemIssueCount + updateIssueCount}`;

        return (
            <div className="holding-chart" title={tooltip} >
                <div className={open > 180 ? "pie-vesselgroup big" : "pie-vesselgroup"} data-start={0} data-value={open}></div>
                <div className={warnings > 180 ? "pie-vesselgroup big" : "pie-vesselgroup"} data-start={open} data-value={warnings}></div>
                <div className={issues > 180 ? "pie-vesselgroup big" : "pie-vesselgroup"} data-start={open + warnings} data-value={issues}></div>
                <p className="chart-text">{total}</p>
            </div>
        );
    }, [itemCount]);
};


export const HoldingLinesStatus = ({ lines }) => {
    // console.log('HoldingStatus', lines);
    const itemCount = lines.length;

    return useMemo(() => {

        const inactiveCount = lines.filter(X => !X.active).length;

        const itemIssueCount = lines.filter(X => X.active && X.itemIssue).length;
        const updateIssueCount = lines.filter(X => X.active && X.updateIssue).length;

        const itemWarningCount = lines.filter(X => X.active && X.itemWarning).length;
        const updateWarningCount = lines.filter(X => X.active && X.updateWarning).length;


        //console.log('Counts Lines', lines);
        // console.log('Counts', { itemCount, inactiveCount, Issues: (itemIssueCount + updateIssueCount), Warnings: (itemWarningCount + updateWarningCount)});

    // const { itemCount, itemIssueCount, updateIssueCount, itemWarningCount, updateWarningCount } = lines;
    const total = itemCount;
    const issues = Math.ceil(((itemIssueCount + updateIssueCount) * (1 / total) * 360));
    const warnings = Math.ceil(((itemWarningCount + updateWarningCount) * (1 / total) * 360));
    const inactive = Math.ceil((inactiveCount * (1 / total) * 360));
    const open = Math.max(360 - (issues + warnings + inactive), 0);

    
    //console.log('Counts', { total, inactiveCount, issues, warnings, inactive, open});

    const tooltip = `Warnings: ${itemWarningCount + updateWarningCount}\nIssues: ${itemIssueCount + updateIssueCount}\nInactive: ${inactiveCount}`;

    return (
        <div className="holding-chart" title={tooltip} >
            <div className={open > 180 ? "pie-vesselgroup big" : "pie-vesselgroup"} data-start={0} data-value={open}></div>
            <div className={warnings > 180 ? "pie-vesselgroup big" : "pie-vesselgroup"} data-start={open} data-value={warnings}></div>
            <div className={issues > 180 ? "pie-vesselgroup big" : "pie-vesselgroup"} data-start={open + warnings} data-value={issues}></div>
            <div className={inactive > 180 ? "pie-vesselgroup big" : "pie-vesselgroup"} data-start={open + warnings + issues} data-value={inactive}></div>
            <p className="chart-text">{total}</p>
        </div>
    );
}, [itemCount]);
};

export default ProductGroupStatus;
