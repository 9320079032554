import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import App from './App';
import ReactGA from 'react-ga';
import * as serviceWorker from './serviceWorker';
import { UserProvider } from './Global/Stores/userStore';
import './index.scss';
import { SaleProvider } from './Global/Stores/saleStore';
import { NotificationProvider } from './Global/Stores/notificationStore';
import { MapProvider } from './Global/Stores/mapStore';
import { FleetProvider } from './Global/Stores/fleetStore';
import { FolioProvider } from './Global/Stores/folioStore';
import { GlobalProvider } from './Global/Stores/globalStore';
import { BasketProvider } from './Global/Stores/basketStore';
import { ColumnProvider } from './Global/Stores/columnStore';
import { CatalogueProvider } from './Global/Stores/catalogueStore';
import { AvcsoProvider } from './Global/Stores/avcsoStore';

ReactGA.initialize('UA-141005571-1');
ReactDOM.render(
    <Router>
        <NotificationProvider>
            <UserProvider>
                <GlobalProvider>
                    <ColumnProvider>
                        <BasketProvider>
                            <CatalogueProvider>
                                <AvcsoProvider>
                                    <MapProvider>
                                        <SaleProvider>
                                            <FleetProvider>
                                                <FolioProvider>
                                                    <App />
                                                </FolioProvider>
                                            </FleetProvider>
                                        </SaleProvider>
                                    </MapProvider>
                                </AvcsoProvider>
                            </CatalogueProvider>
                        </BasketProvider>
                    </ColumnProvider>
                </GlobalProvider>
            </UserProvider>
        </NotificationProvider>
    </Router>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
