import React, { useMemo } from 'react';
import { Typography } from '@material-ui/core';
import CommentIcon from '@material-ui/icons/Comment';
import List from '@material-ui/core/List';
import ListItemLink from '../Misc/ListItemLink';
import { useSaleStore } from '../../../Global/Stores/saleStore';
import ContentColumn from '../../Custom/ContentColumn';
import SummaryCardHeader from '../Home/Summaries/SummaryCardHeader';
import img from "./../../../Images/summaries/icon-ordermanagment.png";
import _ from 'lodash';

const SalesOverview = () => {
    return useMemo(() => {
        // console.log('SalesOverview');
        return (
            <ContentColumn loaded={true} access={true}>
                <SalesOverviewContent />
            </ContentColumn>
        );
    }, []);
}

const SalesOverviewContent = () => {
    const [saleState] = useSaleStore();

    const approvals = +_.get(saleState.summary, 'approvals');
    const orders = +_.get(saleState.summary, 'orders');
    const flatFees = +_.get(saleState.summary, 'flatFees');
    const shipments = +_.get(saleState.summary, 'shipments');

    return useMemo(() => {
        // console.log('SalesOverview Content');
        return (
            <React.Fragment>
                <div className="contentColumnHeader">
                    {/* <CommentIcon />
                    <Typography className="mainListHeader" component="h1" variant="h5">Order Management</Typography> */}
                    <SummaryCardHeader title="Order Management" image={img} justify="center" />
                </div>

                <div className="contentColumnBody">
                    <List component="div" disablePadding>
                        <ListItemLink text="Quotations to approve" to="/sales/quotes" number={approvals} />
                        <ListItemLink text="Create new order" to="/sales/catalogue" />
                        <ListItemLink text="Order history" to="/sales/orders" number={orders} />
                        <ListItemLink text="Flat Fee Contracts" to="/sales/flatfee" number={flatFees} />
                        <ListItemLink text="Shipment tracking" to="/sales/shipments" number={shipments} />
                        <ListItemLink text="AVCS Online" to="/sales/avcso" />
                    </List>
                </div>
            </React.Fragment>
        );

    }, [approvals, orders, flatFees, shipments]);
    // }, [saleState.summary]);
}

export default SalesOverview;
