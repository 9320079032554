import React, { useEffect } from 'react';
import { Route, Switch } from "react-router-dom";
import MapControl from '../../MapControl/MapControl';
import { useFleetStore } from '../../../Global/Stores/fleetStore';
import ViewFolios from '../Folios/ViewFolios/ViewFolios';
//import VesselFolio from './Folios/ListFolios/VesselFolios';
import FolioItems from './Holdings/FolioHoldingsDetail/FolioItems';


// Import General Support Files
import Breadcrumb from '../Navigation/Breadcrumb';

const Folio = ({ match }) => {

//     //const [, folioActions] = useFolioStore();
//     const [, fleetActions] = useFleetStore();

//     // const selectedCompanyID = +match.params.companyID || 0;
//     // console.log(selectedCompanyID);


//     useEffect(
//         () => {
//             //fleetActions.getHoldingsOverview();
//             fleetActions.getHoldingsOverview();
//             fleetActions.getFleetPositions();
//             //folioActions.getFolioOverview();
// //            folioActions.getManagers();
//         }, []
//     );

    return (
        <React.Fragment >
            <Breadcrumb Module="Fleet Management" currentPage="Folio Status" />
            {/* Folio Management*/}
            <Switch>
            <Route exact path="/folios/status/:managerID?/:folioID?" component={ViewFolios} />
            {/* <Route exact path="/folios/manage/:companyID?/:folioID?/details/:vesselID?" component={FolioItems} /> */}
            <Route exact path="/folios/status/:managerID?/:folioID?/details/:vesselID?/:itemID?" component={FolioItems} />
            </Switch>
            <MapControl />
        </React.Fragment>
    );
};

export default Folio;
