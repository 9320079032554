import React from 'react';

////// Call Map
import MapControl from '../../MapControl/MapControl';

////// Material UI support Files
import { Fab, Icon, Dialog, Slide } from '@material-ui/core';

////// Styling Things
import menuStyling from './../../Mobile/CSS/MenuStyles.module.css';

// Modal Window
// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="down" ref={ref} {...props} />;
// });

const FloatingMenuButton = () => {
  // Modal Window
  // const [open, setOpen] = React.useState(false);

  // const openMap = () => {
  //   setOpen(true);
  // };

  // const closeMap = () => {
  //   setOpen(false);
  // };

  const SwitchToMobile = () => {
    //setOpen(false);
    localStorage.setItem('isMobile', 'mobile');
    window.location.reload(true);
  };

  if (window.screen.width < 700 && window.screen.height < 1000 || window.screen.width < 1000 && window.screen.height < 700)
    {
    return (
      <div>
        {/* <div>
          <Dialog fullScreen open={open} TransitionComponent={Transition} className={menuStyling.mainDialog} >
                <div className={menuStyling.floatRightClose}>
                  <Fab color="primary" onClick={closeMap}>
                  <Icon fontSize="large" >close</Icon>
                  </Fab>
                </div>
                <MapControl showMenu={true} menuOrientation="hor"/>
          </Dialog>
        </div> */}

        <div className={menuStyling.floatRightDesktop}>
          <Fab color="primary" onClick={SwitchToMobile}>
          <Icon fontSize="large" >smartphone</Icon>
          </Fab>
        </div>
      </div>
      
    )
    }
    else 
    {
      return null
    }
}

export default FloatingMenuButton