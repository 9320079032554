import React, { useState } from 'react'
import { Table, TableHead, TableRow, TableCell, TableBody, Paper, Typography } from '@material-ui/core'
import { useMapStore } from '../../../../Global/Stores/mapStore';
import { getAvcsoImageLink } from '../../../../Global/Services/httpService';
import AvcsoFeaturePopup from './AvcsoFeaturePopup';

const AvcsoFeatureContainer = () => {
    const [mapState] = useMapStore();

    const [selectedRcid, setSelectedRcid] = useState(0)


    const featureInfo = mapState.AVCSO.featureInfo;

    if (!featureInfo)
        return (
            <Paper square elevation={0} style={{ margin: '6px 0px', border: '1px solid black' }}>
                <Typography variant="h6" align="center">
                    Click on any point on the map to show the pick report for this location.
                </Typography>
            </Paper>
        );

    const { position, features } = featureInfo;

    const selectedFeature = features.find(X => X.rcid === selectedRcid);

    const handleFeaureSelect = (feature) => {
        setSelectedRcid(P => feature.rcid)
    }


    //console.log('selectedFeature', selectedFeature)
    return (
        <React.Fragment>

            <Paper square elevation={0} style={{ margin: '6px 0px', border: '1px solid black' }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Type</TableCell>
                            <TableCell>Description</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            features.map(F => (
                                <TableRow key={F.rcid} hover selected={(F.rcid === selectedRcid)} onClick={() => handleFeaureSelect(F)}>
                                    <TableCell component="th" scope="row">{F.geometryType}</TableCell>
                                    <TableCell component="th" scope="row">{F.description}</TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </Paper>

            <FeatureAttributesTable Feature={selectedFeature} />
        </React.Fragment>
    )
}

const hideAtts = ['SCAMIN', 'SORDAT', 'SORIND'];
const FeatureAttributesTable = ({ Feature }) => {
    const [mapState,] = useMapStore();
    //const token = `token=tSN2Jq2JL2mQTWPfpiC6yIAucLj-7BWm_lSzJEYd7loCwpr_rHF-pI1tofs03NmA`;


    if (!Feature) return null;

    //const baseLink = `https://avcs-preprod.admiralty.co.uk/server/rest/services/AVCS/MapServer/exts/MaritimeChartService/MapServer/notes?f=text/plain&`;

    const { Attributes } = Feature;
    const showAttributes = Attributes.filter(X => X.description && !hideAtts.includes(X.acronym));
    // const fileAttributes = Attributes.filter(X => X.acronym.endsWith('_token'));


    // console.log('fileAttributes', fileAttributes);

    // const file = `file=7MQUuRmF3Uh=l;G@>7j4eiofafhNHOubi7F6OStE>hQwIo4:ItVwfa1Bphlw3eB7mRlw7iUmdLD0`;
    // const link = `${baseLink}${file}&${token}`;


    const getLink = (filename, fileAttr, isText) => {

        const license = mapState.AVCSO.license;

        const baseLink = license.urlNotes;
        const file = `file=${fileAttr.value}`;
        const token = `token=${license.token}`;;
        let link = `${baseLink}${file}&${token}`;
        // return (<a rel="noopener noreferrer" target="_blank" href={link}>{filename}</a>);

        let comp;
        if (isText) {
            comp = (<iframe src={link} style={{ maxHeight: '200px', width: '340px', borderStyle: 'ridge' }}></iframe>);
        }
        else {
            link = getAvcsoImageLink(link);
            // return (<img src={link} alt={filename} style={{ maxHeight: '200px', maxWidth: '340px' }} />);
            comp = (<img src={link} alt={filename} style={{ maxHeight: '200px', maxWidth: '340px' }} />);
        }
        return (<AvcsoFeaturePopup component={comp} link={link} isImage={!isText} name={filename} />);
    }


    return (
        <Paper square elevation={0} style={{ margin: '6px 0px', border: '1px solid black' }}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Key</TableCell>
                        <TableCell>Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        showAttributes.map(A => {

                            const key = A.description || A.acronym;
                            const value = A.mappedValue || A.value;

                            let media = null;
                            if (A.acronym == 'TXTDSC') {
                                const fileAttribute = Attributes.find(X => X.acronym == 'txtdsc_token');
                                if (fileAttribute) {
                                    media = getLink(value, fileAttribute, true);
                                }
                            }

                            if (A.acronym == 'PICREP') {
                                const fileAttribute = Attributes.find(X => X.acronym == 'picrep_token');
                                if (fileAttribute) {
                                    media = getLink(value, fileAttribute, false);
                                }
                            }

                            if(A.acronym == 'encaff')
                            {
                                media = value.split(',').join(', ');
                                //console.log('encaff 1', value,);
                                //console.log('encaff 2', media);
                            }
                            // if (media) {
                            //     return (
                            //         <TableRow key={A.acronym} hover >
                            //             <TableCell colSpan={2} >
                            //                 {media}
                            //             </TableCell>
                            //         </TableRow>
                            //     );
                            // }

                            return (
                                <TableRow key={A.acronym} hover>
                                    <TableCell >{key}</TableCell>
                                    <TableCell
                                        style={{
                                            whiteSpace: "normal",
                                            wordWrap: "break-word"
                                        }} >{media || value}</TableCell>
                                </TableRow>
                            );
                        })
                    }
                </TableBody>
            </Table>
        </Paper>
    )

}

export default AvcsoFeatureContainer
