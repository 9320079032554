import React, { useEffect, useState } from 'react';
import FolioItemDetailHeader from './FolioItemDetailHeader';
import FolioItemDetailList from './FolioItemDetailList';
import { useFolioStore } from '../../../../../Global/Stores/folioStore';
import HoldingsFilter from '../../../../Widgets/Filters/HoldingsFilter';
import ContentColumn from '../../../../Custom/ContentColumn';
import _ from 'lodash';

const FolioItems = ({ match }) => {
    
    const managerID = +match.params.managerID || 0;
    const folioID = +match.params.folioID || 0;
    const vesselID = +match.params.vesselID || 0;
    const itemID = +match.params.itemID || 0;
    
    const [folioState, folioActions] = useFolioStore();


    const [selectedGroups, setSelectedGroups] = useState([]);

     //console.log('itemID', itemID);

    useEffect(
        () => {
            folioActions.getFolioVesselItems(managerID, folioID, vesselID);
        },
        [vesselID, folioID]
    );

    const toggleSelectedGroup = (pgid) => {

        //console.log("toggleSelectedGroup", pgid);
        // const newState = selectedGroups.includes(pgid) ? selectedGroups.filter(X => X !== pgid) : [...selectedGroups, pgid];
        // setSelectedGroups(prev => prev.includes(pgid) ? prev.filter(X => X !== pgid) : [...prev, pgid]);
        setSelectedGroups(prev => prev.includes(pgid) ? [] : [pgid]);
    }


    const loaded = Boolean(folioState.folioVesselItems);
    //const filterID = +_.get(folioState, 'holdingDetail.filter.id', 0);

    // console.log('filterID', { filterID, ...selectedGroups });


    return (
        <React.Fragment >
            {
                loaded ? (
                    <React.Fragment>
                        <div className="contentColumnHeader">
                            <FolioItemDetailHeader
                                holdingDetail={folioState.folioVessel}
                                managerID={managerID}
                                folioID={folioID}
                            />
                        </div>

                        {/* <div className="contentColumnFilter" style={{ justifyContent: 'space-between', paddingRight: '8px' }}>
                            <HoldingsFilter
                                productItemGroups={fleetState.holdingDetail.productItemGroups}
                                fleetActions={fleetActions}
                                selectedGroups={selectedGroups}
                            />
                        </div> */}

                        <div className="contentColumnBody">
                            <FolioItemDetailList
                                holdingDetail={folioState.folioVesselItems}
                                selectedGroups={selectedGroups}
                                toggleSelectedGroup={toggleSelectedGroup}
                                //selectedItemID={vesselID} 
                                selectedItemID={itemID}
                                managerID={managerID}
                                folioID={folioID}
                                vesselID={vesselID}
                                />
                        </div>
                    </React.Fragment>) : null

            }
        </React.Fragment>
    );
};

export default FolioItems;
