const Menu = [
    {
        id: 1,
        label: "News & Announcements",
        shortDescription: "Get the latest from us",
        to: "/news",
    },
    {
        id: 2,
        label: "Order Management",
        shortDescription: "Manage orders and approvals",
        to: "/sales",
        subItems: [
            { id: 1, label: 'Quotations To Approve', to: "/sales/quotes" },
            { id: 2, label: 'Create New Order', to: "/sales/catalogue" },
            { id: 3, label: 'Order History', to: "/sales/orders" },
            { id: 4, label: 'Flat Fee Contracts', to: "/sales/flatfee" },
            { id: 5, label: 'Shipment Tracking', to: "/sales/shipments" },
            { id: 6, label: 'AVCS Online', to: "/sales/avcso" },
        ],
    },
    {
        id: 3,
        label: "Fleet Management",
        shortDescription: "Manage fleet and updates",
        to: "/fleet",
        subItems: [
            { id: 1, label: 'Fleet Holdings', to: "/fleet/holdings" },
            { id: 2, label: 'Fleet Status', to: "/fleet/status" },
            // { id: 3, label: 'Fleet Tracking', to: "/fleet/tracking" },
            { id: 4, label: 'Fleet Overview', to: "/fleet/overview" },
            { id: 5, label: 'Navigator Updates', to: "/fleet/navigator" },
            { id: 6, label: 'Folio Status', to: "/folios/status" },
        ],
    },
    // {
    //     id: 3,
    //     label: "Reports",
    //     to: "/reports",
    //     subItems: [
    //         { id: 1, label: 'Noon report' },
    //         { id: 2, label: 'Ship Performance' },
    //         { id: 3, label: 'ENC Usage' },
    //         { id: 4, label: 'Alarms & Notifications' },
    //         { id: 5, label: 'Compliancy check' },
    //         { id: 6, label: 'ECDIS Info' },
    //     ],
    // },
    // {
    //     id: 4,
    //     label: "News",
    //     to: "/news",
    //     subItems: [
    //     ],
    // },
    // {
    //     id: 5,
    //     label: "e-Reader",
    //     to: "/e-reader",
    //     subItems: [
    //     ],
    // },
    // {
    //     id: 6,
    //     label: "Map",
    //     to: "/map",
    //     subItems: [
    //     ],
    // }

];

export default Menu;