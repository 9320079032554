import React, { createContext, useContext, useState } from 'react';
// import { useUserStore } from './userStore';
import httpService from '../Services/httpService';

const initialState = {
    mapLayers: [
        1, // OSM
        2, // AVCSO
    ],
    drawerMenu: {
        activeMenu: 0, // 0=Closed, 1=AVCSO Layers, 2=AVCSO FeatureGroups, 3= AVCSO Features, 4=GetFeatures, 5=Routes
    },
    activeLayer: 1, // Default to OSM
    activeOverlays: [1], // 1=fleet Positions
    activeRoutes: [],
    options: {
        useFlyTo: true,
    },
    bounds: [],
    AVCSO: {
        // layers: { value: "20,19,18,17,16,15,14,13,12,11,10,9,8,7,6,5,4,3,2,1,0" },
        layers: { value: [21, 20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0] },
        parameter: [],
        parameterGroup: [
            {
                name: "DatasetDisplayRange",
                Parameter: [
                    {
                        name: "minZoom",
                        value: 0.01,
                    }
                ]
            }
        ],
        featureInfo: null,
        selectionMode: 0, // 0=Normal, 1=GetFeatureInfo
        license: {
            url: "",
            token: null,
            expiration: null,
            userRef: null,
            UserId: null,
            version: null,
            format: null,
        },
        EULA: null,
    }
};

export const MapContext = createContext();

export const MapProvider = ({ children }) => {
    const [state, setState] = useState(initialState);

    // // Clear data when logout
    // const [userState] = useUserStore();
    // const logout = !Boolean(userState.tokenData.token);
    // useEffect(() => {
    //     if (logout === true) {
    //         console.log('MapProvider logout', logout);
    //         setState(P => initialState);
    //     }
    // }, [logout]);

    const loadData = () => {
        //console.log("loadData");
    }

    const clearData = () => {
        //console.log("clearData");
        setState(() => { return { ...initialState } });
    }

    const zoomTo = (points) => {
        setState(prev => {
            return {
                ...prev,
                bounds: points
            }
        });
    }

    const zoomToPoint = (long, lat) => {
        setState(prev => {
            const padFactor = 0.1;
            return {
                ...prev,
                bounds: [
                    [lat - padFactor, long - padFactor],
                    [lat + padFactor, long + padFactor]
                ]
            }
        });
    }

    // This part seems to assume that all incoming lines will have have an item before the actual polygonPoints.
    // Which is not exactly the case for all the API results. 
    // SOLUTION: when calling zoomToLines, include a 2nd parameter (Boolean). if not defined, we will assume it true
    // True means it has a sub .item
    //This way, we don't have to visit all the codes. we will just apply to what we are working on. MUHAHAHAHAHAHAHAHAHAHA (unneccessary laughter)

    const zoomToLines = (lines, gotItem = true) => {
        //Check if lines have .item with it
        //  if(Boolean(lines.item)){
        //      lines = lines.item;
              //console.log("bbbbbNOW: ", lines)
        //  }
        if(gotItem)
        {
            if (lines) {
                if (Array.isArray(lines)) {
                    
                    const points = lines
                        .filter(X => X.item.polygonPoints && X.item.polygonPoints.length > 0)
                        .map(X => X.item.polygonPoints)
                    zoomTo(points);
                }
                else if (lines.item) {
                    zoomTo(lines.item.polygonPoints);
                }
            }
        }
        else
        {
            if (lines) {
                if (Array.isArray(lines)) {
                    //console.log("bbbbIt gets here")
                    const points = lines
                        .filter(X => X.polygonPoints && X.polygonPoints.length > 0)
                        .map(X => X.polygonPoints)
                    zoomTo(points);
                }
                else {
                    zoomTo(lines.polygonPoints);
                }
            }
        }

        //Yes! I hate repeating codes too. yet here we are! life in it self. God help us! :/
    }

    // ORIGINAL CODE: BACKUP
    // const zoomToLines = (lines) => {
    //     console.log(lines)
    //     if (lines) {
    //         if (Array.isArray(lines)) {
    //             console.log("It gets here")
    //             const points = lines
    //                 .filter(X => X.item.polygonPoints && X.item.polygonPoints.length > 0)
    //                 .map(X => X.item.polygonPoints)
    //             zoomTo(points);
    //         }
    //         else if (lines.item) {
    //             zoomTo(lines.item.polygonPoints);
    //         }
    //     }
    // }

    const setAvcsoParameter = ({ Parameter, IsGroup, GroupName }) => {
        setState(prev => {

            //console.log('setAvcsoParameter', GroupName);
            const { name, value } = Parameter;

            const newAVCSO = { ...prev.AVCSO };

            if (IsGroup == false) {
                // Individual Parameters
                let newParameters = [...prev.AVCSO.parameter];
                const val = newParameters.find(X => X.name === name);

                if (val) {
                    val.value = value;
                }
                else {
                    newParameters = [...newParameters, { ...Parameter }]
                }

                newAVCSO.parameter = newParameters;
                //console.log('newAVCSO', newAVCSO);
                return {
                    ...prev,
                    AVCSO: newAVCSO,
                }
            }
            else {

                // Group Parameters
                let newGroups = [...prev.AVCSO.parameterGroup];
                let grp = newGroups.find(X => X.name === GroupName);

                // Create Group if not exists
                if (!grp) {
                    grp = {
                        name: GroupName,
                        Parameter: []
                    }
                    newGroups = [...prev.AVCSO.parameterGroup, grp]
                }

                // Find parameter in group
                let par = grp.Parameter.find(X => X.name === name);

                // Create parameter in group if not exists
                if (!par) {
                    par = { ...Parameter }
                    grp.Parameter = [...grp.Parameter, par];
                    // console.log('create Par', par, grp);
                }
                else {
                    // Set new parameter value

                    if (value !== null) {
                        // Change value of parameter
                        par.value = value;
                    }
                    else {
                        // Remove Parameter
                        grp.Parameter = grp.Parameter.filter(X => X.name !== name);
                    }
                }

                newAVCSO.parameterGroup = newGroups;


                //console.log('Group Parameters', newAVCSO);

                return {
                    ...prev,
                    AVCSO: newAVCSO,
                }
            }
        });
    }

    const setAvcsoLayers = (lays) => {

        setState(prev => {

            const newAVCSO = { ...prev.AVCSO };
            newAVCSO.layers = lays;
            return {
                ...prev,
                AVCSO: newAVCSO,
            }
        });
    }


    const setAvcsoFeatureInfo = (featureInfo) => {

        setState(prev => {

            const newAVCSO = { ...prev.AVCSO };
            newAVCSO.featureInfo = featureInfo;
            return {
                ...prev,
                AVCSO: newAVCSO,
            }
        });
    }

    const toggleAvcsoSelectionMode = () => {


        setState(prev => {

            const newState = { ...prev };
            const newAVCSO = newState.AVCSO;
            newAVCSO.selectionMode = (newAVCSO.selectionMode + 1) % 2;

            if (newAVCSO.selectionMode == 1) {
                // GetFeaturInfo Active
                newState.drawerMenu.activeMenu = 4;
            }
            else if (newState.drawerMenu.activeMenu === 4) {
                newState.drawerMenu.activeMenu = 0;
            }

            // //console.log('toggleAvcsoSelectionMode', newAVCSO.selectionMode);
            // return {
            //     ...prev,
            //     AVCSO: newAVCSO,
            // }

            return newState;
        });
    }

    const getAvcsoToken = (expiration) => {

        const pars = {
            params: {
                expiration: expiration,
            }
        }

        httpService.get('/api/auth/avcso', pars)
            .then(result => {
                const data = result.data;

                //console.log('getAvcsoToken', data);
                if (data && data.token) {

                    setState(prev => {

                        const newState = { ...prev.AVCSO };
                        newState.license = data;
                        return {
                            ...prev,
                            AVCSO: newState
                        }
                    });
                }
            })
            .catch(error => {
                //console.error('getAvcsoToken', error);
            });
    }

    const setActiveLayer = (layerID) => {

        setState(prev => {
            const drawerMenu = { ...prev.drawerMenu };
            drawerMenu.activeMenu = 0;

            const newAVCSO = { ...prev.AVCSO };
            newAVCSO.selectionMode = 0;

            return {
                ...prev,
                activeLayer: layerID,
                AVCSO: newAVCSO,
                drawerMenu: drawerMenu,
            }
        });
    }

    const setOverlayVisible = (overlayID, active) => {

        setState(prev => {
            let overlays = [...prev.activeOverlays];

            // console.log('overlays 1', overlays);

            if (active === true) {
                overlays = [...overlays, overlayID];
            }
            else {
                overlays = overlays.filter(X => X !== overlayID);
            }

            // console.log('overlays 2', overlays);

            return {
                ...prev,
                activeOverlays: overlays,
            }
        });

    }

    const setActiveDrawerMenu = (activeMenu) => {

        setState(prev => {
            const drawerMenu = { ...prev.drawerMenu };

            if (drawerMenu.activeMenu !== activeMenu) {
                drawerMenu.activeMenu = activeMenu;
            }
            else {
                drawerMenu.activeMenu = 0;
            }

            return {
                ...prev,
                drawerMenu: drawerMenu,
            }
        });
    }


    const setAvcsoEULA = (accepted) => {

        setState(prev => {
            const newAVCSO = { ...prev.AVCSO };
            newAVCSO.EULA = accepted;
            return {
                ...prev,
                AVCSO: newAVCSO,
            }
        });

        if (accepted === true) {
            httpService.post('/api/auth/avcso/eula', { accept: true })
                .then(result => {
                    //console.log('result.data', result.data);
                });
        }
    }

    const getAvcsoEULA = () => {

        httpService.get('/api/auth/avcso/eula')
            .then(result => {
                const data = result.data;
                //console.log('EULA_DATA', data)

                setState(prev => {
                    const newAVCSO = { ...prev.AVCSO };
                    newAVCSO.EULA = data.eula;
                    return {
                        ...prev,
                        AVCSO: newAVCSO,
                    }
                });
            });
    }


    const isFeatureInfoActive = () => {
        return state.drawerMenu.activeMenu === 4;
    }

    const setActiveRoutes = (routes) => {
        setState(prev => {
            return {
                ...prev,
                activeRoutes: routes,
            }
        });
    }

    const clearActiveRoutes = () => {
        setActiveRoutes([]);
    }

    const actions = {
        loadData: loadData,
        clearData: clearData,

        setActiveLayer,
        setActiveDrawerMenu,
        setOverlayVisible,

        isFeatureInfoActive,

        zoomTo,
        zoomToPoint,
        zoomToLines,
        // zoomToQuote

        setAvcsoLayers,
        setAvcsoParameter,
        setAvcsoEULA,
        setAvcsoFeatureInfo,
        getAvcsoEULA,
        toggleAvcsoSelectionMode,
        getAvcsoToken,

        // routes
        setActiveRoutes,
        clearActiveRoutes
    };

    return (
        <MapContext.Provider value={[state, actions]}>
            {children}
        </MapContext.Provider >
    )
};

export const useMapStore = () => useContext(MapContext);


