import React from 'react'

// Maetrial UI support files
import { Typography, Divider, Button, ButtonGroup } from "@material-ui/core";

// Import General Support Files
import Breadcrumb from '../Navigation/Breadcrumb';

const HelpStatement = () => {
    return(
        <React.Fragment>
            <Breadcrumb Module="Help &amp; Support" currentPage="Help" />
            <div style={{padding: 10}}>
                <div>
                    <h1><b>We Love to Support!</b></h1>
                    <Divider />
                    <Typography>
                        We know how important support is to every business. So we have taken time to provide you with a lot of information on our systems and answers to Frequently Asked Questions.
                        <br /><br />Please do take your time to go through. Your answer might just there. If its not, feel free to contact us. We are always ready to help and improve on our support.<br /><br />
                    </Typography>
                    <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
                        <Button href="/help/faqs">FAQs</Button>
                        <Button href="/help/usermanual">User Manual</Button>
                        <Button href="/help/contactus">Contact us Directly</Button>
                    </ButtonGroup>
                    <br /><br />
                </div>
            </div>
        </React.Fragment>
    )
}

export default HelpStatement