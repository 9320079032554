import React, { useEffect, useMemo, useState } from 'react';
import { FeatureGroup, Popup, Tooltip } from 'react-leaflet'
import { Table, TableBody, TableRow, TableCell, Typography } from '@material-ui/core';
import { useFleetStore } from '../../../Global/Stores/fleetStore';
import { useGlobalStore } from '../../../Global/Stores/globalStore';
import TrackMarker from '../Markers/TrackMarker';
import Moment from 'react-moment';
import { useParams } from 'react-router-dom';


const FleetPositionsLayer = ({ Visible, mapActions }) => {

    // const { map } = useLeaflet();

    // let params = useParams();
    // console.log('FleetPositionsLayer Params', params);

    // let pars = useParams();
    // let location = useLocation();
    // let history = useHistory();
    // let routeMatch = useRouteMatch();

    // console.log('useParams', pars);
    // console.log('useLocation', location);
    // console.log('useHistory', history);
    // console.log('routeMatch', routeMatch);

    const [globalState] = useGlobalStore();
    const [fleetState, fleetActions] = useFleetStore();

    // const selectedVesselID = +match.params.vesselID || 0;
    const selectedVesselID = 0;

    const [popup, setPopup] = useState({
        key: 0,
        position: null,
        lines: []
    });

    const vessels = globalState.vessels || [];
    const loaded = Boolean(fleetState.fleetPositions) && Boolean(globalState.vessels);

    // console.table(fleetState.fleetPositions);
    // console.table(globalState.vessels);

    // const positions = fleetState.fleetPositions;
    const positions = fleetState.fleetPositions;

    useEffect(() => {

        fleetActions.getFleetPositions();

    }, []);

    useEffect(() => {
        if (loaded && selectedVesselID > 0) {
            const pos = fleetState.fleetPositions.find(X => X.vesselID === selectedVesselID);

            if (pos) {
                mapActions.zoomToPoint(pos.long, pos.lat);
            }
        }
    }, [selectedVesselID]);


    // useEffect(() => {

    //     console.warn("Track", L);
    //     // const ts3 = new L.aisTrackSymbol({ "latitude": 54.3, "longitude": 8.1 }).addTo(map);
    //     return () => {

    //     };
    // }, [])



    return useMemo(() => {
        //console.log('FleetPositionsLayer', positions);

        // console.log('useMemo');

        // if (!loaded || !Visible)
        //     return null;

        const showVessels = loaded && Visible;
        //const showVessels = false;

        // return null;


        const handleVesselClick = (e) => {

            setPopup(prev => {
                return {
                    key: prev.key + 1,
                    position: e.latlng,
                    lines: e.layer
                }
            });
        }

        return (
            <React.Fragment>
                <FeatureGroup onClick={handleVesselClick}>

                    {/* <VesselPositionPopup
                        vessel={globalState.vessels.find(V => V.vesselID === P.vesselID)}
                        vesselPosition={P}
                    /> */}


                    {
                        showVessels && positions.map(P => {

                            const vsl = vessels.find(V => V.vesselID === P.vesselID);

                            // if(!vsl){
                            //     console.log('Vessel not found:', P.vesselID);
                            // }

                            return vsl ? (
                                <TrackMarker
                                    key={P.vesselLocationID}
                                    vessel={vsl}
                                    vesselPosition={P}>

                                    <VesselPositionPopup
                                        Vessel={vsl}
                                        VesselPosition={P}
                                    />
                                    <Tooltip direction="center" offset={[0, 20]}>{vsl.vesselName}</Tooltip>

                                </TrackMarker>
                            ) : null;
                        })
                    }
                </FeatureGroup>
            </React.Fragment>
        );
    }, [positions, loaded, Visible, selectedVesselID, popup, vessels]);
};


const VesselPositionPopup = ({ Vessel, VesselPosition }) => {

    const { vesselName, mmsi, imo } = Vessel;
    const { logDate, speed, course, long, lat } = VesselPosition;

    const speedValue = speed <= 0 ? "N/A" : `${speed} kn`;
    const courseValue = course <= 0 ? "N/A" : `${course}°`;

    return useMemo(() => {

        return (
            <Popup className="vesselPositionPopup">
                <Table size="small" padding="none" className="pupupTable">
                    <TableBody>

                        <TableRow className="ctcolors">
                            <TableCell colSpan={2}>
                                <Typography variant="subtitle2" align="center" style={{ color: '#eee' }}>{vesselName}</Typography>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>
                                <Typography variant="caption" >Timestamp:&nbsp;</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="caption"><Moment date={logDate} format="YYYY-MM-DD HH:mm:ss" /></Typography>
                            </TableCell>
                        </TableRow>


                        <TableRow>
                            <TableCell>
                                <Typography variant="caption">Position:</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="caption">{`Lat: ${lat.toFixed(2)} Lng: ${long.toFixed(2)}`}</Typography>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>
                                <Typography variant="caption">MMSI:</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="caption">{mmsi || 'N/A'}</Typography>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>
                                <Typography variant="caption">IMO:</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="caption">{imo || 'N/A'}</Typography>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>
                                <Typography variant="caption">Speed:</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="caption">{speedValue}</Typography>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>
                                <Typography variant="caption">Course:</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="caption">{courseValue}</Typography>
                            </TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
            </Popup>
        );

    }, [Vessel, VesselPosition]);


}


export default FleetPositionsLayer;
