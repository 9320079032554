import React, { useMemo } from 'react';
import { ListItemIcon } from '@material-ui/core';
import CircleIcon from '@material-ui/icons/FiberManualRecord';

const OrderLineStatus = ({ line }) => {


    const getSalesOrderStatusClass = (salesOrderLineStatusID) => {
        let result = "statusGray";
        switch (salesOrderLineStatusID) {
            case 3:
                result = "statusOrange";
                break;
            case 4:
                result = "statusGreen";
                break;
            case 5:
                result = "statusRed";
                break;
            default:
        }
        return result;
    };

    const className = getSalesOrderStatusClass(line.salesOrderLineStatusID);

    return useMemo(() => {

        return (
            <ListItemIcon className={className} style={{ width: '30px', minWidth: '24px' }} >
                <CircleIcon />
            </ListItemIcon>
        );

    }, line.salesOrderLineStatusID);

};

export default OrderLineStatus;
