import React, { useMemo } from 'react';
import { getSalesOrderStatusColor } from '../Helpers/GlobalHelper';
import ItemPolygon from './ItemPolygon';

const SalesOrderLinePolygon = ({ line, selected }) => {

    return useMemo(() => {

        if (!line.item.polygonPoints)
            return null;

        const positions = line.item.polygonPoints;
        const color = getSalesOrderStatusColor(line.salesOrderLineStatusID);

        return (
            <ItemPolygon
                positions={positions}
                fillColor={color}
                color={color}
                selected={selected}
            />
        );

    }, [selected]);

};

export default SalesOrderLinePolygon;
