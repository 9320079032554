import React from 'react';
import { Typography, List, Divider } from '@material-ui/core';
import { useGlobalStore } from '../../../../Global/Stores/globalStore';
import ContentColumn from '../../../Custom/ContentColumn';
import ListItemVessel from '../../_StyledComponents/ListItemVessel';
import _ from 'lodash';
import SearchFilter from '../../Misc/SearchFilter';

const FleetDetails = ({ match }) => {

    const [globalState] = useGlobalStore();
    const [filter, setFilter] = React.useState('');
    const [sort, setSort] = React.useState(1);


    const selectedVesselID = +match.params.vesselID || 0;

    const loaded = Boolean(globalState.vessels);

    const vessels = _.orderBy(
        (globalState.vessels || []).filter(X => (X.vesselName.toLowerCase().indexOf(filter) !== -1)),
        ['vesselName'],
        [sort > 0 ? 'asc' : 'desc']);

    const counter = selectedVesselID + filter.length + sort;
    return (
        <ContentColumn loaded={loaded} selectedID={counter}>
            <div className="contentColumnHeader" style={{ padding: '0px 10px' }}>
                <Typography variant="h6">Fleet Overview</Typography>
            </div>

            <div className="contentColumnHeader" style={{ padding: '0px 10px' }}>
                <SearchFilter placeholder="Search Vessel" onSearch={(F) => setFilter(() => F.toLowerCase())} onSort={(S) => setSort(() => S)} />
            </div>

            <div className="contentColumnBody disablePadding">
                {
                    <List component="div" disablePadding>
                        {
                            vessels
                                .map(X => (
                                    <ListItemVessel
                                        key={X.vesselID}
                                        vessel={X}
                                        // onClick={handleVesselClick}
                                        selected={X.vesselID === selectedVesselID}
                                        linkTo={`/fleet/overview/${X.vesselID}`}
                                    />
                                ))
                        }
                        <Divider />
                    </List>
                }
            </div>
        </ContentColumn>
    );
}

export default FleetDetails
