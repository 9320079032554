import React from 'react'
// import { Router, Route, Link, browserHistory, IndexRoute } from 'react-router-dom'
import { Link, useHistory } from "react-router-dom";

////// Images
import pageNotFoundBG from './Images/OldShip-Fading.jpg';


////// Material UI
import {Button } from '@material-ui/core';

const PageNotFound = () => {
    
    // let children = "hey";
    function BackButton() {
        let history = useHistory()
        // console.log(history.length);
            return (
                <React.Fragment>
                    <Button variant="contained" size="small" color="secondary" onClick={() => history.goBack(1)}>
                        Previous Page
                    </Button>
                    &nbsp; &nbsp;
                    <Link to="/home" style={{textDecoration: "none"}}>
                        <Button variant="contained"  size="small" color="secondary" >
                            Home
                        </Button>
                    </Link>
                </React.Fragment>
            )
      }

    //   let history = useHistory()

    return (
        <div style={{ backgroundImage: `url(${pageNotFoundBG})`, backgroundPosition: "Center bottom", backgroundSize: "contain", 
                      backgroundRepeat: "no-repeat", textAlign: "center", width: 300, height: 400, marginLeft: 30 }}>
            <p> <b>404 Error!</b><br />
                oops! We could not find the page you requested for. <br />
                Where Do you want to sail to?
            </p>
            {BackButton()}
        </div>
    )
}

export default PageNotFound