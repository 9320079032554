
function fixLat(lat) {
    if (lat > 85 || lat < -85) {
        if (lat > 85) {
            return 85;
        }
        if (lat < -85) {
            return -85;
        }
    }
    return lat;
}

const regex = /\(([^()]+)\)/g;
const wktToCoordinatesArray = (wkt) => {
    let polygon = [];

    //console.log(wkt);
    if (!wkt)
        return polygon;

    let pols = wkt.split("),(");
    for (let i = 0; i < pols.length; i++) {
        polygon[i] = [];
        let results;
        while ((results = regex.exec(pols[i]))) {
            const line = results[1];
            const points = line.split(",");

            //points.pop(); // remove last point

            points.forEach(point => {
                const xy = point.split(" ");
                polygon[i].push([fixLat(parseFloat(xy[1])), parseFloat(xy[0])]);
            });
        }
    }
    return polygon;
}

const checkPolygonInPoint = (LatLng, polyPoints) => {

    for (let index = 0; index < polyPoints.length; index++) {
        const poly = polyPoints[index];
        if (isMarkerInsidePolygon(LatLng, poly) === true)
            return true
    }
}

const isMarkerInsidePolygon = (LatLng, polyPoints) => {

    if (polyPoints.length === 0)
        return false;

    const y = LatLng.lng;
    const x = LatLng.lat;

    let inside = false;
    for (let i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
        const xi = polyPoints[i][0], yi = polyPoints[i][1];
        const xj = polyPoints[j][0], yj = polyPoints[j][1];

        const intersect = ((yi > y) !== (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);

        if (intersect)
            inside = !inside;
    }
    return inside;
};

const setSalesQuoteLines = (salesQuote) => {
    salesQuote.lines.forEach(line => {
        line.item.polygonPoints = wktToCoordinatesArray(line.item.polygon);
    });
}

const getLineBounds = (lines) => {
    return lines
        .filter(X => X.item.polygonPoints && X.item.polygonPoints.length > 0)
        .map(X => X.item.polygonPoints);
}



export { checkPolygonInPoint }