const MobileConfiguration =
    {
        Shutdown:
            { 
                Code: "900",
                Status: false,
                Header: "Mobile Version Unavailable",
                ShortDescription: "System Maintenance" ,
                LongDescription: "Our Technical Team is working on maintaing a perfect System for you. This usually takes 30minutes to an hour.",
                Resolve: "Please check back shortly or contact our customer service agents for alternative solutions to what you want to do",
            },
        Version:
        { 
            Current: "1.0.0",
            PreviousVersion: ""
        },
        Display:
        { 
            MobileVersion: null,
        }
    }

export default MobileConfiguration