import React, { useMemo, useState, useEffect } from 'react';
import List from '@material-ui/core/List';
// import SummaryCardHeader from './SummaryCardHeader';
import ListItemLink from '../Misc/ListItemLink';
// import SummaryCard from './SummaryCard';
// import img from "./../../../../Images/summaries/icon-news.png";
import { Divider } from '@material-ui/core';
import Moment from 'react-moment';
import { useGlobalStore } from '../../../Global/Stores/globalStore';

const AnnouncementSummary = () => {

    const [globalState, globalActions] = useGlobalStore();
    const newsItems = globalState.newsItems || [];

    useEffect(() => {
        globalActions.getAnnouncements();
    }, []);

    return useMemo(() => {
        return (
            <div>
                {/* <SummaryCardHeader title="News & Announcements" image={img} /> */}

                <List component="nav" dense >

                    {
                        newsItems.map(X => (<AnnouncementLine key={X.chartagentNewsID} newsItem={X} />))
                    }



                    {/* <AnnouncementLine text="Exceptional large data size for paper corrections for week 28/20" to="/news/1" />
                    <AnnouncementLine text="Upgrade your fleet today!" to="/news/2" />
                    <AnnouncementLine text="Support for Navigator 1 discontinued from July 2019" to="/news/3" /> */}
                    <Divider />
                </List>
            </div >
        );
    }, [newsItems]);
}


export const AnnouncementLine = ({ newsItem, selected }) => {

    return useMemo(() => {

        const { chartagentNewsID, title, startDate } = newsItem;
        const to = `/news/${chartagentNewsID}`;
        const isSelected = selected === true;
        // const posted = Date.parse(startDate).toLocaleString();
        const posted = (<Moment date={startDate} format="LL" />);



        return (
            <React.Fragment>
                <Divider />
                <ListItemLink text={title} subText={posted} to={to} isHeader={false} selected={isSelected} />
            </React.Fragment>
        )
    }, [newsItem]);
}

export default AnnouncementSummary;
