import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import { Switch } from '@material-ui/core';

const PurpleSwitch = withStyles({
  switchBase: {
    color: red[300],
    '&$checked': {
      color: green[500],
    },
    '&$checked + $track': {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

const ValidationWidget = ({ validationID, onChange }) => {

  const check = validationID === 1;

  const handleChange = (e) => {
    e.preventDefault();
    // console.log('handleChange', check);
    onChange(check === true ? 2 : 1);
  }

  return (
    // <div className="validation-widget" >
    <PurpleSwitch
      edge="start"
      onClick={handleChange}
      checked={check}
    />
    // </div>
  );
};

export default ValidationWidget;
