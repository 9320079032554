import React from 'react';
import { Polygon } from 'react-leaflet';

const defaultWeight = 1;
const defaultWeightSelected = 5;

const ItemPolygon = (props) => {

    const selected = Boolean(props.selected || false);
    const focused = Boolean(props.focused || false);

    const fillOpacity = focused === true ? 0.2 : 0.01;
    const weight = selected === true ? defaultWeightSelected : defaultWeight;

    // const positions = props.positions;

    return (
        <Polygon
            {...props}
            weight={weight}
            fill={true}
            fillOpacity={fillOpacity}
        />
    );
};

export default ItemPolygon;
