import React, { useEffect } from 'react';
import { Route } from "react-router-dom";
import MapControl from '../../MapControl/MapControl';
import NewsList from './NewsList';
import NewsDetail from './NewsDetail';

const News = () => {

    return (
        <div className="fleetContainer">
            <Route  path="/news" component={NewsList} />
            <Route path="/news/:newsID?" component={NewsDetail} />

            <MapControl />
        </div>
    );
}

export default News;
