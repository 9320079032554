import React, { useState, useMemo } from 'react';
import { List, ListItem, ListItemText, ListItemIcon, Collapse, Link } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import _ from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import { HoldingLinesStatus } from '../../../../Widgets/ProductGroupStatus';
import HoldingLineStatus from '../../../../Widgets/HoldingLineStatus';

const HoldingDetailList = ({ holdingDetail, selectedItemID, selectedGroups, toggleSelectedGroup }) => {


    const { vessel, productGroups } = holdingDetail;

    // console.log('HoldingDetailList', holdingDetail);

    if (!holdingDetail || !vessel.vesselItems) return null;


    let lines = vessel.vesselItems;
    if (holdingDetail.filter) {
        const statusFilter = holdingDetail.filter.status;
        lines = lines
            // .filter(X => (holdingDetail.filter.productGroupID === -1 || holdingDetail.filter.productGroupID === X.item.productGroupID) &&
            //     holdingDetail.filter.scale.includes(X.item.productItemGroupID || 0))
            .filter(X => X.active || statusFilter.inactive)
            .filter(X => (
                (
                    (statusFilter.issue && (X.issue)) ||
                    (statusFilter.warning && (X.warning)) ||
                    (statusFilter.valid && (X.valid))
                    // (!statusFilter.inactive && (X.active))
                )));
        //console.log('Lines', lines, statusFilter.inactive);
    }

    lines = _.orderBy(lines, ['item.itemNumber'], ['asc']);

    const headers = productGroups.map(X => <HoldingProductGroup
        key={`V${vessel.vesselID}PG${X.productGroupID}`}
        productGroup={X}
        vessel={vessel}
        lines={lines}
        open={selectedGroups.includes(X.productGroupID)}
        toggleSelectedGroup={toggleSelectedGroup}
        selectedItemID={selectedItemID}
    />);


    return (
        <List className="ListItemHeader" disablePadding>
            {headers}
        </List>
    );
};



const HoldingProductGroup = ({ productGroup, vessel, lines, open, toggleSelectedGroup, selectedItemID }) => {

    const productgroupItemsAll = vessel.vesselItems.filter(I => I.item.productGroupID === productGroup.productGroupID);
    const productgroupItems = lines.filter(I => I.item.productGroupID === productGroup.productGroupID);

    const itemLength = productgroupItems.length;


    return useMemo(() => {

        // console.log('productgroupItemsAll.length', productgroupItemsAll.length);
        // console.log('productgroupItems.length', open, productgroupItems.length);

        if (productgroupItemsAll.length === 0)
            return null;

        return (
            <React.Fragment>
                <ListItem onClick={() => toggleSelectedGroup(productGroup.productGroupID)} button divider selected={open}>
                    <ListItemIcon>
                        <SendIcon />
                    </ListItemIcon>

                    <ListItemText primary={productGroup.caption} />
                    <HoldingLinesStatus lines={vessel.vesselItems.filter(I => I.item.productGroupID === productGroup.productGroupID)} />
                    {/* <Chip color="primary" variant="default" label={productgroupItems.length} size="small" /> */}
                    {
                        open ? <ExpandLess /> : <ExpandMore />
                    }
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {
                            productgroupItems.map(X => <HoldingLine key={X.item.itemID} line={X} selected={selectedItemID === X.item.itemID} />)
                        }
                    </List>
                </Collapse>
            </React.Fragment>);

    }, [open, selectedItemID, itemLength]);
}


const HoldingLine = ({ line, selected }) => {
    const to = `/fleet/holdings/${line.vesselID}/${line.item.itemID}`;
    return (
        <Link className="ListItemLink" component={RouterLink} to={to} color='inherit' style={{ textDecoration: 'none' }}>
            <ListItem button disableRipple dense divider selected={selected}>
                <HoldingLineStatus line={line} />
                <ListItemText primary={line.item.itemNumber} secondary={line.item.itemTitle} />
            </ListItem>
        </Link>
    );
}

export default HoldingDetailList;
