import React, { useEffect, useState, useMemo } from 'react'
import ContentColumn from '../../../Custom/ContentColumn';
import { Typography, Button, List } from '@material-ui/core';
import httpService from '../../../../Global/Services/httpService';
import { BasketButton } from '../Catalogue/Catalogue';
import { useAvcsoStore } from '../../../../Global/Stores/avcsoStore';

const AVCSO = () => {

    const [avcsoState, avcsoActions] = useAvcsoStore();
    // const [state, setState] = useState(null);

    const loaded = avcsoActions.isLoaded();
    const hasLicense = avcsoActions.hasAvcsoLicense();
    // console.log('AVCSO', state, hasLicense);

    useEffect(() => {
        avcsoActions.loadData();

    }, []);


    return (
        <ContentColumn loaded={loaded}>
            {
                hasLicense ? <AvcsoLicenses /> : <AvcsoInformation />
            }
        </ContentColumn>
    );
}


const AvcsoLicenses = () => {

    return (
        <React.Fragment>

            <div className="contentColumnHeader" style={{ padding: '0px 10px' }}>
                <Typography variant="h6">AVCSO Licenses</Typography>

            </div>

            <div className="contentColumnBody">
                <AvcsoButtonList />
            </div>

            <div className="contentColumnFooter">

            </div>
        </React.Fragment>

    );
}

const AvcsoButtonList = ({ Vessel }) => {
    return (
        <div
            className={`contentColumnFilter`}
            style={{ alignItems: 'stretch', flexDirection: 'column' }}>

            <List component="div">

                <AvcsoAddItemsButton />
                <AvcsoReviewButton />
                <AvcsoConfirmButton />

            </List>
        </div>
    );
}

const AvcsoAddItemsButton = () => {
    const [avcsoState] = useAvcsoStore();
    const { basketLines } = avcsoState;

    const selected = basketLines.length > 0;
    const disabled = false;

    // return useMemo(() => {

    return (
        <BasketButton
            Text="1. Add items to basket:"
            To={`/sales/avcso/add/`}
            Location={`/sales/avcso/add/`}
            Disabled={disabled}
            Selected={selected}
            Done={selected}
        />);

    // }, []);
}


const AvcsoReviewButton = () => {
    const [avcsoState, avcsoActions] = useAvcsoStore();
    const { basketLines } = avcsoState;


    // const hasLines = Boolean(basketLines.length);

    // const vesselID = basketState.vesselID;
    const basketItemCount = basketLines.length;
    const selected = avcsoActions.hasBasketPrices();
    //const selected = null;
    const disabled = basketLines.length === 0;


    useEffect(() => {

        avcsoActions.getBasketPrices();

    }, []);


    return useMemo(() => {

        // if (!hasLines)
        //     return null;

        return (
            <BasketButton
                Text="2. Review Basket:"
                To={`/sales/avcso/basket/`}
                Location={`/sales/avcso/basket/`}
                Disabled={disabled}
                Selected={selected}
                Count={basketLines.length}
            />);

    }, [basketItemCount, disabled, selected]);
}

const AvcsoConfirmButton = () => {
    const [avcsoState, avcsoActions] = useAvcsoStore();
    // const hasLines = Boolean(basketState.basketLines);

    // const vesselID = basketState.vesselID;
    const lineCount = avcsoState.basketLines.length;
    const pricesLoaded = avcsoActions.hasBasketPrices();
    const disabled = (lineCount === 0) || (!pricesLoaded);
    const done = !disabled && avcsoState.reference.length > 0;

    // return useMemo(() => {

    //     if (!hasLines)
    //         return null;

    return (
        <BasketButton
            Text="3. Confirm Basket:"
            To={`/sales/avcso/confirm/`}
            Location={`/sales/avcso/confirm/`}
            Disabled={disabled}
            Done={done}
        />);

    // }, [hasLines, vesselID, disabled, done]);
}

const AvcsoInformation = () => {

    return (
        <React.Fragment>
            <div className="contentColumnHeader" style={{ padding: '0px 10px' }}>
                <Typography variant="h6">AVCS Online</Typography>
            </div>

            <div className="contentColumnBody" style={{ padding: '10px' }}>
                <Typography variant="subtitle2">
                    With AVCS online you can now view official UKHO ENC’s directly in your web browser.
                    This allows you to verify the safety of intended routes for your vessels, while looking at the same charts as on board.
                </Typography>
                <br />
                <Typography variant="subtitle2">
                    A standard AVCS Online license grants access to all ENC’s in band 1.
                </Typography>
                <br />

                <Typography variant="subtitle2">
                    Additional ENC coverage can be purchased and added to your AVCS Online license, should this be required.
                </Typography>
                <br />

                <Typography variant="subtitle2">
                    AVCS Online is the easiest way to have updated ENC’s available to you at any time, anywhere you are.
                </Typography>
                <br />

                <Typography variant="caption">
                    -	In depth insight for maritime professionals     -
                </Typography>
            </div>

            <div className="contentColumnFooter">
                <RequestAvcsoInformationButton />
            </div>
        </React.Fragment>
    );

}




const RequestAvcsoInformationButton = () => {


    const handleRequestInformation = () => {

        // const vesselID = 0;

        httpService.get(`/api/sales/avcso/information`)
            .then(response => {
                const data = response.data;
                // console.log('handleRequestInformation', data);
            })
            .catch(error => {
                //alert(error);
            });
    }


    return (
        <Button
            variant="contained"
            size="small"
            fullWidth
            onClick={handleRequestInformation}>Request more information</Button>);
}

export default AVCSO
