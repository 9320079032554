import React from 'react'

// Maetrial UI support files 
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Breadcrumb from '../Navigation/Breadcrumb';


const UserManual = () => {
    return(
        <React.Fragment>
            <Breadcrumb Module="Help &amp; Support" currentPage="User Manual" />
            <div  style={{margin: 10}}>
                <h1><b>ChartTrack Application Manual</b></h1>
                
                <div>
                    <h2>Colour Key</h2>
                    <p>Following colour key is used throughout the ShipManager site:</p>
                    <p>Green: Good
                    <br />Yellow: Warning
                    <br />Red: Issue
                    <br />Grey: Neutral/Not applicable</p>
                    <p>For more information on the colour key used for a specific section, please go to the Help information of that section.</p>
                    <h1>Home </h1>
                    <p>Provides a high-level overview of your account and fleet.
                    <br />Shows functions grouped per module and lets you navigate easily.</p>
                    <h2>News &amp; Announcements</h2>
                    <p>General announcements from your chart agent, system maintenance, product updates, ...
                    <br />Click on any item to see full details.</p>
                    <h2>Order Management</h2>
                    <p>All order related information and actions.</p>
                    <h3>Quotations to approve</h3>
                    <p>Provides the possibility to approve and reject quotes sent to you by your chart agent.</p>
                    <p>
                    <img src="http://www.charttrack.com/Shipmanager/QuoteSTatus.png" alt="Quote Status" /> The status circle in the header shows the total number of items and the status of the items.</p>
                    <p>Colour key:
                    <br />Green: Item has been approved.
                    <br />Red: Item has been rejected.
                    <br />Grey: Still to approve/reject.</p>
                    <p>Select the vessel whose quotations you want to approve, and then select the quotation. Click on an item in the list for details and to zoom to the selected item on the map. Once all items have been approved/rejected, click the &lt;Confirm&gt; button. You will be able to change your reference, if necessary, before sending the order through for processing. Please note the order will be processed immediately after confirmation, this can't be undone!</p>
                    <h3>Create new order</h3>
                    <p>Allows you to create new orders. Orders can be created from routes, existing baskets or quotes, the catalogue, and any mix of these.
                    <br />Select the vessel fpr which you want to order, select the source for your orders (route, basket, quote, catalogue), review and confirm.
                    <br />You can create and save routes, upload basket files, select existing quotes to select items from. Once you add your selected items to the basket, the srouce for your items will reset and you can add more items by selecting another source.
                    <br />Durarions and quantities can be set during basket review.
                    <br />An order reference must be set before final confirmation.</p>
                    <p>Please note the order will be processed immediately after confirmation, this can't be undone!</p>
                    <h3>Order history</h3>
                    <p>Provides an overview of the order history for the current and previous year for each vessel.</p>
                    <p>
                    <img src="http://www.charttrack.com/Shipmanager/OrderStatus.png" alt="Order Status" />Status circle indicates the total number of items in the order and order status for each item.</p>
                    <p>Colour key:
                    <br />Grey: Your chart agent has received your order.
                    <br />Red: Item(s) on backorder.
                    <br />Yellow: Item(s) shipped.
                    <br />Green: Item(s) delivered.</p>
                    <p>Clicking any order in the list shows the order details and status per item.</p>
                    <h3>Flat Fee contracts</h3>
                    <p>Provides an overview of your running flat fee contracts, if you have any.</p>
                    <p>Select the vessel whose flat fee contracts you wish to see. Select a contract to see what orders were placed within the contract. Select an order to see the items in it.</p>
                    <h3>Shipment tracking</h3>
                    <p>Shows status of all your shipments.</p>
                    <p>Select the vessel whose shipments you want to see. Click any shipment to see the items in it. If available, the tracking link 
                    <img src="http://www.charttrack.com/Shipmanager/ShipmentTracker.png" alt="Track Shipment"  /> will open the transport company's tracking website showing actual status of your shipment.</p>
                    <h3>AVCS Online</h3>
                    <p>Allows you to contact your chartagent to activate an AVCS Online subscription.
                    <br />If you have an active AVCSO subscription, allows you to order individual AVCSO cells.</p>
                    <h2>Fleet Management</h2>
                    <p>Complete overview of your fleet with compliance issues &amp; warnings.</p>
                    <h3>Fleet Holdings</h3>
                    <h3>Fleet Status</h3>
                    <h3>Fleet Tracking</h3>
                    <p>Allows you to contact your chartagent to activate fleet tracking for all your vessels.
                    <br />If you have fleet tracking activated, your vessels can be shown on the map, with tracking details.</p>
                    <h3>Fleet overview</h3>
                    <p>shows a per-vessel overview of your entire fleet. Selecting any vessel shows you full details.
                    <br />Certificate of enrolment and ChartTrack Navigator license can be downloaded and sent to vessel here.</p>
                    <h3>Navigator Updates</h3>
                    <p>Shows last Navigator update for all your vessels.
                    <br />Selecting a vessels shows details about their updates.</p>
                    <p>Colour key:
                    <br />Grey: Vessel does not use ChartTrack Navigator.
                    <br />Red: Vessel has not updates in over 2 weeks.
                    <br />Yellow: Vessel has not updated in over 1 week.
                    <br />Green: Vessel has updated within the last week.</p>
                    <h2>Help </h2>
                    <p>Opens this help page.</p>
                    <h2>Profile &amp; Users </h2>
                    <p>Manage your profile and your users.</p>
                    <table>
                        <tr>
                            <td colspan="2" >Colour key:</td>
                        </tr>
                        <tr>
                            <td class="indent-10">Green</td>
                            <td>No issues or warnings, completely up to date.</td>
                        </tr>
                        <tr>
                            <td class="indent-10">Yellow</td>
                            <td>Paper products: An edition, correction or updates is out of date, but no more than 1 week.
                            <br />Digital licenses: Licenses are renewable.</td>
                        </tr>
                        <tr>
                            <td class="indent-10">Red</td>
                            <td>Paper products: An edition, correction or updates is more than 1 week out of date.
                            <br />Digital licenses: Licenses have expired but are still in their grace period or have been deprecated.</td>
                        </tr>
                        <tr>
                            <th colspan="2">Update information:</th>
                        </tr>
                        <tr>
                            <td colspan="2">Overview of the updating behaviour of the vessels in your fleet.
                            <br />(relevant for vessels using Navigator only)</td>
                        </tr>
                        <tr>
                            <td colspan="2" >Colour key:</td>
                        </tr>
                        <tr>
                            <td class="indent-10">Green</td>
                            <td>Vessel is updating on a weekly basis.</td>
                        </tr>
                        <tr>
                            <td class="indent-10">Yellow</td>
                            <td>Vessel hasn't updated for a week.</td>
                        </tr>
                        <tr>
                            <td class="indent-10">Red</td>
                            <td>Vessel hasn't updated for more than a week</td>
                        </tr>
                        <tr>
                            <td class="indent-10">Grey</td>
                            <td>Vessel is not using Navigator.</td>
                        </tr>
                    </table>
                    <table class="helptable-approvals">
                        <colgroup>
                            <col class="c3" />
                            <col />
                        </colgroup>
                        <thead>
                            <tr>
                                <th colspan="2">
                                    <h3 class="c4">Quotations to approve</h3>
                                </th>
                            </tr>
                            <tr>
                                <th colspan="2" class="c6">
                                    <h3 class="c5">
                                        <small>Provides the possibility to approve and reject quotes sent to you by your chart agent.</small>
                                    </h3>
                                </th>
                            </tr>
                        </thead>
                        <tfoot>
                            <tr>
                                <td colspan="2">&nbsp;</td>
                            </tr>
                        </tfoot>
                        <tbody>
                            <tr>
                                <td colspan="2">
                                    <img src="/images/help/vessels-status.png" alt="" class="c7" />
                                    <div>The status circle in the header shows the total number of items and the status of the items.</div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" >Colour key:</td>
                            </tr>
                            <tr>
                                <td class="indent-10">Green</td>
                                <td>Item has been approved.</td>
                            </tr>
                            <tr>
                                <td class="indent-10">Red</td>
                                <td>Item has been rejected.</td>
                            </tr>
                            <tr>
                                <td class="indent-10">Grey</td>
                                <td>Still to approve/reject.</td>
                            </tr>
                            <tr>
                                <td colspan="2">Click on an item in the list for details and to zoom to the selected item on the map.
                                <br />
                                <br />Once all items have been approved/rejected, click the "Order" button.
                                <br />You will be able to change your reference, if necessary, before confirming the order.
                                <br /></td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <h4 class="text-danger">Please note the order will be processed immediately after confirmation, this can't be undone!</h4>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="helptable-approvals">
                        <colgroup>
                            <col class="c3" />
                            <col />
                        </colgroup>
                        <thead>
                            <tr>
                                <th colspan="2">
                                    <h3 class="c4">Create new order</h3>
                                </th>
                            </tr>
                            <tr>
                                <th colspan="2" class="c6">
                                    <h3 class="c5">
                                        <small>Provides the possibility to create new orders.</small>
                                    </h3>
                                </th>
                            </tr>
                        </thead>
                        <tfoot>
                            <tr>
                                <td colspan="2">&nbsp;</td>
                            </tr>
                        </tfoot>
                        <tbody>
                            <tr>
                                <td colspan="2">
                                    <img src="/images/help/vessels-status.png" alt="" class="c7" />
                                    <div>The status circle in the header shows the total number of items and the status of the items.</div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" >Colour key:</td>
                            </tr>
                            <tr>
                                <td class="indent-10">Green</td>
                                <td>Item has been approved.</td>
                            </tr>
                            <tr>
                                <td class="indent-10">Red</td>
                                <td>Item has been rejected.</td>
                            </tr>
                            <tr>
                                <td class="indent-10">Grey</td>
                                <td>Still to approve/reject.</td>
                            </tr>
                            <tr>
                                <td colspan="2">Click on an item in the list for details and to zoom to the selected item on the map.
                                <br />
                                <br />Once all items have been approved/rejected, click the "Order" button.
                                <br />You will be able to change your reference, if necessary, before confirming the order.
                                <br /></td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <h4 class="text-danger">Please note the order will be processed immediately after confirmation, this can't be undone!</h4>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="helptable-vessels">
                        <colgroup>
                            <col class="c3" />
                            <col />
                        </colgroup>
                        <thead>
                            <tr>
                                <th colspan="2">
                                    <h3 class="c4">Vessels</h3>
                                </th>
                            </tr>
                            <tr>
                                <th colspan="2" class="c6">
                                    <h3 class="c5">
                                        <small>Provides detailed information about the vessels in your fleet.</small>
                                    </h3>
                                </th>
                            </tr>
                        </thead>
                        <tfoot>
                            <tr>
                                <td colspan="2">&nbsp;</td>
                            </tr>
                        </tfoot>
                        <tbody>
                            <tr>
                                <td colspan="2">
                                    <img src="/images/help/vessels-chart.png" alt="" class="c7" />
                                    <div>Provides inventory and status information for all your vessels.
                                    <br />The status circles indicate the total number of items in inventory (per vessel or per product) and their status.</div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" >Colour key:</td>
                            </tr>
                            <tr>
                                <td class="indent-10">Green</td>
                                <td>Item/Update status are completely up to date.</td>
                            </tr>
                            <tr>
                                <td class="indent-10">Yellow</td>
                                <td>For paper products: Item/Update status is out of date, but no more than 1 week.
                                <br />For Digital licenses: License is renewable or update status is out of date, but no more than 1 week.</td>
                            </tr>
                            <tr>
                                <td class="indent-10">Red</td>
                                <td>For paper products: Item/Update status is out of date, for more than 1 week.
                                <br />For Digital licenses: Licenses have expired but are still in their grace period, have been deprecated or update status is out of date, for more than 1 week.</td>
                            </tr>
                            <tr>
                                <td colspan="2">Click a vessel in the list to get detailed information on the vessel.
                                <br />Click a product type in the list to see the items in inventory and their status.</td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <img src="/images/help/vessels-filter.png" alt="" />
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">Click on an item in the list for details and to zoom to the selected item on the map.</td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="helptable-orderhistory">
                        <colgroup>
                            <col class="c3" />
                            <col />
                        </colgroup>
                        <thead>
                            <tr>
                                <th colspan="2">
                                    <h3 class="c4">Order History</h3>
                                </th>
                            </tr>
                            <tr>
                                <th colspan="2" class="c6">
                                    <h3 class="c5">
                                        <small>Provides an overview of the full order history for each vessel.</small>
                                    </h3>
                                </th>
                            </tr>
                        </thead>
                        <tfoot>
                            <tr>
                                <td colspan="2">&nbsp;</td>
                            </tr>
                        </tfoot>
                        <tbody>
                            <tr>
                                <td colspan="2">
                                    <img src="/images/help/orders-chart.png" alt="" class="c7" />
                                    <div>Status circle indicates the total number of items in the order and order status for each item.</div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" >Colour key:</td>
                            </tr>
                            <tr>
                                <td class="indent-10">Grey</td>
                                <td>Your chart agent has received your order.</td>
                            </tr>
                            <tr>
                                <td class="indent-10">Red</td>
                                <td>Item(s) on backorder.</td>
                            </tr>
                            <tr>
                                <td class="indent-10">Yellow</td>
                                <td>Item(s) shipped.</td>
                            </tr>
                            <tr>
                                <td class="indent-10">Green</td>
                                <td>Item(s) delivered.</td>
                            </tr>
                            <tr>
                                <td colspan="2">Clicking any order in the list shows the order details and status per item.</td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <img src="/images/help/orders-filter.png" alt="" />
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">Click on an item in the list for details and to zoom to the selected item on the map.</td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="helptable-account">
                        <colgroup>
                            <col />
                            <col />
                        </colgroup>
                        <thead>
                            <tr>
                                <th colspan="2">
                                    <h3 class="c4">Account</h3>
                                </th>
                            </tr>
                            <tr>
                                <th colspan="2" class="c6">
                                    <h3 class="c5">
                                        <small>Provides a way to manage user accounts.</small>
                                    </h3>
                                </th>
                            </tr>
                        </thead>
                        <tfoot>
                            <tr>
                                <td colspan="2">&nbsp;</td>
                            </tr>
                        </tfoot>
                        <tbody>
                            <tr>
                                <td colspan="2">Profile: Allows you to change your e-mail and password.
                                <br />Users: Admin users also have the possibility to add and remove other users for your account, and assign Shipmanger modules access.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    )
}

export default UserManual