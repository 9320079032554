import React, { useState } from 'react'
import { Container, Typography, TextField, InputAdornment, IconButton, Button, Box, Grid, Link } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import AccountCircle from '@material-ui/icons/MailOutline';
import queryString from 'query-string';
import http from '../../Global/Services/httpService';
import CT_Logo from "./../../Images/CT_TEXT.png";
import _ from 'lodash';

const ResetPassword = ({ match, location, history, Title }) => {

    const [formState, setFormState] = useState({
        password: '',
        password2: '',
        remember: false,
        showPassword: false,
        loading: false,
    });

    const query = queryString.parse(location.search, {});

    // const midsVal = query.mids || ''

    // console.log('access_token', query.access_token, !query.access_token);
    // console.log('mids', query);

    if (!query.access_token) {
        history.replace('/login');
    }

    const token = query.access_token;

    // console.log('token', token);

    const handleChange = event => {
        const target = event.target;
        if (!formState.loading)
            setFormState({ ...formState, [target.name]: (target.type === 'checkbox' ? target.checked : target.value) });
    };

    const handleClickShowPassword = () => {
        if (!formState.loading)
            setFormState({ ...formState, showPassword: !formState.showPassword });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setFormState({ ...formState, loading: true });

        const postData = {
            Password: formState.password,
            Password2: formState.password2,
            mids: query.mids,
        };

        http.post('/api/account/setpassword', postData, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(result => {
                // actions.login(result.data);
                const typeID = _.get(result.data, 'toast.typeID');
                if (typeID === 0)
                    history.replace('/login');

                // console.log('typeID', typeID);
                // console.log('result.data', result.data);
            })
            .catch((error) => {
                // console.log('login error', error)
                // notificationActions.showNotification({variant: 'error', message: 'Invalid Credentials'});
                setFormState({
                    ...formState,
                    password: '',
                    password2: '',
                    loading: false
                });
            });
    };


    const validPassword = formState.password.length > 5 && formState.password === formState.password2;

    return (
        <Grid container direction="row" justify="center" alignItems="center" style={{ height: '75%' }}>

            {/* <Grid item>
                <a href="https://www.charttrack.com/" target="_blank">
                    <img className="logo" src={CT_Logo} />
                </a>
            </Grid> */}


            <Grid item>
                <Container component="div" maxWidth="xs">
                    <Box component="div" maxWidth="xs" border={1} borderRadius={5} padding={3}>
                        <Grid container direction="row" justify="center" alignItems="center">
                            <Grid item>
                                <a href="https://www.charttrack.com/" target="_blank">
                                    <img className="logo" src={CT_Logo} />
                                </a>
                            </Grid>
                        </Grid>

                        <Typography component="h1" variant="h5" align={"center"}>{Title}</Typography>
                        <form noValidate>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                autoFocus
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type={formState.showPassword ? 'text' : 'password'}
                                id="password"
                                autoComplete="new-password"
                                value={formState.password}
                                onChange={handleChange}
                                InputProps={{
                                    // autoComplete: "new-password",
                                    // form: { autoComplete: "off", },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                aria-label="Toggle password visibility"
                                                onClick={handleClickShowPassword}
                                            >
                                                {formState.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password2"
                                label="Repeat Password"
                                type={formState.showPassword ? 'text' : 'password'}
                                id="password2"
                                autoComplete="new-password"
                                value={formState.password2}
                                onChange={handleChange}
                                InputProps={{
                                    // autoComplete: "new-password",
                                    // form: { autoComplete: "off", },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                aria-label="Toggle password visibility"
                                                onClick={handleClickShowPassword}
                                            >
                                                {formState.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />


                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={!validPassword || formState.loading}
                                // className={classes.submit}
                                onClick={onSubmit}
                            >RESET PASSWORD</Button>
                        </form>
                        <Box mt={2}>
                            <Typography variant="body2" color="textSecondary" align="center">
                                <Link color="inherit" href="http://www.charttrack.com/" target="_blank">
                                    &copy; Chart Track {new Date().getFullYear()}
                                </Link>
                            </Typography>
                        </Box>

                    </Box>

                </Container>
            </Grid>
        </Grid>
    )
}

export default ResetPassword
