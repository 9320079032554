import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, Typography, DialogActions, Box, TextField } from '@material-ui/core';
import AddSharpIcon from '@material-ui/icons/AddSharp';
import { PortList, RoutingList } from '../../Desktop/Sales/Catalogue/RouteCreate/RouteCreate';
import { useGlobalStore } from '../../../Global/Stores/globalStore';
import { useParams } from "react-router";
import httpService from '../../../Global/Services/httpService';

const CreateRouteButton = () => {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Button
                variant="outlined"
                component="label"
                style={{ minWidth: '40px', padding: '5px' }}
                onClick={handleClickOpen}
            >
                <AddSharpIcon />
            </Button>
            <CreateRouteDialog open={open} handleClose={handleClose} />
        </React.Fragment>
    );
};

const CreateRouteDialog = ({ open, handleClose }) => {

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle onClose={handleClose}>Create new route</DialogTitle>
            <CreateRoute handleClose={handleClose} />
            {/* <DialogContent dividers style={{ padding: '0px', width: '600px' }}>
                <CreateRoute />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">Create</Button>
                <Button autoFocus onClick={handleClose} color="primary">Close</Button>
            </DialogActions> */}
        </Dialog>
    )
}


const CreateRoute = ({ handleClose }) => {

    const [globalState, globalActions] = useGlobalStore();

    const [selectedPorts, setSelectedPorts] = useState([]);
    const [routingPoints, setRoutingPoints] = useState([]);
    const [routeName, setRouteName] = useState("");

    const params = useParams();
    const vesselID = +params.vesselID;

    const ports = globalState.portInfo.ports;
    // const routingPointCount = routingPoints.length;

    const hasPorts = Boolean(ports);
    // if (!ports) return null;

    // console.log('globalState.portInfo.routingPoints', globalState.portInfo.routingPoints);
    const allRoutingPoints = globalState.portInfo.routingPoints;

    useEffect(() => {
        if (hasPorts) {
            setRoutingPoints([...allRoutingPoints])
        }
    }, [hasPorts, allRoutingPoints])


    const createDisabled = (selectedPorts.length < 2) || (routeName.length < 3);

    const AddPort = (port) => {
        setSelectedPorts(prev => [...prev, { ...port, id: Math.random() }]);
    }

    const DeletePort = (port) => {
        setSelectedPorts(prev => prev.filter(X => X.id !== port.id))
    }

    const ToggleRoutingPoint = (point) => {

        const rps = [...routingPoints];
        const p1 = rps.find(P => P.name === point.name);
        // console.log(p1.open);
        p1.open = !(Boolean(point.open));
        setRoutingPoints(() => rps)
    }

    const handleCreateRoute = () => {

        const ports = selectedPorts.map(P => P.code);
        const routes = routingPoints
            .filter(P => P.open !== P.openByDefault)
            .map(P => P.shortCode);

        const result = {
            PortCodes: ports,
            RoutingCodes: routes,
            RouteName: routeName
        };

        httpService.post(`api/catalogue/routes/create`, result)
            .then(result => {
                // const newRouteID = result.data;
                // console.log("Result.DATA", newRouteID);
                globalActions.getRoutes();
                handleClose();
                // history.push('/sales/catalogue/5994/routes/0');
                // history.push(`/sales/catalogue/${vesselID}/routes/${newRouteID}`);
            })
            .catch(error => {
                //alert(error)
            });
    }


    return (
        <React.Fragment>
            <DialogContent dividers style={{ padding: '0px', width: '600px' }}>
                <Box border={0} style={{ padding: '20px 10px' }}>

                    <PortList AddPort={AddPort} DeletePort={DeletePort} ports={globalState.portInfo.ports} selectedPorts={selectedPorts} />

                    <RoutingList RoutingPoints={routingPoints} ToggleRoutingPoint={ToggleRoutingPoint} />

                    <div style={{ marginTop: '16px' }}>
                        <Typography variant="subtitle2" >Route Name:</Typography>
                        <TextField
                            fullWidth
                            id="outlined-name"
                            size="small"
                            label="Name"
                            value={routeName}
                            onChange={(X) => {
                                const val = X.target.value;
                                setRouteName(() => val)
                            }}
                            margin="dense"
                            variant="outlined"
                        />
                    </div>


                    {/* <Button fullWidth size="small" variant="outlined" disabled={createDisabled} onClick={CreateRoute}>Create Route</Button> */}
                </Box>
            </DialogContent>

            <DialogActions>
                <Button disabled={createDisabled} onClick={handleCreateRoute} color="primary" variant="outlined">Create</Button>
                <Button autoFocus onClick={handleClose} color="primary" variant="outlined">Close</Button>
            </DialogActions>
        </React.Fragment>
    );
}

export default CreateRouteButton;
