import React, { useEffect, useState, useMemo } from 'react'
import { Box, Typography, Divider, TextField, Button, Card, CardActions, CardContent } from '@material-ui/core';
import { useUserStore } from '../../../../Global/Stores/userStore';
import { isValidateEmail } from '../../../../Global/Data/Methods';
import http from '../../../../Global/Services/httpService';
import ContentColumn from '../../Custom/ContentColumn';
import ProtectedArea from '../../../Custom/ProtectedArea';

import Breadcrumb from '../../Navigation/Breadcrumb';

const Profile = () => {
    const [userState, userActions] = useUserStore();
    const [loading, setLoading] = useState(false);
    const [profileState, setProfileState] = useState();

    useEffect(() => {
        setProfileState(() => ({ ...userState.user, password: "", password2: "" }))
    }, []);

    return useMemo(() => {

        if (!profileState)
            return null;

        const { firstName, lastName, email, password, password2 } = profileState;
        // console.log('profileState', profileState);

        const canSave = (
            (firstName.length > 0) &&
            (lastName.length > 0) &&
            (email.length > 0) &&
            (isValidateEmail(email)) &&
            (password.length > 5) &&
            (password === password2));

        const handleChange = (e) => {
            if (!loading) {
                const { name, value } = e.target;
                setProfileState(() => ({ ...profileState, [name]: value }));
            }
        };

        const onSubmit = (e) => {
            e.preventDefault();

            if (!canSave) return;

            const postData = {
                firstName: firstName,
                lastName: lastName,
                email: email,
                password: password,
            };

            setLoading(() => true);

            http.post('/api/account/users/change', postData)
                .then(result => {

                    // console.log('changed Profile', result.data);
                    userActions.login({ user: result.data.user });
                })
                .catch(() => {

                    setLoading(() => false);
                });
        }

        return (
            <ContentColumn loaded={true}>
                <Breadcrumb Module="Account Settings" currentPage="My Profile" />
                <br />
                <div style={{ width: "100%", alignContent: "center" }} disabled={true}>
                    <Card elevation={20} >
                        <CardContent>
                            <Typography variant="h1" component="h2">
                                First Name
                            </Typography>
                            <Typography variant="h1" component="h1">
                                {firstName}
                            </Typography>
                            <br />
                            <Typography variant="h1" component="h2">
                                Last Name
                            </Typography>
                            <Typography variant="h1" component="h1">
                                {lastName}
                            </Typography>
                            <br />
                            <Typography variant="h1" component="h2">
                                Email Address
                            </Typography>
                            <Typography variant="h1" component="h1">
                                {email}
                            </Typography>
                        </CardContent>
                        <Divider />
                        <CardActions>
                            <Button size="small" href="/account/profile">Edit Profile</Button>
                        </CardActions>
                    </Card>
                </div>
            </ContentColumn>
        )

    }, [profileState, loading])
}

export default Profile
