
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { withLeaflet, MapControl } from "react-leaflet";
import L from "leaflet";
import AVCS_LOGO from './../../../Images/AVCS/AVCS_LOGO.png';
// import AVCS_LOGO from './../../../Images/AVCS/ADM_TM_rgb.png';

class MapAVCSOLogo extends MapControl {

    constructor(props, context) {
        super(props);
    }

    openLink = () => {
        //console.log('openLink');
        window.open(`https://www.admiralty.co.uk/digital-services/digital-charts/admiralty-vector-chart-service`, `_blank`);
    }

    createLeafletElement(opts) {
        const MapAVCSOLogo = L.Control.extend({
            onAdd: map => {
                this.panelDiv = L.DomUtil.create("div", "leaflet-avcso-image");
                this.panelDiv.innerHTML = ReactDOMServer.renderToString(<img src={AVCS_LOGO} style={{bottom: '30px!important'}} />)
                return this.panelDiv;
            },
            onRemove: map => {
                this.panelDiv = L.DomUtil.create("div", "leaflet-avcso-image");
                return this.panelDiv;
            },
        });
        return new MapAVCSOLogo({ position: (this.props.position || "bottomleft") });
    }

    componentDidMount() {
        const { map } = this.props.leaflet;
        this.leafletElement.addTo(map);
        this.leafletElement.getContainer().addEventListener("click", this.openLink);
    }

    componentWillUnmount() {
        // const { map } = this.props.leaflet;
        this.leafletElement.getContainer().removeEventListener("click", this.openLink);
        this.leafletElement.remove();
    }
}

export default withLeaflet(MapAVCSOLogo);