import React from 'react';
import { withRouter } from 'react-router-dom';
import BackIcon from '@material-ui/icons/ArrowBack';
import { IconButton, Divider, ListItemText } from '@material-ui/core';
import { MailOrderDetailTo } from '../../../Misc/MailTo';
import { DownloadOrder } from '../../../Misc/DownloadFile';
import OrderStatus from '../../../../Widgets/OrderStatus';

const OrderDetailHeader = ({ orderDetail, history }) => {

    if (!orderDetail || !orderDetail.order) return null;

    const { order } = orderDetail;

    const handleBack = () => {
        const url = history.location.pathname;
        const popLink = url.substring(0, url.lastIndexOf("/"));
        history.push(popLink);
    }

    return (
        <React.Fragment>
            <IconButton onClick={handleBack}>
                <BackIcon />
            </IconButton>
            <ListItemText className="flexFill" primary={order.reference} secondary={`${order.customerReference} (${order.orderDate})`}>
            </ListItemText>

            <OrderStatus order={order} />

            <MailOrderDetailTo orderDetail={orderDetail} />

            <DownloadOrder salesOrderID={order.salesOrderID} />

            <Divider />
        </React.Fragment>
    );
};

export default withRouter(OrderDetailHeader);
