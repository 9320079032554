import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@material-ui/core';
import Loading from '../Desktop/Misc/Loading';
import { useColumnStore } from '../../Global/Stores/columnStore';
import { useUserStore } from '../../Global/Stores/userStore';

const ContentColumnNoMemo = ({ children, loaded, selectedID = null, access = false, disablePadding = false }) => {

    const [, columnActions] = useColumnStore();
    const [, userActions] = useUserStore();

    const [featureAccess, setFeatureAccess] = useState(false);

    const location = window.location.pathname;

    const id = selectedID === null ? new Date() : selectedID;

    useEffect(() => {
        const hasAccess = access || userActions.canAccessPath(window.location.pathname);
        setFeatureAccess(() => hasAccess);
    }, [])

    useEffect(() => {
        columnActions.addColumn(1);
        return () => {
            columnActions.addColumn(-1);
        };
    }, []);


    // console.log('location', window.location.pathname);

    const paddingClass = disablePadding === true ? 'disablePadding' : '';

    // return useMemo(() => {
         //console.log('location', location);

        return featureAccess === true ? (
            <Box className={`contentColumn ${paddingClass}`} borderTop={1} borderRight={1} borderBottom={1}>
                {/* <Loading /> */}
                {loaded === true ? children : <Loading />}
            </Box>
        ) : null;

    // }, [loaded, featureAccess, location, id]);

};

export default ContentColumnNoMemo;
