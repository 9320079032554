import React from 'react'

// Maetrial UI support files
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Breadcrumb from '../Navigation/Breadcrumb';


const ContactUs = () => {
    return(
        <React.Fragment>
            <Breadcrumb Module="Help &amp; Support" currentPage="Contact Us" />
            <div>
                <h1>So you still want to contact us?</h1>
                
            </div>
        </React.Fragment>
    )
}

export default ContactUs