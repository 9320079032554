import React, { useMemo } from 'react';
import ItemPolygon from '../Polygons/ItemPolygon';
// import { useSaleStore } from '../../../Global/Stores/saleStore';
import _ from 'lodash';

// const WorldAreaLayer = ({ match }) => {
const WorldAreaLayer = ({ worldArea }) => {
    // const [saleState] = useSaleStore();

    // const flatFeeID = +match.params.id || 0;
    // const vesselFlatFeePeriodID = _.get(saleState, 'flatFeeDetail.flatFee.vesselFlatFeePeriodID', -1);
    // const isValid = vesselFlatFeePeriodID === flatFeeID;

    // const worldArea = _.get(saleState, 'flatFeeDetail.flatFee.worldArea', null);


    return useMemo(() => {

        // console.log('WorldAreaLayer', worldArea);
        if (!worldArea)
            return null;

        const { caption, description, polygonPoints } = worldArea;

        const color = "orange";
        const selected = true;
        return (
            <ItemPolygon
                positions={polygonPoints}
                fillColor={color}
                color={color}
                selected={selected}
            />
        )

    }, [worldArea]);
};

export default WorldAreaLayer;
