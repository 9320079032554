import React from 'react';
import { CircularProgress } from '@material-ui/core';

const Loading = () => {
    return (
        <div className="loadingSpinner">
            <CircularProgress color="primary" />
            <CircularProgress color="primary" />
            <CircularProgress color="primary" />
        </div>
    );
};

export default Loading;
