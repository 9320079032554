import React, { useEffect, useState } from 'react';
import ContentColumn from '../../../Custom/ContentColumn';
import { Typography, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
// import { useFleetStore } from '../../../../Global/Stores/fleetStore';
import httpService from '../../../../Global/Services/httpService';
import Moment from 'react-moment';
import DoneIcon from '@material-ui/icons/Done';
import { useFleetStore } from '../../../../Global/Stores/fleetStore';


const NavigatorUpdateList = ({ match }) => {

    const loaded = true;
    const vesselID = +match.params.vesselID || 0;

    const [, fleetActions] = useFleetStore();

    useEffect(() => {
        fleetActions.zoomToVessel(vesselID);
    }, [vesselID]);

    return (
        <ContentColumn loaded={loaded}>
            <div className="contentColumnHeader" style={{ padding: '0px 10px' }}>
                <Typography variant="h6">Update History</Typography>
            </div>

            <div className="contentColumnBody disablePadding">

                <VesselUpdateHistory VesselID={vesselID} />

            </div>
        </ContentColumn>
    );
};

const VesselUpdateHistory = ({ VesselID }) => {
    const [updates, setUpdates] = useState([]);

    useEffect(() => {

        httpService.get(`/api/fleet/navigator/${VesselID}`)
            .then(response => {
                const data = response.data;
                // console.log('VesselUpdateHistory', data);
                setUpdates(() => data);
            })
            .catch(error => {
                //alert(error);
            });


    }, [VesselID]);

    return (
        <Paper>
            <Table size="small" stickyHeader >
                <TableHead>
                    <TableRow>
                        <TableCell align="center">#</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Size</TableCell>
                        <TableCell>Loaded</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        updates.map(row => (
                            <TableRow hover key={`V${VesselID}R${row.edbRequestID}`}>
                                <TableCell align="center" >{row.edbRequestID}</TableCell>

                                <TableCell >
                                    <Moment date={row.receiveDateTime} format="YYYY/MM/DD" />
                                </TableCell>

                                <TableCell >{readableBytes(row.updateSize)}</TableCell>

                                <TableCell align="center" >{row.loaded ? <DoneIcon fontSize="small" /> : ''}</TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </Paper>
    );
}

const readableBytes = (bytes) => {
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']


    if (!bytes) return 'N/A'

    const i = Math.floor(Math.log(bytes) / Math.log(1024));

    return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
}

export default NavigatorUpdateList;
