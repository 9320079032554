import React, { useMemo } from 'react';
import withRoot from './Global/MUI/withRoot';
import { Switch, Route, Redirect } from "react-router-dom";
import { useUserStore } from './Global/Stores/userStore';
// import Login from './Components/Authentication/Login';
import AppAuthenticated from './Components/AppAuthenticated';
import Analytics from './Components/Helper/Analytics';
import Notifications from './Components/Helper/Notifications';
// import NewUserPassword from './Components/Authentication/NewUserPassword';
// import ForgotPassword from './Components/Authentication/ForgotPassword';
// import UserInvitation from './Components/Authentication/UserInvitation';
// import '../node_modules/leaflet-ais-tracksymbol/src/'

// Load Checkscreen to determine which device/view version to load
import CheckScreen from "./Components/CheckScreen";

/* 
Note: Inside the CheckScreen Component contains the condition to switch between mobile and desktop views through out the application

TODO: In Checkscreen, allow option to switch to desktop view during browse.
*/
function App(props) {
  const [, userActions] = useUserStore();
  const isAuthenticated = userActions.isAuthenticated();

  return useMemo(() => {

    const content = isAuthenticated ? <AppAuthenticated /> : (
      <React.Fragment>
        <Switch>
          <CheckScreen {...props} authed={false} /> {/* IMPORTANT: In this componenet holds the condition to switch to mobile or desktop view. Please make view changes within the CheckScreen Component. */}
        </Switch>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <Analytics />
        <Notifications />
        {content}
      </React.Fragment>
    );

  }, [isAuthenticated]);
}

export default withRoot(App);
