import React from 'react';
import { Typography, Box } from '@material-ui/core';
// import CommentIcon from '@material-ui/icons/Comment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import List from '@material-ui/core/List';
import ListItemLink from '../../Misc/ListItemLink';
import ContentColumn from '../../../Custom/ContentColumn';

const Users = () => {
    return (
        <ContentColumn loaded={true} access={true}>
            <div className="contentColumnHeader">
                <AccountCircle />
                <Typography className="mainListHeader" component="h1" variant="h5">User Management</Typography>
            </div>

            <div className="contentColumnBody">
                <List component="div" disablePadding>
                    <ListItemLink text="Create Users" to="/account/users/create" />
                    <ListItemLink text="Manage Users" to="/account/users/manage" />
                </List>
            </div>
        </ContentColumn>
    )
}

export default Users
