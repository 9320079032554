import React, { createContext, useContext, useState } from 'react';
// import http from './../Services/httpService';

const initialState = {
    notifications: [
        // {
        //   variant: 'error',
        //   message: 'Welcome to Shi Manager 2.0'
        //   displayTime: 1000 // time in milliseconds
        // }
    ]
};


export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
    const [state, setState] = useState(initialState);

    const clearData = () => {
        setState(() => {
            const newState = { ...initialState };
            return newState;
        })
    }

    const showNotification = (notification) => {

        // console.log('showNotification', notification);

        setState(prev => ({
            ...prev,
            notifications: [...prev.notifications, notification],
        }));

        if(notification.displayTime) {
            setTimeout(() => {
                closeNotification();
            }, notification.displayTime);
        }
    };


    const showError = (msg) => {
        showNotification({ variant: 'error', message: msg });
    }

    const closeNotification = () => {

        setState(prev => ({
            ...prev,
            notifications: prev.notifications.slice(1),
        }));
    };


    const actions = {
        clearData: clearData,
        showNotification, closeNotification,
        showError: showError,
    };

    return (
        <NotificationContext.Provider value={[state, actions]}>
            {children}
        </NotificationContext.Provider >
    );

};

export const useNotificationStore = () => useContext(NotificationContext);



