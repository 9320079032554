import React, { useEffect } from 'react';
import { Box, List, Divider } from '@material-ui/core';
import SearchFilter from '../../Misc/SearchFilter';
import SelectList from '../../Misc/SelectList';
//import { useFleetStore } from '../../../../Global/Stores/fleetStore';
import { useFolioStore } from '../../../../Global/Stores/folioStore';

//import Loading from '../../Misc/Loading';
import _ from 'lodash';
//import VesselProductGroups from './VesselProductGroups';
//import ContentColumn from '../../../Custom/ContentColumn';
//import ListItemVessel from '../../_StyledComponents/ListItemVessel';
import ListFoilioItems from '../../_StyledComponents/ListFoilioItems';
//import { HoldingStatus } from '../../../Widgets/ProductGroupStatus';
import ContentColumnNoMemo from '../../../Custom/ContentColumnNoMemo';
import { useHistory, Link, Redirect } from 'react-router-dom';
//import http from '../../../../Global/Services/httpService';


const Folio = ({ match }) => {

    const [folioState ,folioAction] = useFolioStore();

    //const [fleetState] = useFleetStore();
    //const [folioState] = useFolioStore();

    const [filter, setFilter] = React.useState('');
    const [sort, setSort] = React.useState(1);

    

    let history = useHistory();


    const selectedManagerID = +match.params.managerID || 0;
    const selectedFolioID = +match.params.folioID || 0;
    const selectedVesselID = +match.params.vesselID || 0;
    // console.log('match', selectedVesselID);
    //console.log(selectedManagerID);
    // const salesOrderID = 0;
    


    useEffect(
        () => {
            folioAction.getManagers();
        }, []
        );
        
    useEffect(
        () => {
            if(selectedManagerID > 0){
                folioAction.getFolios(selectedManagerID);
            }
            
            if(selectedManagerID > 0 && selectedFolioID > 0){
                folioAction.getFolioVessels(selectedManagerID, selectedFolioID);
                folioAction.getFolioItems(selectedManagerID, selectedFolioID);
        }
        }, [selectedManagerID, selectedFolioID]
    );

    useEffect (
         () => {
            if(Boolean(folioState.managers)){
                //console.log(folioState.managers.length)
                if(folioState.managers.length <= 1){
                    history.push(`/folios/status/${folioState.managers[0].managerID}`)
                }
            }
         }, [folioState.managers]
     );

    const onSearch = (text) => {
        setFilter(() => { return text.toLowerCase(); })
    };

    //const loadedFleet = Boolean(fleetState.vessels);
    const loadedVessels = Boolean(folioState.folioVessels);
    const loadedFolio = Boolean(folioState.folios);
    const loadedManagers = Boolean(folioState.managers);


    //let vessels = loadedFleet ? fleetState.vessels.filter(X => X.vesselName.toLowerCase().indexOf(filter) !== -1 && X.managerID == selectedManagerID) : [];
    let vessels = loadedVessels ? folioState.folioVessels.filter(X => X.vesselName.toLowerCase().indexOf(filter) !== -1 && X.managerID == selectedManagerID) : [];

    let companyList = loadedManagers ? folioState.managers : [];
    //let reload = loadedManagers ? folioAction.getFolios(selectedManagerID) : [];
    let FolioList = loadedFolio ? folioState.folios : [];

    vessels = _.orderBy(vessels, ['vesselName'], [sort > 0 ? 'asc' : 'desc']);


    const counter = selectedManagerID + filter.length + sort;
    // const [getFirstManagerID] = companyList;

    return (
        <ContentColumnNoMemo loaded={loadedManagers} selectedID={counter}>
            {/* Select Company/Manager 
                We count the number of company results and if less than 2 we do not show this drop down.
            */}
            {companyList.length > 1 ?
                <div style={{ padding: '3px', margin: '0px 5px', width: '97%' }}>
                    <SelectList managerID={''} dataList={companyList} inputLabel="Select Shipment Company" inputType="managers" selected={selectedManagerID} />
                </div>
            :null}
            
            {/* Select Folios */}
            {selectedManagerID != 0 ?
            <div style={{ padding: '3px', margin: '0px 5px' , width: '97%' }}>
                <SelectList managerID={selectedManagerID} dataList={FolioList} inputLabel="Select Folio" inputType="folios" selected={selectedFolioID} />
            </div>
            :null}

            {selectedFolioID != 0 ?
            <div className="contentColumnHeader" style={{ padding: '0px 10px' }}>
                <SearchFilter placeholder="Search Vessel" onSearch={onSearch} onSort={(S) => setSort(() => S)} />
            </div>
            :null}

            <div className="contentColumnBody disablePadding">
                {
                    <List component="div">
                        {
                            selectedFolioID != 0 ?
                            vessels.map(X => (
                                <ListFoilioItems
                                    key={X.vesselID}
                                    vessel={X}
                                    selected={X.vesselID === selectedVesselID}
                                    statusComponent={ <FolioStatus 
                                        folioStatus={X.folioStatus}
                                        /> 
                                        /*<HoldingStatus vesselProductGroupStatus={X.vesselItemProductGroups} />*/ }
                                    linkTo={`/folios/status/${selectedManagerID}/${selectedFolioID}/details/${X.vesselID}`}
                                />))
                            :null
                        }
                        <Divider />
                    </List>
                }
            </div>
        </ContentColumnNoMemo>
    );
};

const FolioStatus =({ folioStatus })=>{

    const loaded = Boolean(folioStatus);
    //console.log(folioStatus)

    const itemsPresent = loaded ? folioStatus.vesselItemsActive : 0;
    const itemsMissing = loaded ? folioStatus.vesselItemsMissing : 0;
    const itemsSleeping = loaded ? folioStatus.vesselItemsSleeping : 0; //Buffles me too. why would we have items that sleep? someone already wake them up!!!

    const total = itemsPresent + itemsMissing + itemsSleeping;
    const issues = Math.ceil(((itemsMissing) * (1 / total) * 360));
    const warnings = Math.ceil(((itemsSleeping) * (1 / total) * 360));
    const open = Math.max(360 - (issues + warnings), 0);
    
    // console.log("VesselID: ", vesselID, "Present: ", itemsPresent, "\nMissing: ", itemsMissing)
    return(
        <div className="holding-chart" title={null} >
            <div className={open > 180 ? "pie-vesselgroup big" : "pie-vesselgroup"} data-start={0} data-value={open}></div>
            <div className={warnings > 180 ? "pie-vesselgroup big" : "pie-vesselgroup"} data-start={open} data-value={warnings}></div>
            <div className={issues > 180 ? "pie-vesselgroup big" : "pie-vesselgroup"} data-start={open + warnings} data-value={issues}></div>
            <p className="chart-text">{itemsMissing}</p>
        </div>
    )
}

export default Folio;
