import React from 'react';
import { Route } from "react-router-dom";
import FlatFee from './FlatFee/FlatFee';
import FlatFeeOrders from './FlatFee/FlatFeeOrders';
import FlatFeeOrderDetail from './FlatFee/FlatFeeOrderDetail';
import FlatFeeOrderLineDetail from './FlatFee/FlatFeeOrderLineDetail';


// Import General Support Files
import Breadcrumb from '../Navigation/Breadcrumb';


const FlatFeeContract = () => {

    return (
        <React.Fragment>
            <Breadcrumb Module="Order Management" currentPage="Flat Fee Contracts" />
            <div>
            <Route exact path="/sales/flatfee" component={FlatFee} />
            <Route exact path={["/sales/flatfee/:id"]} component={FlatFeeOrders} />
            <Route exact path="/sales/flatfee/:id/:orderID/:lineID?" component={FlatFeeOrderDetail} />
            <Route exact path="/sales/flatfee/:id/:orderID/:lineID" component={FlatFeeOrderLineDetail} />

            </div>
        </React.Fragment>
    );
};

export default FlatFeeContract;
