import React, { useEffect } from 'react';
import { Redirect } from "react-router-dom";
import { useSaleStore } from '../../../../../Global/Stores/saleStore';
import QuoteDetailHeader from './QuoteDetailHeader';
import QuoteDetailList from './QuoteDetailList';
import QuoteDetailFooter from './QuoteDetailFooter';
import _ from 'lodash';
import ContentColumn from '../../../Custom/ContentColumn';

const VesselQuoteDetail = ({ match }) => {
    const salesQuoteID = +match.params.id;
    const selectedLineID = +match.params.lineID;
    // const [, mapActions] = useMapStore();
    const [saleState, salesActions] = useSaleStore();

    // console.log('props', props);
    // console.log(salesQuoteID); 

    useEffect(
        () => {

            salesActions.getSalesQuote(salesQuoteID);

            return () => {
                salesActions.clearSalesQuote();
            }
        },
        [salesQuoteID]
    );

    const confirmed = _.get(saleState, 'saleState.quoteDetail.confirmed', false);
    if (confirmed === true)
        return <Redirect to={'/sales/quotes/'} />;

    const loaded = _.get(saleState, 'quoteDetail.quote.salesQuoteID', -1) === salesQuoteID;

    return (
        <ContentColumn loaded={loaded}>
            {
                loaded ? (
                    <React.Fragment>
                        <div className="contentColumnHeader"  style={{ padding: '15px 0px', margin: '-10px 0px 0px 0px', width: '98%', backgroundColor: '#fff', zIndex: 1, position: 'absolute' }}>
                            <QuoteDetailHeader quoteDetail={saleState.quoteDetail} />
                        </div>

                        <div className="contentColumnBody">
                            <br /><br /><br />
                            <QuoteDetailList selectedLineID={selectedLineID} quoteDetail={saleState.quoteDetail} salesActions={salesActions} />
                        </div>

                        <div className="contentColumnFooter">
                            <QuoteDetailFooter quote={saleState.quoteDetail.quote} salesActions={salesActions} />
                        </div>
                    </React.Fragment>
                ) : null
            }
        </ContentColumn>
    );
};

export default VesselQuoteDetail;
