import React from 'react'

// 
import httpService from '../../../Global/Services/httpService';

// Maetrial UI support files
import { makeStyles, Button, CardMedia, CardContent, CardActions, CardActionArea, Card, Typography } from "@material-ui/core";

import Breadcrumb from '../Navigation/Breadcrumb';

// Images


/* Custom CSS Files */
//import loginStyles from '../CSS/LoginStyles.module.css'
//  theme.palette.secondary.main

// FIXES:


/*All view imports*/


// Unneccessary required styling 
const useStyles = makeStyles({
    card: {
      maxWidth: "100%",
    },
    media: {
      height: 140,
    },
  });

// Request Information
const handleRequestInformation = () => {

    // const vesselID = 0;
    httpService.get(`/api/fleet/tracking/information`)
        .then(response => {
            const data = response.data;
            // console.log('Tracking Information', data);
        })
        .catch(error => {
            //alert(error);
        });
}



const FleetTracking = () => {
    const classes = useStyles();
    return(
        <React.Fragment>
            <Breadcrumb Module="Fleet Management" currentPage="Fleet Tracking" />
            <Card className={classes.card}>
                <br /><br />
                <CardActionArea>
                    <CardMedia
                    className={classes.media}
                    image="https://blog.orbcomm.com/wp-content/uploads/2015/04/offshore-supply-vessel.jpg"
                    title="Contemplative Reptile"
                    />
                    <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        Track Your Vessels
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                    Do you need to know where your assets are at any time of the day?
                    
                    Contact us right now to get more information on the various fleet tracking options Ship Manager has to offer!
                    </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions>
                <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    fullWidth
                    onClick={handleRequestInformation}>Request more information
                </Button>
                </CardActions>
            </Card>
        </React.Fragment>
    )
}

export default FleetTracking