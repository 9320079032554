import React, { useEffect, useRef, useState } from 'react';
import { Map as LeafletMap, TileLayer, LayerGroup } from 'react-leaflet';
import { Route, Switch } from "react-router-dom";
// import storage from 'store';
import SalesOrderLayer from './Layers/SalesOrderLayer';
import { useMapStore } from '../../Global/Stores/mapStore';
import SalesQuoteLayer from './Layers/SalesQuoteLayer';
import HoldingLayer from './Layers/HoldingLayer';
import FolioItemsLayer from './Layers/FolioItemsLayer';
import FolioVesselItemsLayer from './Layers/FolioVesselItemsLayer';
// import { Switch } from 'react-router-dom'
import FleetPositionsLayer from './Layers/FleetPositionsLayer';
import NmScale from "@marfle/react-leaflet-nmscale";
// import CatalogueItemsLayer from './Layers/Catalogue/CatalogueItemsLayer';
// import BasketItemsLayer from './Layers/Catalogue/BasketItemsLayer';
// import BasketRoutesLayer from './Layers/Catalogue/BasketRoutesLayer';
// import ManagerBasketLayer from './Layers/Catalogue/ManagerBasketLayer';
// import QuoteBasketLayer from './Layers/Catalogue/QuoteBasketLayer';
import SalesShipmentLayer from './Layers/SalesShipmentLayer';
// import PurchaseOrderLayer from './Layers/PurchaseOrderLayer';
// import WorldAreaLayer from './Layers/WorldAreaLayer';
import FlatFeeContractLayer from './Layers/FlatFeeContractLayer';
import { useColumnStore } from '../../Global/Stores/columnStore';
import AvcsoLayer from './Layers/AVCSO/AvcsoLayer';
import { useUserStore } from '../../Global/Stores/userStore';
import { Grid, Divider } from '@material-ui/core';
import LayersIcon from '@material-ui/icons/Layers';

import QueueIcon from '@material-ui/icons/Queue';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import SettingsIcon from '@material-ui/icons/Settings';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import AdjustIcon from '@material-ui/icons/Adjust';
import GetAppIcon from '@material-ui/icons/GetApp';

import { AVCSO_LayerOptionsContainer, AVCSO_ParameterOptionsContainer, AVCSO_ParameterGroupOptionsContainer } from './Layers/AVCSO/AvcsOLayerOptions';
import RouteContainer from './Layers/Routing/RouteContainer';
import MapInfo from './Markers/MapInfo';
// import MapAVCSOLogo from './Markers/MapAVCSOLogo';
import SatelliteIcon from '@material-ui/icons/Satellite';

import http, { getToken } from './../../Global/Services/httpService'
import CatalogueSelectLayer from './Layers/Catalogue/CatalogueSelectLayer';
import CatalogueBasketLayer from './Layers/Catalogue/CatalogueBasketLayer';
import AvcsoFeatureContainer from './Layers/AVCSO/AvcsoFeatureContainer';
import AvcsoItemsLayer from './Layers/Catalogue/AvcsoItemsLayer';
import AvcsoBasketLayer from './Layers/Catalogue/AvcsoBasketLayer';

import RouteIcon from './../../Images/Routes/Route-Icon.svg'
import GeneralRoutesLayer from './Layers/Catalogue/GeneralRoutesLayer';
import LeafletRuler from './LeafletRuler/LeafletRuler';

/*
NOTE:
Making the map generic
Since the map component is used by both mobile and desktop views, there are a few UI issues to solve
    [1] The mobile view requires menu items to display horizontally. 
    [2] There are as well instances where the mobile view requires a map without the menu. 
To solve this, calling the MapControl will have to include 2 arguments/props. 
    [1] MenuOrientation
        [1] Horizontal
        [2] Vertical
    [2] Show Menu
        [1] True
        [2] False

Example Usage:
    <MapControl menuOrientation = "hor", showMenu ={true} />

- The menuOrientation argument/prop runs through other components since several components
  require a view difference

- If no arguments/props are passed, error is returned. so ensure that correct arguments/props in lowercases are passed

*/

// const { BaseLayer, Overlay } = LayersControl;
const MapControl = ({menuOrientation = 'ver', showMenu = true }) => {
    // const [width, setwidth] = useState(0);
    const ref = useRef(null);
    const [showRoutes, setShowRoutes] = useState(false);
    const [mapState, mapActions] = useMapStore();
    const [columnStore] = useColumnStore();

    const viewPort = JSON.parse(sessionStorage.getItem('viewPort')) || {
        lat: 60.505,
        lng: -0.09,
        zoom: 4
    };
    const position = [viewPort.lat, viewPort.lng]; 

    useEffect(() => {
        // console.log('columnStore', columnStore);
        const map = ref.current.leafletElement;
        setTimeout(() => map.invalidateSize(false), 10);
    }, [columnStore]);

    const bounds = (mapState.bounds && mapState.bounds.length > 0) ? mapState.bounds : null;

    const { activeLayer, drawerMenu, AVCSO } = mapState;

    const selectionMode = AVCSO.selectionMode;

    const onViewportChange = (viewPort) => {
        setShowRoutes(false);
    };

    const onViewportChanged = (viewPort) => {
        setShowRoutes(mapState.activeRoutes && mapState.activeRoutes.length > 0);
    };

    // console.log("bounds", bounds);
    // const outer = [[50.505, -29.09], [52.505, 29.09]]
    // const inner = [[49.505, -2.09], [53.505, 2.09]]


    // const hasAVCSO = !!mapState.AVCSO.license.token;


    // console.log('AVCSO.parameter', AVCSO.parameter);


    // const cursorValue = selectionMode === 1 ? 'crosshair' : '';
    const cursorValue = mapActions.isFeatureInfoActive() ? 'crosshair' : '';

    return (
        <React.Fragment>
            <LeafletMap
                ref={ref}
                style={{ height: '100%', backgroundColor: '', flexGrow: '1', cursor: `${cursorValue}` }}
                center={position}
                zoom={viewPort.zoom}
                bounds={bounds}
                useFlyTo={mapState.options.useFlyTo}
                worldCopyJump={false}
                dragging={true}
                touchZoom={true}
                onViewportChange={onViewportChange}
                onViewportChanged={onViewportChanged}
            >

                {/* <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                /> */}

                {
                    activeLayer === 2 ? (<AvcsoLayer />) : (
                        <LayerGroup>
                            <TileLayer
                                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            {/* <TileLayer
                                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="http://otile{s}.mqcdn.com/tiles/1.0.0/{type}/{z}/{x}/{y}.png"
                            /> */}
                            <TileLayer
                                url="http://tiles.openseamap.org/seamark/{z}/{x}/{y}.png"
                            />

                        </LayerGroup>)
                }


                <FleetPositionsLayer Visible={mapState.activeOverlays.includes(1)} mapActions={mapActions} />

                {/* <Switch> */}
                <Route
                    path="/sales/quotes/:id/:lineID?"
                    render={(props) => <SalesQuoteLayer {...props} mapActions={mapActions} />}
                />

                <Route
                    path="/sales/orders/:id/:lineID?"
                    render={(props) => <SalesOrderLayer {...props} mapActions={mapActions} />}
                />

                <Route
                    path="/sales/shipments/:id/:lineID?"
                    render={(props) => <SalesShipmentLayer {...props} mapActions={mapActions} />}
                />


                {/* FlatFee Layers */}
                <Route
                    path="/sales/flatfee/:id/:orderID?/:lineID?"
                    render={(props) => <FlatFeeContractLayer {...props} mapActions={mapActions} />}
                />

                {/* <TrackMarker /> */}
                {/* Catalogue Select Items */}
                <Route
                    path="/sales/catalogue/:vesselID/add/:itemID?"
                    render={(props) => <CatalogueSelectLayer {...props} mapActions={mapActions} />}
                />

                {/* Catalogue Route Items */}
                {/* <Route
                    path="/sales/catalogue/:vesselID/routes/:routeID/items/:itemID?"
                    render={(props) => <CatalogueItemsLayer {...props} mapActions={mapActions} />}
                /> */}

                {/* Catalogue Basket Layer */}
                <Route
                    path={["/sales/catalogue/:vesselID/basket/:itemID?", "/sales/catalogue/:vesselID/confirm/:itemID?"]}
                    render={(props) => <CatalogueBasketLayer {...props} mapActions={mapActions} />}
                />


                {/* AVCS Online Items */}
                <Route
                    path="/sales/avcso/add/:itemID?"
                    render={(props) => <AvcsoItemsLayer {...props} mapActions={mapActions} />}
                />
                {/* AVCS Online Items */}
                <Route
                    path={["/sales/avcso/basket/:itemID?", "/sales/avcso/confirm/:itemID?"]}
                    render={(props) => <AvcsoBasketLayer {...props} mapActions={mapActions} />}
                />

                {/* Basket Items to Order */}
                {/* <Route
                    path="/sales/catalogue/:vesselID/basket/:itemID?"
                    render={(props) => <BasketItemsLayer {...props} mapActions={mapActions} />}
                /> */}

                {/* Basket Routes to Order */}
                {/* <Route
                    path="/sales/catalogue/:vesselID/basket/:itemID?"
                    render={(props) => <BasketRoutesLayer {...props} mapActions={mapActions} />}
                /> */}

                {/* Catalogue Route Items */}
                {/* <Route
                    path="/sales/catalogue/:vesselID/routes/:routeID/items/:itemID?"
                    render={(props) => <CatalogueItemsLayer {...props} mapActions={mapActions} />}
                /> */}

                {/* Catalogue Product Items */}
                {/* <Route
                    path="/sales/catalogue/:vesselID/products/:itemID?"
                    render={(props) => <CatalogueItemsLayer {...props} mapActions={mapActions} />}
                /> */}

                {/* Catalogue Routes */}
                {/* <Route
                    path="/sales/catalogue/:vesselID/routes/:id"
                    render={(props) => <CatalogueRouteLayer {...props} mapActions={mapActions} />}
                /> */}

                {/* Catalogue ManagerBaskets */}
                {/* <Route
                    path="/sales/catalogue/:vesselID/baskets/:id/:itemID?"
                    render={(props) => <ManagerBasketLayer {...props} mapActions={mapActions} />}
                /> */}

                {/* Catalogue QuoteBaskets */}
                {/* <Route
                    path="/sales/catalogue/:vesselID/quotes/:id/:itemID?"
                    render={(props) => <QuoteBasketLayer {...props} mapActions={mapActions} />}
                /> */}

                {/* Holdings */}
                <Route
                    path="/fleet/holdings/:vesselID/:itemID?"
                    render={(props) => <HoldingLayer {...props} mapActions={mapActions} />}
                />

                {/* Folios */}
                <Switch>
                <Route
                    path="/folios/status/:managerID/:folioID?/details/:vesselID?/:itemID?"
                    render={(props) => <FolioVesselItemsLayer {...props} mapActions={mapActions} />}
                />
                
                <Route
                    path="/folios/status/:managerID?/:folioID?"
                    render={(props) => <FolioItemsLayer {...props} mapActions={mapActions} />}
                />
                </Switch>

                {/* <Route
                    path="/folios/status/:managerID/:folioID?/details/:vesselID?"
                    render={(props) => <FolioItemsLayer {...props} mapActions={mapActions} />}
                /> */} 

                {/* <Route
                    path="/fleet/status/:vesselID?"
                    render={(props) => <FleetPositionsLayer {...props} mapActions={mapActions} />}
                /> */}

                {/* {
                    mapState.activeOverlays.includes(1) ? <FleetPositionsLayer mapActions={mapActions} /> : null
                } */}

                <MapInfo position="topright" />
                <NmScale position="topright" />
                <LeafletRuler mapRef={ref} />

                { showRoutes ? <GeneralRoutesLayer Routes={mapState.activeRoutes} /> : null }
            </LeafletMap>

            { showMenu === true ? <MapMenuBar drawerMenu={drawerMenu} menuOrientation={menuOrientation} /> : null }

        </React.Fragment>
    );
};

const MapMenuBar = ({ drawerMenu, menuOrientation }) => {

    return (
        <div className="mapMenuBar" style={{width: "auto"}}>
            <MapMenuDrawerContainer menuOrientation={menuOrientation} drawerMenu={drawerMenu} />

            <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="stretch"
                className="mapMenuBarItems"
            >
                <Divider />
                <MapMenuLayers menuOrientation={menuOrientation} />
            </Grid>

        </div >
    );
}

const MapMenuLayers = ({menuOrientation}) => {

    const [mapState, mapActions] = useMapStore();
    const { activeLayer } = mapState;
    const activeMenu = mapState.drawerMenu.activeMenu;
    const EULA = mapState.AVCSO.EULA;

    const onLayerClick = (layerID) => {
        // console.log('onLayerClick', layerID);
        mapActions.setActiveLayer(layerID);
    }

    const onToggleMapMenu = (drawerMenu) => {
        // console.log('onLayerClick', layerID);
        mapActions.setActiveDrawerMenu(drawerMenu);
    }

    // OSM
    // AVCSO -> Features + FeatureGroups + Layers
    if(menuOrientation === "ver"){
        return (
            <React.Fragment>
                <MapMenuBarItem Icon={<LayersIcon fontSize="large" />} onSelect={() => onLayerClick(1)} Selected={activeLayer === 1} />

                <MapMenuItemAVCSO
                    onSelect={() => onLayerClick(2)}
                    Selected={activeLayer === 2}
                    ToggleMapMenu={onToggleMapMenu}
                    ActiveMenu={activeMenu}
                    EULA={EULA}
                    menuOrientation={menuOrientation}
                />

                <MapMenuOverlays />

                <MapMenuRoutes ToggleMapMenu={onToggleMapMenu} />

            </React.Fragment>
        )
    } else if (menuOrientation === "hor"){
        return (
            <React.Fragment>
                <Grid container spacing={5}>
                    <Grid item xs={1}>
                        <MapMenuBarItem Icon={<LayersIcon fontSize="large" />} onSelect={() => onLayerClick(1)} Selected={activeLayer === 1} />
                    </Grid>
                    <Grid item xs={1}>
                        <MapMenuOverlays />
                    </Grid>
                    <Grid item xs={1}>
                        <MapMenuRoutes ToggleMapMenu={onToggleMapMenu} />
                    </Grid>
                    <Grid item xs={6}>
                        <MapMenuItemAVCSO
                            onSelect={() => onLayerClick(2)}
                            Selected={activeLayer === 2}
                            ToggleMapMenu={onToggleMapMenu}
                            ActiveMenu={activeMenu}
                            EULA={EULA}
                            menuOrientation={menuOrientation}
                        />
                    </Grid>                    
                </Grid>

            </React.Fragment>
        )
    }
}

const MapMenuOverlays = () => {
    const [mapState, mapActions] = useMapStore();

    const FleetPositionsOverlayID = 1;

    const setOverlay = (overlayID, active) => {
        // console.log('toggleOverlay', overlayID, active);
        mapActions.setOverlayVisible(overlayID, active);
    }

    const isFleetPositionsActive = mapState.activeOverlays.includes(FleetPositionsOverlayID);

    return (
        <React.Fragment>
            <MapMenuBarItem Icon={<SatelliteIcon fontSize="large" />} onSelect={() => setOverlay(FleetPositionsOverlayID, !isFleetPositionsActive)} Selected={isFleetPositionsActive} />
        </React.Fragment>
    );
}

const MapMenuRouteIcon = () => {
    return (<img src={RouteIcon} style={{padding: "4px 0"}} />)
}

const MapMenuRoutes = ({ ToggleMapMenu }) => {

    const [mapState] = useMapStore();

    const RouteID = 5;
    const isRouteActive = mapState.drawerMenu.activeMenu === RouteID;
    return (
        <React.Fragment>
            <MapMenuBarItem Icon={<MapMenuRouteIcon />} onSelect={() => ToggleMapMenu(RouteID)} Selected={isRouteActive} />
        </React.Fragment>
    );
}


const MapMenuDrawerContainer = ({ drawerMenu, menuOrientation }) => {

    const { activeMenu } = drawerMenu;
    const menuShowing = activeMenu > 0;

    let menuContainer = null;
    switch (activeMenu) {
        case 1: // AVCSO Layers
            menuContainer = <AVCSO_LayerOptionsContainer />;
            break;
        case 2: // AVCSO Paremeter Options
            menuContainer = <AVCSO_ParameterOptionsContainer />;
            break;
        case 3: // AVCSO Group Options
            menuContainer = <AVCSO_ParameterGroupOptionsContainer />;
            break;
        case 4: // AVCSO GetFeatures
            menuContainer = <AvcsoFeatureContainer />;
            break;
        case 5: // Routes
            menuContainer = <RouteContainer />;
            break;
        default:
            break;
    }

    if(menuOrientation === "ver"){
        return(
            <div className={`mapMenuDrawer${menuShowing === true ? ' active' : ''}`} >
                {
                    menuContainer
                }
            </div>
        )
    } else if(menuOrientation === "hor"){
        return(
            <div className={`mapMenuDrawer${menuShowing === true ? ' active' : ''}`} style={{position: "absolute", top: 0, right: 0, height: "96%", marginBottom: 20}}>
                {
                    menuContainer
                }
            </div>
        )
    }

}


const MapMenuItemAVCSO = ({ Selected, onSelect, ToggleMapMenu, ActiveMenu, EULA, menuOrientation }) => {
    // console.log('ActiveMenu', ActiveMenu);
    const [, userActions] = useUserStore();
    const hasAVCSO = userActions.hasFeatureID(6, false) && userActions.hasAppModuleID(7, false);


    const disabled = !hasAVCSO;// || EULA === false;
    if(menuOrientation === "ver"){
    return (
        <React.Fragment>
            <MapMenuBarItem
                Icon={<QueueIcon fontSize="large" />}
                onSelect={onSelect}
                Selected={Selected}
                divider={false}
                disabled={disabled}
            />


            {
                Selected === true && EULA === true ? (
                    <React.Fragment>
                        <MapMenuBarItem Icon={<ClearAllIcon fontSize="large" />} onSelect={() => ToggleMapMenu(1)} Selected={ActiveMenu === 1} divider={false} />
                        <MapMenuBarItem Icon={<SettingsIcon fontSize="large" />} onSelect={() => ToggleMapMenu(2)} Selected={ActiveMenu === 2} divider={false} />
                        <MapMenuBarItem Icon={<SettingsApplicationsIcon fontSize="large" />} onSelect={() => ToggleMapMenu(3)} Selected={ActiveMenu === 3} divider={false} />
                        <MapMenuBarItemAvcsoSelectionMode />
                        <MapMenuBarItemAvcsoReadme />
                    </React.Fragment>
                ) : null
            }
            <Divider />
        </React.Fragment>
    )
    } else if(menuOrientation === "hor"){
        return (
            <div>
                <Grid container spacing={4}>
                <Grid item xs={1}>
                    <MapMenuBarItem
                        Icon={<QueueIcon fontSize="large" />}
                        onSelect={onSelect}
                        Selected={Selected}
                        divider={false}
                        disabled={disabled}
                    />
                </Grid>
    
                {
                    Selected === true && EULA === true ? (
                        <React.Fragment>
                                <Grid item xs={1}>
                            <MapMenuBarItem Icon={<ClearAllIcon fontSize="large" />} onSelect={() => ToggleMapMenu(1)} Selected={ActiveMenu === 1} divider={false} />
                                </Grid>
                                <Grid item xs={1}>
                            <MapMenuBarItem Icon={<SettingsIcon fontSize="large" />} onSelect={() => ToggleMapMenu(2)} Selected={ActiveMenu === 2} divider={false} />
                                </Grid>
                                <Grid item xs={1}>
                            <MapMenuBarItem Icon={<SettingsApplicationsIcon fontSize="large" />} onSelect={() => ToggleMapMenu(3)} Selected={ActiveMenu === 3} divider={false} />
                                </Grid>
                                <Grid item xs={1}>
                            <MapMenuBarItemAvcsoSelectionMode />
                                </Grid>
                                <Grid item xs={1}>
                            <MapMenuBarItemAvcsoReadme />
                                </Grid>
                                </React.Fragment>
                    ) : null
                }
                {/* <Divider /> */}
                
                </Grid>
            </div>
        )
    }
}

const MapMenuBarItemAvcsoReadme = () => {

    const onSelect = () => {
        const to = `${http.endpoint}/api/file/avcso/readme?access_token=${getToken()}`;
        window.open(to, "_blank");
    }

    return (
        <React.Fragment>
            <Divider />
            <MapMenuBarItem Icon={<GetAppIcon fontSize="large" />} onSelect={onSelect} Selected={false} divider={false} />
        </React.Fragment>
    );
}

const MapMenuBarItemAvcsoSelectionMode = () => {
    const [mapState, mapActions] = useMapStore();

    // const selected = mapState.AVCSO.selectionMode === 1; // selctionMode = getFeatureInfo
    const selected = mapState.drawerMenu.activeMenu === 4; // selctionMode = getFeatureInfo

    // console.log('SelectionMode', mapState.AVCSO.selectionMode);
    const onSelect = () => {
        // Toggle SelectionMode
        // mapActions.toggleAvcsoSelectionMode();
        mapActions.setActiveDrawerMenu(4);
    }

    return (
        <React.Fragment>
            <Divider />
            <MapMenuBarItem Icon={<AdjustIcon fontSize="large" />} onSelect={onSelect} Selected={selected} divider={false} />
        </React.Fragment>
    );

}

const MapMenuBarItem = ({ Icon, Selected, onSelect, divider = false, disabled = false }) => {

    const classNames = `mapMenuItem${(Selected === true ? " active" : "")}${disabled === true ? ' ctdisabled' : ''}`;
    return (
        <React.Fragment>
            <div className={classNames} onClick={onSelect} >
                {Icon}
            </div>
            {divider === true ? <Divider /> : null}
        </React.Fragment>
    );
}

export default MapControl;
