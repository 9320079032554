import React, { useMemo } from 'react'
import httpService from '../../../Global/Services/httpService';
import UploadButton from './UploadButton';

const UploadBasketButton = ({ vesselID }) => {

    // const [, globalsActions] = useGlobalStore();

    return useMemo(() => {

        const handleFileSelected = (file) => {
            const files = Array.from(file);

            //console.log('files', files);

            const formData = new FormData();
            formData.append("File", files[0]);

            httpService.post("/api/catalogue/baskets/upload", formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then((result) => {

                    const data = result.data;
                    //console.log('Upload Basket Result', data);

                    //const basketID = +result.data;
                    //globalsActions.getBaskets();
                    // history.push(`/sales/catalogue/${vesselID}/baskets/${basketID}`);
                })
                .catch(error => {
                    //alert(error)
                });
        };

        return (
            <UploadButton text="Upload Basket File" accept=".bsk , .txt" FileSelected={handleFileSelected} />
        );

    }, [vesselID])
}

export default UploadBasketButton
