import React, { useEffect } from 'react';
import { List, Divider } from '@material-ui/core';
import SearchFilter from '../../Misc/SearchFilter';
import { useSaleStore } from '../../../../Global/Stores/saleStore';
import VesselOrders from './VesselOrders';
import _ from 'lodash';
import ContentColumn from '../../Custom/ContentColumn';

const Orders = ({ match }) => {
    const [saleState, salesActions] = useSaleStore();

    const [filter, setFilter] = React.useState('');
    const [sort, setSort] = React.useState(1);

    const salesOrderID = +match.params.id || 0;

    // console.log('orders', salesOrderID);

    useEffect(
        () => {
            salesActions.getVesselOrders();
        },
        []
    );


    // const onSearch = (text) => {
    //     setFilter(() => { return text.toLowerCase(); })
    // };


    const loaded = Boolean(saleState.vesselOrders);
    let vessels = loaded ?
        saleState.vesselOrders.filter(X => X.vesselName.toLowerCase().indexOf(filter) !== -1) : [];

    vessels = _.orderBy(vessels, ['vesselName'], [sort > 0 ? 'asc' : 'desc']);


    // const sum = _.sumBy(vessels, (V) => V.orders.length);
    // console.log('sum', sum);

    return (
        <ContentColumn loaded={loaded}>
            <div className="contentColumnHeader" style={{ padding: '10px 0px', margin: "-10px 0px 0px 0px", width: '100%', backgroundColor: '#fff', zIndex: 1, position: 'absolute' }}>
                <SearchFilter placeholder="Search Vessel" onSearch={(F) => setFilter(() => F.toLowerCase())} onSort={(S) => setSort(() => S)} />
            </div>

            <div className="contentColumnBody disablePadding">
                <br /><br /><br />
                {
                    <List component="div">
                        {
                            vessels.map(X => (<VesselOrders key={X.vesselID} vesselOrders={X} selectedSalesOrderID={salesOrderID} />))
                        }
                        <Divider />
                    </List>
                }
                <br /><br /><br />
            </div>
        </ContentColumn>
    );
};

export default Orders;
