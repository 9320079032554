import React, { useMemo, useEffect, useRef } from 'react';
import { useGlobalStore } from '../../../../../Global/Stores/globalStore';
import ContentColumn from '../../../../Custom/ContentColumn';
import { Link as Link1 } from 'react-router-dom';
import { Typography, Box, ListItemText, Checkbox, List, ListItem, ListItemIcon, Button, Badge, Chip, Link, Divider, Grid, TextField, InputAdornment } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useParams } from "react-router";
import { useCatalogueStore } from '../../../../../Global/Stores/catalogueStore';
import { FixedSizeList } from 'react-window';
import _ from 'lodash';
import { NavigateBeforeSharp } from '@material-ui/icons';
import AutoSizer from "react-virtualized-auto-sizer";
import { useBasketStore } from '../../../../../Global/Stores/basketStore';
import http from '../../../../../Global/Services/httpService';
import UploadBasketButton from '../../../../Widgets/Buttons/UploadBasketButton';
import UploadRouteButton from '../../../../Widgets/Buttons/UploadRouteButton';
import CreateRouteButton from '../../../../Widgets/Buttons/CreateRouteButton';
import SearchFilter, { TextFieldSearch } from '../../../Misc/SearchFilter';
import { useFleetStore } from '../../../../../Global/Stores/fleetStore';
// import SearchIcon from '@material-ui/icons/Search';



const CatalogueSelectItems = () => {
    const [globalState] = useGlobalStore();
    const [, catalogueActions] = useCatalogueStore();
    const [, fleetActions] = useFleetStore();
    const pars = useParams();
    const vesselID = +pars.vesselID || 0;

    const { productGroups, productItemGroups, catalogue } = globalState;
    const loaded = Boolean(productGroups) && Boolean(productItemGroups) && Boolean(catalogue);

    useEffect (
        () => {
        fleetActions.getVesselHoldings(vesselID)
        return () => {
            fleetActions.clearVesselHoldings();
        }
        }, [vesselID])


    useEffect(() => {
        catalogueActions.reset();
        catalogueActions.loadVesselItemIDs(vesselID);
        //console.log("we run this")
    }, [vesselID]);


    return (
        <ContentColumn loaded={loaded} selectedID={vesselID} disablePadding={true}>
            {/* <div className="contentColumnHeader">
                <Typography variant="h6" display="block" >Add Items to basket</Typography>
            </div> */}

            <div className="contentColumnBody" style={{ padding: '0px 8px', display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}>
                <BasketItemsAddFrom />
                <SourceItems />
            </div>

            <div className="contentColumnFooter" style={{ justifyContent: 'space-between' }}>
                <CatalogSelectFooter />
            </div>

        </ContentColumn>
    );
}


const BasketItemsAddFrom = () => {

    return (
        <React.Fragment>
            <Box paddingTop={1} paddingBottom={1}>
                <SourceSelector />
                <SourceRecordSelector />
                <ProductGroupSelectorContainer />
                {/* <ProductItemFilter /> */}
            </Box>
        </React.Fragment>
    );
}




const ProductItemFilter = () => {
    const [, catalogueActions] = useCatalogueStore();

    const handleSearch = (e) => {
        // console.log('handleSearch', e);
        catalogueActions.setFilterText(e);
    };

    return <TextFieldSearch placeholder="Search Items" OnSearch={handleSearch} />
}


const SourceItems = () => {

    const ref = useRef(null);
    const [catalogueState,] = useCatalogueStore();
    const [fleetState,] = useFleetStore();
    const { itemSource, sourceLines, selectedItemIDs, vesselItemIDs, productGroup, productItemGroups, filterText } = catalogueState;
    
    //console.log("Getting datassssss: ", vesselItemIDs)

    const loadedHoldings = Boolean(fleetState.holdingDetailItems);

    const holdingItems = loadedHoldings ? fleetState.holdingDetailItems : []

    const pars = useParams();
    const focusedItemID = +pars.itemID || 0;

    let listItems = sourceLines.filter(X => (productItemGroups.includes(X.productItemGroupID)));
    if (Boolean(filterText)) {
        listItems = listItems.filter(X => (X.itemNumber.toLowerCase().indexOf(filterText) !== -1) || (X.itemTitle.toLowerCase().indexOf(filterText) !== -1));
    }

    useEffect(() => {

        if (ref && ref.current && focusedItemID > 0) {
            const focusedIndex = _.findIndex(listItems, (I) => I.itemID === focusedItemID);
            // ref.current.state.scrollOffset = 1800;
            // console.log('focusedItemID', focusedItemID, ref.current.state.scrollOffset)
            ref.current.scrollToItem(focusedIndex);
        }
    }, [focusedItemID, listItems]);


    if (!itemSource || !productGroup)
        return null;

    const itemCount = listItems.length;

    // console.log('sourceLines', sourceLines, itemCount);
    // console.log('selectedItemIDs', selectedItemIDs);

    return (
        <React.Fragment>
            {/* <Collapse in={open} timeout="auto"> */}
            <ProductItemFilter />
            <div style={{ flex: '1 0' }}>
                <AutoSizer>
                    {
                        ({ width, height }) => (

                            <List component="div" disablePadding>
                                <FixedSizeList
                                    ref={ref}
                                    height={height}
                                    width={width}
                                    itemSize={61}
                                    itemCount={itemCount}
                                    style={{ border: '1px solid rgba(0, 0, 0, 0.2)' }}

                                    itemData={
                                        {
                                            listItems,
                                            selectedItemIDs: selectedItemIDs,
                                            vesselItemIDs: holdingItems,
                                            focusedItemID: focusedItemID,
                                        }
                                    }
                                >
                                    {Row}
                                </FixedSizeList>
                            </List>
                        )
                    }
                </AutoSizer>
            </div>
        </React.Fragment>
    );
}


const Row = ({ data, index, style }) => {
    // const history = useHistory();
    const [, catalogueActions] = useCatalogueStore();
    const { listItems, selectedItemIDs, vesselItemIDs, focusedItemID } = data;
    //console.log("vID: ", vesselItemIDs)

    const I = listItems[index];
    const itemID = Boolean(I) ? I.itemID : 0;

    const focused = itemID === focusedItemID;
    const selected = selectedItemIDs.includes(itemID);
    const inInventory = vesselItemIDs.some(Y => Y.itemID === itemID);

    return React.useMemo(() => {

        // console.log('Row', I, listItems);

        const handleItemChecked = (id) => {
            catalogueActions.toggleSelectedItem(id);
        }

        return (
            <Link component={Link1} to={`${I.itemID}`} color='inherit' style={{ textDecoration: 'none' }}>
                <ListItem button disableRipple dense divider
                    key={I.itemID}
                    style={style}
                    disabled={inInventory}
                    selected={focused}
                // disabled={vesselItemIDs.includes(I.itemID)}
                // selected={I.itemID === detailItemID}
                >
                    <ListItemIcon>
                        <Checkbox
                            color="primary"
                            disableRipple
                            checked={selected}
                            onClick={() => { handleItemChecked(I.itemID, !selected) }}
                        />
                    </ListItemIcon>
                    <ListItemText primary={I.itemNumber} secondary={I.itemTitle} style={{ maxHeight: '61px', overflow: 'hidden' }} />
                </ListItem>
            </Link>
        );

    }, [itemID, selected, inInventory, focused]);
};


const SourceSelector = () => {
    const [catalogueState, catalogueActions] = useCatalogueStore();
    const { itemSourceOptions, itemSource } = catalogueState;

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);

    React.useEffect(() => {
        if (inputLabel.current) {
            setLabelWidth(inputLabel.current.offsetWidth);
        }
    }, []);


    // console.log('SourceSelector', itemSource);

    return useMemo(() => {

        const handleChange = event => {
            const id = event.target.value;
            // console.log('val', id);
            // setSelectValue(val);
            catalogueActions.setItemSource(id);
        };

        const value = itemSource ? itemSource.id : 0;

        return (
            <FormControl variant="outlined" fullWidth margin="dense">
                <InputLabel ref={inputLabel}>Add items from</InputLabel>
                <Select
                    style={{ minWidth: '350px' }}
                    value={value}
                    onChange={handleChange}
                    labelWidth={labelWidth}
                >
                    {
                        itemSourceOptions.map(X => <MenuItem key={X.id} value={X.id}>{X.caption}</MenuItem>)
                    }
                </Select>
            </FormControl>
        );

    }, [itemSource, catalogueActions, labelWidth]);
}

// const SourceRecordSelector = () => {

// }

const SourceRecordSelector = () => {

    const pars = useParams();
    const vesselID = +pars.vesselID || 0;

    const [catalogueState, catalogueActions] = useCatalogueStore();
    const { itemSource, sourceRecord } = catalogueState;

    return useMemo(() => {

        //console.log('SourceRecordSelector', itemSource);
        const sourceID = itemSource ? itemSource.id : 0;

        switch (sourceID) {
            case 1:
                return (<RouteSelector VesselID={vesselID} CatalogueState={catalogueState} CatalogueActions={catalogueActions} />);
                break;
            case 2:
                return (<BasketSelector VesselID={vesselID} CatalogueState={catalogueState} CatalogueActions={catalogueActions} />);
                break;
            case 3:
                return (<QuoteSelector VesselID={vesselID} CatalogueState={catalogueState} CatalogueActions={catalogueActions} />);
                break;
            case 4:
                return (<CatalogueSelector VesselID={vesselID} CatalogueState={catalogueState} CatalogueActions={catalogueActions} />);
                break;
            default:
                return null;
                break;
        }
    }, [vesselID, itemSource, sourceRecord]);
}


const BasketSelector = ({ VesselID, CatalogueState, CatalogueActions }) => {
    const [globalState] = useGlobalStore();
    const items = globalState.catalogue.items;

    const baskets = _.orderBy(globalState.baskets, [B => B.name.toLowerCase()]);
    const { sourceRecord } = CatalogueState;

    const selectedBasket = sourceRecord;

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        if (inputLabel.current) {
            setLabelWidth(inputLabel.current.offsetWidth);
        }
    }, []);


    return useMemo(() => {
        const basketID = selectedBasket ? selectedBasket.sourceBasketID : 0;

        // console.log('BasketSelector', basketID)

        const handleChange = event => {
            const id = event.target.value;
            const basket = baskets.find(X => X.sourceBasketID === id);
            // console.log('New Basket: ', basket);

            CatalogueActions.setSourceRecord(basket);

            // Set Items
            const itemIDs = basket.lines.map(X => X.itemID);
            const basketItems = items.filter(X => itemIDs.includes(X.itemID));
            CatalogueActions.setSourceLines(basketItems);
        };

        return (
            <React.Fragment>

                <Grid container spacing={1} alignItems="center" wrap='nowrap'>
                    <Grid item >

                        <FormControl variant="outlined" fullWidth margin="dense">
                            <InputLabel ref={inputLabel}>Select a basket</InputLabel>
                            <Select
                                style={{ minWidth: '335px', maxWidth: '335px' }}
                                value={basketID}
                                onChange={handleChange}
                                labelWidth={labelWidth}
                            >
                                {
                                    basketID !== 0 ? null : <MenuItem key={0} value={0}>Select a basket</MenuItem>
                                }
                                {
                                    baskets.map(X => <MenuItem key={X.sourceBasketID} value={X.sourceBasketID}>{X.name}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item>
                        <UploadBasketButton vesselID={VesselID} />
                    </Grid>

                </Grid>
            </React.Fragment>
        );

    }, [labelWidth, baskets, selectedBasket, items, CatalogueActions, VesselID]);
}

const QuoteSelector = ({ VesselID, CatalogueState, CatalogueActions }) => {

    // Label
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        if (inputLabel.current) {
            setLabelWidth(inputLabel.current.offsetWidth);
        }
    }, []);


    const [globalState] = useGlobalStore();
    const items = globalState.catalogue.items;


    const selectedquote = CatalogueState.sourceRecord;
    const [vesselQuotes, setVesselQuotes] = React.useState([]);
    React.useEffect(() => {

        //console.log('vesselID', VesselID);

        http.get(`/api/sales/vesselquotes/${VesselID}`)
            .then(result => {
                const quotes = result.data;
                //console.log('vesselQuotes', quotes);
                setVesselQuotes(() => quotes);
            })
            .catch(error => {
                //alert(error);
            });

        return setVesselQuotes(() => []);

    }, [VesselID, setVesselQuotes]);


    // return <div>{selectedquote}</div>

    return useMemo(() => {
        const quoteID = selectedquote ? selectedquote.salesQuoteID : 0;

        // console.log('QuoteSelector', quoteID)

        const handleChange = event => {
            const id = event.target.value;
            const quote = vesselQuotes.find(X => X.salesQuoteID === id);
            // console.log('New quote: ', quote);

            CatalogueActions.setSourceRecord(quote);

            CatalogueActions.loadSalesQuoteItems(id);
        };

        return (
            <FormControl variant="outlined" fullWidth margin="dense">
                <InputLabel ref={inputLabel}>Select a quote</InputLabel>
                <Select
                    style={{ minWidth: '350px' }}
                    value={quoteID}
                    onChange={handleChange}
                    labelWidth={labelWidth}
                >
                    {
                        quoteID !== 0 ? null : <MenuItem key={0} value={0}>Select a quote</MenuItem>
                    }
                    {
                        vesselQuotes.map(X => <MenuItem key={X.salesQuoteID} value={X.salesQuoteID}>{X.reference}</MenuItem>)
                    }
                </Select>
            </FormControl>
        );

    }, [labelWidth, vesselQuotes, selectedquote, items, CatalogueActions]);

}

const RouteSelector = ({ VesselID, CatalogueState, CatalogueActions }) => {
    const [globalState] = useGlobalStore();
    const items = globalState.catalogue.items;

    const routes = _.orderBy(globalState.routes, [B => B.name.toLowerCase()]);
    const { sourceRecord } = CatalogueState;

    const selectedroute = sourceRecord;

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        if (inputLabel.current) {
            setLabelWidth(inputLabel.current.offsetWidth);
        }
    }, []);


    return useMemo(() => {
        const routeID = selectedroute ? selectedroute.sourceRouteID : 0;

        const handleChange = event => {
            const id = event.target.value;
            const route = routes.find(X => X.sourceRouteID === id);
            //console.log('New route: ', route);

            CatalogueActions.setSourceRecord(route);

            // const routeID = route.sourceRouteID;
            http.post(`/api/catalogue/${VesselID}/routes/${id}/items`, {
                vesselID: VesselID,
                routeID: id,
                productGroupIDs: []
            })
                .then(result => {
                    // console.log("addVesselRouteItems", result.data);
                    const response = result.data;
                    // const newVesselItemIDs = response.vesselItemIDs;
                    const selectedItemIDs = response.selectedItemIDs;
                    const bufferItemIDs = response.bufferItemIDs;

                    // console.log('response', response);


                    // Set Items
                    const itemIDs = [...selectedItemIDs, ...bufferItemIDs];
                    const routeItems = items.filter(X => itemIDs.includes(X.itemID));
                    // console.log('routeItems', routeItems);
                    CatalogueActions.setSourceLines(routeItems, selectedItemIDs);
                    // CatalogueActions.setSelectedItems(selectedItemIDs);

                })
                .catch(error => {
                    //alert(error);
                });

            // CatalogueActions.loadRouteItems(VesselID, id, []);

        };

        return (
            <React.Fragment>

                <Grid container spacing={1} alignItems="center" wrap='nowrap'>
                    <Grid item >
                        <FormControl variant="outlined" fullWidth margin="dense">
                            <InputLabel ref={inputLabel}>Select a route</InputLabel>
                            <Select
                                style={{ minWidth: '285px', maxWidth: '285px' }}
                                value={routeID}
                                onChange={handleChange}
                                labelWidth={labelWidth}
                            >
                                {
                                    routeID !== 0 ? null : <MenuItem key={0} value={0}>Select a route</MenuItem>
                                }
                                {
                                    routes.map(X => <MenuItem key={X.sourceRouteID} value={X.sourceRouteID}>{X.name}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item>
                        <CreateRouteButton vesselID={VesselID} />
                    </Grid>
                    <Grid item>
                        <UploadRouteButton vesselID={VesselID} />
                    </Grid>

                </Grid>

                {/* <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <UploadRouteButton vesselID={VesselID} />
                    </Grid>
                    <Grid item xs={6}>
                        <UploadRouteButton vesselID={VesselID} />
                    </Grid>
                </Grid> */}

            </React.Fragment>
        );

    }, [labelWidth, VesselID, routes, selectedroute, items, CatalogueActions]);
}

const CatalogueSelector = ({ VesselID, CatalogueActions }) => {
    const [globalState] = useGlobalStore();
    const items = globalState.catalogue.items;

    useEffect(() => {

        // console.log('CatalogueSelector', items);

        CatalogueActions.setSourceLines(items);

    }, [items, CatalogueActions])

    return (null);
}

const ProductGroupSelectorContainer = () => {
    const [catalogueState,] = useCatalogueStore();
    const { sourceLines } = catalogueState;

    const visible = sourceLines.length > 0;

    if (!visible)
        return null;


    return (
        <React.Fragment>
            <Divider variant="middle" style={{ marginTop: '8px', marginBottom: '8px' }} />
            <div className="d-flex flex-between flexFill" autoComplete="off">
                <ProductGroupSelector />
                <ProductScaleSelector />
            </div>
        </React.Fragment>
    );
}

// const ProductGroupSelector = ({ ProductGroup, CatalogueActions, catalogue, productGroups, productItemGroups }) => {
const ProductGroupSelector = () => {

    const [globalState] = useGlobalStore();
    const { productGroups, catalogue } = globalState;

    const [catalogueState, catalogueActions] = useCatalogueStore();
    const { sourceLines, productGroup } = catalogueState;


    const selectableProductGroupIDs = _.uniqBy(sourceLines, X => X.productGroupID).map(X => X.productGroupID);
    const selectableProductGroups = productGroups.filter(X => selectableProductGroupIDs.includes(X.productGroupID));

    // console.log('selectableProductGroups', selectableProductGroups)
    // console.log('catalogue.items', catalogue.items)

    // console.log('ProductGroupSelector', selectableProductGroups);

    const PG = productGroup ? productGroups.find(X => X.productGroupID === productGroup) : null;
    const PGID = PG ? PG.productGroupID : 0;

    const valid = catalogue && productGroups;

    // console.log('ProductGroupSelector', productGroups, catalogue, valid);

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        if (inputLabel.current) {
            setLabelWidth(inputLabel.current.offsetWidth);
        }
    }, [PGID, valid]);


    const handleProductChange = (e) => {
        const id = e.target.value;

        // console.log('handleProductChange', id);
        catalogueActions.setProductGroup(id);
    }


    if (!valid)
        return null;

    return (
        <div>
            <FormControl variant="outlined" style={{ minWidth: 20, maxWidth: '380px' }} margin="dense">
                <InputLabel ref={inputLabel}>Select Product</InputLabel>
                <Select
                    style={{ width: '150px', maxWidth: '380px' }}
                    value={PGID}
                    onChange={handleProductChange}
                    renderValue={() => (PG ? <div>{PG.code}</div> : <em>Product</em>)}
                    labelWidth={labelWidth}
                >
                    {
                        selectableProductGroups ? null : <MenuItem key={0} value={0} />
                    }

                    {
                        selectableProductGroups && selectableProductGroups.map(PG => {
                            const valid = catalogue.items.findIndex(X => X.productGroupID === PG.productGroupID) > -1;

                            // console.log('ProductGroup', PG.productGroupID, valid, catalogue.items);

                            return valid ? (
                                <MenuItem key={PG.productGroupID} value={PG.productGroupID}>
                                    <ListItemText primary={PG.caption} />
                                </MenuItem>
                            ) : null;
                        })
                    }

                </Select>
            </FormControl>
            {/* <ProductScaleSelector ProductGroup={PG} /> */}
        </div>
    );
}

// const ProductScaleSelector = ({ ProductGroup, CatalogueActions, catalogue, productItemGroups, selectedIDs }) => {

const ProductScaleSelector = () => {

    const [, globalActions] = useGlobalStore();
    // const { productGroups, productItemGroups: allItemGroups, catalogue } = globalState;

    const [catalogueState, catalogueActions] = useCatalogueStore();
    const { productGroup: PGID, productItemGroups: PGIDS } = catalogueState;

    const productScales = globalActions.getProductItemGroupsLocal(PGID);

    // console.log('productScales', productScales);

    // const selectedScales = selectedIDs;
    const disabled = productScales.length === 0;

    // const PGID = productGroup;
    const valid = PGID > 0 && productScales.length > 0;

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        if (inputLabel.current) {
            setLabelWidth(inputLabel.current.offsetWidth);
        }
    }, [PGIDS, valid]);


    const handleScaleChange = (e) => {
        const newScales = e.target.value;
        catalogueActions.setProductItemGroups(newScales);
    }

    if (!valid)
        return null;


    return (
        <FormControl variant="outlined" style={{ minWidth: 20 }} disabled={disabled} margin="dense">
            <InputLabel ref={inputLabel}>Select Scales</InputLabel>
            <Select
                style={{ width: '150px' }}
                multiple
                value={PGIDS}
                renderValue={() => <div>Scale [{PGIDS.length}/{productScales.length}]</div>}
                onChange={handleScaleChange}
                labelWidth={labelWidth}
            >
                {
                    productScales && productScales.map(PIG => {

                        const count = catalogueActions.getSelectedProductGroupItemCount(PIG.productItemGroupID);
                        const checked = PGIDS.includes(PIG.productItemGroupID);

                        const countText = count > 0 ? ` [${count}]` : "";

                        return (
                            <MenuItem key={PIG.productItemGroupID} value={PIG.productItemGroupID}>
                                <Checkbox checked={checked} color="primary" />
                                <ListItemText primary={`${PIG.caption} ${countText}`} />
                                {/* <Chip variant="outlined" color="secondary" size="small" label="5" /> */}
                            </MenuItem>
                        )
                    })
                }
            </Select>
        </FormControl>
    );
}

const CatalogSelectFooter = () => {
    const [, basketActions] = useBasketStore();

    const [catalogueState, catalogueActions] = useCatalogueStore();
    const { sourceRecord, sourceLines, productItemGroups } = catalogueState;

    // const sourceLineItemIDs = sourceLines.map(X => X.itemID);

    const selectedProductGroupItems = catalogueActions.getSelectedProductItems();
    const selectedItemIDs = selectedProductGroupItems.map(X => X.itemID);

    const hasLines = sourceLines.length > 0;
    const selectedCount = selectedProductGroupItems.length
    const hasSelected = selectedCount > 0;


    const listItems = sourceLines.filter(X => productItemGroups.includes(X.productItemGroupID)).map(X => X.itemID);

    const handleAddToBasket = () => {
        // console.log('handleAddToBasket', selectedItemIDs);
        basketActions.addBasketItems(selectedItemIDs);
        basketActions.addSourceRecord(sourceRecord);
    }

    return (
        <React.Fragment>
            <div>
                <Button
                    variant="contained"
                    size="small"
                    disabled={!hasLines}
                    onClick={() => { catalogueActions.addSelectedItems(listItems); }}>All</Button>

                <Button
                    style={{ marginLeft: '8px' }}
                    variant="contained"
                    size="small"
                    disabled={!hasLines && !hasSelected}
                    onClick={() => { catalogueActions.removeSelectedItems(listItems); }}>None</Button>
            </div>

            <div style={{ marginRight: '0px' }}>
                <Badge
                    color="secondary"
                    badgeContent={selectedCount}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}

                >
                    <Button variant="contained" size="small" disabled={!hasSelected} onClick={handleAddToBasket}>Add to Basket</Button>
                </Badge>
            </div>

        </React.Fragment>
    );
}


export default CatalogueSelectItems
