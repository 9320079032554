import React, { useState } from 'react'
import { Grid, TextField, Button, } from '@material-ui/core';
import http from '../../../../Global/Services/httpService';
import { isValidateEmail } from '../../../../Global/Data/Methods';
import ContentColumn from '../../Custom/ContentColumn';

import Breadcrumb from '../../Navigation/Breadcrumb';

// The option to set password has only been commented out,  state set to false and value check set to 0
// Should this option ever come back to version, we would only have to undo than re-write

const UserCreate = ({ history }) => {

    const [creating, setCreating] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState(false);
    const [passwordRepeat, setPasswordRepeat] = useState(false);

    const disabled = creating ||
        (firstName.length < 3) ||
        (lastName.length < 3) ||
        (password.length < 0) ||
        (passwordRepeat.length < 0) ||
        password !== passwordRepeat || !isValidateEmail(email);

    const firstNameChange = (e) => {
        const val = e.target.value;
        setFirstName(() => val);
    }

    const lastNameChange = (e) => {
        const val = e.target.value;
        setLastName(() => val);
    }

    const emailChange = (e) => {
        const val = e.target.value;
        setEmail(() => val);
    }

    const passwordChange = (e) => {
        const val = e.target.value;
        setPassword(() => val);
    }

    const passwordRepeatChange = (e) => {
        const val = e.target.value;
        setPasswordRepeat(() => val);
    }


    const onSubmit = (e) => {
        e.preventDefault();

        const postData = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: password,
        };


        setCreating(() => true);

        http.post('/api/account/users/create', postData)
            .then(result => {
                // actions.login(result.data);

                setFirstName(() => "");
                setLastName(() => "");
                setEmail(() => "");
                setPassword(() => "");
                setPasswordRepeat(() => "");

                history.push("/account/users");
            })
            .catch(() => {
                setCreating(() => false);
            });
    }


    return (
        <ContentColumn loaded={true}>
            <div style={{ padding: '10px' }} disabled={true}>
            <Breadcrumb Module="Account Settings" currentPage="Create Users" />

                {/* <Typography component="h1" variant="h5" align="center">Create new user</Typography> */}

                <form noValidate>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete="fname"
                                value={firstName}
                                onChange={firstNameChange}
                                name="firstName"
                                variant="outlined"
                                required
                                fullWidth
                                id="firstName"
                                label="First Name"
                                autoFocus
                                disabled={creating}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                value={lastName}
                                onChange={lastNameChange}
                                required
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                autoComplete="lname"
                                disabled={creating}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                value={email}
                                onChange={emailChange}
                                required
                                type="email"
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                disabled={creating}
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                value={password}
                                onChange={passwordChange}
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                disabled={creating}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                value={passwordRepeat}
                                onChange={passwordRepeatChange}
                                required
                                fullWidth
                                name="passwordRepeat"
                                label="Repeat Password"
                                type="password"
                                id="passwordRepeat"
                                autoComplete="current-password-repeat"
                                disabled={creating}
                            />
                        </Grid> */}

                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={onSubmit}
                                disabled={disabled}
                            >Create</Button>
                        </Grid>

                    </Grid>
                </form>
            </div>
        </ContentColumn>
    )
}

export default UserCreate
