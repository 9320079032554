import React, { useEffect } from 'react';
import { Route } from "react-router-dom";

import { useFleetStore } from '../../../Global/Stores/fleetStore';
import NavigatorUpdates from './Navigator/Navigator';
import NavigatorUpdateList from './Navigator/NavigatorUpdateList';

import BreadCrumb from '../Navigation/Breadcrumb';


const FleetNavigatorStatus = () => {

    const [, fleetActions] = useFleetStore();

    useEffect(
        () => {
            fleetActions.getHoldingsOverview();
            fleetActions.getFleetPositions();
        }, []
    );

    return (
            <React.Fragment>
                <BreadCrumb Module="Fleet Manager" currentPage="Navigator Updates" />
                
                <Route path="/fleet/navigator/:vesselID?" component={NavigatorUpdates} />
                <Route path="/fleet/navigator/:vesselID" component={NavigatorUpdateList} />
            
            <br /><br /><br />
            </React.Fragment>
        );
};

export default FleetNavigatorStatus;
