import React, { useMemo } from 'react';
import ListItemVessel from '../../../_StyledComponents/ListItemVessel';
import { useGlobalStore } from '../../../../../Global/Stores/globalStore';
import { List, Typography, Divider } from '@material-ui/core';
import ContentColumn from '../../../../Custom/ContentColumn';
import { useBasketStore } from '../../../../../Global/Stores/basketStore';
import { useHistory } from "react-router-dom";
import _ from 'lodash';

const CatalogueSelectVessel = () => {

    const [basketState, basketActions] = useBasketStore();
    const [globalState] = useGlobalStore();
    const vessels = _.orderBy(globalState.vessels, ['vesselName'], ['asc']);

    //console.table("Vessels", vessels);
    // console.log('vesselID', basketState.vesselID);

    const itemsLoaded = Boolean(globalState.catalogue);
    const vesselsLoaded = Boolean(vessels.length);
    const loaded = itemsLoaded && vesselsLoaded;

    const { vesselID } = basketState;

    return (

        <ContentColumn loaded={loaded} selectedID={vesselID}>
            <div className="contentColumnHeader">
                <Typography variant="h6" display="block" >Select a Vessel</Typography>
            </div>

            <div className="contentColumnBody disablePadding">
                <List component="div">
                    {
                        vessels.map(V => <CatalogueVesselListItem key={V.vesselID} Vessel={V} BasketActions={basketActions} Selected={V.vesselID == basketState.vesselID} />)
                    }
                    <Divider />
                </List>
            </div>
        </ContentColumn>
    );
}


const CatalogueVesselListItem = ({ Vessel, BasketActions, Selected }) => {

    let history = useHistory();
    //console.log('basket VesselID', Selected);

    return useMemo(() => {

        // console.log('basket VesselID', Selected);

        const handleVesselClick = (vesselID) => {
            // console.log('Vessel Click', Vessel, vesselID);
            BasketActions.setVessel(vesselID);
            history.push(`/sales/catalogue/${vesselID}`);
        }

        return (
            <ListItemVessel
                vessel={Vessel}
                onClick={handleVesselClick}
                selected={Selected}
            >

            </ListItemVessel>
        );

    }, [Vessel, Selected]);

}




export default CatalogueSelectVessel;
