import React from 'react'

// Material UI Support Files
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import { HeadsetMic, ShoppingBasket, DirectionsBoat, Explore } from '@material-ui/icons';
import Home from '@material-ui/icons/Home';


// Custom Styling Properties
import menuStyles from '../CSS/MenuStyles.module.css';

// Actual work starts here
const BottomNav = () => {

    // const [value, setValue] = React.useState(0);

    return (
        <React.Fragment>
            {/*Bottom Menu: Holds Bottom Menu with default quick menu items defined per the most likely used menus by users*/}
            <div className={menuStyles.bottomNav}>
                <BottomNavigation className={menuStyles.bottomNavStyle}>
                    <BottomNavigationAction href="/sales/catalogue" label="Order" icon={<ShoppingBasket />} />
                    <BottomNavigationAction href="/fleet/holdings" label="Fleet" icon={<DirectionsBoat />} />
                    <BottomNavigationAction href="/sales/shipments" label="Shipment" icon={<Explore />} />
                    {/* <BottomNavigationAction href="/help" label="Contact" icon={<HeadsetMic />} /> */}
                    <BottomNavigationAction href="/home" label="Home" icon={<Home />} />
                </BottomNavigation>
            </div>
        </React.Fragment>
    )
}

export default BottomNav