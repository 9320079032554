import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({  
  palette: {
    primary: {
      main: '#0499d4',
      // ligth: '#6cb842',
      // dark: '#005900'
    },
    // secondary: {
    //   light: '#01689b',
    //   main: '#0044ff',
    //   contrastText: '#ffcc00',
    // },
  // },
  // overrides: {
  //   MuiInputLabel: {
  //     root: {
  //       color: 'black',
  //       fontSize: 13,
  //     },
  //   },
  }
});

function withRoot(Component) {
  function WithRoot(props) {
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.


    
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
