import React, { useEffect } from 'react';
import { Collapse, List } from '@material-ui/core';
import ListItemVessel from '../../_StyledComponents/ListItemVessel';
import { ListItemSubQuote } from '../../_StyledComponents/ListItemSub';
import _ from 'lodash';
// import storage from 'store';

const VesselQuotes = ({ vesselQuotes, selectedID }) => {

    const key = `VQL${vesselQuotes.vesselID}`;
    const openLoad = JSON.parse(sessionStorage.getItem(key)) || false;

    // console.log('selectedID', selectedID);

    const [open, setOpen] = React.useState(openLoad);
    useEffect(() => { sessionStorage.setItem(key, open); }, [open]);

    const quotes = _.orderBy(vesselQuotes.approvals, ['reference'], ['desc']);
    return (
        // <List component="div">
        <ListItemVessel
            vessel={vesselQuotes}
            number={quotes.length}
            open={open}
            setOpen={setOpen}
            selected={open}
            subSelectedID={selectedID}
        >

            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {
                        // quotes.map(X => (<VesselQuote key={X.salesQuoteID} quote={X} selected={selectedID === X.salesQuoteID} />))
                        quotes.map(X => (<ListItemSubQuote key={X.salesQuoteID} quote={X} selected={selectedID === X.salesQuoteID} />))
                    }
                </List>
            </Collapse>

        </ListItemVessel>
        // </List>
    );
};


// const VesselQuote = ({ quote, selected }) => {

//     const to = `/sales/quotes/${quote.salesQuoteID}`;
//     // const price = _.sumBy(quote.lines, 'linePrice').toFixed(2);

//     return ((
//         <Link className="ListItemLink" component={RouterLink} to={to} color='inherit' style={{ textDecoration: 'none' }}>
//             <ListItem key={quote.salesQuoteID}
//                 style={{ paddingLeft: '20px' }}
//                 selected={selected}
//                 button
//                 disableRipple
//                 divider>

//                 <ListItemText
//                     primary={`${quote.reference} [${quote.customerReference}]`}
//                     secondary={`${quote.quoteDate + " - "} Total: ${quote.currency} ${(quote.totalPrice || '').toFixed(2)}`}
//                 />


//                 <Chip size="small" color="primary" variant="outlined" label={quote.lineCount} />
//             </ListItem>
//         </Link>
//     ));
// };

export default VesselQuotes;
