import React, { useState, useEffect } from 'react';
import { Typography, List } from '@material-ui/core';
import { AnnouncementLine } from './AnnouncementSummary';
import ContentColumn from '../Custom/ContentColumn';
import Loading from '../Misc/Loading';
import { useGlobalStore } from '../../../Global/Stores/globalStore';

const NewsList = ({ match }) => {
    const [globalState, globalActions] = useGlobalStore();
    const newsItems = globalState.newsItems || [];
    const selectedID = +match.params.newsID;
    const loaded = Boolean(newsItems.length);

    // console.log(selectedID)

    useEffect(() => {
        globalActions.getAnnouncements();
    }, []);
    
// console.log(newsItems.length)
    return (
        <ContentColumn loaded={true} > {/*} Removed loaded={loaded} */}
            {/* <div className="contentColumnHeader" style={{ padding: '10px', width: "100%" }}>
                <Typography variant="h6">{`News & Announcements`}</Typography>
            </div> */}

            <div className="contentColumnBody" style={{ padding: '10px', width: "100%" }}>
                {
                    loaded ? (
                        <List component="div">
                            {
                                newsItems.map(X => (<AnnouncementLine key={X.chartagentNewsID} newsItem={X} selected={selectedID === X.chartagentNewsID} />))
                            }
                        </List>
                    ) : <div> No news to display </div>
                }
            </div>
        </ContentColumn>
    );
}

export default NewsList
