import React from 'react';
import SalesSummary from './Summaries/SalesSummary';
import FleetSummary from './Summaries/FleetSummary';
import AnnouncementSummary from './Summaries/AnnouncementSummary';
import { AppBar, Toolbar, Typography, IconButton, Grid } from '@material-ui/core';
// import ReportSummary from './Summaries/ReportSummary';
import CT_Logo from "./../../../../src/Images/CT_TEXT.png";
import CT_DIST_Logo from "./../../../../src/Images/AVCS/ADM_ICA_DD_rgb.png";

const Home = () => {
    // const [sale] = useSaleStore();

    // console.log('Home', sale);

    return (
        <div className="homeContainer">
            <div className="cardContainer">
                <AnnouncementSummary />
                <SalesSummary />
                <FleetSummary />
                {/* <ReportSummary /> */}
            </div>
            <HomeFooter />
        </div>
    );
};


// Desktop View
const onMobileView = () => {
    // setAnchorEl(null);
    localStorage.setItem('isMobile', 'mobile');
    window.location.reload(true);

  }

  //Switch to mobile display content
const SwitchToMobile = () => {

    if (window.screen.width < 700 && window.screen.height < 900 || window.screen.width < 900 && window.screen.height < 700)
    {
    return (
        <React.Fragment>
        <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className="homeFooter"
        // spacing={2}
        onClick={onMobileView}
        >
            <Grid item>
                <span >Switch to Mobile View</span>
            </Grid>

        </Grid>
        </React.Fragment>
    );
    }else {
        return (
            <React.Fragment></React.Fragment>
        )
    }
}

const HomeFooter = () => {


    return (
        <React.Fragment>
        
        {/* <SwitchToMobile /> */}
            <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            className="homeFooter"
            // spacing={2}
            >
                <Grid item>
                    <a href="https://www.charttrack.com/" target="_blank">
                        <img className="logo" src={CT_Logo} />
                    </a>
                </Grid>

                <Grid item>
                    <a href="https://www.ukho.gov.uk" target="_blank">
                        <img className="logo" src={CT_DIST_Logo} />
                    </a>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default Home;
