import React, {useState} from 'react'
import queryString from 'query-string';
import _ from 'lodash';

// Material UI support files
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { CssBaseline, Button, TextField, Grid, Container, IconButton, InputAdornment, Typography, Paper } from "@material-ui/core";

// Global Support Imports
import http from '../../../Global/Services/httpService';

// Images
import CT_Logo from "./../../../../src/Images/CT-logo-transparant.png";

/* Custom CSS Files */
import loginStyles from '../CSS/LoginStyles.module.css'
//  theme.palette.secondary.main

/*
Emmanuel Eshun-Davies, January 23, 2020

TODO:
*/


const ResetPassword = ({ match, location, history, Title }) => {

    // const loginStyles = useStyles();

    const [formState, setFormState] = useState({
        password: '',
        password2: '',
        remember: false,
        showPassword: false,
        loading: false,
    });

    const query = queryString.parse(location.search, {});

    // const midsVal = query.mids || ''

    // console.log('access_token', query.access_token, !query.access_token);
    // console.log('mids', query);

    if (!query.access_token) {
        history.replace('/login');
    }

    const token = query.access_token;

    // console.log('token', token);

    const handleChange = event => {
        const target = event.target;
        if (!formState.loading)
            setFormState({ ...formState, [target.name]: (target.type === 'checkbox' ? target.checked : target.value) });
    };

    const handleClickShowPassword = () => {
        if (!formState.loading)
            setFormState({ ...formState, showPassword: !formState.showPassword });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setFormState({ ...formState, loading: true });

        const postData = {
            Password: formState.password,
            Password2: formState.password2,
            mids: query.mids,
        };

        http.post('/api/account/setpassword', postData, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(result => {
                // actions.login(result.data);
                const typeID = _.get(result.data, 'toast.typeID');
                if (typeID === 0)
                    history.replace('/login');

                // console.log('typeID', typeID);
                // console.log('result.data', result.data);
            })
            .catch((error) => {
                // console.log('login error', error)
                // notificationActions.showNotification({variant: 'error', message: 'Invalid Credentials'});
                setFormState({
                    ...formState,
                    password: '',
                    password2: '',
                    loading: false
                });
            });
    };


    const validPassword = formState.password.length > 5 && formState.password === formState.password2;

    return (
        <Container component="div" maxWidth="xs">
            <CssBaseline />
            <div className={loginStyles.logoCenter}>
                <Grid>
                    <img className="logo" width="60%" src={CT_Logo} />
                </Grid>
            </div>
            <div className={loginStyles.leftText}>
                <Typography component="h5" variant="body2" align="left">
                    <Paper style={{padding: 10}} raised="true">
                        <b>Set New Password</b><br />
                    1. Enter your new password (must be at least 6 characters)<br />
                    2. Enter Your new password again<br />
                    3. Tap Reset Password<br />
                    4. Your new Password is set<br />
                    </Paper>
                </Typography>
            </div>
            <div className={loginStyles.forms}>
            <form noValidate>
                            <TextField
                                margin="normal"
                                size="small"
                                autoFocus
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type={formState.showPassword ? 'text' : 'password'}
                                id="password"
                                autoComplete="new-password"
                                value={formState.password}
                                onChange={handleChange}
                                InputProps={{
                                    // autoComplete: "new-password",
                                    // form: { autoComplete: "off", },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                style={{ color: '#C0C0C0' }}
                                                edge="end"
                                                aria-label="Toggle password visibility"
                                                onClick={handleClickShowPassword}
                                            >
                                                {formState.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <TextField
                                margin="normal"
                                size="small"
                                required
                                fullWidth
                                name="password2"
                                label="Repeat Password"
                                type={formState.showPassword ? 'text' : 'password'}
                                id="password2"
                                autoComplete="new-password"
                                value={formState.password2}
                                onChange={handleChange}
                                InputProps={{
                                    // autoComplete: "new-password",
                                    // form: { autoComplete: "off", },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                            style={{ color: '#C0C0C0' }}
                                                edge="end"
                                                aria-label="Toggle password visibility"
                                                onClick={handleClickShowPassword}
                                            >
                                                {formState.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />


                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                // className={loginStyles.submit}
                                disabled={!validPassword || formState.loading}
                                // className={classes.submit}
                                onClick={onSubmit}
                            >RESET PASSWORD</Button>
                        </form>
            </div>
        </Container>
    )
}


export default ResetPassword