import React, { useMemo } from 'react';
import List from '@material-ui/core/List';
import { Box, makeStyles, Divider } from '@material-ui/core';
import ListItemLink from '../../Misc/ListItemLink';
import SummaryCardHeader from './SummaryCardHeader';
import { useSaleStore } from '../../../../Global/Stores/saleStore';
import img from "./../../../../Images/summaries/icon-ordermanagment.png";
import SummaryCard, { SummaryCardLine } from './SummaryCard';
import _ from 'lodash';

const SalesSummary = () => {
    const [saleState,] = useSaleStore();

    const approvals = +_.get(saleState.summary, 'approvals');
    const orders = +_.get(saleState.summary, 'orders');
    const flatFees = +_.get(saleState.summary, 'flatFees');
    const shipments = +_.get(saleState.summary, 'shipments');

    return useMemo(() => {

        // console.log('SalesSummary', saleState.summary);

        return (
            <SummaryCard>
                <SummaryCardHeader title="Order Management" image={img} />

                <List component="div" dense>
                    <SummaryCardLine text="Quotations to approve" to="/sales/quotes" numberSmall={approvals} isHeader={false} />
                    <SummaryCardLine text="Create new order" to="/sales/catalogue" isHeader={false} />
                    <SummaryCardLine text="Order history" to="/sales/orders" numberSmall={orders} isHeader={false} />
                    <SummaryCardLine text="Flat Fee contracts" to="/sales/flatfee" numberSmall={flatFees} isHeader={false} />
                    <SummaryCardLine text="Shipment tracking" to="/sales/shipments" numberSmall={shipments} isHeader={false} />
                    <SummaryCardLine text="AVCS Online" to="/sales/avcso" isHeader={false} />
                    <Divider />
                </List>

            </SummaryCard>
        );
    }, [approvals, orders, flatFees, shipments]);
    // }, [saleState.summary]);
}

export default SalesSummary
