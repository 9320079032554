import React, { createContext, useContext, useState, useEffect } from 'react';
import http from './../Services/httpService';
import _ from 'lodash';

const initialState = {
    itemSourceOptions:
        [
            {
                id: 1,
                caption: 'Route',
                preSelect: false,
                isBasket: false,
                isQuote: false,
                isRoute: true,
            },
            {
                id: 2,
                caption: 'Basket',
                preSelect: true,
                isBasket: true,
                isQuote: false,
                isRoute: false,
            },
            {
                id: 3,
                caption: 'Quote',
                preSelect: true,
                isBasket: false,
                isQuote: true,
                isRoute: false,
            },
            {
                id: 4,
                caption: 'Catalogue',
                preSelect: false,
                isBasket: false,
                isQuote: false,
                isRoute: false,
            },
        ],
    itemSource: null,
    sourceRecord: null,
    productGroup: 0,
    productItemGroups: [],
    sourceLines: [],
    selectedItemIDs: [],
    vesselItemIDs: [],
    filterText: null,
};


export const CatalogueContext = createContext();

export const CatalogueProvider = ({ children }) => {
    const [state, setState] = useState(initialState);


    const clearData = () => {
        reset();
    }

    const reset = () => {
        setState(() => {
            const newState = { ...initialState };
            return newState;
        })
    }

    const setItemSource = (id) => {

        setState(() => {
            const newState = { ...initialState };
            newState.itemSource = newState.itemSourceOptions.find(X => X.id === id);
            return newState;
        })
    }

    const setSourceRecord = (record) => {
        setState(prev => {
            const newState = { ...initialState };
            newState.itemSource = { ...prev.itemSource };
            newState.sourceRecord = { ...record };
            return newState;
        })
    }

    const setSourceLines = (lines, selectedIDs = []) => {


        setState(prev => {
            // console.log('setSourceLines', lines);

            const newState = { ...prev };
            newState.sourceLines = [...lines];

            const PGIDS = _.uniq(newState.sourceLines.map(X => X.productGroupID));
            const itemGroupIDs = _.uniq(newState.sourceLines.map(X => X.productItemGroupID));

            // console.log('PGIDS', PGIDS);

            if (PGIDS.length === 1) {
                newState.productGroup = PGIDS[0];
                newState.productItemGroups = itemGroupIDs;
            }

            if (prev.itemSource.preSelect === true) {
                newState.selectedItemIDs = newState.sourceLines.map(X => X.itemID);
            }
            else {
                newState.selectedItemIDs = selectedIDs;
            }

            return newState;
        })
    }


    const setProductGroup = (PGID) => {

        //console.log('setProductGroup 1', PGID);


        setState(prev => {
            const newState = { ...prev };
            newState.productGroup = PGID;

            // Auto select all groups
            const PGIDS = _.uniq(prev.sourceLines.filter(X => X.productGroupID === PGID && X.productItemGroupID).map(X => X.productItemGroupID));

            // console.log('setProductGroup 2', PGID, PGIDS);

            newState.productItemGroups = PGIDS.length > 0 ? PGIDS : [0];
            //newState.productItemGroups = [];
            return newState;
        })
    }

    const setProductItemGroups = (productItemGroups) => {

        // console.log('setProductItemGroups', productItemGroups);

        setState(prev => {
            const newState = { ...prev };
            newState.productItemGroups = [...productItemGroups];
            return newState;
        })
    }

    const setFilterText = (text) => {

        // console.log('setProductItemGroups', productItemGroups);

        setState(prev => {
            const newState = { ...prev, filterText: text };
            // newState.productItemGroups = [...productItemGroups];
            return newState;
        })
    }

    const addSelectedItems = (itemIDs) => {

        const selectedItemIDs = _.uniq([...state.selectedItemIDs, ...itemIDs]);
        setSelectedItems(selectedItemIDs);

        // setState(prev => {
        //     const newState = { ...prev };
        //     newState.selectedItemIDs = _.uniq([...prev.selectedItemIDs, ...itemIDs]);
        //     return newState;
        // })
    }

    const removeSelectedItems = (itemIDs) => {

        const selectedItemIDs = state.selectedItemIDs.filter(X => !itemIDs.includes(X));
        setSelectedItems(selectedItemIDs);

        // setState(prev => {
        //     const newState = { ...prev };
        //     newState.selectedItemIDs = prev.selectedItemIDs.filter(X => !itemIDs.includes(X));
        //     //newState.selectedItemIDs = [];
        //     return newState;
        // })
    }

    const toggleSelectedItem = (itemID) => {

        // const isSelected = state.selectedItemIDs.includes(itemID);
        // const selectedItemIDs = isSelected ? state.selectedItemIDs.filter(X => X !== itemID) : [...state.selectedItemIDs, itemID];
        // setSelectedItems(selectedItemIDs);
        setState(prev => {
            const newState = { ...prev };
            const isSelected = prev.selectedItemIDs.includes(itemID);
            newState.selectedItemIDs = isSelected ? prev.selectedItemIDs.filter(X => X !== itemID) : [...prev.selectedItemIDs, itemID];
            return newState;
        })
    }

    const setSelectedItems = (selectedItemIDs) => {

        //console.log('setSelectedItems', selectedItemIDs.length)

        setState(prev => {
            const newState = { ...prev };
            newState.selectedItemIDs = _.difference(selectedItemIDs, prev.vesselItemIDs);
            //newState.selectedItemIDs = [];
            return newState;
        })

    }


    const getSelectedProductGroupItemCount = (PGID) => {

        const { sourceLines, selectedItemIDs } = state;
        return sourceLines
            .filter(X => X.productItemGroupID === PGID && selectedItemIDs.includes(X.itemID)).length;
    }

    const loadVesselItemIDs = (vesselID) => {

        if (vesselID > 0) {
            http.get(`/api/fleet/holdings/${vesselID}/ID`)
                .then(result => {

                    const VIDS = result.data.vesselItemIDs;
                    setVesselItemIDs(VIDS);
                })
                .catch(error => {
                    //alert(error);
                });
        }
        else {
            // Reset            
            setVesselItemIDs([]);
        }
    }

    const loadSalesQuoteItems = (SQID) => {

        if (SQID > 0) {

            http.get(`/api/sales/quotes/${SQID}/lines`)
                .then(result => {
                    const lines = result.data;
                    const items = lines.map(X => X.item);
                    // console.log('items', items);
                    setSourceLines(items);
                })
                .catch(error => {
                    //alert(error);
                });
        }
        else {
            // Reset            
            setVesselItemIDs([]);
        }
    }

    const loadRouteItems = (vesselID, routeID, PGIDS) => {

        // const routeID = route.sourceRouteID;
        http.post(`/api/catalogue/${vesselID}/routes/${routeID}/items`, {
            vesselID: vesselID,
            routeID: routeID,
            productGroupIDs: PGIDS
        })
            .then(result => {
                // console.log("addVesselRouteItems", result.data);
                const response = result.data;
                // const newVesselItemIDs = response.vesselItemIDs;
                // const selectedItems = response.selectedItemIDs;
                // const bufferItemIDs = response.bufferItemIDs;

                //console.log('response', response);

            })
            .catch(error => {
                //alert(error);
            });
    }


    const setVesselItemIDs = (VIDS) => {

        // console.log('setVesselItemIDs', VIDS);

        setState((prev) => {
            const newState = { ...prev };
            newState.vesselItemIDs = VIDS || [];
            return newState;
        })
    }


    const getSelectedProductItems = () => {
        const { productGroup, selectedItemIDs, sourceLines } = state;
        return sourceLines.filter(X => X.productGroupID === productGroup && selectedItemIDs.includes(X.itemID));
    }

    const actions = {
        // loadData: loadData,
        clearData: clearData,
        reset: reset,

        setItemSource: setItemSource,
        setSourceRecord: setSourceRecord,
        setProductGroup: setProductGroup,
        setProductItemGroups: setProductItemGroups,
        setFilterText: setFilterText,
        setSourceLines: setSourceLines,

        // addSourceRecord:addSourceRecord, // Items added to basket

        addSelectedItems: addSelectedItems,
        removeSelectedItems: removeSelectedItems,
        toggleSelectedItem: toggleSelectedItem,

        loadSalesQuoteItems: loadSalesQuoteItems,
        loadRouteItems: loadRouteItems,

        getSelectedProductGroupItemCount: getSelectedProductGroupItemCount,

        loadVesselItemIDs: loadVesselItemIDs,

        getSelectedProductItems: getSelectedProductItems,
    };

    return (
        <CatalogueContext.Provider value={[state, actions]}>
            {children}
        </CatalogueContext.Provider >
    );
}


export const useCatalogueStore = () => useContext(CatalogueContext);