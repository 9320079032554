import React, { useState, useMemo } from 'react';
import { useGlobalStore } from '../../../../../Global/Stores/globalStore';
import { Box, Typography, List, Button, ListItemText, ListItem, ListItemSecondaryAction, IconButton, ListItemIcon, Checkbox, TextField } from '@material-ui/core';
import Select from 'react-select';
import DeleteIcon from '@material-ui/icons/Delete';
import _ from 'lodash';
import http from '../../../../../Global/Services/httpService';
import ContentColumn from '../../../../Custom/ContentColumn';

const RouteCreate = ({ match, history }) => {

    const [selectedPorts, setSelectedPorts] = useState([]);
    const [routingPoints, setRoutingPoints] = useState([]);
    const [routeName, setRouteName] = useState("");
    const [globalState, globalActions] = useGlobalStore();


    const vesselID = +match.params.vesselID;

    const { ports } = globalState.portInfo;

    const hasPorts = Boolean(ports);
    // if (!ports) return null;


    if (hasPorts && routingPoints.length === 0) {
        setRoutingPoints([...globalState.portInfo.routingPoints])
    }

    const AddPort = (port) => {
        setSelectedPorts(prev => [...prev, { ...port, id: Math.random() }]);
    }

    const DeletePort = (port) => {
        setSelectedPorts(prev => prev.filter(X => X.id !== port.id))
    }

    const ToggleRoutingPoint = (point) => {

        const rps = [...routingPoints];
        const p1 = rps.find(P => P.name === point.name);
        // console.log(p1.open);
        p1.open = !(Boolean(point.open));
        setRoutingPoints(() => rps)
    }



    const CreateRoute = () => {

        // console.log("Create Route");
        const ports = selectedPorts.map(P => P.code);
        const routes = routingPoints
            .filter(P => P.open !== P.openByDefault)
            .map(P => P.shortCode);

        const result = {
            PortCodes: ports,
            RoutingCodes: routes,
            RouteName: routeName
        };

        http.post(`api/catalogue/routes/create`, result)
            .then(result => {
                const newRouteID = result.data;
                // console.log("Result.DATA", newRouteID);
                globalActions.getRoutes();
                // history.push('/sales/catalogue/5994/routes/0');
                history.push(`/sales/catalogue/${vesselID}/routes/${newRouteID}`);
            })
            .catch(error => {
                //alert(error)
            });
    }

    const createDisabled = (selectedPorts.length < 2) || (routeName.length < 3)

    return (
        <ContentColumn loaded={hasPorts}>
            <div className="contentColumnHeader" style={{ padding: '0px 10px' }}>
                <Typography variant="h6" display="block" >Create new Route</Typography>
            </div>

            <div className="contentColumnBody" style={{ padding: '8px' }}>

                <PortList AddPort={AddPort} DeletePort={DeletePort} ports={globalState.portInfo.ports} selectedPorts={selectedPorts} />

                <RoutingList RoutingPoints={routingPoints} ToggleRoutingPoint={ToggleRoutingPoint} />

                <div style={{ marginTop: '16px' }}>
                    <Typography variant="subtitle2" >Route Name:</Typography>
                    <TextField
                        fullWidth
                        id="outlined-name"
                        size="small"
                        label="Name"
                        value={routeName}
                        onChange={(X) => {
                            const val = X.target.value;
                            setRouteName(() => val)
                        }}
                        margin="normal"
                        variant="outlined"
                    />
                </div>


                <Button fullWidth size="small" variant="outlined" disabled={createDisabled} onClick={CreateRoute}>Create Route</Button>

            </div>
        </ContentColumn>
    );
};



export const PortList = ({ ports, AddPort, DeletePort, selectedPorts }) => {

    const [portFilterText, setPortFilterText] = useState("");
    const portCount = selectedPorts.length;

    return useMemo(() => {

        if (!Boolean(ports))
            return null;

        const displayPorts = ports.filter(X => (X.name.toLowerCase().indexOf(portFilterText) !== -1));
        const canShowMenu = displayPorts.length > 50 ? false : undefined;

        const OnPortFilterChange = (filter) => {
            setPortFilterText(() => filter.toLowerCase());
        }

        return (
            <div>
                <Typography variant="subtitle2" >Add ports:</Typography>
                <Select
                    placeholder="Ports"
                    getOptionLabel={(X) => X.name}
                    getOptionValue={(X) => X}
                    styles={{ width: '100%' }}
                    className="basic-single"
                    classNamePrefix="select"
                    isSearchable={true}
                    onInputChange={(X) => {
                        OnPortFilterChange(X);
                    }}
                    menuIsOpen={canShowMenu}
                    name="color"
                    value={""}
                    onChange={(X) => { AddPort(X) }}
                    options={displayPorts}
                />

                <List dense component="div" style={{ height: '25vh', margin: '4px 0px', border: '1px solid black', overflow: 'auto' }}>
                    {
                        selectedPorts.map(P => (
                            <ListItem key={P.id} button>
                                <ListItemText  >{P.name} - {P.locode}</ListItemText>
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="Comments" onClick={() => DeletePort(P)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))
                    }
                </List>

            </div>
        );

    }, [portFilterText, portCount])

}


export const RoutingList = ({ RoutingPoints, ToggleRoutingPoint }) => {

    const openPoints = RoutingPoints.filter(X => X.open === true).length;

    return useMemo(() => {
        // console.log("RoutingList", RoutingPoints);
        return (
            <div style={{ marginTop: '16px' }}>
                <Typography variant="subtitle2" >Avoid places:</Typography>
                <List dense component="div" style={{ height: '25vh', margin: '4px 0px', border: '1px solid black', overflow: 'auto' }}>
                    {
                        RoutingPoints.map(P => (
                            <ListItem key={P.name} button>
                                <ListItemIcon>
                                    <Checkbox
                                        color="primary"
                                        edge="start"
                                        checked={Boolean(P.open)}
                                        tabIndex={-1}
                                        disableRipple
                                        onClick={() => ToggleRoutingPoint(P)}
                                    />
                                </ListItemIcon>
                                <ListItemText  >{P.name} - {P.shortCode}</ListItemText>
                            </ListItem>
                        ))
                    }

                </List>
            </div>
        );

    }, [RoutingPoints, openPoints])

}

export default RouteCreate;
