import React, { useMemo } from 'react';
import List from '@material-ui/core/List';
import { Paper, Divider } from '@material-ui/core';
import SummaryCardHeader from './SummaryCardHeader';
import ListItemLink from '../../Misc/ListItemLink';
import img from "./../../../../Images/summaries/icon-fleetmanagment.png";
import SummaryCard, { SummaryCardLine } from './SummaryCard';

const FleetSummary = () => {

    return useMemo(() => {
        return (
            <SummaryCard>
                <SummaryCardHeader title="Fleet Management" image={img} />
                <List component="div" dense>

                    <SummaryCardLine text="Fleet Holdings" to="/fleet/holdings" isHeader={false} />
                    <SummaryCardLine text="Fleet Status" to="/fleet/status" isHeader={false} />
                    {/* <SummaryCardLine text="Fleet Tracking" to="/fleet/tracking" isHeader={false} /> */}
                    {/* <SummaryCardLine text="Routing" to="/fleet" isHeader={false} /> */}
                    <SummaryCardLine text="Fleet Overview" to="/fleet/overview" isHeader={false} />
                    <SummaryCardLine text="Navigator Updates" to="/fleet/navigator" isHeader={false} />
                    <SummaryCardLine text="Folio Status" to="/folios/status" isHeader={false} />
                    <Divider />


                    {/* <Divider />
                    <ListItemLink text="Fleet Holdings" to="/fleet/holdings" isHeader={false} />
                    <Divider />
                    <ListItemLink text="Fleet Status" to="/fleet/status" isHeader={false} />
                    <Divider />
                    <ListItemLink text="Fleet Tracking" to="/fleet/tracking" isHeader={false} />
                     <Divider />
                    <ListItemLink text="Routing" to="/fleet" isHeader={false} /> 
                    <Divider /> */}
                </List>
            </SummaryCard >
        );

    }, []);
}

export default FleetSummary
