import React, { useEffect, useState  } from "react";
import { Link } from "react-router-dom";

////// Material UI Support Files
import { makeStyles } from "@material-ui/core/styles";
import { Drawer, Button, List, ListItem, ListItemText, Divider, Icon, Grid, Collapse } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import {ExpandLess, ExpandMore} from '@material-ui/icons';
// import ExpandMore from '@material-ui/icons/ExpandMore';

////// Global Files
import Menu from '../../../Global/Data/Menu';
import { useUserStore } from '../../../Global/Stores/userStore';

////// Custom Styling Properties
import menuStyles from '../CSS/MenuStyles.module.css'; 

////// Images
import CT_Logo from "../../../../src/Images/CT_TEXT.png";
import CT_DIST_Logo from "../../../../src/Images/AVCS/ADM_ICA_DD_rgb.png";
import MobileConfiguration from "../../../Global/Data/Config";

const useStyles = makeStyles({
  list: {
    width: window.screen.width,
  },
  noFormat: {
    color: "inherit",
    textDecoration: "none",
  },
});

const theme = createMuiTheme({
  overrides: {
    // Style sheet name
    MuiListItemText: {
      // Name of the rule
      primary: {
        // Some CSS
        fontSize: 12,
      },
      secondary: {
        fontSize: 10,
      }
    },
  },
});

const Copyright = () => {
  return (
    <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={0}
    >
        <Grid item xs={2}>
            <a href="https://www.charttrack.com/" target="_blank">
                <img className="logo" src={CT_Logo} width="40px" />
            </a>
        </Grid>

        <Grid item xs={2}>
            <a href="https://www.ukho.gov.uk" target="_blank">
                <img className="logo" src={CT_DIST_Logo} width="40px" />
            </a>
        </Grid>
    </Grid>
  );
}


const TopNav = () => {
  // Get user Data
  const [userState, userActions] = useUserStore(); // Userinfo
  const [profileState, setProfileState] = useState(); // Userinfo
  const classes = useStyles();
  
  
  useEffect(() => {
    setProfileState(() => ({ ...userState.user }))
  }, []);
  
  
  //  Menu slide from left
  const [state, setState] = React.useState({
    left: false
  });
  
  const toggleDrawer = (side, open) => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
      
      setState({ ...state, [side]: open });
    };
    
  
    
    const [openMenu, setOpenMenu] = React.useState(false);
    
    const clickMenu = () => {
      setOpenMenu(!openMenu);
    };


    // Desktop View
    const onDesktopView = () => {
      // setAnchorEl(null);
      localStorage.setItem('isMobile', 'desktop');
      window.location.reload(true);
    }

    // Logout
    const onLogout = () => {
      // setAnchorEl(null);
      userActions.logout(true);
    }
    
    
    // Complete this function and it's use later
    
    
    if (!profileState){
      return null;
    }
    
    // console.log(profileState);
    // Load Menu Items from JSON File 
    
    const sideList = side => (
      <div
      className={classes.list}
      role="presentation"
      // 
      onKeyDown={toggleDrawer(side, false)}
      >
      {/* Back/Menu Exit Button */} 
      <ThemeProvider theme={theme}>
      <List component="nav">
        <ListItem>
          <ListItemText onClick={toggleDrawer(side, false)} primary={<Icon>arrow_back</Icon>} />
        </ListItem>
      </List>
      <Divider />
      {/* Start: Load menu from JSON File */}
      <ShowMenu onClick={toggleDrawer("left", false)} />
      {/* Account and Settings */}
      <List component="nav">
        <Link to="/account" className={classes.noFormat}>
          <ListItem button>
              <ListItemText primary="Account &amp; Settings" onClick={toggleDrawer(side, false)} /> 
          </ListItem>
        </Link>
      </List>
      <Divider />
      {/* Logout */}
      <List component="nav">
        <ListItem button  onClick={toggleDrawer(side, false), onDesktopView}>
          <ListItemText primary="Switch to Desktop View"/> 
        </ListItem>
      </List>
      <Divider />
      {/* Logout */}
      <List component="nav">
        <ListItem button  onClick={toggleDrawer(side, false), onLogout}>
          <ListItemText primary="Logout"/> 
        </ListItem>
      </List>
      <Divider />
      </ThemeProvider>
    </div>
  );

  return (
    <div className={menuStyles.topNav}>
      <Grid container spacing={1} alignContent="flex-end">
        <Grid item xs={3}>
          <Button size="large" onClick={toggleDrawer("left", true)} > {<Icon fontSize="large" className={menuStyles.whiteIcons}>menu_icon</Icon>} </Button>
          <Drawer open={state.left} onClose={toggleDrawer("left", false)}>
            {sideList("left")}
            <br /><br /><br />
            {<Copyright />}
          </Drawer>
        </Grid>
        <Grid item xs={9} style={{paddingTop: 7}}>
          <span className={menuStyles.tnUserName}>{profileState.firstName} {profileState.lastName}</span><br />
          <span  className={menuStyles.tnVesselName}> {profileState.company} </span>
        </Grid>
      </Grid>
      
    </div>
  );
};


const MenuList = props => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const classes = useStyles();

  const subMenuItems = props.subMenus;
//  console.log("Sub Menu Items: ", subMenuItems, "\n Main Menu Items: ", props.mainMenuItem);
  if(subMenuItems){
  return (
    <React.Fragment>
      <List component="nav" key={props.mainMenuItem.id}>
        <ListItem button onClick={handleClick}>
          <ListItemText primary={props.mainMenuItem.label}  secondary={props.mainMenuItem.shortDescription} />
        {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List key={props.mainMenuItem.id} component="div" disablePadding>
          {subMenuItems.map(subMenuItem => (
            <Link key={subMenuItem.id} to={subMenuItem.to} className={classes.noFormat} >
              <ListItem button onClick={props.onClick} >
                  <ListItemText primary={subMenuItem.label} />
              </ListItem>
            </Link>
          ))}
          </List>
        </Collapse>
      </List>
      <Divider />
    </React.Fragment>
  );
  } else {
    return (
      <React.Fragment>
        <List component="nav" >
          <Link key={props.mainMenuItem.id} to={props.mainMenuItem.to} className={classes.noFormat}  onClick={props.onClick} >
            <ListItem button>
                <ListItemText primary={props.mainMenuItem.label}  secondary={props.mainMenuItem.shortDescription} />
            </ListItem>
          </Link>
        </List>
        <Divider />
      </React.Fragment>
    );
  }
};

const ShowMenu = (props) => {
  return (
    <React.Fragment>
      {Menu.map(mainMenu => (
        <MenuList
        key={mainMenu.id}
        mainMenuItem={mainMenu}
        subMenus={mainMenu.subItems}
        onClick={props.onClick}
        />
      ))}
    </React.Fragment>
  );
};



export default TopNav;
