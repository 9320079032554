import React from 'react';
import { Route, Switch } from "react-router-dom";
import Orders from './Orders/Orders';
import SalesOverview from './SalesOverview';
import MapControl from '../../MapControl/MapControl';
import Quotes from './Quotes/Quotes';
import VesselQuoteLineDetail from './Quotes/VesselQuoteLineDetail/VesselQuoteLineDetail';
import VesselOrderLineDetail from './Orders/VesselOrderLineDetail/VesselOrderLineDetail';
import VesselOrderDetail from './Orders/VesselOrderDetail/VesselOrderDetail';
import VesselQuoteDetail from './Quotes/VesselQuoteDetail/VesselQuoteDetail';
import Catalogue from './Catalogue/Catalogue';
import RouteCreate from './Catalogue/RouteCreate/RouteCreate';
import RouteSelect from './Catalogue/RouteSelect/RouteSelect';
import RouteItems from './Catalogue/RouteItems/RouteItems';
import CatalogueBasketReview from './Catalogue/Basket/CatalogueBasketReview';
import BasketItemDetail from './Catalogue/BasketItemDetail/BasketItemDetail';
import ManagerBaskets from './Catalogue/ManagerBaskets/ManagerBaskets';
import QuoteBaskets from './Catalogue/QuoteBaskets/QuoteBaskets';
import CatalogueItems from './Catalogue/CatalogueItems';
import Shipments from './Shipments/Shipments';
import VesselShipmentDetail from './Shipments/VesselShipmentDetail/VesselShipmentDetail';
import FlatFee from './FlatFee/FlatFee';
import FlatFeeOrders from './FlatFee/FlatFeeOrders';
import FlatFeeOrderDetail from './FlatFee/FlatFeeOrderDetail';
import FlatFeeOrderLineDetail from './FlatFee/FlatFeeOrderLineDetail';
import CatalogueSelectVessel from './Catalogue/Basket/CatalogueSelectVessel';
import CatalogueSelectItems from './Catalogue/Basket/CatalogueSelectItems';
import CatalogueBasketConfirm from './Catalogue/Basket/CatalogueBasketConfirm';
import { useAvcsoStore } from '../../../Global/Stores/avcsoStore';
import AVCSO from './AVCSO/AVCSO';
import AVCSO_SelectItems from './AVCSO/AVCSO_SelectItems';
import AVCSO_BasketReview from './AVCSO/AVCSO_BasketReview';
import AVCSO_BasketConfirm from './AVCSO/AVCSO_BasketConfirm';

const Sales = () => {
    const [avcsoState, avcsoActions] = useAvcsoStore();
    // const [state, setState] = useState(null);
    const hasLicense = avcsoActions.hasAvcsoLicense();

    return (
        <div className="salesContainer">

            {/* Overview */}
            <Route exact path={["/sales/", "/sales/quotes", "/sales/orders", "/sales/flatfee", "/sales/shipments", "/sales/catalogue2"]} component={SalesOverview} />

            {/* Catalogue */}
            < Route path="/sales/catalogue/:vesselID?" component={Catalogue} />

            {/* Catalogue Select vessel */}
            <Route exact path="/sales/catalogue/:vesselID?" component={CatalogueSelectVessel} />

            {/* Catalogue Select Items */}
            <Route exact path="/sales/catalogue/:vesselID/add/:itemID?" component={CatalogueSelectItems} />


            {/* Catalogue Basket Review */}
            <Route path="/sales/catalogue/:vesselID/basket/:itemID?" component={CatalogueBasketReview} />
            {/* <Route path="/sales/catalogue/:vesselID/basket/:itemID" render={(props) => <BasketItemDetail {...props} isReview={true} />} */}

            {/* Catalogue Basket Confirm */}
            <Route path="/sales/catalogue/:vesselID/confirm/:itemID?" component={CatalogueBasketConfirm} />




            {/* Routes */}
            {/* <Switch>
                <Route path="/sales/catalogue/:vesselID/routes/create/" component={RouteCreate} />
                <Route exact path="/sales/catalogue/:vesselID/routes/:id?" component={RouteSelect} />
            </Switch>
            <Route path="/sales/catalogue/:vesselID/routes/:id/items/:itemID?" component={RouteItems} />
            <Route path="/sales/catalogue/:vesselID/routes/:id/items/:itemID" component={BasketItemDetail} /> */}


            {/* ManagerBaskets */}
            {/* <Route exact path="/sales/catalogue/:vesselID/baskets/:id?/:itemID?" component={ManagerBaskets} /> */}

            {/* QuoteBaskets */}
            {/* <Route exact path="/sales/catalogue/:vesselID/quotes/:id?/:itemID?" component={QuoteBaskets} /> */}

            {/* Catalogue Products */}
            {/* <Route exact path="/sales/catalogue/:vesselID/products/:itemID?" component={CatalogueItems} /> */}


            {/* Flatfee */}
            {/* <Route path="/sales/flatfee/:id?" component={FlatFee} /> */}
            <Route exact path={[
                "/sales/flatfee",
                "/sales/flatfee/:id",
            ]} component={FlatFee} />
            <Route exact path={["/sales/flatfee/:id", "/sales/flatfee/:id/:orderID?"]} component={FlatFeeOrders} />
            <Route path="/sales/flatfee/:id/:orderID/:lineID?" component={FlatFeeOrderDetail} />
            <Route path="/sales/flatfee/:id/:orderID/:lineID" component={FlatFeeOrderLineDetail} />


            {/* Shipments */}
            <Route path="/sales/shipments/:id?" component={Shipments} />
            <Route path="/sales/shipments/:id/:lineID?" component={VesselShipmentDetail} />


            {/* Order History */}
            {/* <Route path="/sales/orders/:id?" component={Orders} /> */}
            <Route exact path={["/sales/orders", "/sales/orders", "/sales/orders/:id"]} component={Orders} />
            <Route path="/sales/orders/:id/:lineID?" component={VesselOrderDetail} />
            <Route path="/sales/orders/:id/:lineID" component={VesselOrderLineDetail} />

            {/* Quotations */}
            {/* <Route path="/sales/quotes/:id?" component={Quotes} /> */}
            <Route exact path={["/sales/quotes", "/sales/quotes/:id"]} component={Quotes} />
            <Route path="/sales/quotes/:id/:lineID?" component={VesselQuoteDetail} />
            <Route path="/sales/quotes/:id/:lineID" component={VesselQuoteLineDetail} />


            {/* AVCS Online */}
            <Route path="/sales/avcso" component={AVCSO} />
            {/* included a check here. This will prevent accessing AVCSO via a link in the absence of a license. without which the idea of a license is defeated. */}
            {hasLicense ? 
            <React.Fragment>
            <Route path="/sales/avcso/add/:itemID?" component={AVCSO_SelectItems} />
            <Route path="/sales/avcso/basket/:itemID?" component={AVCSO_BasketReview} />
            <Route path="/sales/avcso/confirm/:itemID?" component={AVCSO_BasketConfirm} />
            </React.Fragment>
            :null}

            <MapControl showMenu={true} menuOrientation="ver" />

        </div>
    );
};

export default Sales;
