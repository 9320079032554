import React from 'react';
import { Button, Dialog, DialogContent, DialogActions, Link } from '@material-ui/core';

const AvcsoFeaturePopup = ({ isImage, link, name }) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = (e) => {
        // console.log('handleClickOpen');
        e.preventDefault();
        setOpen(_ => true);
    };

    const handleClose = () => {
        setOpen(_ => false);
    };

    return (
        <div>
            <Link href="#" onClick={handleClickOpen}>
                {name}
            </Link>

            <Dialog open={open} onClose={handleClose} maxWidth="lg">
                <DialogContent>
                    {
                        isImage ?
                            (<img src={link} style={{ maxHeight: '80vh' }} />) :
                            (<div style={{ height: '70vh', width: '60vw' }}><iframe src={link} style={{ borderStyle: 'ridge', height: '100%', width: '100%' }}></iframe></div>)
                    }

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AvcsoFeaturePopup;
