import React, { useMemo, useEffect } from 'react';
import { Typography, Button, Modal, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import _ from 'lodash';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ConfirmButton from './QuoteConfirmation';
import { useHistory } from "react-router-dom";

const QuoteDetailFooter = ({ quote, salesActions }) => {
    let history = useHistory();

    const subTotal = _.sumBy(quote.lines, 'linePrice').toFixed(2);

    const selectedLines = quote.lines.filter(X => X.validationID === 1);
    const subTotalSelected = (_.sumBy(selectedLines, 'linePrice') || 0).toFixed(2);

    const complete = quote.lines.length === quote.lines.filter(X => (X.validationID || 0) > 0).length;
    const confirmed = quote.confirmed || false;

    
    //console.log('confirmed 1', quote.confirmed);

    useEffect(() => {


        //console.log('confirmed 2', confirmed);

        if (confirmed == true) {
            history.replace("/sales/quotes/");
        }
    }, [confirmed]);

    return useMemo(() => {



        return (
            <React.Fragment>
                <Typography variant="subtitle2" align="left" style={{ flexGrow: '1' }}>
                    {`${quote.currency} ${subTotalSelected} / ${subTotal}`}
                </Typography>

                <ButtonGroup size="small" aria-label="Small outlined button group" color="inherit" style={{ margin: '0px 10px' }}>
                    <Button onClick={() => { salesActions.validateSalesQuote(2) }}>None</Button>
                    <Button onClick={() => { salesActions.validateSalesQuote(1) }}>All</Button>
                </ButtonGroup>

                <ConfirmButton quote={quote} active={!complete} salesActions={salesActions} />
            </React.Fragment>
        );
    }, [subTotalSelected, complete, confirmed]);
};

export default QuoteDetailFooter;
