import React, { useMemo } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
    root: {
        color: '#4d4d4d',
        fontVariant: 'small-caps',
        fontSize: '1.1rem',
        letterSpacing: '4px',
        alignItems: 'center',
        padding: '7px 0px 7px 0px',
    },
});

const SummaryCardHeader = ({ title, image, justify = null }) => {
    const classes = useStyles();

    return useMemo(() => {
        return (
            <Grid container alignItems="center" justify={justify} className={classes.root}>
                <img src={image} alt="Logo" />
                <Typography variant="h6" component="h3" className={classes.root}>&nbsp;{title}</Typography>
            </Grid>
        );

    }, [title, image]);
};

export default SummaryCardHeader;
