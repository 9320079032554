
import React from "react";
import { withLeaflet, Path } from "react-leaflet";
import L from "leaflet";
import './leaflet-tracksymbol';


class TrackMarker extends Path {

    // constructor(props, context) {
    //     super(props);
    // props.leaflet.map.addEventListener("mousemove", ev => {
    //     this.panelDiv.innerHTML = `<h3><span>Lat: ${ev.latlng.lat.toFixed(4)}</span>&nbsp;<span>Lng: ${ev.latlng.lng.toFixed(4)}</span></h3>`;
    //     console.log(this.panelDiv.innerHTML);
    // });
    // props.leaflet.map.addEventListener("mousemove", this.displayInfo);

    // const map = props.leaflet.map;

    // map.on("zoomend", (ev) => {

    //     var currZoom = map.getZoom();
    //     console.log('zoomend', currZoom);
    //     console.log('this.leafletElement', this.leafletElement)
    // });

    // const { vessel, vesselPosition } = props;

    // console.log('Vessel', vessel);
    // console.log('VesselPosition', vesselPosition);
    //}

    // displayInfo = (ev) => {
    //     this.panelDiv.innerHTML = `<h4><span>Lat: ${ev.latlng.lat.toFixed(4)}</span>&nbsp;<span>Lng: ${ev.latlng.lng.toFixed(4)}</span></h4>`;
    //     // console.log(this.panelDiv.innerHTML);
    // }

    createLeafletElement(opts) {
        // console.log('opts', opts);
        const { vesselPosition } = opts;

        // if(!vesselPosition) return null;

        const { lat, long, speed, course, vesselID, vesselLocationID } = vesselPosition;

        const latlng = L.latLng(parseFloat(lat), parseFloat(long));


        //const courseRnd = Math.floor(Math.random() * 360);
        const courseMarker = course * Math.PI / 180.0; // Radians from north
        const headingMarker = course * Math.PI / 180.0; // Radians from north
        //const speed = 10.0; // In m/s
        // const course = 45.0 * Math.PI / 180.0; // Radians from north
        // const heading = 45.0 * Math.PI / 180.0; // Radians from north

        // this.contextValue = { ...opts.leaflet };

        // this.leafletElement = 

        //return new L.Circle(latlng);

        // return new L.TestSymbol(latlng, {
        //     // return new L.Circle(latlng, {
        //     trackId: vesselID,
        //     fill: true,
        //     fillColor: '#0000ff',
        //     fillOpacity: 1.0,
        //     stroke: true,
        //     color: '#000000',
        //     opacity: 1.0,
        //     weight: 1.0,
        //     minSilouetteZoom: 14,
        //     speed: speed,
        //     course: courseMarker,
        //     heading: headingMarker,
        //     gpsRefPos: [30, 30, 10, 10],
        // });

        const el = new L.TrackSymbol(latlng, {
            //const el = new L.Circle(latlng, {
            //trackId: vesselID,
            //trackId: `VP${vesselID}`,
            trackId: `VP${vesselLocationID}`,
            //trackId: vesselLocationID,
            fill: true,
            fillColor: '#0000ff',
            fillOpacity: 1.0,
            stroke: true,
            color: '#000000',
            opacity: 1.0,
            weight: 1.0,
            minSilouetteZoom: 14,
            speed: speed,
            course: courseMarker,
            heading: headingMarker,
            gpsRefPos: [30, 30, 10, 10],
        });

        // this.leafletElement = el;
        return el;
    }

    // updateLeafletElement(fromProps, toProps) {

    // }
    // componentDidMount() {
    //     //     // const { map } = this.props.leaflet;

    //     //     // console.log('map', map);

    //     //     // this.leafletElement.addTo(map);
    //     //     // map.addEventListener("mousemove", this.displayInfo);
    //     //     // map.on("zoomend", (ev) => {

    //     //     //     var currZoom = map.getZoom();
    //     //     //     console.log('zoomend', currZoom);
    //     //     // });
    // }

    // componentWillUnmount() {
    //     //this.props.leaflet.map.removeEventListener("mousemove", this.displayInfo);

    //     console.log("TrackMarker Unmount");
    // }


}

export default withLeaflet(TrackMarker);