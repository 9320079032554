import React, { useState, useMemo } from 'react';
import { Popup } from 'react-leaflet';
import { List, ListItem, ListItemText, Link, ListItemIcon, Checkbox } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

const CatalogueMapPopup = ({ popupInfo, selectedItemID, selectedItemIDs, canAddItems = false }) => {
    // const [basketState] = useBasketStore();
    const [closeKey, setCloseKey] = useState(0);

    const showPopup = (popupInfo.position) && (closeKey !== popupInfo.key);

    return useMemo(() => {

        // console.log('popupInfo.lines', popupInfo.lines);
        // console.log('selectedItemIDs 2', selectedItemIDs);

        const closePopup = () => {
            setCloseKey(popupInfo.key);
        }

        return showPopup === true ? (
            <Popup
                key={popupInfo.key}
                position={popupInfo.position}
            >
                <List dense={true} disablePadding style={{ border: '1px solid gray' }}>
                    {
                        popupInfo.lines.map(X => <PopupLine
                            key={X.itemID}
                            line={X}
                            focused={X.itemID === selectedItemID}
                            selected={selectedItemIDs && selectedItemIDs.includes(X.itemID)}
                            closePopup={closePopup}
                            canAddItems={canAddItems}
                        />)
                    }
                </List>
            </Popup>
        ) : null;

    }, [showPopup, popupInfo.key, selectedItemIDs]);
};


const PopupLine = ({ line, focused, selected, closePopup, canAddItems }) => {

    // console.log('selected', selected);
    return (
        <Link
            key={line.itemID}
            className="ListItemLink"
            component={RouterLink}
            to={`${line.itemID}`}
            color='inherit'
            style={{ textDecoration: 'none', color: 'inherit' }}
            onClick={closePopup}
        >
            <ListItem button disableRipple dense divider selected={focused} style={{ backgroundColor: `${selected ? '#9DFF90' : ''}` }}>
                {
                    canAddItems === true ? (<ListItemIcon>
                        <Checkbox
                            color="primary"
                            edge="start"
                            checked={true}
                            disableRipple
                        />
                    </ListItemIcon>) : null
                }
                <ListItemText primary={`${line.itemNumber} - ${line.itemTitle}`} />
            </ListItem>
        </Link>
    );
}

export default CatalogueMapPopup;
