import React, { useMemo } from 'react';
import CT_Logo from "./../../../../src/Images/CT-logo-transparant.png";
import { makeStyles } from '@material-ui/core';
import { withRouter } from "react-router";


const useStyles = makeStyles({
    logo: {
        height: '42px',
        '&:hover': {
            cursor: 'pointer'
        }
    }
});

const NavBarLogo = ({ history }) => {
    const classes = useStyles();

    return useMemo(() => {

        const goHome = () => {
            history.push("/home");
        }

        return (<img src={CT_Logo} className={classes.logo} alt="logo" onClick={goHome} />);

    }, []);
};

export default withRouter(NavBarLogo);
