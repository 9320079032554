import React, { useMemo, useState } from 'react';
import { FeatureGroup } from 'react-leaflet';
import BaseRoute from '../Base/BaseRoute';

const GeneralRoutesLayer = ({ Routes }) => {

    return useMemo(() => {

        // console.log('GeneralRoutesLayer', Routes);

        if (!Routes || Routes.length === 0)
            return null;

        return (
            <FeatureGroup>
                {
                    Routes.length > 0 ? Routes.map(R => (<BaseRoute key={R.sourceRouteID} positions={R.routePoints} />)) : null
                }
            </FeatureGroup>
        );

    }, [Routes]);
};

export default GeneralRoutesLayer;
