import React, { useEffect, useState, useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';
import { WMSTileLayer, Marker, Popup, Tooltip, useLeaflet } from 'react-leaflet';
// import { DisplayParameters } from './DisplayParams';
import { useMapStore } from '../../../../Global/Stores/mapStore';
import _ from 'lodash';
import { useUserStore } from '../../../../Global/Stores/userStore';
// import http from '../../../../Global/Services/httpService';
import axios from 'axios';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core';
import MapAVCSOLogo from '../../Markers/MapAVCSOLogo';
//import MapAVCSOTrademark from '../../Markers/MapAVCSOTrademark';
// import AVCS_TRADEMARK_LOGO from './../../../Images/AVCS/ADM_TM_rgb.png';
import AVCS_TRADEMARK_LOGO from './../../../../Images/AVCS/ADM_TM.png';
import InfoIcon from '@material-ui/icons/Info';

// const { BaseLayer, Overlay } = LayersControl;

//const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI0MTk0MmFjYy0zOTg4LTRhZDctYmQ4ZC0zNzViMTI0OGVhMjQiLCJpYXQiOiIxNTY2Mjk4MDUyIiwic3ViIjoiQjJCQ2hhcnRUcmFja0hLIiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvbmFtZSI6IkIyQkNoYXJ0VHJhY2tISyIsInVzZXJpZCI6IjM3NzE5Iiwic2VjcmV0IjoiMTAwMDpBVzVuR0t5ZGszcS9lQ21wSmh0THFzS00rY0orbmJOdjpjWXROZVRONXE5Mmhzall3L0tVVkRONW1yVDcwWkNlbiIsImVuZHBvaW50L1N1Ym1pdE9yZGVyIjoidHJ1ZSIsImVuZHBvaW50L0dldE9yZGVyIjoidHJ1ZSIsImVuZHBvaW50L0dldEhvbGRpbmdzIjoidHJ1ZSIsImVuZHBvaW50L0dldCBUb2tlbiB3aXRoIExvZ2luIjoidHJ1ZSIsImVuZHBvaW50L1JlbmV3IFRva2VuIjoidHJ1ZSIsImVuZHBvaW50L0dldCBBVkNTIFBlcm1pdHMiOiJ0cnVlIiwiZW5kcG9pbnQvR2V0IGUtTlAgUGVybWl0cyI6InRydWUiLCJlbmRwb2ludC9SZWdpc3RlciBBRU5QIFZpZXdlciI6InRydWUiLCJlbmRwb2ludC9BVkNTIE9ubGluZSI6InRydWUiLCJlbmRwb2ludC9HZXQgQ2F0YWxvZ3VlcyI6InRydWUiLCJlbmRwb2ludC9HZXQgQVJDUyBQZXJtaXRzIjoidHJ1ZSIsImVuZHBvaW50L0dldCBlLU5QIE5NcyI6InRydWUiLCJlbmRwb2ludC9nZXRBRFBLZXlzIjoidHJ1ZSIsImVuZHBvaW50L2dldEFWQ1NXZWVrbHlTY2hlZHVsZUEiOiJ0cnVlIiwiZXhwIjoxNTY2MzAxNjUyLCJpc3MiOiJVS0hPX0ZNIiwiYXVkIjoiVUtIT19GTSJ9.rt39SlbAICt56ld62RwUakl8jmyDMTclIcRXwEb83mA";

// const licenseid = "32079";
// const token = "sWd-OldnfroaQz3jIhQtZrx5j3uDnK-X_JXEykv_f8CQm_dvFANwQDOsShk2PJfj";
// const userref = "32079";
// const version = "1.3.0";
// const baseAddress = "https://avcs-preprod.admiralty.co.uk/server/rest/services/AVCS/MapServer/exts/MaritimeChartService/WMSServer";

const Display_Params = {
    "ECDISParameters": {
        "version": "10.6.1",
        "DynamicParameters": {
            "Parameter": [
                // { "name": "DisplayCategory", "value": "1,2,4" },
                // { "name": "ColorScheme", "value": 5 },
                // { "name": "DisplayFrames", "value": 1 },
                // { "name": "DisplayCategory", "value": "1" },
            ]
        }
    }
}



const AvcsoLayer = () => {
    const [mapState, mapActions] = useMapStore();
    const [, userActions] = useUserStore();

    // const test = DisplayParameters.DisplayParameters.ECDISParameters.DynamicParameters;
    // console.log('AvcsoLayer');

    const hasAVCSO = userActions.hasAppModuleID(7, false);
    const { license, selectionMode, EULA } = mapState.AVCSO;

    const isFeatureInfoActive = mapActions.isFeatureInfoActive();

    useEffect(() => {

        const generateToken = () => {

            if (EULA === null) {
                mapActions.getAvcsoEULA(license.expiration);
            }

            // console.log('generateToken');
            if (hasAVCSO === true && EULA === true) {

                mapActions.getAvcsoToken(license.expiration);
            }
        }

        generateToken();
        const interval = setInterval(generateToken, 60 * 1000 * 1); // 1 minute

        return () => {
            // console.log('clearInterval');
            clearInterval(interval);
        };

    }, [hasAVCSO, license, EULA]);


    // console.log('EULA', EULA);
    if (EULA === null) {
        return null;
    }

    if (EULA === false) {
        return (<AVCSO_Agreement />);
    }

    // if (EULA === null) {
    //     return (<AVCSO_Agreement />);
    // }
    // else if (EULA === false) {
    //     return null;
    // }

    const pars = mapState.AVCSO.parameter.filter(X => X.value !== null);
    const groups = mapState.AVCSO.parameterGroup;

    const layers = _.get(mapState.AVCSO.layers, "value", "");


    //console.log('layers', layers);

    const display = { ...Display_Params };
    display.ECDISParameters.DynamicParameters.Parameter = pars;
    display.ECDISParameters.DynamicParameters.ParameterGroup = groups;


    return (
        <WMSTileLayerExtended
            license={license}
            layers={layers}
            Display_Params={JSON.stringify(display)}

            mapActions={mapActions}
            selectionMode={selectionMode}
            featureInfoActive={isFeatureInfoActive}
        />
    );
};

const WMSTileLayerExtended = ({ license, layers, Display_Params, selectionMode, featureInfoActive, mapActions }) => {

    const { map } = useLeaflet();

    const [Feature, setFeature] = useState({
        // position: {
        //     lat: 45.47554027158593,
        //     lng: -1.4501953125000002
        // }
    })

    // console.log('Display_Params', JSON.parseS(Display_Params))

    //console.table('WMSTileLayerExtended', license)

    useEffect(() => {

        const getFeatureInfo = event => {

            // console.log('getFeatureInfo', selectionMode);
            // if (selectionMode !== 1) {
            //     // console.log('return');
            //     return;
            // }


            if (!featureInfoActive) {
                return;
            }

            const ev = event;
            const bounds = ev.target.getBounds();
            const size = ev.target.getSize();

            const layerString = (layers || [21, 20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0]).join(',');
            const { x, y } = ev.containerPoint;

            const sw = map.options.crs.project(bounds._southWest);
            const ne = map.options.crs.project(bounds._northEast);
            const bbox = sw.x + "," + sw.y + "," + ne.x + "," + ne.y; // works with both EPSG4326, EPSG3857
            const code = map.options.crs.code;

            const infoFormat = 'text/html';
            //const infoFormat = 'application/json';
            //const openUrl = `${license.url}?SERVICE=WMS&REQUEST=GetFeatureInfo&FORMAT=image%2Fpng&TRANSPARENT=true&VERSION=1.3.0&WIDTH=${size.x}&HEIGHT=${size.y}&CRS=${code}&BBOX=${bbox}&info_format=${infoFormat}&LAYERS=${layerString}&query_layers=${layerString}&i=${x}&j=${y}&Userid=${license.userId}&userRef=${license.userId}&token=${license.token}`;

            // console.log('license.token', license.token);

            //console.log('ev', ev);
            // console.log('openUrl', openUrl);
            //window.open(openUrl, "_blank");  //to open new page

            axios.get(license.url, {
                params: {
                    SERVICE: 'WMS',
                    REQUEST: 'GetFeatureInfo',
                    FORMAT: 'image/png',
                    TRANSPARENT: true,
                    VERSION: '1.3.0',
                    WIDTH: size.x,
                    HEIGHT: size.y,
                    CRS: code,
                    BBOX: bbox,
                    // info_format: 'text/html',
                    //info_format: 'text/xml',
                    info_format: 'application/json',
                    LAYERS: layerString,
                    query_layers: layerString,
                    i: x,
                    j: y,
                    Userid: license.userId,
                    userRef: license.userRef,
                    token: license.token,
                    f: 'pjson'
                }
            })
                .then(res => {
                    const data = res.data.features;

                    const featureInfo = {
                        position: ev.latlng,
                        features: data,
                    }

                    //console.log('JSON', data);

                    setFeature(() => {
                        // console.log('setFeature')
                        return ({
                            position: ev.latlng,
                            features: data,
                        })
                    });

                    mapActions.setAvcsoFeatureInfo(featureInfo);

                })
        }


        map.on("click", getFeatureInfo);

        return () => {
            map.off("click", getFeatureInfo);
        };

    }, [license, layers, Display_Params, selectionMode, featureInfoActive, setFeature, mapActions]);

    const trademark = ReactDOMServer.renderToString(<img className="leaflet-avcso-trademark" src={AVCS_TRADEMARK_LOGO} style={{bottom: '-30px!important'}} />)
    return (
        <React.Fragment>
            <WMSTileLayer

                url={license.url}
                token={license.token}
                userId={license.userId}
                userRef={license.userRef}
                version={license.version}
                format={license.format}

                layers={layers}
                Display_Params={Display_Params}

                uppercase={true}
                transparent={true}

                //attribution='&amp;copy <a href="http://osm.org/copyright">AVCSO</a> contributors' 
                attribution={trademark}
            />

            <AVCSO_FeatureMarker Feature={Feature} />

            <MapAVCSOLogo position="bottomleft" />
            {/* <MapAVCSOTrademark position="bottomright" /> */}
        </React.Fragment>

    );
}


const AVCSO_FeatureMarker = ({ Feature }) => {

    // const [, mapActions] = useMapStore();
    const { position, features } = Feature;

    // const features = data.features;

    // console.log('AVCSO_FeatureMarker', Feature);

    return useMemo(() => {

        //console.log('AVCSO_FeatureMarker', position);
        if (!position || features.length === 0) {
            return null;
        }
        // mapActions.toggleAvcsoSelectionMode();

        //const dom = new DOMParser().parseFromString(Feature.data, "text/xml");
        // console.log('AVCSO_FeatureMarker', dom);
        // const content = dom.getElementsByTagName("Feature").map(X => <div>{X.description}</div>);

        return (
            <Marker position={[position.lat, position.lng]}>
                {/* <Tooltip>Tooltip</Tooltip> */}
                {/* <Popup className="AVCSO-popup">
                    <List className="AVCSO-popup-content">
                        {
                            features.map(F => (<FeatureLine key={F.rcid} Feature={F} />))
                        }
                    </List>
                </Popup> */}
            </Marker>
        );

    }, [position, features]);
}

// const FeatureLine = ({ Feature }) => {
//     // const [, mapActions] = useMapStore();

//     const { dataset, cscl, rcid, code, acronym, description, lnam, geometryType, Attributes } = Feature;

//     // const { acronym, description, value, mappedValue } = Attributes[0];

//     const handleFeatureClick = () => {
//         console.log('Feature', Feature.Attributes);
//         // mapActions.setAvcsoFeatureInfo(Feature);
//     }

//     return (
//         <ListItem button disableRipple dense divider selected={false} onClick={handleFeatureClick}>
//             <ListItemText primary={`[${geometryType}] ${description}`} />

//             <ListItemSecondaryAction>
//                 <IconButton edge="end" aria-label="info">
//                     <InfoIcon />
//                 </IconButton>
//             </ListItemSecondaryAction>
//         </ListItem>
//     );

// }


const AVCSO_Agreement = () => {

    const [, mapActions] = useMapStore();

    const [open, setOpen] = useState(true);

    const handleAggreement = (val) => {
        // console.log('handleAggreement', val);

        if (val === true) {
            mapActions.setAvcsoEULA(val);
        }
        else {
            //mapActions.toggleAvcsoSelectionMode();
            setOpen(() => false);
        }
    }

    return (
        <Dialog
            open={open}
            scroll="paper"
            // onClose={handleClose}
            // TransitionComponent={Transition}
            aria-labelledby="form-dialog-title">
            {/* <DialogTitle id="form-dialog-title">AVCS Online - EULA</DialogTitle> */}
            <DialogContent
                style={{ padding: '0px' }}>
                <iframe
                    style={{ height: '80vh', width: '600px' }}
                    src="http://www.charttrack.com/files/AVCSO/eula.html"
                />
                {/* <DialogContentText variant="subtitle2">

                    </DialogContentText> */}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => handleAggreement(true)} color="primary">Accept</Button>
                <Button variant="outlined" onClick={() => handleAggreement(false)} color="primary">Reject</Button>
            </DialogActions>
        </Dialog>
    );
}


export default AvcsoLayer;
