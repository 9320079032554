import React, { createContext, useContext, useState, useEffect } from 'react';
import http from './../Services/httpService';
import _ from 'lodash';


const initialState = {
    productGroup: 3,
    productItemGroups: [8, 10, 11, 12, 13, 14],
    licenseLines: [],

    avcsoItemIDs: [],
    avcsoLines: [],

    selectedItemIDs: [],

    // basketItemIDs: [],
    basketLines: [],

    months: 12,
    reference: "",

};


export const AvcsoContext = createContext();

export const AvcsoProvider = ({ children }) => {
    const [state, setState] = useState(initialState);


    const loadData = () => {

        http.get(`/api/sales/avcso/licenses`)
            .then(response => {
                const data = response.data;

                setState(prev => {
                    const newState = {
                        ...prev,
                        ...data
                    };

                    return newState;
                });
            })
            .catch(error => {
                //alert(error);
            });

    }

    const clearData = () => {
        setState((prev) => {
            const newState = { ...initialState };
            newState.avcsoItemIDs = prev.avcsoItemIDs;
            newState.licenseLines = prev.licenseLines;
            return newState;
        })
    }

    const isLoaded = () => {
        return state.avcsoItemIDs.length > 0;
    }

    const hasAvcsoLicense = () => {
        return isLoaded && state.licenseLines.length > 0;
    }

    const toggleSelectedItem = (itemID) => {

        setState(prev => {
            const newState = { ...prev };
            const isSelected = prev.selectedItemIDs.includes(itemID);
            newState.selectedItemIDs = isSelected ? prev.selectedItemIDs.filter(X => X !== itemID) : [...prev.selectedItemIDs, itemID];
            return newState;
        })
    }

    const setProductItemGroups = (productItemGroups) => {

        setState(prev => {
            const newState = { ...prev };
            newState.productItemGroups = [...productItemGroups];
            return newState;
        })
    }

    const setAvcsoLines = (lines) => {

        setState(prev => {
            const newState = { ...prev };
            newState.avcsoLines = [...lines];
            return newState;
        })
    }

    const addselectedToBasket = () => {

        setState(prev => {
            const newState = { ...prev };

            const basketItemIDs = prev.basketLines.map(X => X.itemID);
            const addItemIDs = prev.selectedItemIDs.filter(X => !basketItemIDs.includes(X));

            const newItemIDs = [...basketItemIDs, ...addItemIDs];

            const newBasketLines = prev.avcsoLines.filter(X => newItemIDs.includes(X.itemID));
            // console.log('newBasketLines', newBasketLines);
            newState.basketLines = newBasketLines;
            return newState;
        })
    }

    const removeBasketItems = (itemIDs) => {
        setState(prev => {
            const newState = { ...prev };
            newState.basketLines = newState.basketLines.filter(X => !itemIDs.includes(X.itemID));
            return { ...newState }
        });
    }

    const getBasketPrices = () => {

        const vesselID = 0;
        const itemIDs = state.basketLines.filter(L => !Boolean(L.itemPrice)).map(L => L.itemID);
        // console.log("getBasketPrices", itemIDs)

        if (itemIDs.length === 0)
            return null;

        http.post(`/api/catalogue/${vesselID}/prices`, {
            vesselID: vesselID,
            itemIDs: itemIDs,
            avcso: true
        })
            .then(result => {
                const newItemPrices = _.orderBy(result.data, ['itemID', 'months'], ['asc', 'desc']);

                setState(prev => {
                    const months = prev.months;
                    const BL = [...prev.basketLines];

                    BL
                        .filter(L => !Boolean(L.itemPrice))
                        .forEach(L => {
                            const prices = newItemPrices.filter(I => L.itemID === I.itemID);
                            L.itemDurations = prices;
                            L.itemPrice = prices.filter(I => I.months === months)[0] || prices[0];
                        });

                    //console.log('BasketLinePrices', BL);

                    return {
                        ...prev,
                        basketLines: BL,
                    };
                });
            })
            .catch(error => {
                //alert(error);
            });
    }

    const hasBasketPrices = () => {
        const lineCount = state.basketLines.length;
        const validLines = state.basketLines.filter(X => Boolean(X.itemPrice));
        return lineCount === validLines.length;
    }


    const setBasketDuration = (months, itemID) => {

        setState(prev => {
            const newState = { ...prev };
            const id = itemID || 0;

            if (id === 0) {
                // Set all durations
                newState.months = months;
                newState.basketLines
                    .filter(X => Boolean(X.itemDurations))
                    .forEach(L => {
                        L.itemPrice = L.itemDurations.filter(D => D.months === months)[0] || L.itemPrice || L.itemDurations[0];
                    });
            }
            else {
                // Set line duration
                const line = newState.basketLines.find(I => I.itemID === itemID);
                line.itemPrice = line.itemDurations.filter(D => D.months === months)[0] || line.itemPrice || line.itemDurations[0];
            }
            return newState;
        });
    }


    const getTotalPriceLocal = () => {

        const result = {
            currencyID: 0,
            totalPrice: 0,
            valid: false,
        };
        const priceLines = state.basketLines.filter(X => X.itemPrice);

        if (priceLines.length === 0 || priceLines.length != state.basketLines.length)
            return result;

        // const currencyPrice = _.sum(state.basketLines).toFixed(2);


        const currencyPrice = _.sumBy(priceLines, (L) => L.itemPrice.price).toFixed(2);

        result.currencyID = state.basketLines[0].itemPrice.currencyID;
        result.totalPrice = currencyPrice;
        result.valid = true;

        // console.log('getTotalPriceLocal', result);

        return result;
    }    

    const setReference = (reference) => {
        setState(prev => {
            return {
                ...prev,
                reference: reference
            }
        });
    }

    const orderBasket = () => {

        const postData = {
            vesselID: 0,
            sourceRouteIDs: [],
            salesQuoteIDs: [],
            reference: state.reference,
            lines: state.basketLines.map(X => ({
                itemID: X.itemID,
                quantity: X.quantity,
                months: _.get(X, "itemPrice.months", 12),
            })),
            avcso: true,
        };

        http.post("/api/sales/orders/create", postData)
            .then((result) => {
                setState(prev => {
                    return { ...prev, ordered: true }
                });
            })
            .catch(error => {
                //alert(error)
            });
    }

    const actions = {
        loadData: loadData,
        clearData: clearData,

        isLoaded: isLoaded,
        hasAvcsoLicense: hasAvcsoLicense,

        toggleSelectedItem: toggleSelectedItem,

        setProductItemGroups: setProductItemGroups,
        setAvcsoLines: setAvcsoLines,

        addselectedToBasket: addselectedToBasket,
        removeBasketItems: removeBasketItems,

        getBasketPrices: getBasketPrices,
        hasBasketPrices: hasBasketPrices,

        setBasketDuration: setBasketDuration,
        setReference: setReference,

        getTotalPriceLocal, getTotalPriceLocal,

        orderBasket: orderBasket,
    };

    return (
        <AvcsoContext.Provider value={[state, actions]}>
            {children}
        </AvcsoContext.Provider >
    );
}

export const useAvcsoStore = () => useContext(AvcsoContext);


