import React from 'react';
import { withRouter } from 'react-router-dom';
import ArrowBack from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/SaveAlt';
import { IconButton, Divider, ListItemText, FormControlLabel } from '@material-ui/core';
import MailTo, { MailHoldingDetailTo } from '../../../Misc/MailTo';
// import OrderStatus from '../../../Widgets/OrderStatus';
import { HoldingLinesStatus } from '../../../../Widgets/ProductGroupStatus';
import { DownloadHoldings } from '../../../Misc/DownloadFile';

const FolioItemDetailHeader = ({ managerID, folioID, holdingDetail, history }) => {

    if (!holdingDetail) return null;

    
    const handleBack = () => {
        //const url = history.location.pathname;
        //const uurl = url.substring(-1, url.lastIndexOf("/")); // remove Vessel ID
        //const popLink = uurl.substring(-1, uurl.lastIndexOf("/")); // remove /details
        history.push(`/folios/status/${managerID}/${folioID}`);
    }

    return (
        <React.Fragment>
            {/* <div className="ApprovalDetailHeader"> */}
            <IconButton onClick={handleBack}>
                <ArrowBack />
                <ListItemText style={{marginLeft: "10px"}} className="flexFill" primary={holdingDetail.vesselName}/>
            </IconButton>

            {/* </ListItemText> */}

            {/* <HoldingLinesStatus lines={holdingDetail.vessel.vesselItems} /> */}
            {/* <div>{holdingDetail.vessel.vesselItems.length}</div> */}


            {/* <MailHoldingDetailTo vessel={holdingDetail.vessel} />

            <DownloadHoldings vesselID={holdingDetail.vessel.vesselID} /> */}
            {/* </div> */}
            <Divider />
        </React.Fragment>
    );
};

export default withRouter(FolioItemDetailHeader);
