import React, { useEffect, useMemo } from 'react';
import { Typography, Table, TableHead, TableRow, TableCell, TableBody, Dialog, Grid } from '@material-ui/core';
import Loading from '../../Misc/Loading';
import { useFleetStore } from '../../../../Global/Stores/fleetStore';
import CloseButtonLink from '../../../Widgets/Buttons/CloseButtonLink';
import _ from 'lodash';
import ChipStatus, { ChipStatusValid, ChipStatusWarning, ChipStatusIssue } from '../../../Widgets/Status/ChipStatus';
import ContentColumn from '../../Custom/ContentColumn';
import FloatingMenuButton from '../../Navigation/FloatingMenuButton';

const FleetStatusDetail = ({ match }) => {

    const [fleetState, fleetActions] = useFleetStore();
    const vesselID = +match.params.vesselID;

    const [openDialog, setOpenDialog] = React.useState(true);
    
      const closeDialog = () => {
        setOpenDialog(false);
      };



    useEffect(
        () => {
            fleetActions.getVesselHoldings(vesselID);

            // return () => {
            //     fleetActions.clearVesselHoldings();
            // }
        },
        [vesselID]
    );

    const detail = fleetState.holdingDetail;
    const loaded = _.get(fleetState, 'holdingDetail.vessel.vesselID', -1) === vesselID;

    // console.log('detail.vessel', detail.vessel);
    return (
        <Dialog open={openDialog} onClose={closeDialog} >
            <ContentColumn loaded={loaded}>
                {
                    loaded ? (
                        <React.Fragment>
                            <div className="contentColumnHeader">
                                <Grid container spacing={0}>
                                    <Grid>
                                        <CloseButtonLink to={`/fleet/status/`} />
                                    </Grid>
                                    <Grid>
                                        <Typography className="flexFill" variant="h6">{detail.vessel.vesselName}</Typography>
                                    </Grid>
                                </Grid>
                                
                                
                            </div>

                            <div className="contentColumnBody">
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Product</TableCell>
                                            <TableCell align="center" style={{ width: '1px' }}>OK</TableCell>
                                            <TableCell align="center" style={{ width: '1px' }}>Item Issues</TableCell>
                                            <TableCell align="center" style={{ width: '1px' }}>Update Issues</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            _.sortBy(detail.productGroups, 'sortIndex')
                                                .map((P) => {
                                                    return (
                                                        <VesselProductGroupStatus
                                                            key={P.productGroupID}
                                                            productGroup={P}
                                                            vesselItems={detail.vessel.vesselItems.filter(V => P.productGroupID === V.item.productGroupID)} />
                                                    );
                                                })
                                        }
                                    </TableBody>
                                </Table>
                            </div>
                        </React.Fragment>
                    ) : null
                }
            </ContentColumn>
            <FloatingMenuButton />
        </Dialog>
    );
};


const VesselProductGroupStatus = ({ productGroup, vesselItems }) => {

    //console.log('vesselItems', vesselItems)

    return useMemo(() => {
        if (vesselItems.length === 0)
            return null;

        const totalQuantity = +_.sumBy(vesselItems, "quantity");
        const itemWarning = +_.sumBy(vesselItems, "itemWarning");
        const itemIssue = +_.sumBy(vesselItems, "itemIssue");
        const updateWarning = +_.sumBy(vesselItems, "updateWarning");
        const updateIssue = +_.sumBy(vesselItems, "updateIssue");

        const totalValid = totalQuantity - itemWarning - itemIssue - updateWarning - updateIssue;

        // console.log('Status', {
        //     totalQuantity,
        //     itemWarning,
        //     itemIssue,
        //     updateWarning,
        //     updateIssue,
        // })

        return (
            <TableRow>
                <TableCell>{productGroup.caption}</TableCell>
                <TableCell align="center" style={{ width: '1px' }}>
                    <ChipStatusValid number={totalValid} />
                </TableCell>
                <TableCell align="center" style={{ width: '1px' }}>
                    <ChipStatusWarning number={itemWarning} />
                    <ChipStatusIssue number={itemIssue} />
                </TableCell>
                <TableCell align="center" style={{ width: '1px' }}>
                    <ChipStatusWarning number={updateWarning} />
                    <ChipStatusIssue number={updateIssue} />
                </TableCell>
            </TableRow>
        );

    }, [vesselItems.length])


}

export default FleetStatusDetail;
