import React from 'react'
import { Link, Route } from "react-router-dom";

// Matrial UI support files
import { ListItem, ListItemText, Divider, Card } from "@material-ui/core";

// Global Support Files
import Menu from '../../Global/Data/Menu';

// Content Files
import NewsList from './News/NewsList';

//Alert/Status Message Dialog
import StatusMessage from './StatusMessage';

// CSS Files
import homeStyling from './CSS/HomeStyles.module.css';

/*
NOTES:

TODOs:
Modify Popup message to a dynamic load from a remote/seperate modifiable file

*/
// const showStatus = 0;
// Construct popup Message
const msgTitle = "Development Notice!!!";
const msgBody = "Development is ongoing and you may every now and then see weird stuff or the page reloading. Do not panic! smile! Development is in progress!!! :) <a href='#' > Click Me </a>";
const msgBtnTitle = "Learn More";
const msgBtbLink = "/help";


const MyHome = () => {
    return(
        <React.Fragment>
           {/* (window.location.pathname+window.location.search == "/home?mv") ? <StatusMessage messageTitle={msgTitle} messageBody={msgBody} actionValue={msgBtnTitle} actionLink={msgBtbLink} showActions={false} showTitle={true}  /> : null */}
                <div className={homeStyling.newsHeader}>
                <h1>News &amp; Announcements</h1>
                <Divider />
                </div>
                <div className={homeStyling.newsBody}>
                    <Card raised style={{padding: 10}}>
                        {/* <NewsList /> */}
                        <Route path="/home" component={NewsList} />
                    </Card>
                </div>
                <div className={homeStyling.homeMenuHeader} >
                    <br />
                    <h1>What Do You Want To Do?</h1>
                </div>
                <div className={homeStyling.homeMenuBody}>
                <br /><br /><br />

                {Menu.map(mainMenu => {
                if (mainMenu.subItems){
                return (<div  key={mainMenu.id}>
                        <Card raised>
                            <Link  to={mainMenu.to} className={homeStyling.noFormat}>
                                <ListItem>
                                    <ListItemText primary={mainMenu.label} secondary={mainMenu.shortDescription} />
                                </ListItem>
                            </Link>
                        </Card>
                        <Divider />
                    </div>);
                }
                    
                })}
                <br /><br /><br /><br /><br /><br /><br /><br /><br />
            </div>
        </React.Fragment>
    )
}

export default MyHome